import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Card } from "@salesforce/design-system-react";

import NavigationTree from "../../components/navigation-tree/NavigationTree";
import useAuthContext from "../../context/useAuthContext";
import { TreeItemKeys, TreeSectionKeys } from "../../components/navigation-tree/types";
import { SETUP, TREE_DATA_ITEMS } from "../../components/navigation-tree/constants";
import PsUserList from "../../components/ps-user-list/PsUserList";
import PsUser from "../../components/ps-user/PsUser";
import PsAccount from "../../components/ps-account/PsAccount";
import PsActionList from "../../components/ps-action-list/PsActionList";
import PsAction from "../../components/ps-action/PsAction";
import { EventType } from "../types";
import PsNavigationHeader from "../../components/ps-navigation-header/PsNavigationHeader";
import PsErrorBoundary from "../../components/ps-error-boundary/PsErrorBoundary";
import PsSetupStatus from "../../components/ps-setup-status/PsSetupStatus";

const treeSectionList: TreeSectionKeys[] = ["setupOverview"];
const defaultSection = treeSectionList[0]; // "setupOverview"
const defaultObj = TREE_DATA_ITEMS.setup.object; // "setup"
const defaultId = SETUP.users.id; // "users" Tree static data setup for setupOverview section (use id to change view in right side)
const defaultSelected = `${defaultSection}_${defaultObj}_${defaultId}`;

const Setup = () => {
    // state
    const [selected, setSelected] = useState<string>(defaultSelected);
    const [selectedObject, setSelectedObject] = useState<TreeSectionKeys | TreeItemKeys>(defaultObj);
    const [selectedId, setSelectedId] = useState<string>(defaultId);
    const [parentId, setParentId] = useState<string>("");
    const [propagateEvent, setPropagateEvent] = useState<EventType | undefined>();

    const { handleLogout, account } = useAuthContext();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        syncStateWithUrl(searchParams);
    }, [searchParams]);

    function syncStateWithUrl(searchParams: URLSearchParams) {
        const urlSelected = searchParams.get("selected") || defaultSelected;
        const urlParentId = searchParams.get("parentId") || "";

        if (urlSelected !== selected) {
            const [_section, obj, id] = urlSelected.split("_");
            setSelected(urlSelected);
            setSelectedId(id);
            setSelectedObject(obj as any);
        }
        if (urlParentId !== parentId) setParentId(urlParentId);
    }

    function handleEventRouter(event: EventType) {
        switch (event.type) {
            case "tab":
                // TODO: change event.tab to event.path
                navigate("/" + event.tab);
                break;
            case "logout":
                handleLogout();
                break;
            case "sync":
                setPropagateEvent(event);
                break;
            case "record":
                searchParams.delete("parentId");
                const { id: idR, obj: objR, parentId: parentIdR } = event;
                const sectionR = defaultSection; // defaultSection because is just one section
                if (event.action === "delete" || event.action === "cancel") {
                    searchParams.set("selected", `${sectionR}_${defaultObj}_${parentIdR}`); // default object because is just one section for now
                } else {
                    searchParams.set("selected", `${sectionR}_${objR}_${idR}`);
                }
                setSearchParams(searchParams);
                break;
            case "navigation":
                const { id, obj, parentId } = event;
                const section = event.section || defaultSection; // defaultSection because is just one section

                if (event.source === "grid" && !id) {
                    searchParams.set("parentId", parentId); // if event.id is false and source is grid that mean is new record
                } else {
                    searchParams.delete("parentId");
                    searchParams.set("selected", `${section}_${obj}_${id}`);
                }
                setSearchParams(searchParams);
                break;

            default:
                setPropagateEvent(event);
                break;
        }
    }

    const renderUserList = (pId: string) =>
        !pId ? (
            <PsUserList parentId={defaultId} childToParent={handleEventRouter} propagateEvent={propagateEvent} />
        ) : (
            <PsUser recordId={""} parentId={pId} childToParent={handleEventRouter} propagateEvent={propagateEvent} /> // New user Record
        );

    return (
        <div className="Store">
            <PsNavigationHeader childToParent={handleEventRouter} />

            <div className="tab-content slds-p-around_medium">
                {/* <!-- navigation tree --> */}
                <Card className="slds-m-right_medium card-height-full" heading={<span className="card-main-title-lh32 slds-card__header-title">Setup</span>}>
                    <NavigationTree showSearch={false} pxOffsetHV={194} sectionList={treeSectionList} bubbleEvent={handleEventRouter} selected={[selected]} propagateEvent={propagateEvent} />
                </Card>

                <div className={"right"}>
                    {/* <PsSetupStatus title="Setup" tagLine="Configure your settings" /> */}
                    <div className={"right slds-scrollable"}>
                        {/* <!-- Overview tree level--> */}
                        {selectedObject === defaultSection && renderUserList(parentId)}
                        {/* <!-- Setup tree level--> */}
                        {selectedObject === defaultObj && (
                            <>
                                {selectedId === defaultId && renderUserList(parentId)}
                                {selectedId === SETUP.account.id && account?.id && <PsAccount recordId={account.id} propagateEvent={propagateEvent} />}
                                {selectedId === SETUP.actions.id && <PsActionList childToParent={handleEventRouter} propagateEvent={propagateEvent} />}

                                {/* {selectedId === SETUP.apiKeys.id && (
                                <>
                                    {!parentId ? (
                                        <PsApiKeyList parentId={SETUP.apiKeys.id} childToParent={handleEventRouter} propagateEvent={propagateEvent} />
                                    ) : (
                                        <PsApiKey parentId={parentId} propagateEvent={propagateEvent} childToParent={handleEventRouter} />
                                    )}
                                </>
                            )} */}
                            </>
                        )}

                        {/* <!-- Record level--> */}
                        {selectedObject === TREE_DATA_ITEMS.user.object && <PsUser recordId={selectedId} parentId={defaultId} childToParent={handleEventRouter} propagateEvent={propagateEvent} />}
                        {selectedObject === TREE_DATA_ITEMS.action.object && <PsAction recordId={selectedId} propagateEvent={propagateEvent} />}
                        {/* {selectedObject === TREE_DATA_ITEMS.apikey.object && (
                        <PsApiKey recordId={selectedId} parentId={SETUP.apiKeys.id} propagateEvent={propagateEvent} childToParent={handleEventRouter} />
                    )} */}
                    </div>
                </div>
            </div>
        </div>
    );
};

const ErrorHandledSetup = () => <PsErrorBoundary children={<Setup />} />;
export default ErrorHandledSetup;
