import { Input, InputIcon } from "@salesforce/design-system-react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import "./TreeInput.css";
import { TreeSectionKeys, TreeItemKeys } from "./types";

import { FilterQueryType } from "../../services/types";

import NavigationTree from "./NavigationTree";

interface TreeInputProps {
    inputProps?: { label?: string; disabled?: boolean; required?: boolean; errorText?: string };
    section: TreeSectionKeys;
    object: TreeItemKeys;
    selectedRecord?: any;
    filters?: FilterQueryType; // to initialize the dropdown filter should set to a object even if is empty if it is undefine the component it will wait for filters
    disableOnView?: boolean;
    focusItem?: { itemKey: TreeItemKeys; id: string }; // itemKey should be on the section that is used
    onSelect?: (record: any) => void;
    onFocus?: () => void;
    onBluer?: () => void;
}

const TreeInput = ({ inputProps, section: sectionKey, filters, object, onSelect, onFocus, selectedRecord, onBluer, disableOnView, focusItem }: TreeInputProps) => {
    const containerRef = useRef<HTMLDivElement | null>(null);

    const [loading, setLoading] = useState<boolean>(false);

    const [selected, setSelected] = useState<string[]>([]);
    const [searchQuery, setSearchQuery] = useState<string | null>(null);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const isDropdownVisibleRef = useRef<boolean>(false);

    const treeElement = useMemo(() => {
        return (
            <div className="slds-dropdown slds-dropdown_fluid">
                <NavigationTree
                    selected={selected}
                    searchText={searchQuery}
                    navigationInputProps={{ object, filters, focusItem, selectedRecord }}
                    sectionList={[sectionKey]}
                    width="100%"
                    pxOffsetHV={window.innerHeight - 250}
                    bubbleSearchLoading={setLoading}
                    bubbleEvent={(ev) => {
                        onSelect?.(ev.record);
                        hideDropdown();
                    }}
                    showSearch={false}
                />
            </div>
        );
    }, [searchQuery, selected]);

    useEffect(() => {
        if (selectedRecord?.id) {
            setSelected([`${sectionKey}_${object}_${selectedRecord.id}`]);
        } else {
            setSelected([]);
        }
    }, [selectedRecord]);

    // Add a click listener for outside clicks
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    // Handles input change and updates the state
    function handleOnChange(_ev: any, { value }: any) {
        setSearchQuery(value);
    }

    function handleIsDropdownVisible(val: boolean) {
        isDropdownVisibleRef.current = val;
        setIsDropdownVisible(val);
    }

    // Shows the dropdown on input focus
    async function showDropdown() {
        handleIsDropdownVisible(true);
        setSearchQuery("");
        onFocus?.();
    }
    function hideDropdown() {
        handleIsDropdownVisible(false);
        setSearchQuery(null);
        onBluer?.();
    }
    // Hides the dropdown when clicking outside the container
    function handleClickOutside(event: MouseEvent) {
        if (containerRef.current && !containerRef.current.contains(event.target as Node) && !!isDropdownVisibleRef?.current) hideDropdown();
    }

    return (
        <div className="slds-form-element ps-navigation-input" ref={containerRef}>
            <div title={selectedRecord?.title || ""}>
                <Input
                    hasSpinner={loading}
                    label={inputProps?.label}
                    disabled={inputProps?.disabled}
                    required={inputProps?.required}
                    errorText={!isDropdownVisible && inputProps?.errorText}
                    iconLeft={<InputIcon name="search" category="utility" assistiveText={{ icon: "Search" }} />}
                    iconRight={searchQuery !== null && <InputIcon name="clear" category="utility" assistiveText={{ icon: "Clear" }} onClick={hideDropdown} />}
                    value={searchQuery === null ? selectedRecord?.name || "" : searchQuery}
                    onChange={handleOnChange}
                    onFocus={showDropdown}
                    placeholder="Search..."
                />
            </div>
            {isDropdownVisible && treeElement}
        </div>
    );
};

export default TreeInput;
