import React, { useCallback, useEffect, useRef, useState } from "react";
import "./DropBoxBar.css";
import { Card, Spinner } from "@salesforce/design-system-react";

import { mapTreeItemWithConstraints, SLOT_DATA } from "./constants";
import { DroppedItemsType, ConstraintsType, SlotType, UpdateValuesType, SlotDataType } from "./types";
import DropBox from "./DropBox";
import { convertSlotDataToConstraints, initializedDroppedItems } from "./utils";
import { TreeItemKeys } from "../../../../components/navigation-tree/types";
import FilterDropbox from "./filter/FilterDropbox";
import { areArraysEqual } from "../../../../utils/index2";

interface DropBoxBarProps {
    constraints: ConstraintsType | null;
    onChange: (constraints: ConstraintsType) => void;
    grabbedItemFromMenu: any;
}

const DropBoxBar = ({ onChange, constraints, grabbedItemFromMenu }: DropBoxBarProps) => {
    const droppedItemsRef = useRef<DroppedItemsType>({});
    const slotDataRef = useRef<SlotDataType>({});

    const [slotData, setSlotData] = useState<SlotDataType>({});
    const [loading, setLoading] = useState(false);
    const [grabbedItem, setGrabbedItem] = useState(null);

    useEffect(() => {
        if (!grabbedItemFromMenu && !grabbedItem) return;
        if (!grabbedItemFromMenu) {
            setGrabbedItem(null);
            return;
        }
        setGrabbedItem({ item: grabbedItemFromMenu, origin: "menu" });
    }, [grabbedItemFromMenu]);

    // initialize
    useEffect(() => {
        let isItemsChanged: boolean = false;
        const initSlotDataIds: SlotDataType = slotDataRef.current;
        const initItemKeysIds: { [key in TreeItemKeys]?: string[] } = {};

        setLoading(true);
        const initializedProcess = async () => {
            try {
                const itemsIdList = Object.keys(droppedItemsRef.current);
                const deepCopyConstraints: ConstraintsType = JSON.parse(JSON.stringify(constraints));
                if (deepCopyConstraints?.flt) delete deepCopyConstraints.flt;

                // Define ids that has changed
                const processSlotCategories = (slot: SlotType, key: TreeItemKeys, value: any, urlSlotIds: string[]) => {
                    const { category, attribute, constraintType } = value;
                    const ids: string[] | undefined = deepCopyConstraints?.[slot]?.[category]?.[attribute]?.[constraintType];
                    if (ids?.length) {
                        urlSlotIds.push(...ids);
                        ids.forEach((id) => {
                            if (!itemsIdList.includes(id)) {
                                isItemsChanged = true;
                                if (!initItemKeysIds[key]) initItemKeysIds[key] = [];
                                initItemKeysIds[key]?.push(id);
                            }
                        });
                    }
                };
                if (deepCopyConstraints) {
                    for (const slot of SLOT_DATA) {
                        const stateSlotIds = slotDataRef?.current?.[slot] || [];
                        let urlSlotIds: string[] = [];
                        Object.entries(mapTreeItemWithConstraints).forEach(([key, value]) => processSlotCategories(slot, key as TreeItemKeys, value, urlSlotIds));
                        if (!areArraysEqual(stateSlotIds, urlSlotIds)) {
                            isItemsChanged = true;
                            initSlotDataIds[slot] = urlSlotIds;
                        }
                    }
                } else {
                    slotDataRef.current = {};
                    setSlotData({});
                }
                if (isItemsChanged) {
                    // initialed dropped item if does not exist
                    if (Object.entries(initItemKeysIds).length) await initializedDroppedItems(droppedItemsRef, initItemKeysIds);
                    slotDataRef.current = initSlotDataIds;
                    setSlotData(initSlotDataIds);
                }
            } catch (error) {
                console.error("Error initializedProcess:", error);
                setLoading(false);
            } finally {
                setLoading(false);
            }
        };

        initializedProcess();
    }, [constraints]);

    const isDropValid = useCallback(
        (grabbedItem: any, slot: SlotType) => {
            if (!grabbedItem) return true;
            let config = "";
            if (grabbedItem?.origin === "menu") config = grabbedItem?.item?.setting?.config;
            if (grabbedItem?.origin === "box") config = grabbedItem?.item?.obj;
            if ((slot === "xst" || slot === "xnd") && config === "agg") return false;
            if (slot === "acr" && config === "agg" && grabbedItem.item.id !== "min" && grabbedItem.item.id !== "max") return false;

            return true;
        },
        [grabbedItem]
    );

    function onUpdateBoxValues(val: UpdateValuesType) {
        const deepCopySlotData: SlotDataType = JSON.parse(JSON.stringify(slotData));
        const boxItems = droppedItemsRef.current;
        if (val?.add) {
            const { id, slot, value } = val.add;
            const idExist = deepCopySlotData[slot]?.includes(id);
            const updateIds = deepCopySlotData[slot]?.length ? [...deepCopySlotData[slot], id] : [id];
            if (!idExist) deepCopySlotData[slot] = updateIds;
            if (value) boxItems[id] = value;
        }
        if (val?.delete) {
            const { id, slot } = val.delete;
            const updateIds = deepCopySlotData[slot]?.length ? deepCopySlotData[slot].filter((strId) => strId !== id) : [];
            deepCopySlotData[slot] = updateIds;
        }
        if (val?.deleteAll) delete deepCopySlotData[val.deleteAll];
        const updatedConstraints = convertSlotDataToConstraints(deepCopySlotData, droppedItemsRef);
        if (constraints?.flt) updatedConstraints["flt"] = constraints?.flt;

        slotDataRef.current = deepCopySlotData;
        setGrabbedItem(null);
        setSlotData(deepCopySlotData);
        onChange(updatedConstraints);
    }
    return (
        <div className="build-drop-box-container slds-m-right_medium ">
            <Card className="PsRecordGrid slds-scrollable card-drop-box" heading="">
                {loading && <Spinner />}
                <div className="box-content slds-pill_container ">
                    {SLOT_DATA.map((slot) => {
                        if (slot !== "flt")
                            return (
                                <DropBox
                                    key={slot}
                                    slot={slot}
                                    getItem={(id) => droppedItemsRef?.current[id] || null}
                                    idList={slotData[slot] || []}
                                    setGrabbedItem={setGrabbedItem}
                                    onUpdateValues={onUpdateBoxValues}
                                    dropAllowed={isDropValid(grabbedItem, slot)}
                                />
                            );
                        return (
                            <FilterDropbox
                                key={slot}
                                dropAllowed={!grabbedItem || (grabbedItem?.origin === "menu" && grabbedItem?.item?.setting?.config === "key")}
                                isItemGrabbed={!!grabbedItem}
                                onFilterChange={(val) => {
                                    const deepCopyConstraints = constraints ? JSON.parse(JSON.stringify(constraints)) : {};
                                    if (!val && deepCopyConstraints.flt) delete deepCopyConstraints.flt;
                                    if (val) deepCopyConstraints.flt = val;
                                    setGrabbedItem(null);
                                    onChange(deepCopyConstraints);
                                }}
                                flt={constraints?.flt || null}
                            />
                        );
                    })}
                </div>
            </Card>
        </div>
    );
};

export default DropBoxBar;
