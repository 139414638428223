import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Card } from "@salesforce/design-system-react";

import "./Store.css";
import PsSourceList from "../../components/ps-source-list/PsSourceList";
import PsContainer2 from "../../components/ps-container/PsContainer2";
import PsContainerList from "../../components/ps-container-list/PsContainerList";
import PsKeyList from "../../components/ps-key-list/PsKeyList";
import PsChainList from "../../components/ps-chain-list/PsChainList";
import { PsLinkList } from "../../components/ps-link-list/PsLinkList";
import { PsMapList } from "../../components/ps-map-list/PsMapList";
import PsFilterList from "../../components/ps-filter-list/FilterList";
import PsSetupStatus from "../../components/ps-setup-status/PsSetupStatus";
import useAuthContext from "../../context/useAuthContext";
import PsKey2 from "../../components/ps-key/PsKey2";
import PsSource2 from "../../components/ps-source/PsSource2";
import PsFilter2 from "../../components/ps-filter/PsFilter2";
import PsMap2 from "../../components/ps-map/PsMap2";
import PsLink2 from "../../components/ps-link/PsLink2";
import PsChain2 from "../../components/ps-chain/PsChain2";
import NavigationTree from "../../components/navigation-tree/NavigationTree";
import { TREE_SECTIONS } from "../../components/navigation-tree/constants";
import { TreeItemKeys, TreeSectionKeys } from "../../components/navigation-tree/types";
import { EventType } from "../types";
import PsNavigationHeader from "../../components/ps-navigation-header/PsNavigationHeader";
import { getDefaultItems } from "../../components/navigation-tree/utils";
import PsErrorBoundary from "../../components/ps-error-boundary/PsErrorBoundary";

const treeSectionList: TreeSectionKeys[] = ["keys", "filters", "maps", "links", "chains"]; // type TreeSectionsKeys[] from types navigation tree

const defaultSection = treeSectionList[0];
const defaultObj = treeSectionList[0]; // section and object is same for top level item
const defaultId = TREE_SECTIONS[defaultObj].id;
const defaultSelected = `${defaultSection}_${defaultObj}_${defaultId}`;

const optFilter = { filter: { newScopes: ["Global", "SubGroup"], showRequiredFieldError: false } };
const optMap = { map: { newScopes: ["Global"] } };

const Store = () => {
    // state
    const [selected, setSelected] = useState<string>(defaultSelected);
    const [selectedSection, setSelectedSection] = useState<TreeSectionKeys>(defaultSection);
    const [selectedObject, setSelectedObject] = useState<TreeSectionKeys | TreeItemKeys>(defaultObj);
    const [selectedId, setSelectedId] = useState<string>(defaultId);
    const [parentId, setParentId] = useState<string>("");

    const [propagateEvent, setPropagateEvent] = useState<EventType | undefined>();
    const [setupStatusVisible, setSetupStatusVisible] = useState(true);

    const { handleLogout } = useAuthContext();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        syncStateWithUrl(searchParams);
    }, [searchParams]);

    function syncStateWithUrl(searchParams: URLSearchParams) {
        const urlSelected = searchParams.get("selected") || defaultSelected;
        const urlParentId = searchParams.get("parentId") || "";

        if (urlSelected !== selected) {
            const [section, obj, id] = urlSelected.split("_");
            setSelected(urlSelected);
            setSelectedId(id);
            setSelectedObject(obj as any);
            setSelectedSection(section as TreeSectionKeys);
        }
        if (urlParentId !== parentId) setParentId(urlParentId);
    }

    function handleEventRouter(event: EventType) {
        switch (event.type) {
            case "tab":
                // TODO: change event.tab to event.path
                navigate("/" + event.tab);
                break;

            case "logout":
                handleLogout();
                break;

            case "sync":
                setPropagateEvent(event);
                break;
            case "record":
                if (event.action === "cancel") {
                    searchParams.delete("parentId");
                    setSearchParams(searchParams);
                } else {
                    const section = selectedSection || treeSectionList[0];
                    setPropagateEvent({ ...event, section });
                    // does not need to navigate when update the record just update the tree that it will happen from setPropagateEvent
                    if (["delete", "create"].includes(event.action)) {
                        const { obj, id, parentId } = event;
                        const parentObj = getDefaultItems(section).defaultItems[obj].parentDataKey;
                        const selected = event.action === "delete" ? `${section}_${parentObj}_${parentId}` : `${section}_${obj}_${id}`;
                        searchParams.set("selected", selected);
                        searchParams.delete("parentId");
                        setSearchParams(searchParams);
                    }
                }
                break;
            case "navigation":
                if (event.source === "grid" && !event.id) {
                    searchParams.set("parentId", event?.parentId); // if event.id is false and source is grid that mean is new record
                } else {
                    const { id, obj } = event;
                    const section = event?.section || selectedSection || defaultSection;
                    searchParams.set("selected", `${section}_${obj}_${id}`);
                    searchParams.delete("parentId");
                }
                setSearchParams(searchParams);
                break;

            default:
                setPropagateEvent(event);
                break;
        }
    }

    return (
        <div className="Store">
            <PsNavigationHeader childToParent={handleEventRouter} />

            <div className="tab-content slds-p-around_medium">
                <div className="left slds-m-right_medium">
                    {/* <!-- navigation tree --> */}
                    <Card className="card-height-full" heading={<span className="card-main-title-lh32 slds-card__header-title">Browse</span>}>
                        <NavigationTree
                            pxOffsetHV={194}
                            sectionList={treeSectionList}
                            bubbleEvent={handleEventRouter}
                            propagateEvent={propagateEvent}
                            selected={[selected]}
                            isTopLevelExpanded={false}
                        />
                    </Card>
                </div>
                {/* <!-- right panel view --> */}
                <div className={setupStatusVisible ? "right" : "right slds-scrollable"}>
                    <PsSetupStatus
                        title="Store Management"
                        tagLine="Manage processed data."
                        includeStatus={["Authenticated", "Error", "Inventory Loaded", "Data Loaded"]}
                        onSetupStatusVisible={setSetupStatusVisible}
                    />
                    {/* selectedObject: keys, filters, maps, links, chains  */}
                    {treeSectionList.includes(selectedObject as TreeSectionKeys) && (
                        <>
                            {!parentId ? (
                                <PsSourceList parentId={TREE_SECTIONS[selectedObject].id} queryFilter={{}} childToParent={handleEventRouter} propagateEvent={propagateEvent} />
                            ) : (
                                //  New Source mode
                                <PsSource2 parentId={parentId} childToParent={handleEventRouter} propagateEvent={propagateEvent} />
                            )}
                        </>
                    )}

                    {/* selectedObject: source */}
                    {selectedObject === "source" && (
                        <>
                            <div className="slds-m-bottom_medium">
                                <PsSource2 recordId={selectedId} parentId={TREE_SECTIONS[selectedSection].id} childToParent={handleEventRouter} propagateEvent={propagateEvent} />
                            </div>
                            <PsContainerList queryFilter={{ sourceId: selectedId }} parentId={selectedId} childToParent={handleEventRouter} propagateEvent={propagateEvent} />
                        </>
                    )}
                    {/* selectedObject: container */}
                    {selectedObject === "container" && (
                        <>
                            {!parentId ? (
                                <>
                                    <div className="slds-m-bottom_medium">
                                        <PsContainer2 recordId={selectedId} childToParent={handleEventRouter} propagateEvent={propagateEvent} />
                                    </div>
                                    {selectedSection === "keys" && (
                                        <PsKeyList queryFilter={{ containerId: selectedId }} parentId={selectedId} childToParent={handleEventRouter} propagateEvent={propagateEvent} />
                                    )}
                                    {selectedSection === "filters" && (
                                        <PsFilterList queryFilter={{ containerId: selectedId }} parentId={selectedId} childToParent={handleEventRouter} propagateEvent={propagateEvent} />
                                    )}

                                    {selectedSection === "maps" && (
                                        <PsMapList queryFilter={{ containerId: selectedId }} parentId={selectedId} childToParent={handleEventRouter} propagateEvent={propagateEvent} />
                                    )}
                                    {selectedSection === "chains" && (
                                        <PsChainList queryFilter={{ containerId: selectedId }} parentId={selectedId} childToParent={handleEventRouter} propagateEvent={propagateEvent} />
                                    )}
                                    {selectedSection === "links" && (
                                        <PsLinkList queryFilter={{ containerId: selectedId }} parentId={selectedId} childToParent={handleEventRouter} propagateEvent={propagateEvent} />
                                    )}
                                </>
                            ) : (
                                //  New mode
                                <>
                                    {selectedSection === "filters" && <PsFilter2 parentId={parentId} childToParent={handleEventRouter} propagateEvent={propagateEvent} options={optFilter} />}
                                    {selectedSection === "maps" && <PsMap2 parentId={parentId} childToParent={handleEventRouter} propagateEvent={propagateEvent} options={optMap} />}
                                    {selectedSection === "links" && <PsLink2 parentId={parentId} childToParent={handleEventRouter} propagateEvent={propagateEvent} />}
                                    {selectedSection === "chains" && <PsChain2 parentId={parentId} childToParent={handleEventRouter} propagateEvent={propagateEvent} />}
                                </>
                            )}
                        </>
                    )}

                    {/* selectedObject: key, filter, map, link, chain */}
                    {selectedObject === "key" && <PsKey2 recordId={selectedId} childToParent={handleEventRouter} propagateEvent={propagateEvent} />}
                    {selectedObject === "filter" && <PsFilter2 recordId={selectedId} childToParent={handleEventRouter} propagateEvent={propagateEvent} options={optFilter} />}
                    {selectedObject === "map" && <PsMap2 recordId={selectedId} childToParent={handleEventRouter} propagateEvent={propagateEvent} options={optMap} />}
                    {selectedObject === "link" && <PsLink2 recordId={selectedId} childToParent={handleEventRouter} propagateEvent={propagateEvent} />}
                    {selectedObject === "chain" && <PsChain2 recordId={selectedId} childToParent={handleEventRouter} propagateEvent={propagateEvent} />}
                </div>
            </div>
        </div>
    );
};
const ErrorHandledStore = () => <PsErrorBoundary children={<Store />} />;
export default ErrorHandledStore;
