import React, { useEffect, useState } from "react";
import { Button, Card, Spinner, ButtonGroup } from "@salesforce/design-system-react";

import IllustrationDesert from "../../ui/IllustrationDesert";
import { ModuleApiType } from "../../services/types";
import { Mode, ParsedCreateRequestTypeMap, ParsedUpdateRequestTypeMap, RecordTypeMap, ResponseInputTypeMap } from "../../types";
import { parseOverrides, updateOverridden, toastErrorMessage } from "../../utils";
import { deleteRecordAPI, getRecordAPI, submitRecordAPI } from "../../services/api";
import Modal from "../../ui/Modal";
import useToastContext from "../../context/useToastContext";
import { FIELD_ERROR_MESSAGES } from "../../constants";
import { EventRecordType, EventType } from "../../pages/types";
import ExpandButton from "../../ui/buttons/ExpandButton";

interface RecordItemProps<T extends keyof RecordTypeMap> {
    recordObject: T; // Dinamic type,  "source", "connector"...
    recordLabel: string;
    recordModule: ModuleApiType;
    showEdit: boolean;
    mode: string;
    recordId: string;
    parentId: string;
    showCardActions?: boolean;
    isModal?: boolean;
    showDelete?: boolean;
    record: RecordTypeMap[T];
    recordValue?: RecordTypeMap[T];
    defaultRecord?: RecordTypeMap[T];
    overrideFields?: string[];
    loading: boolean;
    propagateEvent?: EventType;
    showExpand?: boolean;
    processPhase?: "pre-save" | "on-save";
    fieldErrors?: { [key: string]: string };

    // JSX element
    cardActions?: React.ReactNode;
    cardBody: React.ReactNode;

    // setState
    setMode: React.Dispatch<React.SetStateAction<Mode>>;
    setRecord: React.Dispatch<React.SetStateAction<RecordTypeMap[T]>>;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    setFieldErrors?: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>;

    // Function
    childToParent?: (event: EventRecordType) => void;
    onEdit?: () => void;
    updateUI?: Function;
    setParent?: (record: any) => void;
    processAfterSaveOrCancel?: Function;
    displayValidationToast?: Function;
    postSubmit?: Function;
    parseInputToEncrypt?: Function;

    parseResponse?: (response: ResponseInputTypeMap[T][]) => RecordTypeMap[T][];
    parseUpdateRequest?: (updatedRecord: RecordTypeMap[T]) => RecordTypeMap[T] | ParsedUpdateRequestTypeMap[T];
    parseCreateRequest?: (createdRecord: RecordTypeMap[T]) => RecordTypeMap[T] | ParsedCreateRequestTypeMap[T];
    processPhaseRequest?: () => void;
}

const PsRecord2 = <T extends keyof RecordTypeMap>(props: RecordItemProps<T>) => {
    const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);
    const [expand, setExpand] = useState<boolean>(true);

    const { addToast } = useToastContext();

    const {
        recordLabel,
        recordModule,
        recordObject,
        showEdit,
        mode,
        recordId,
        parentId,
        showCardActions = false,
        cardActions,
        isModal,
        showDelete = false,
        record,
        overrideFields,
        loading,
        propagateEvent,
        fieldErrors,
        setLoading,
        setMode,
        setRecord,
        setFieldErrors,
        childToParent,
        parseResponse,
        parseUpdateRequest,
        parseCreateRequest,
        onEdit = () => {},
        postSubmit = () => {},
        updateUI = () => {},
        setParent = () => {},
        cardBody,
        processAfterSaveOrCancel,
        displayValidationToast = undefined,
        defaultRecord,
        recordValue,
        showExpand,
        parseInputToEncrypt,
        processPhase,
        processPhaseRequest,
    } = props;

    useEffect(() => {
        if (propagateEvent?.type === "sync" && propagateEvent?.action === "reload") getRecord();
    }, [propagateEvent]);

    useEffect(() => {
        getRecord();
    }, [recordId]);

    useEffect(() => {
        if (processPhase === "on-save") onSubmit();
    }, [processPhase]);

    const getRecord = (newId?: string) => {
        if (recordValue) {
            setRecord(recordValue);
            updateUI(recordValue, true);
            setMode("edit");
        } else if (recordId || newId) {
            setLoading(true);
            const onSuccess = function (response: any) {
                if (response && response.length > 0) {
                    let mapped = parseResponse ? parseResponse(response) : response;
                    mapped = parseOverrides(mapped, overrideFields);
                    setRecord(mapped[0]);
                    setMode("view");
                    updateUI(mapped[0], true);
                    setParent(response[0]);
                }
                setLoading(false);
            };

            const onError = function (response: any) {
                setLoading(false);
                addToast("error", "Error", toastErrorMessage(response));
                setMode("error");
            };

            getRecordAPI({ module: recordModule, object: recordObject, recordId: recordId || newId }, onSuccess, onError);
        } else {
            setMode("new");
            defaultRecord && setRecord(defaultRecord);
        }
    };

    const isFormValid = (): boolean => {
        let isValid = true;

        if (record && fieldErrors && Object.entries(fieldErrors).some(([key, error]) => error || (key in record && record[key as keyof typeof record] === ""))) {
            return false;
        }

        const validateElement = (element: React.ReactNode): void => {
            if (!element) return;

            if (React.isValidElement(element)) {
                const { props } = element;
                if (props.body && props.body?.props?.required && (!props.body?.props?.value || props.body?.props?.value === "--None--")) {
                    isValid = false;
                    if (props.body.props.name && setFieldErrors) {
                        setFieldErrors((prev) => ({
                            ...prev,
                            [props.body.props.name]: props.body.props.errorMessage || FIELD_ERROR_MESSAGES.GENERAL_REQUIRED_FIELD,
                        }));
                    }
                }
                if (props.children) {
                    React.Children.forEach(props.children, validateElement);
                }
            } else if (Array.isArray(element)) {
                element.forEach(validateElement);
            }
        };
        validateElement(cardBody);

        return isValid;
    };

    const onSubmit = () => {
        if (!isFormValid()) {
            const toastId = record?.id || "recordCreateErrorId";
            addToast("error", "Input Error", "Please update the invalid form entries and try again.", toastId);
            return;
        }

        // for some special cases
        if (displayValidationToast && displayValidationToast()) {
            displayValidationToast();
            return;
        }

        if (processPhase === "pre-save") {
            processPhaseRequest?.();
            return;
        }
        setLoading(true);
        const onSuccess = function (response: any) {
            setLoading(false);
            setMode("view");
            const id = response[0]?.id;
            getRecord(id);

            const action = recordId ? "update" : "create";
            notifyChanged(action, id);
            if (processAfterSaveOrCancel) {
                processAfterSaveOrCancel();
            }
        };

        const onError = function (response: any) {
            setLoading(false);
            addToast("error", "Error", toastErrorMessage(response));
        };

        let updatedRecord = updateOverridden(record, overrideFields);
        let dataToEncrypt = null;
        if (parseInputToEncrypt) {
            dataToEncrypt = parseInputToEncrypt();
        }

        // we do not send some fields
        updatedRecord = updatedRecord.id ? parseUpdateRequest(updatedRecord) : parseCreateRequest(updatedRecord);

        if (dataToEncrypt) {
            const encryptOnError = function (response: any) {
                setLoading(false);
                addToast("error", "Connector Failed to Save", response?.data?.message || "An unexpected error occurred");
            };

            const encryptOnSuccess = function (response: string) {
                updatedRecord = { ...updatedRecord, credentials: response };
                submitRecordAPI({ module: recordModule, object: recordObject, inputBody: [updatedRecord] }, onSuccess, onError);
            };

            submitRecordAPI({ module: "core", object: "crypto", inputBody: dataToEncrypt }, encryptOnSuccess, encryptOnError);
        } else {
            submitRecordAPI({ module: recordModule, object: recordObject, inputBody: [updatedRecord] }, onSuccess, onError);
            postSubmit();
        }
    };

    const cancelDeleteRecord = () => {
        setShowDeleteConfirmDialog(false);
    };

    const onDelete = () => {
        setShowDeleteConfirmDialog(true);
    };

    const confirmDeleteRecord = () => {
        setShowDeleteConfirmDialog(false);

        setLoading(true);

        const onSuccess = function () {
            setLoading(false);
            addToast("success", "Record Deleted", "Record successfully deleted");
            notifyChanged("delete", recordId);
        };

        const onError = function (response: any) {
            setLoading(false);
            addToast("error", "Error", toastErrorMessage(response));
        };

        deleteRecordAPI({ module: recordModule, object: recordObject, recordId }, onSuccess, onError);
    };

    const onCancel = () => {
        if (mode === "edit") {
            getRecord();
        } else {
            // mode = new
            notifyChanged("cancel", recordId);
        }
        if (processAfterSaveOrCancel) {
            processAfterSaveOrCancel();
        }
    };

    // fire event for changed record(s)
    const notifyChanged = (action: "delete" | "update" | "create" | "cancel", id: string) => {
        const parentNav = parentId || "";
        const recordChangedEvent: EventRecordType = {
            type: "record",
            action,
            source: "record",
            id,
            obj: recordObject,
            parentId: parentNav,
        };
        childToParent?.(recordChangedEvent);
    };

    const headerActions = () => {
        const editButton = showEdit && mode === "view" && <Button key="edit" disabled={loading} title={`Edit this ${recordLabel}`} label="Edit" onClick={onEdit} />;

        const deleteButton = showDelete && !loading && mode === "view" && <Button key="delete" label="Delete" title={`Delete this ${recordLabel}`} onClick={onDelete} disabled={loading} />;

        const cardActionsContent = showCardActions && cardActions ? <div key="cardActions">{cardActions}</div> : null;

        const buttons = [editButton, deleteButton, cardActionsContent].filter(Boolean); // `null` clears the array

        return buttons.length > 0 ? <ButtonGroup variant="list">{buttons}</ButtonGroup> : null;
    };

    return (
        <>
            {showDeleteConfirmDialog ? (
                <Modal
                    apply={() => confirmDeleteRecord()}
                    cancel={() => cancelDeleteRecord()}
                    header="Confirmation"
                    modalContent="Deleting this Record will also delete all its associated loaded data. Are you sure?"
                    applyButtonContent="Delete"
                />
            ) : null}
            <Card
                className={expand ? "slds-p-bottom_small" : "slds-hide"}
                heading={<span className="card-main-title-lh32 slds-card__header-title">{recordLabel}</span>}
                headerActions={headerActions()}
                footer={
                    !isModal && ((showDelete && mode === "edit") || mode === "edit" || mode === "new") ? (
                        <div>
                            {(mode === "new" || mode === "edit") && (
                                <>
                                    <Button label="Cancel" title={mode === "new" ? "Cancel creating" : "Cancel editing"} onClick={() => onCancel()} disabled={loading} />
                                    <Button label="Save" title={"Save this " + recordLabel} onClick={() => onSubmit()} disabled={loading} variant="brand" />
                                </>
                            )}
                        </div>
                    ) : null
                }
            >
                {/*  error */}
                {mode === "error" ? (
                    <div className="slds-is-relative">
                        <div className="slds-p-around_medium slds-illustration slds-illustration_large" aria-hidden="true">
                            <IllustrationDesert />
                            <div className="slds-text-color_weak">
                                <h3 className="slds-text-heading_medium">{recordLabel} not found</h3>
                            </div>
                        </div>
                        {loading && <Spinner assistiveText={{ label: "Loading" }} />}
                    </div>
                ) : (
                    <>
                        {/* record form */}
                        {cardBody}
                        {loading && <Spinner assistiveText={{ label: "Loading" }} />}
                    </>
                )}
            </Card>
            {showExpand && <ExpandButton expand={expand} setExpand={setExpand} position="horizontal" />}
        </>
    );
};

export default PsRecord2;
