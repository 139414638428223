import React, { useState } from "react";
import { ActionResponseType, Mode, PsRecordPropsType, ActionType } from "../../types";
import Field from "../../ui/wrappers/Field";
import PsRecord2 from "../ps-record/PsRecord2";
import { formattedDateTime, formattedNumber } from "../../utils";

const PsAction: React.FC<PsRecordPropsType> = ({ recordId = "", parentId = "", propagateEvent }) => {
    const [mode, setMode] = useState<Mode>("init");
    const [record, setRecord] = useState<ActionType>({} as ActionType);
    const [loading, setLoading] = useState<boolean>(false);

    function parseResponse(response: ActionResponseType[]): ActionType[] {
        return response.map((item) => {
            const isRunning = item.runStatus === "Running";
            const isTimeout = Date.parse(item.runTimeout) < Date.now();

            return {
                id: item.id,
                name: item.name,
                origin: item.origin,
                runStatus: isRunning && isTimeout ? "Timeout" : item.runStatus,
                usedCredit: formattedNumber(item.usedCredit),
                parameters: item.parameters ? JSON.stringify(item.parameters) : "",
                runMessage: item.runMessage || "",
                runEnd: formattedDateTime(isTimeout ? item.runTimeout : item.runEnd),
                createdOn: formattedDateTime(item.createdOn),
                reservedFailCount: isRunning && !isTimeout ? "" : item.reservedFailCount?.toString() || "",
                remainingCredit: formattedNumber(item.remainingCredit),
            };
        });
    }

    return (
        <PsRecord2
            recordLabel="Action"
            recordModule="core"
            recordObject="action"
            record={record}
            showEdit={false}
            mode={mode}
            recordId={recordId}
            parentId={parentId}
            propagateEvent={propagateEvent}
            setMode={setMode}
            setRecord={setRecord}
            loading={loading}
            setLoading={setLoading}
            parseResponse={parseResponse}
            cardBody={
                <div className="slds-form slds-m-around_medium" role="list">
                    <h3 className="slds-section-title--divider slds-m-top_medium">Action details</h3>

                    <div className="slds-form__row">
                        <Field mode="view" value={record?.name} label="Name" />
                        <Field mode="view" value={record?.runStatus} label="Status" />
                    </div>
                    <div className="slds-form__row">
                        <Field mode="view" value={record?.origin} label="Origin" />
                        <Field mode="view" value={record?.reservedFailCount} label="Consecutive Fails" />
                    </div>
                    <div className="slds-form__row">
                        <Field mode="view" value={record?.createdOn} label="Started" /> <Field mode="view" value={record?.runEnd} label="Completed" />
                    </div>
                    <div className="slds-form__row">
                        <Field mode="view" value={record?.usedCredit} label="Used Compute Credit" />
                        <Field mode="view" value={record?.remainingCredit} label="Remaining Compute Credit" />
                    </div>
                    <div className="slds-form__row">
                        <Field mode="view" value={record?.parameters} label="Parameters" />
                    </div>
                    <div className="slds-form__row">
                        <Field mode="view" value={record?.runMessage} label="Error Message" />
                    </div>
                </div>
            }
        />
    );
};

export default PsAction;
