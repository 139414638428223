import { Combobox, Input } from "@salesforce/design-system-react";
import React, { useState } from "react";

import RecordConstants from "../../constants/RecordConstants";
import { addIds } from "../../utils";
import { UpdateUserRequestType, Mode, PsRecordPropsType, CreateUserRequestType, UserType, UserResponseType } from "../../types";
import Field from "../../ui/wrappers/Field";
import { checkRequiredField } from "../../utils/index2";
import PsRecord2 from "../../components/ps-record/PsRecord2";
import Toggle from "../../ui/Toggle";
import CheckAndCloseIcons from "../../components/ps-key/components/CheckAndCloseIcons";
import useAuthContext from "../../context/useAuthContext";
import { EventRecordType } from "../../pages/types";
import useToastContext from "../../context/useToastContext";

const { ENVIRONMENT_STATUS: statusOpt, USER_TYPE: typeOpt, ACCOUNT_SETTINGS_DEFAULT_COLOR_MAP: colorOpt, ACCOUNT_SETTINGS_DATE_TIME_FORMATS: dateFormatOpt } = RecordConstants;

const statusOptions: OptionsType[] = addIds(Object.values(statusOpt));
const typeOptions: OptionsType[] = addIds(Object.values(typeOpt));
const colorOptions: OptionsType[] = addIds(Object.values(colorOpt));
const dateFormatOptions: OptionsType[] = addIds(Object.values(dateFormatOpt));

const defaultRecord: UserType = {
    name: "",
    email: "",
    login: "",
    type: typeOpt.User.value as "User",
    status: statusOpt.Active.value as "Active",

    preferBarOverDoughnut: false,
    disableDropOutliers: false,
    disableDecimation: false,

    defaultColorMap: colorOpt.empty.value,
    dateTimeFormat: dateFormatOpt.empty.value,
};

type OptionsType = {
    value: string;
    label: string;
    description?: string;
};

const PsUser: React.FC<PsRecordPropsType> = ({ recordId = "", parentId = "", childToParent, propagateEvent }) => {
    const [mode, setMode] = useState<Mode>("init");
    const [record, setRecord] = useState<UserType>(defaultRecord);
    const [loading, setLoading] = useState<boolean>(false);
    const [fieldErrors, setFieldErrors] = useState({} as { [key: string]: string });
    const { profile, userLoginName }: any = useAuthContext();
    const { addToast } = useToastContext();

    function parseResponse(response: UserResponseType[]): UserType[] {
        return response.map((item) => ({
            id: item.id,
            name: item.name,
            login: item?.login || "",
            email: item.email,
            type: item.type,
            status: item.status,
            dateTimeFormat: item.settings?.dateTimeFormat || dateFormatOpt.empty.value,
            defaultColorMap: item.settings?.defaultColorMap || colorOpt.empty.value,
            disableDecimation: !!item.settings?.disableDecimation,
            disableDropOutliers: !!item.settings?.disableDropOutliers,
            preferBarOverDoughnut: !!item.settings?.preferBarOverDoughnut,
        }));
    }

    function parseUpdateRequest(updatedRecord: UserType): UpdateUserRequestType {
        const { id, name, login, email, type, status, dateTimeFormat, defaultColorMap, disableDecimation, disableDropOutliers, preferBarOverDoughnut } = updatedRecord;
        const settings = {
            ...(defaultColorMap !== colorOpt.empty.value && { defaultColorMap }),
            ...(dateTimeFormat !== dateFormatOpt.empty.value && { dateTimeFormat }),
            disableDecimation,
            disableDropOutliers,
            preferBarOverDoughnut,
        };
        return {
            id: id!, // Assuming `id` is always present for updates
            name,
            ...(!!login && { login }),
            email,
            type,
            status,
            settings: Object.values(settings).some((value) => value !== undefined) ? settings : null,
        };
    }

    function parseCreateRequest(newRecord: UserType): CreateUserRequestType | any {
        const { name, email, login, type, status, dateTimeFormat, defaultColorMap, disableDecimation, disableDropOutliers, preferBarOverDoughnut } = newRecord;
        const settings = {
            ...(defaultColorMap !== colorOpt.empty.value && { defaultColorMap }),
            ...(dateTimeFormat !== dateFormatOpt.empty.value && { dateTimeFormat }),
            disableDecimation,
            disableDropOutliers,
            preferBarOverDoughnut,
        };
        return {
            name,
            ...(!!login && { login }),
            email,
            type,
            status,
            settings: Object.values(settings).some((value) => value !== undefined) ? settings : null,
        };
    }

    function onSelectDropdown(name: string, data: any) {
        if (data.selection.length === 0) return;
        setRecord((prev) => ({ ...prev, [name]: data.selection[0].value }));
    }

    function onEdit() {
        setFieldErrors({});
        setMode("edit");
    }
    function childToParentHandler(event: EventRecordType) {
        if (event.action === "create") addToast("success", "User Created", "Login details have been submitted to the user's email");
        childToParent?.(event);
    }

    return (
        <PsRecord2
            defaultRecord={defaultRecord}
            recordLabel="User"
            recordModule="core"
            recordObject={"user"}
            record={record}
            showEdit
            showDelete={recordId !== profile?.id} // if the id equal with profile id user can not delete the record
            mode={mode}
            recordId={recordId}
            parentId={parentId}
            propagateEvent={propagateEvent}
            setMode={setMode}
            onEdit={onEdit}
            setRecord={setRecord}
            setFieldErrors={setFieldErrors}
            loading={loading}
            setLoading={setLoading}
            childToParent={childToParentHandler}
            parseResponse={parseResponse}
            parseUpdateRequest={parseUpdateRequest}
            parseCreateRequest={parseCreateRequest}
            cardBody={
                <div className="slds-form slds-m-around_medium" role="list">
                    <h3 className="slds-section-title--divider slds-m-top_medium">User Details</h3>

                    <div className="slds-form__row">{record?.id === profile?.id && <Field mode="view" value={userLoginName} label="Login Name" isEditable={false} />}</div>
                    <div className="slds-form__row">
                        <Field
                            recordObject="user"
                            setRecord={setRecord}
                            mode={mode}
                            value={record.name}
                            label="Display Name"
                            fieldName="name"
                            onEdit={onEdit}
                            isEditable
                            checkValidity={checkRequiredField}
                            setFieldErrors={setFieldErrors}
                            body={<Input name="name" autoComplete="off" label="Display Name" required value={record?.name} errorText={fieldErrors?.name} />}
                        />
                        <Field
                            recordObject="user"
                            setRecord={setRecord}
                            mode={mode}
                            value={record.email}
                            label="Email"
                            onEdit={onEdit}
                            isEditable
                            fieldName="email"
                            checkValidity={checkRequiredField}
                            setFieldErrors={setFieldErrors}
                            body={<Input name="email" autoComplete="off" label="Email" required value={record?.email} errorText={fieldErrors?.name} />}
                        />
                    </div>
                    <div className="slds-form__row">
                        <Field
                            customChangeHandler
                            mode={mode}
                            value={typeOpt[record.type].label}
                            label="Type"
                            onEdit={onEdit}
                            isEditable
                            body={
                                <Combobox
                                    events={{ onSelect: (_event: any, data: any) => onSelectDropdown("type", data) }}
                                    labels={{ label: "Type", placeholder: "--Please Select--" }}
                                    options={typeOptions}
                                    selection={[typeOpt[record.type]]}
                                    variant="readonly"
                                />
                            }
                        />
                        <Field
                            customChangeHandler
                            mode={mode}
                            value={statusOpt[record.status].label}
                            label="Status"
                            onEdit={onEdit}
                            isEditable
                            showStaticViewInNewMode
                            body={
                                <Combobox
                                    events={{ onSelect: (_event: any, data: any) => onSelectDropdown("status", data) }}
                                    labels={{ label: "Status", placeholder: "--Please Select--" }}
                                    options={statusOptions}
                                    selection={[statusOpt[record.status]]}
                                    variant="readonly"
                                />
                            }
                        />
                    </div>
                    <h3 className="slds-section-title--divider slds-m-top_medium">Settings</h3>
                    <div className="slds-form__row">
                        <Field
                            customChangeHandler
                            mode={mode}
                            label="Prefer Bar Over Doughnut"
                            onEdit={onEdit}
                            isEditable
                            value={mode === "edit" ? "" : <CheckAndCloseIcons selectedItem={record.preferBarOverDoughnut} />}
                            body={
                                <Toggle
                                    label="Prefer Bar Over Doughnut"
                                    active={record.preferBarOverDoughnut}
                                    setActive={() => setRecord((prev) => ({ ...prev, preferBarOverDoughnut: !record.preferBarOverDoughnut }))}
                                />
                            }
                        />
                        <Field
                            customChangeHandler
                            mode={mode}
                            label="Disable Drop Outliers"
                            onEdit={onEdit}
                            isEditable
                            value={mode === "edit" ? "" : <CheckAndCloseIcons selectedItem={record.disableDropOutliers} />}
                            body={
                                <Toggle
                                    active={record.disableDropOutliers}
                                    label="Disable Drop Outliers"
                                    setActive={() => setRecord((prev) => ({ ...prev, disableDropOutliers: !record.disableDropOutliers }))}
                                />
                            }
                        />
                        <Field
                            customChangeHandler
                            mode={mode}
                            label="Disable Decimation"
                            onEdit={onEdit}
                            isEditable
                            value={mode === "edit" ? "" : <CheckAndCloseIcons selectedItem={record.disableDecimation} />}
                            body={
                                <Toggle
                                    active={record.disableDecimation}
                                    label="Disable Decimation"
                                    setActive={() => setRecord((prev) => ({ ...prev, disableDecimation: !record.disableDecimation }))}
                                />
                            }
                        />
                    </div>

                    <div className="slds-form__row">
                        <Field
                            customChangeHandler
                            mode={mode}
                            value={colorOpt[record.defaultColorMap].label}
                            label="Chart Color Scheme"
                            onEdit={onEdit}
                            isEditable
                            body={
                                <Combobox
                                    events={{ onSelect: (_event: any, data: any) => onSelectDropdown("defaultColorMap", data) }}
                                    labels={{ label: "Chart Color Scheme", placeholder: "--Please Select--" }}
                                    options={colorOptions}
                                    selection={[colorOpt[record.defaultColorMap]]}
                                    variant="readonly"
                                />
                            }
                        />
                        <Field
                            customChangeHandler
                            mode={mode}
                            value={dateFormatOpt[record.dateTimeFormat].label}
                            label="Date and Time Format"
                            onEdit={onEdit}
                            isEditable
                            body={
                                <Combobox
                                    events={{ onSelect: (_event: any, data: any) => onSelectDropdown("dateTimeFormat", data) }}
                                    labels={{ label: "Date and Time Format", placeholder: "--Please Select--" }}
                                    options={dateFormatOptions}
                                    selection={[dateFormatOpt[record.dateTimeFormat]]}
                                    variant="readonly"
                                />
                            }
                        />
                    </div>
                </div>
            }
        />
    );
};

export default PsUser;
