import { Combobox, Input, Tooltip } from "@salesforce/design-system-react";
import React, { useState } from "react";

import RecordConstants from "../../constants/RecordConstants";
import { addIds } from "../../utils";
import { UpdateAccountRequestType, Mode, PsRecordPropsType, AccountType, AccountResponseType } from "../../types";
import Field from "../../ui/wrappers/Field";
import PsRecord2 from "../ps-record/PsRecord2";
import Toggle from "../../ui/Toggle";
import CheckAndCloseIcons from "../ps-key/components/CheckAndCloseIcons";
import { formatDuration, intervalToDuration } from "date-fns";
import { EventType } from "../../pages/types";
import useAuthContext from "../../context/useAuthContext";

const { ACCOUNT_SETTINGS_DEFAULT_COLOR_MAP: colorOpt, ACCOUNT_SETTINGS_DATE_TIME_FORMATS: dateFormatOpt, ACCOUNT_SETTINGS_GENERATIVE_AI: genAiOpt } = RecordConstants;

const colorOptions: OptionsType[] = addIds(Object.values(colorOpt));
const dateFormatOptions: OptionsType[] = addIds(Object.values(dateFormatOpt));
const genAiOptions: OptionsType[] = addIds(Object.values(genAiOpt));

const defaultRecord: AccountType = {
    setupStatus: "No Results",
    computeCredit: 0,
    totalStorageBytes: 0,
    deferUpdatesSeconds: 0,
    deferUpdatesInterval: "",
    maxNumPatterns: 0,
    limitsGenerativeAI: "",

    //  editableValues
    name: "",
    enableGenerativeAIExplain: false,
    enableGenerativeAISearch: false,
    enableGenerativeAIStore: false,
    disableDropOutliers: false,
    disableDecimation: false,
    significantDigits: "",
    preferBarOverDoughnut: false,

    generativeAI: genAiOpt.Data.value,
    defaultColorMap: colorOpt.empty.value,
    dateTimeFormat: dateFormatOpt.empty.value,
    logoUrl: "",
    faviconUrl: "",
};

type OptionsType = {
    value: string;
    label: string;
    description?: string;
};

const PsAccount: React.FC<PsRecordPropsType> = ({ recordId = "", parentId = "", propagateEvent }) => {
    const [mode, setMode] = useState<Mode>("init");
    const [record, setRecord] = useState<AccountType>(defaultRecord);
    const [loading, setLoading] = useState<boolean>(false);
    const [fieldErrors, setFieldErrors] = useState({} as { [key: string]: string });

    const { refreshAccount }: any = useAuthContext();

    function parseResponse(response: AccountResponseType[]): AccountType[] {
        return response.map((item) => ({
            name: item?.name,
            setupStatus: item.setupStatus,
            computeCredit: Math.floor(item?.usage?.computeCredit || 0),
            totalStorageBytes: item?.usage?.totalStorageBytes || 0,
            deferUpdatesSeconds: item?.limits?.deferUpdatesSeconds || 0,
            deferUpdatesInterval: formatDuration(intervalToDuration({ start: 0, end: item?.limits?.deferUpdatesSeconds * 1000 || 0 })),
            maxNumPatterns: item?.limits?.maxNumPatterns || 0,
            limitsGenerativeAI: item?.limits?.generativeAI || "",
            id: item?.id,
            generativeAI: item.settings?.generativeAI || "",
            enableGenerativeAIExplain: item?.settings?.enableGenerativeAIExplain ?? false,
            enableGenerativeAISearch: item?.settings?.enableGenerativeAISearch ?? false,
            enableGenerativeAIStore: item?.settings?.enableGenerativeAIStore ?? false,
            disableDropOutliers: item?.settings?.disableDropOutliers ?? false,
            disableDecimation: item?.settings?.disableDecimation ?? false,
            preferBarOverDoughnut: item?.settings?.preferBarOverDoughnut ?? false,
            significantDigits: item?.settings?.significantDigits || "",
            defaultColorMap: item.settings?.defaultColorMap || colorOpt.empty.value,
            dateTimeFormat: item.settings?.dateTimeFormat || dateFormatOpt.empty.value,
            logoUrl: item?.settings?.logoUrl || "",
            faviconUrl: item?.settings?.faviconUrl || "",
        }));
    }

    function parseUpdateRequest(updatedRecord: AccountType): UpdateAccountRequestType {
        const {
            id,
            name,
            dateTimeFormat,
            significantDigits,
            defaultColorMap,
            enableGenerativeAIExplain,
            generativeAI,
            enableGenerativeAISearch,
            enableGenerativeAIStore,
            disableDecimation,
            disableDropOutliers,
            preferBarOverDoughnut,
            faviconUrl,
            logoUrl,
        } = updatedRecord;

        const settings = {
            ...(significantDigits !== "" && { significantDigits: Number(significantDigits) }),
            ...(defaultColorMap !== colorOpt.empty.value && { defaultColorMap }),
            ...(dateTimeFormat !== dateFormatOpt.empty.value && { dateTimeFormat }),
            ...(faviconUrl !== "" && { faviconUrl }),
            ...(logoUrl !== "" && { logoUrl }),

            enableGenerativeAIExplain,
            enableGenerativeAISearch,
            enableGenerativeAIStore,
            generativeAI,
            disableDropOutliers,
            disableDecimation,
            preferBarOverDoughnut,
        };
        return {
            id: id!, // Assuming `id` is always present for updates
            name,
            settings: settings,
        };
    }

    function onSelectDropdown(name: string, data: any) {
        if (data.selection.length === 0) return;
        setRecord((prev) => ({ ...prev, [name]: data.selection[0].value }));
    }

    function onEdit() {
        setFieldErrors({});
        setMode("edit");
    }
    function handleChildToParent(event: EventType) {
        if (event.type === "record" && event.action === "update") refreshAccount();
    }

    return (
        <PsRecord2
            defaultRecord={defaultRecord}
            recordLabel="Account"
            recordModule="core"
            recordObject={"account"}
            record={record}
            showEdit
            mode={mode}
            recordId={recordId}
            parentId={parentId}
            propagateEvent={propagateEvent}
            setMode={setMode}
            onEdit={onEdit}
            setRecord={setRecord}
            setFieldErrors={setFieldErrors}
            loading={loading}
            setLoading={setLoading}
            parseResponse={parseResponse}
            parseUpdateRequest={parseUpdateRequest}
            childToParent={handleChildToParent}
            cardBody={
                <div className="slds-form slds-m-around_medium" role="list">
                    <h3 className="slds-section-title--divider slds-m-top_medium">Account details</h3>

                    <div className="slds-form__row">
                        <Field
                            recordObject="account"
                            mode={mode}
                            value={record.name}
                            label="Name"
                            onEdit={onEdit}
                            setRecord={setRecord}
                            fieldName="name"
                            isEditable
                            body={<Input id="name" name="name" label="Name" value={record.name} />}
                        />
                        <Field mode="view" value={record.setupStatus} label="Setup Status" isEditable={false} />
                    </div>

                    <h3 className="slds-section-title--divider slds-m-top_medium">Settings</h3>
                    <div className="slds-form__row">
                        <Field
                            mode={mode}
                            value={genAiOpt[record.generativeAI].label}
                            label="Generative AI Access Level"
                            onEdit={onEdit}
                            isEditable
                            customChangeHandler
                            body={
                                <Combobox
                                    events={{ onSelect: (_event: any, data: any) => onSelectDropdown("generativeAI", data) }}
                                    labels={{ label: "Generative AI Access Level", placeholder: "--Please Select--" }}
                                    options={genAiOptions}
                                    selection={[genAiOpt[record.generativeAI]]}
                                    variant="readonly"
                                />
                            }
                        />
                        <Field
                            customChangeHandler
                            mode={mode}
                            label="Enable Generative AI Explain"
                            onEdit={onEdit}
                            isEditable
                            value={mode === "edit" ? "" : <CheckAndCloseIcons selectedItem={record.enableGenerativeAIExplain} />}
                            body={
                                <Toggle
                                    label="Enable Generative AI Explain"
                                    active={record.enableGenerativeAIExplain}
                                    setActive={() => setRecord((prev) => ({ ...prev, enableGenerativeAIExplain: !record.enableGenerativeAIExplain }))}
                                />
                            }
                        />
                    </div>
                    <div className="slds-form__row">
                        <Field
                            customChangeHandler
                            mode={mode}
                            label="Enable Generative AI Search"
                            onEdit={onEdit}
                            isEditable
                            value={mode === "edit" ? "" : <CheckAndCloseIcons selectedItem={record.enableGenerativeAISearch} />}
                            body={
                                <Toggle
                                    label="Enable Generative AI Search"
                                    active={record.enableGenerativeAISearch}
                                    setActive={() => setRecord((prev) => ({ ...prev, enableGenerativeAISearch: !record.enableGenerativeAISearch }))}
                                />
                            }
                        />
                        <Field
                            customChangeHandler
                            mode={mode}
                            label="Enable Generative AI Store"
                            onEdit={onEdit}
                            isEditable
                            value={mode === "edit" ? "" : <CheckAndCloseIcons selectedItem={record.enableGenerativeAIStore} />}
                            body={
                                <Toggle
                                    label="Enable Generative AI Store"
                                    active={record.enableGenerativeAIStore}
                                    setActive={() => setRecord((prev) => ({ ...prev, enableGenerativeAIStore: !record.enableGenerativeAIStore }))}
                                />
                            }
                        />
                    </div>
                    <div className="slds-form__row">
                        <Field
                            recordObject="account"
                            mode={mode}
                            value={record.logoUrl}
                            label="Logo Url"
                            onEdit={onEdit}
                            isEditable
                            setRecord={setRecord}
                            fieldName="logoUrl"
                            body={
                                <Input
                                    id="logoUrl"
                                    name="logoUrl"
                                    label="Logo Url"
                                    value={record.logoUrl}
                                    fieldLevelHelpTooltip={
                                        <Tooltip
                                            id="field-level-help-tooltip"
                                            align="top left"
                                            content="Provide the url of your logo in .png or .jpg format with a maximum height of 400 pixels and maximum width of 200 pixels."
                                        />
                                    }
                                />
                            }
                        />
                        <Field
                            recordObject="account"
                            mode={mode}
                            value={record.faviconUrl}
                            label="Favicon Url"
                            fieldName="faviconUrl"
                            onEdit={onEdit}
                            isEditable
                            setRecord={setRecord}
                            body={
                                <Input
                                    id="faviconUrl"
                                    name="faviconUrl"
                                    label="Favicon Url"
                                    value={record.faviconUrl}
                                    fieldLevelHelpTooltip={
                                        <Tooltip
                                            id="field-level-help-tooltip"
                                            align="top left"
                                            content="Provide the url of your favorite icon in .png, .bmp or .ico format with a maximum height of 64 pixels and maximum width of 64 pixels"
                                        />
                                    }
                                />
                            }
                        />
                    </div>

                    <div className="slds-form__row">
                        <Field
                            customChangeHandler
                            mode={mode}
                            label="Prefer Bar Over Doughnut"
                            onEdit={onEdit}
                            isEditable
                            value={mode === "edit" ? "" : <CheckAndCloseIcons selectedItem={record.preferBarOverDoughnut} />}
                            body={
                                <Toggle
                                    label="Prefer Bar Over Doughnut"
                                    active={record.preferBarOverDoughnut}
                                    setActive={() => setRecord((prev) => ({ ...prev, preferBarOverDoughnut: !record.preferBarOverDoughnut }))}
                                />
                            }
                        />
                        <Field
                            customChangeHandler
                            mode={mode}
                            label="Disable Drop Outliers"
                            onEdit={onEdit}
                            isEditable
                            value={mode === "edit" ? "" : <CheckAndCloseIcons selectedItem={record.disableDropOutliers} />}
                            body={
                                <Toggle
                                    active={record.disableDropOutliers}
                                    label="Disable Drop Outliers"
                                    setActive={() => setRecord((prev) => ({ ...prev, disableDropOutliers: !record.disableDropOutliers }))}
                                />
                            }
                        />
                        <Field
                            customChangeHandler
                            mode={mode}
                            label="Disable Decimation"
                            onEdit={onEdit}
                            isEditable
                            value={mode === "edit" ? "" : <CheckAndCloseIcons selectedItem={record.disableDecimation} />}
                            body={
                                <Toggle
                                    active={record.disableDecimation}
                                    label="Disable Decimation"
                                    setActive={() => setRecord((prev) => ({ ...prev, disableDecimation: !record.disableDecimation }))}
                                />
                            }
                        />
                    </div>

                    <div className="slds-form__row">
                        <Field
                            customChangeHandler
                            mode={mode}
                            value={colorOpt[record.defaultColorMap].label}
                            label="Chart Color Scheme"
                            onEdit={onEdit}
                            isEditable
                            body={
                                <Combobox
                                    events={{ onSelect: (_event: any, data: any) => onSelectDropdown("defaultColorMap", data) }}
                                    labels={{ label: "Chart Color Scheme", placeholder: "--Please Select--" }}
                                    options={colorOptions}
                                    selection={[colorOpt[record.defaultColorMap]]}
                                    variant="readonly"
                                />
                            }
                        />
                        <Field
                            recordObject="account"
                            mode={mode}
                            value={record.significantDigits}
                            label="Significant Digits"
                            onEdit={onEdit}
                            setRecord={setRecord}
                            fieldName="significantDigits"
                            isEditable
                            body={<Input type="number" id="significantDigits" name="significantDigits" label="Significant Digits" value={record.significantDigits} />}
                        />
                        <Field
                            customChangeHandler
                            mode={mode}
                            value={dateFormatOpt[record.dateTimeFormat].label}
                            label="Date and Time Format"
                            onEdit={onEdit}
                            isEditable
                            body={
                                <Combobox
                                    events={{ onSelect: (_event: any, data: any) => onSelectDropdown("dateTimeFormat", data) }}
                                    labels={{ label: "Date and Time Format", placeholder: "--Please Select--" }}
                                    options={dateFormatOptions}
                                    selection={[dateFormatOpt[record.dateTimeFormat]]}
                                    variant="readonly"
                                />
                            }
                        />
                    </div>

                    <h3 className="slds-section-title--divider slds-m-top_medium">Limits</h3>
                    <div className="slds-form__row">
                        <Field mode="view" value={record.deferUpdatesInterval} label="Analysis Update Interval" isEditable={false} />
                        <Field mode="view" value={record.maxNumPatterns} label="Maximum Discovered Patterns" isEditable={false} />
                        <Field mode="view" value={record.limitsGenerativeAI} label="Generative AI Access Level" isEditable={false} />
                    </div>
                    <h3 className="slds-section-title--divider slds-m-top_medium">Usage</h3>
                    <div className="slds-form__row">
                        <Field mode="view" value={record.computeCredit} label="Compute Credits" isEditable={false} />
                        <Field mode="view" value={record.totalStorageBytes} label="Storage Usage" isEditable={false} />
                    </div>
                </div>
            }
        />
    );
};

export default PsAccount;
