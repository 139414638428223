import React from "react";
import { BrowserRouter } from "react-router-dom";
import { IconSettings } from "@salesforce/design-system-react";

import "./App.css";
import "./index.css";
import Router from "./router/Router";
import { AuthContextProvider } from "./context/useAuthContext";
import { ToastContextProvider } from "./context/useToastContext";
import ToastComponent2 from "./components/toast-component/ToastComponent2";
import { GlobalStateContextProvider } from "../src/context/useGlobalStateContext";

const App = () => {
    return (
        <ToastContextProvider>
            <AuthContextProvider>
                <BrowserRouter>
                    <IconSettings iconPath="/assets/icons">
                        <GlobalStateContextProvider>
                            <Router />
                            <ToastComponent2 />
                        </GlobalStateContextProvider>
                    </IconSettings>
                </BrowserRouter>
            </AuthContextProvider>
        </ToastContextProvider>
    );
};

export default App;
