import React, { Tooltip, Button, Icon, Pill } from "@salesforce/design-system-react";
import "./DropBox.css";
import { Tag } from "antd";
import { BOX_DATA } from "./constants";
import { SlotTypeWithoutFlt, SlotType, UpdateValuesType, DroppedItemType } from "./types";
import { TREE_DATA_ITEMS } from "../../../../components/navigation-tree/constants";
import { createDroppedItem } from "./utils";

interface DropBoxProps {
    slot: SlotTypeWithoutFlt;
    dropAllowed: boolean;
    idList: string[];
    onUpdateValues: (val: UpdateValuesType) => void;
    setGrabbedItem: (val: any) => void;
    getItem: (id: string) => DroppedItemType | null;
}

const DropBox = ({ dropAllowed, setGrabbedItem, onUpdateValues, slot, idList, getItem }: DropBoxProps) => {
    function onDrop(e) {
        e.preventDefault();
        if (!dropAllowed) return;
        const dataJson = e.dataTransfer.getData("data");
        if (!!dataJson) {
            setGrabbedItem(null);
            const { dragBoxSlot, value }: { dragBoxSlot: SlotTypeWithoutFlt; value: DroppedItemType } = JSON.parse(dataJson);
            if (dragBoxSlot === slot) return;
            onUpdateValues({ delete: { slot: dragBoxSlot, id: value.id }, add: { slot, id: value.id } });
            return;
        }

        const field = JSON.parse(e.dataTransfer.getData("item"));
        let title = "";
        field.breadcrumb?.forEach((breadcrumbItem) => {
            if (breadcrumbItem.name) title = title ? title + " -> " + breadcrumbItem.name : breadcrumbItem.name;
        });
        const obj = field?.setting?.config;

        const value = createDroppedItem({ id: field.id, title, label: field.label, obj });
        onUpdateValues({ add: { slot, value, id: value.id } });
    }
    function oDragOver(e) {
        e.preventDefault();
        if (!dropAllowed) {
            e.dataTransfer.dropEffect = "none";
        } else {
            e.dataTransfer.dropEffect = "copy";
        }
    }

    function onDragStart(e: any, item: DroppedItemType, slot: SlotType) {
        e.dataTransfer.setData("data", JSON.stringify({ value: item, dragBoxSlot: slot }));
        setGrabbedItem({ item, origin: "box" });
    }

    return (
        <div onDrop={onDrop} onDragOver={oDragOver} className="builder-drop-box-container">
            <div className="box-name-wrapper">
                <p className="box-name">{BOX_DATA[slot].name}</p>
                <div className="tooltip-wrapper">
                    <Tooltip content={BOX_DATA[slot].info} id="tooltip" align="top left" variant="learnMore" />
                </div>
            </div>

            <div className={`drop-zone ${!dropAllowed ? "disabled" : "enabled"} `}>
                {!dropAllowed && (
                    <span className="drop-zone-container disabled">
                        <Icon assistiveText={{ label: "Ban" }} category="utility" name="ban" size="x-small" />
                        <p className="text">Can not drop this item</p>
                    </span>
                )}
                {dropAllowed && (
                    <span className="drop-zone-container">
                        {!idList?.length ? (
                            <p className="text">Drag from selection menu on the left</p>
                        ) : (
                            <>
                                {idList.map((id, i) => {
                                    const item = getItem(id);
                                    if (!item) return null;
                                    return (
                                        <div key={i} onDragStart={(e: any) => onDragStart(e, item, slot)} onDragEnd={() => setGrabbedItem(null)} draggable>
                                            <Pill
                                                className="tag-drop-box"
                                                key={i}
                                                variant="option"
                                                labels={{ label: TREE_DATA_ITEMS[item.obj].name + " : " + item.label, title: item.title, removeTitle: "Remove" }}
                                                onRemove={() => onUpdateValues({ delete: { slot, id } })}
                                            />
                                        </div>
                                    );
                                })}
                                <div className="delete-all-box">
                                    <Button
                                        iconCategory="utility"
                                        iconName="clear"
                                        variant="icon"
                                        title="Clear"
                                        iconVariant="bare"
                                        iconSize="small"
                                        onClick={() => onUpdateValues({ deleteAll: slot })}
                                    />
                                </div>
                            </>
                        )}
                    </span>
                )}
            </div>
        </div>
    );
};

export default DropBox;
