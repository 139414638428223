import React, { useEffect, useState } from "react";
import { Card } from "@salesforce/design-system-react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

import "./Profile.css";
import useAuthContext from "../../context/useAuthContext.js";
import PsSetupStatus from "../../components/ps-setup-status/PsSetupStatus";
import NavigationTree from "../../components/navigation-tree/NavigationTree";
import PsUser from "../../components/ps-user/PsUser";
import { TREE_SECTIONS, TREE_STATIC_DATA } from "../../components/navigation-tree/constants";
import { TreeSectionKeys } from "../../components/navigation-tree/types";
import { EventType } from "../types";
import PsNavigationHeader from "../../components/ps-navigation-header/PsNavigationHeader";
import PsErrorBoundary from "../../components/ps-error-boundary/PsErrorBoundary";

const treeSectionList: TreeSectionKeys[] = ["profile"]; // type TreeSectionsKeys[] from types navigation tree
const defaultSection = treeSectionList[0];
const defaultObj = TREE_SECTIONS[defaultSection].treeItems[0];
const defaultId = TREE_STATIC_DATA[defaultObj][0].id; // Tree static data setup for setupOverview section (use id to change view in right side)
const defaultSelected = `${defaultSection}_${defaultObj}_${defaultId}`;

function Profile() {
    const [selected, setSelected] = useState<string>(defaultSelected);
    const [selectedId, setSelectedId] = useState<string>(defaultId);
    const [propagateEvent, setPropagateEvent] = useState<EventType>();

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const { profile, refreshProfile, handleLogout } = useAuthContext();
    const [setupStatusVisible, setSetupStatusVisible] = useState(true);
    useEffect(() => {
        syncStateWithUrl(searchParams, true);
    }, []);

    function syncStateWithUrl(searchParams: URLSearchParams, isFromParams?: boolean) {
        const urlSelected = searchParams.get("selected") || defaultSelected;
        if (urlSelected !== selected) {
            const [_section, _obj, id] = urlSelected.split("_");
            setSelected(urlSelected);
            setSelectedId(id);
        }
        // if is from params do not update searchParams
        if (!isFromParams) setSearchParams(searchParams);
    }

    function handleEventRouter(event: EventType) {
        switch (event.type) {
            case "tab":
                // TODO: change event.tab to event.path
                navigate("/" + event.tab);
                break;
            case "logout":
                handleLogout();
                break;
            case "sync":
                setPropagateEvent(event);
                break;
            case "record":
                if (event.id === profile?.id) refreshProfile();
                setPropagateEvent(event);
                break;
            case "navigation":
                const { id, obj, section } = event;
                searchParams.set("selected", `${section}_${obj}_${id}`);
                searchParams.delete("formId");
                setSearchParams(searchParams);
                break;

            default:
                setPropagateEvent(event);
                break;
        }
    }

    return (
        <div className="profile-page-container">
            <PsNavigationHeader childToParent={handleEventRouter} />
            <div className="tab-content slds-p-around_medium">
                <Card className="slds-m-right_medium card-height-full" heading={<span className="card-main-title-lh32 slds-card__header-title">Profile</span>}>
                    <NavigationTree pxOffsetHV={194} showSearch={false} selected={[selected]} sectionList={treeSectionList} bubbleEvent={handleEventRouter} propagateEvent={propagateEvent} />
                </Card>
                {/* <!-- right panel view --> */}
                <div className="um-right" style={{ overflow: setupStatusVisible ? "hidden" : "scroll" }}>
                    <PsSetupStatus title="Profile" tagLine="Manage your profile." includeStatus={["Error"]} onSetupStatusVisible={setSetupStatusVisible} />
                    {!!profile?.id && (selectedId === defaultId || selectedId === TREE_SECTIONS[defaultSection].id) && (
                        <PsUser recordId={profile.id} childToParent={handleEventRouter} propagateEvent={propagateEvent} />
                    )}
                </div>
            </div>
        </div>
    );
}
const ErrorHandledProfile = () => <PsErrorBoundary children={<Profile />} />;
export default ErrorHandledProfile;
