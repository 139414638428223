import React from "react";
import { Button, Spinner, Icon } from "@salesforce/design-system-react";

import "./PsNavigationHeader.css";
import { EventType } from "../../pages/types";

interface Props {
    childToParent?: (event: EventType) => void;
    loading?: boolean;
    showClose?: boolean;
    showFilters?: boolean;
    showUndo?: boolean;
    showRedo?: boolean;
    showRefresh?: boolean;
    showApplyNow?: boolean;
    applyNow?: boolean;
    setApplyNow?: (val: boolean) => void;
}

function PsNavigationHeader({ childToParent, loading, showClose, showFilters, showUndo, showRedo, showRefresh = true, showApplyNow, applyNow, setApplyNow }: Props) {
    const handleCloseDetail = () => {
        dispatchEvent({ type: "navigation", source: "header bar", action: "close" });
    };

    const handleApply = () => {
        dispatchEvent({ type: "sync", action: "apply" });
    };

    const handleUndo = () => {
        dispatchEvent({ type: "payload", action: "undo" });
    };

    const handleRedo = () => {
        dispatchEvent({ type: "payload", action: "redo" });
    };

    const handleReload = () => {
        dispatchEvent({ type: "sync", action: "reload" });
    };

    const handleDataFilters = () => {
        console.error("handleDataFilters");
    };

    const handleSupport = () => {
        console.error("handleSupport");
    };

    const dispatchEvent = (event) => {
        childToParent?.(event);
    };

    const handleUndoRedo = (item) => {
        window.history[item]();
    };

    const handleApplyChange = (event) => {
        let checked = event?.target?.checked;
        setApplyNow?.(checked);
        if (checked) handleApply();
    };

    // This styling will be applied to a <NavLink> when the
    // route that it links to is currently selected.
    // let activeClassName = "slds-context-bar__item slds-is-active";
    // let inactiveClassName = "slds-context-bar__item";
    return (
        <div id="navigationHeader" className="navigationHeader">
            <div className="navigationHeaderItem">
                {!!showClose && (
                    <div className="navigationHeaderButton slds-p-around_medium borderRight" onClick={handleCloseDetail}>
                        <Icon assistiveText={{ label: "Back" }} category="utility" name="back" size="x-small" inverse />
                        &nbsp;Back to Results
                    </div>
                )}
            </div>
            <div className="navigationHeaderItem borderRight"></div>
            <div className="navigationHeaderItemRight">
                {!!showFilters && (
                    <div id="dataFiltersButton" className="navigationHeaderButton slds-p-around_medium borderRight" onClick={handleDataFilters}>
                        <Icon assistiveText={{ label: "Filter" }} category="utility" name="filterList" size="x-small" inverse />
                        &nbsp;Data Filters&nbsp;
                        <Icon assistiveText={{ label: "Down" }} category="utility" name="chevrondown" size="x-small" inverse />
                    </div>
                )}

                {!!showApplyNow && (
                    <>
                        <div className="navigationHeaderButton slds-p-horizontal_medium borderRight">
                            <Button
                                label={
                                    <>
                                        Apply changes
                                        {loading && !applyNow ? <Spinner size="small" /> : null}
                                    </>
                                }
                                disabled={applyNow}
                                onClick={() => handleApply()}
                                title="Apply changes now"
                            />
                        </div>
                        <div className="navigationHeaderButton slds-p-horizontal_medium borderRight" style={{ marginLeft: -10, paddingRight: 2 }}>
                            <div style={{ margin: "10px" }}>
                                <div className="slds-form-element">
                                    <div className="slds-form-element__control">
                                        <div className="slds-checkbox">
                                            <input
                                                type="checkbox"
                                                name="options"
                                                id="checkbox-unique-id-159"
                                                value="checkbox-unique-id-159"
                                                onChange={(e) => handleApplyChange(e)}
                                                checked={applyNow}
                                            />
                                            <label className="slds-checkbox__label" htmlFor="checkbox-unique-id-159">
                                                <span className="slds-checkbox_faux"></span>
                                                <span className="slds-form-element__label" style={{ color: "white" }}>
                                                    Apply changes immediately
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}

                {!!showUndo && (
                    <>
                        <div className="navigationHeaderButton slds-p-around_medium borderRight" onClick={() => handleUndo()}>
                            <Icon assistiveText={{ label: "Undo" }} category="utility" name="undo" size="x-small" inverse title="Undo" />
                        </div>
                    </>
                )}

                {!!showRedo && (
                    <>
                        <div className="navigationHeaderButton slds-p-around_medium borderRight" onClick={() => handleRedo()}>
                            <Icon assistiveText={{ label: "Redo" }} category="utility" name="redo" size="x-small" inverse title="Redo" />
                        </div>
                    </>
                )}

                {!!showRefresh && (
                    <>
                        <div className="navigationHeaderButton slds-p-around_medium borderRight" onClick={handleReload}>
                            <Icon assistiveText={{ label: "Refresh" }} category="utility" name="refresh" size="x-small" inverse title="Refresh" />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default PsNavigationHeader;
