import React, { forwardRef, useImperativeHandle } from "react";
import { Button, Checkbox, Input, Textarea, Modal } from "@salesforce/design-system-react";
import Combobox from "@salesforce/design-system-react/components/combobox";

import Field from "../../../ui/wrappers/Field";
import { checkRequiredField } from "../../../utils/index2";
import FormattedTooltip from "./FormattedTooltip";

interface AuthDetailsModalRef {
    modalContent: React.ReactNode;
}

interface AuthDetailsModalPropsType {
    loading: boolean;
    connectOptions: any[];
    selectedConnectOption: any;
    selectedConnectMethod: any;
    selectConnect: (option: any) => void;
    setConnectProperty: Function;
    selectConnectMethodInput: Function;
    handleCancelCredentials: Function;
    handleSaveCredentials: Function;
    connectSelection: any;
    fieldErrors?: { [key: string]: string };
    setFieldErrors: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>;
    ref?: React.RefObject<AuthDetailsModalRef>;
}

const AuthDetailsModal = forwardRef<AuthDetailsModalRef, AuthDetailsModalPropsType>(
    (
        {
            loading,
            connectOptions,
            selectedConnectOption,
            selectedConnectMethod,
            selectConnect,
            setConnectProperty,
            selectConnectMethodInput,
            handleCancelCredentials,
            handleSaveCredentials,
            connectSelection,
            fieldErrors,
            setFieldErrors,
        },
        ref
    ) => {
        useImperativeHandle(ref, () => ({
            modalContent,
        }));

        const modalContent = (
            <div className="slds-modal__content slds-p-around_medium" id="modal-content-id-1">
                <div className="slds-form slds-m-around_medium" role="list">
                    <div className="message">
                        <p>Point Sigma will connect to your source system to download data. Your authentication details are securely encrypted using AES-256 cryptography. </p>
                        <p>Point Sigma will only download data that is accessible to the user whose authentication details you provide.</p>
                    </div>

                    {/* <!-- only show Authentiation method combobox if it has more than 1 option (selectedConnectOption is already set automatically in code if there is just one option anyway) --> */}
                    {connectOptions[1] && (
                        <div className="slds-form__row">
                            <div className="slds-form__item slds-grid_vertical-align-center" role="listitem">
                                <div className="slds-form-element slds-form-element_stacked">
                                    <Field
                                        mode="edit"
                                        value={selectedConnectOption}
                                        fieldName="selectedConnectOption"
                                        isFormItem
                                        checkValidity={checkRequiredField}
                                        setFieldErrors={setFieldErrors}
                                        customChangeHandler
                                        body={
                                            <Combobox
                                                menuItemVisibleLength={10}
                                                menuPosition="relative"
                                                name="selectedConnectOption"
                                                events={{
                                                    onSelect: (event: React.ChangeEvent, data: any) => {
                                                        selectConnect(data);
                                                    },
                                                }}
                                                labels={{
                                                    label: "Authentication Method",
                                                    placeholderReadOnly: "--Please Select--",
                                                }}
                                                options={connectOptions}
                                                selection={connectSelection}
                                                value={selectedConnectOption}
                                                variant="readonly"
                                                required={true}
                                                errorText={fieldErrors?.selectedConnectOption}
                                            />
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    )}

                    {selectedConnectMethod &&
                        selectedConnectMethod.inputs &&
                        selectedConnectMethod.inputs.map((item1: any, index: number) => {
                            return (
                                // extra div might break the row part
                                <div key={"selectedConnectMethod_inputs_" + index}>
                                    {!item1.oauth && item1.type && item1.type !== "section" && (
                                        <div className="slds-form__row">
                                            <div className="slds-form__item slds-grid_vertical-align-center" role="listitem">
                                                <div className="slds-form-element slds-form-element_stacked">
                                                    {(item1.type === "text" || item1.type === "password") && (
                                                        <>
                                                            <FormattedTooltip item={item1} />
                                                            <Field
                                                                mode="edit"
                                                                value={item1.value || ""}
                                                                fieldName={item1.name}
                                                                isFormItem
                                                                checkValidity={item1.required ? checkRequiredField : null}
                                                                setFieldErrors={setFieldErrors}
                                                                customChangeHandler
                                                                body={
                                                                    <Input
                                                                        name={item1.name}
                                                                        type={item1.type}
                                                                        placeholder={item1.placeholder}
                                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setConnectProperty(e.target.value, [index], item1.name)}
                                                                        value={item1.value || ""}
                                                                        required={item1.required}
                                                                        pattern={item1.pattern}
                                                                        errorText={fieldErrors?.[item1.name]}
                                                                    />
                                                                }
                                                            />
                                                        </>
                                                    )}

                                                    {item1.type === "checkbox" && (
                                                        <div className="slds-grid">
                                                            <Field
                                                                mode="edit"
                                                                value={item1.value || ""}
                                                                fieldName={item1.name}
                                                                isFormItem
                                                                checkValidity={item1.required ? checkRequiredField : null}
                                                                setFieldErrors={setFieldErrors}
                                                                customChangeHandler
                                                                body={
                                                                    <Checkbox
                                                                        name={item1.name}
                                                                        assistiveText={{ label: item1.label }}
                                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setConnectProperty(e.target.checked, [index], item1.name, item1.type)}
                                                                        checked={item1.value === undefined ? false : item1.value}
                                                                        required={item1.required}
                                                                        pattern={item1.pattern}
                                                                        errorText={fieldErrors?.[item1.name]}
                                                                    />
                                                                }
                                                            />
                                                            <FormattedTooltip item={item1} />
                                                        </div>
                                                    )}
                                                    {item1.type === "number" && (
                                                        <>
                                                            <FormattedTooltip item={item1} />
                                                            <Field
                                                                mode="edit"
                                                                value={item1.value || ""}
                                                                fieldName={item1.name}
                                                                isFormItem
                                                                checkValidity={item1.required ? checkRequiredField : null}
                                                                setFieldErrors={setFieldErrors}
                                                                customChangeHandler
                                                                body={
                                                                    <Input
                                                                        name={item1.name}
                                                                        type="text"
                                                                        placeholder={item1.placeholder?.toString()}
                                                                        label=""
                                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setConnectProperty(e.target.value, [index], item1.name)}
                                                                        value={item1.value || ""}
                                                                        required={item1.required}
                                                                        pattern={item1.pattern ? item1.pattern : "^\\d*"}
                                                                        errorText={fieldErrors?.[item1.name]}
                                                                    />
                                                                }
                                                            />
                                                        </>
                                                    )}

                                                    {item1.type === "textarea" && (
                                                        <>
                                                            <FormattedTooltip item={item1} />
                                                            <Field
                                                                mode="edit"
                                                                value={item1.value || ""}
                                                                fieldName={item1.name}
                                                                isFormItem
                                                                checkValidity={item1.required ? checkRequiredField : null}
                                                                setFieldErrors={setFieldErrors}
                                                                customChangeHandler
                                                                body={
                                                                    <Textarea
                                                                        name={item1.name}
                                                                        label=""
                                                                        placeholder={item1.placeholder}
                                                                        value={item1.value || ""}
                                                                        required={item1.required}
                                                                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setConnectProperty(e.target.value, [index], item1.name)}
                                                                        errorText={fieldErrors?.[item1.name]}
                                                                    />
                                                                }
                                                            />
                                                        </>
                                                    )}
                                                    {item1.type === "list" && (
                                                        <>
                                                            <FormattedTooltip item={item1} />
                                                            <Field
                                                                mode="edit"
                                                                value={item1.value || ""}
                                                                fieldName={item1.name}
                                                                isFormItem
                                                                checkValidity={item1.required ? checkRequiredField : null}
                                                                setFieldErrors={setFieldErrors}
                                                                customChangeHandler
                                                                body={
                                                                    <Input
                                                                        name={item1.name}
                                                                        type="text"
                                                                        placeholder={item1.placeholder}
                                                                        label=""
                                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setConnectProperty(e.target.value, [index], item1.name)}
                                                                        value={item1.value || ""}
                                                                        required={item1.required}
                                                                        pattern={item1.pattern}
                                                                        errorText={fieldErrors?.[item1.name]}
                                                                    />
                                                                }
                                                            />
                                                        </>
                                                    )}
                                                    {item1.type === "select" && (
                                                        <>
                                                            <FormattedTooltip item={item1} />
                                                            <Field
                                                                mode="edit"
                                                                value={item1.value || ""}
                                                                fieldName={item1.name}
                                                                isFormItem
                                                                checkValidity={item1.required ? checkRequiredField : null}
                                                                setFieldErrors={setFieldErrors}
                                                                customChangeHandler
                                                                body={
                                                                    <Combobox
                                                                        menuItemVisibleLength={10}
                                                                        menuPosition="relative"
                                                                        name={item1.name}
                                                                        events={{
                                                                            onSelect: (event: React.ChangeEvent, data: any) => {
                                                                                selectConnectMethodInput(data, index, item1.name);
                                                                            },
                                                                        }}
                                                                        labels={{
                                                                            label: "",
                                                                            placeholderReadOnly: item1.placeholder,
                                                                        }}
                                                                        options={item1.options}
                                                                        selection={[item1.options?.find((option) => option.value === item1.value)]}
                                                                        value={item1.value}
                                                                        variant="readonly"
                                                                        required={item1.required}
                                                                        errorText={fieldErrors?.[item1.name]}
                                                                    />
                                                                }
                                                            />
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {/* <!-- subsection, optionally with conditional input set selector dropdown --> */}
                                    {!item1.oauth && item1.type === "section" && (
                                        <div className="slds-box slds-m-bottom_x-small slds-m-horizontal_xx-small">
                                            {!item1.options && <FormattedTooltip item={item1} />}
                                            {item1.options && (
                                                <>
                                                    <FormattedTooltip item={{ ...item1, required: true }} />
                                                    <Field
                                                        mode="edit"
                                                        value={item1.value || ""}
                                                        fieldName={item1.name}
                                                        isFormItem
                                                        checkValidity={checkRequiredField}
                                                        setFieldErrors={setFieldErrors}
                                                        customChangeHandler
                                                        body={
                                                            <Combobox
                                                                name={item1.name}
                                                                events={{
                                                                    onSelect: (event: React.ChangeEvent<HTMLInputElement>, data: any) => {
                                                                        selectConnectMethodInput(data, index, item1.name);
                                                                    },
                                                                }}
                                                                labels={{
                                                                    label: "",
                                                                    placeholderReadOnly: item1.placeholder || "--Please Select--",
                                                                }}
                                                                options={item1.options}
                                                                selection={[item1.options?.find((option) => option.value === item1.value)]}
                                                                value={item1.value}
                                                                variant="readonly"
                                                                required={true}
                                                                errorText={fieldErrors?.[item1.name]}
                                                            />
                                                        }
                                                    />
                                                </>
                                            )}

                                            {item1.inputs &&
                                                item1.inputs.map((item2: any, nestedIndex: number) => {
                                                    const combinedItemName = item1.name + item2.name;
                                                    return (
                                                        <div key={"item1_input_" + nestedIndex}>
                                                            {(!item1.options || item1.value === item2.option) && item2.type && item2.type !== "section" && (
                                                                <div className="slds-form__row">
                                                                    <div className="slds-form__item slds-grid_vertical-align-center" role="listitem">
                                                                        <div className="slds-form-element slds-form-element_stacked">
                                                                            {(item2.type === "text" || item2.type === "password") && (
                                                                                <>
                                                                                    <FormattedTooltip item={item2} />
                                                                                    <Field
                                                                                        mode="edit"
                                                                                        value={item2.value || ""}
                                                                                        fieldName={combinedItemName}
                                                                                        isFormItem
                                                                                        checkValidity={item2.required ? checkRequiredField : null}
                                                                                        setFieldErrors={setFieldErrors}
                                                                                        customChangeHandler
                                                                                        body={
                                                                                            <Input
                                                                                                name={combinedItemName}
                                                                                                type={item2.type}
                                                                                                placeholder={item2.placeholder}
                                                                                                label=""
                                                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                                                                    setConnectProperty(e.target.value, [index, nestedIndex], combinedItemName)
                                                                                                }
                                                                                                value={item2.value || ""}
                                                                                                required={item2.required}
                                                                                                pattern={item2.pattern}
                                                                                                errorText={fieldErrors?.[combinedItemName]}
                                                                                            />
                                                                                        }
                                                                                    />
                                                                                </>
                                                                            )}

                                                                            {item2.type === "checkbox" && (
                                                                                <div className="slds-grid">
                                                                                    <Field
                                                                                        mode="edit"
                                                                                        value={item2.value || ""}
                                                                                        fieldName={combinedItemName}
                                                                                        isFormItem
                                                                                        checkValidity={item2.required ? checkRequiredField : null}
                                                                                        setFieldErrors={setFieldErrors}
                                                                                        customChangeHandler
                                                                                        body={
                                                                                            <Checkbox
                                                                                                name={combinedItemName}
                                                                                                assistiveText={{
                                                                                                    label: item2.label,
                                                                                                }}
                                                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                                                                    setConnectProperty(e.target.checked, [index], combinedItemName)
                                                                                                }
                                                                                                checked={item2.value === undefined ? false : item2.value}
                                                                                                required={item2.required}
                                                                                                pattern={item2.pattern}
                                                                                                errorText={fieldErrors?.[combinedItemName]}
                                                                                            />
                                                                                        }
                                                                                    />
                                                                                    <FormattedTooltip item={item2} />
                                                                                </div>
                                                                            )}

                                                                            {item2.type === "number" && (
                                                                                <>
                                                                                    <FormattedTooltip item={item2} />
                                                                                    <Field
                                                                                        mode="edit"
                                                                                        value={item2.value || ""}
                                                                                        fieldName={combinedItemName}
                                                                                        isFormItem
                                                                                        checkValidity={item2.required ? checkRequiredField : null}
                                                                                        setFieldErrors={setFieldErrors}
                                                                                        customChangeHandler
                                                                                        body={
                                                                                            <Input
                                                                                                name={combinedItemName}
                                                                                                type="text"
                                                                                                placeholder={item2.placeholder?.toString()}
                                                                                                label=""
                                                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                                                                    setConnectProperty(e.target.value, [index, nestedIndex], combinedItemName)
                                                                                                }
                                                                                                value={item2.value || ""}
                                                                                                required={item2.required}
                                                                                                pattern={item2.pattern ? item2.pattern : "^\\d*"}
                                                                                                errorText={fieldErrors?.[combinedItemName]}
                                                                                            />
                                                                                        }
                                                                                    />
                                                                                </>
                                                                            )}

                                                                            {item2.type === "textarea" && (
                                                                                <>
                                                                                    <FormattedTooltip item={item2} />
                                                                                    <Field
                                                                                        mode="edit"
                                                                                        value={item2.value || ""}
                                                                                        fieldName={combinedItemName}
                                                                                        isFormItem
                                                                                        checkValidity={item2.required ? checkRequiredField : null}
                                                                                        setFieldErrors={setFieldErrors}
                                                                                        customChangeHandler
                                                                                        body={
                                                                                            <Textarea
                                                                                                name={combinedItemName}
                                                                                                label=""
                                                                                                placeholder={item2.placeholder}
                                                                                                value={item2.value || ""}
                                                                                                required={item2.required}
                                                                                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                                                                                                    setConnectProperty(e.target.value, [index, nestedIndex], combinedItemName)
                                                                                                }
                                                                                                errorText={fieldErrors?.[combinedItemName]}
                                                                                            />
                                                                                        }
                                                                                    />
                                                                                </>
                                                                            )}
                                                                            {item2.type === "list" && (
                                                                                <>
                                                                                    <FormattedTooltip item={item2} />
                                                                                    <Field
                                                                                        mode="edit"
                                                                                        value={item2.value || ""}
                                                                                        fieldName={combinedItemName}
                                                                                        isFormItem
                                                                                        checkValidity={item2.required ? checkRequiredField : null}
                                                                                        setFieldErrors={setFieldErrors}
                                                                                        customChangeHandler
                                                                                        body={
                                                                                            <Input
                                                                                                name={combinedItemName}
                                                                                                type="text"
                                                                                                placeholder={item2.placeholder}
                                                                                                label=""
                                                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                                                                    setConnectProperty(e.target.value, [index, nestedIndex], combinedItemName)
                                                                                                }
                                                                                                value={item2.value || ""}
                                                                                                required={item2.required}
                                                                                                pattern={item2.pattern}
                                                                                                errorText={fieldErrors?.[combinedItemName]}
                                                                                            />
                                                                                        }
                                                                                    />
                                                                                </>
                                                                            )}
                                                                            {item2.type === "select" && (
                                                                                <>
                                                                                    <FormattedTooltip item={item2} />
                                                                                    <Field
                                                                                        mode="edit"
                                                                                        value={item2.value || ""}
                                                                                        fieldName={combinedItemName}
                                                                                        isFormItem
                                                                                        checkValidity={item2.required ? checkRequiredField : null}
                                                                                        setFieldErrors={setFieldErrors}
                                                                                        customChangeHandler
                                                                                        body={
                                                                                            <Combobox
                                                                                                menuItemVisibleLength={10}
                                                                                                menuPosition="relative"
                                                                                                name={combinedItemName}
                                                                                                events={{
                                                                                                    onSelect: (event: React.ChangeEvent<HTMLInputElement>, data: any) => {
                                                                                                        selectConnectMethodInput(data, index, combinedItemName, nestedIndex);
                                                                                                    },
                                                                                                }}
                                                                                                labels={{
                                                                                                    label: "",
                                                                                                    placeholderReadOnly: item2.placeholder,
                                                                                                }}
                                                                                                options={item2.options}
                                                                                                selection={[item2.options?.find((option) => option.value === item2.value)]}
                                                                                                value={item2.value}
                                                                                                variant="readonly"
                                                                                                required={item2.required}
                                                                                                errorText={fieldErrors?.[combinedItemName]}
                                                                                            />
                                                                                        }
                                                                                    />
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                </div>
            </div>
        );

        const footerButtons = [
            {
                key: "cancel-button",
                label: "Cancel",
                onClick: handleCancelCredentials,
            },
            selectedConnectMethod.button
                ? {
                      key: "save-button",
                      label: selectedConnectMethod.button,
                      title: selectedConnectMethod.button,
                      onClick: handleSaveCredentials,
                      disabled: loading,
                      variant: "brand",
                  }
                : null,
        ].filter(Boolean);

        return (
            <Modal
                isOpen={true}
                footer={footerButtons.map((button) => (
                    <Button key={button.key} {...button} />
                ))}
                onRequestClose={handleCancelCredentials}
                heading="Authentication Details"
            >
                <div className="slds-modal__content slds-p-around_medium" id="modal-content-id-filter">
                    {modalContent}
                </div>
            </Modal>
        );
    }
);

export default AuthDetailsModal;
