import { TreeSectionsType, TreeItemsType } from "./types";

export const defaultsFilters = {
    orderBy: "name ASC",
    maxRecords: 100,
};

export const emptyNode = {
    id: "none-item-id", // this id is connected with custom css style
    label: "--None--",
    type: "item",
    selfDataKey: "none",
    disabled: true,
    breadcrumb: [{ name: "--None--", id: "none-item-id" }],
};
export const noSearchResultsNode = {
    id: "none-item-id", // this id is connected with custom css style
    label: "--No search results--",
    type: "item",
    selfDataKey: "none",
    disabled: true,
    breadcrumb: [{ name: "--None--", id: "none-item-id" }],
};

export const TREE_DATA_ITEMS: TreeItemsType = {
    //---> DYNAMIC ITEMS
    // NOTE dynamic items are under top level node that is define by TREE_SECTIONS data
    source: { type: "dynamic", module: "core", object: "source", name: "Source" },
    container: { type: "dynamic", module: "store", object: "container", name: "Container" },
    // Data, Fields,
    key: { type: "dynamic", module: "store", object: "key", name: "Field" },
    // Filters
    filter: { type: "dynamic", module: "store", object: "filter", name: "Filter" },
    // Maps
    map: { type: "dynamic", module: "store", object: "map", name: "Map" },
    // Paths
    chain: { type: "dynamic", module: "store", object: "chain", name: "Chain" },
    // Joins
    link: { type: "dynamic", module: "store", object: "link", name: "Link" },
    // Manage
    connector: { type: "dynamic", module: "pump", object: "connector", name: "Connector" },
    object: { type: "dynamic", module: "pump", object: "object", name: "Object" },
    field: { type: "dynamic", module: "pump", object: "field", name: "Field" },
    // Types
    dataRole: { type: "dynamic", module: "core", object: "datarole", name: "Data Role" },
    dataType: { type: "dynamic", module: "core", object: "datatype", name: "Data Type" },
    // Folders
    folder: { type: "dynamic", module: "relate", object: "folder", name: "Folder" },
    // Users
    user: { type: "dynamic", module: "core", object: "user", name: "User" },
    action: { type: "dynamic", module: "core", object: "action", name: "Action" },
    apikey: { type: "dynamic", module: "core", object: "apikey", name: "Api Key" },

    //----> STATIC ITEMS
    // NOTE static items are under top level node that is define by TREE_SECTIONS data

    // Aggregations
    agg: { type: "static", object: "agg", name: "Aggregation" },
    // Transformations
    transform: { type: "static", object: "transform", name: "Transform" },
    // Overview
    connectors: { type: "static", object: "connectors", name: "Connectors" },
    profileUser: { type: "static", object: "profileUser", name: "Profile User" },
    setup: { type: "static", object: "setup", name: "Setup" },
};

// NOTE please do not change order of treeItems array it define the tree level order structure
export const TREE_SECTIONS: TreeSectionsType = {
    data: { id: "data-id", name: "Data", treeItems: ["source", "container", "key"] },
    keys: { id: "keys-id", name: "Fields", treeItems: ["source", "container", "key"] },
    links: { id: "links-id", name: "Joins", treeItems: ["source", "container", "link"] },
    maps: { id: "maps-id", name: "Maps", treeItems: ["source", "container", "map"] },
    filters: { id: "filters-id", name: "Filters", treeItems: ["source", "container", "filter"] },
    chains: { id: "chains-id", name: "Paths", treeItems: ["source", "container", "chain"] },
    pump: { id: "pump-id", name: "Sources", treeItems: ["source", "connector", "object", "field"] },
    containers: { id: "containers-id", name: "Objects", treeItems: ["source", "container"] },
    folders: { id: "folders-id", name: "Folders", treeItems: ["folder"] },
    types: { id: "types-id", name: "Types", treeItems: ["dataRole", "dataType"] },
    aggs: { id: "aggs-id", name: "Aggregations", treeItems: ["agg"] },
    transforms: { id: "transforms-id", name: "Transformations", treeItems: ["transform"] },

    setupOverview: { id: "setupOverview-id", name: "Overview", treeItems: ["setup"] },
    overview: { id: "overview-id", name: "Overview", treeItems: ["connectors"] },
    profile: { id: "profile-id", name: "Overview", treeItems: ["profileUser"] },
};

// please when you creating tree static data use an object with capital name and key the id of the item. also added to the TREE_STATIC_DATA with same key as in TREE_DATA_ITEMS that you have create
export const AGG = {
    mean: { id: "mean", name: "Mean" },
    sum: { id: "sum", name: "Sum" },
    count: { id: "count", name: "Count" },
    nunique: { id: "nunique", name: "Unique Count" },
    min: { id: "min", name: "Min" },
    max: { id: "max", name: "Max" },
    median: { id: "median", name: "Median" },
    range: { id: "range", name: "Range" },
};
export const TRANSFORM = {
    "Transform - Number - Add": { id: "Transform - Number - Add", name: "Add" },
    "Transform - Time Difference": { id: "Transform - Time Difference", name: "Subtract" },
    "Transform - Number - Multiply": { id: "Transform - Number - Multiply", name: "Multiply" },
    "Transform - Number - Divide": { id: "Transform - Number - Divide", name: "Divide" },
    "Transform - Date and Time - Hour": { id: "Transform - Date and Time - Hour", name: "Hour" },
    "Transform - Date and Time - Day of Week": { id: "Transform - Date and Time - Day of Week", name: "Day of Week" },
    "Transform - Date and Time - Is Weekend": { id: "Transform - Date and Time - Is Weekend", name: "Is Weekend" },
    "Transform - Date and Time - Calendar Month": { id: "Transform - Date and Time - Calendar Month", name: "Calendar Month" },
    "Transform - Date and Time - Calendar Quarter": { id: "Transform - Date and Time - Calendar Quarter", name: "Calendar Quarter" },
};
export const CONNECTORS = {
    connectorList: { id: "connectorList", name: "Connectors" },
};
export const PROFILE_USER = {
    user: { id: "user", name: "User" },
};
export const SETUP = {
    users: { id: "users", name: "Users" },
    account: { id: "account", name: "Account Settings" },
    // apiKeys: { id: "apiKeys", name: "Api Keys" },
    actions: { id: "actions", name: "Actions" },
};

export const TREE_STATIC_DATA = {
    // Aggregations
    agg: Object.values(AGG),
    // Transformations
    transform: Object.values(TRANSFORM),
    // Overview
    connectors: Object.values(CONNECTORS),
    profileUser: Object.values(PROFILE_USER),
    setup: Object.values(SETUP),
};


export const iconMapping = {
    //key dataType name
    Count: "topic2",
    "Machine Identifier": "text",
    UUID: "text",
    "Ordered Category": "text",
    Money: "currency",
    Text: "text",
    Place: "text",
    "Zip / Postal Code": "text",
    "Date and Time": "event",
    Number: "topic2",
    Latitude: "topic2",
    Longitude: "topic2",
    SIC: "text",
    Date: "event",
    Boolean: "toggle",
    List: "groups",
    Multiple: "multi_picklist",
    "Machine Index": "text",
    Organization: "hierarchy",
    Story: "news",
    URL: "link",
    Other: "standard_objects",
    "Phone Number": "text",
    Email: "text",
    "Email Address": "text",
    Percentage: "percent",
    "Time Part": "date_time",

    //sections
    data: "database",
    keys: "database",
    links: "join",
    maps: "data_mapping",
    filters: "filterList",
    chains: "data_model",
    pump: "integration",
    containers: "database",
    folders: "open_folder",
    types: "coverage_type",
    aggs: "aggregate",
    transforms: "data_transforms",
    setupOverview: "more",
    overview: "ad_set",
    profile: "more",
};
