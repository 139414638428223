import { useState, useEffect } from "react";
import { Combobox } from "@salesforce/design-system-react";
import { instances } from "../../utils";
import { currentInstance } from "../../services/api";
import useAuthContext from "../../context/useAuthContext";

const RegionSelection = () => {
    // TODO: rename to InstanceSelection
    const { login, setLogin } = useAuthContext();
    const [instanceList, setInstanceList] = useState([]);

    useEffect(() => {
        setInstanceList(instances());
        const instance = login?.instance || currentInstance();
        setLogin((prev) => ({ ...prev, instance }));
    }, []);

    const handleInstanceChange = (data) => {
        const instance = data?.selection?.length ? data.selection[0].value : null;
        setLogin((prev) => ({ ...prev, instance }));
    };

    return (
        <>
            {instanceList.length > 1 ? (
                <div className="user-inputs">
                    <Combobox
                        events={{
                            onSelect: (event, data) => {
                                handleInstanceChange(data);
                            },
                        }}
                        labels={{
                            label: "Instance",
                            placeholderReadOnly: "Select Instance",
                        }}
                        options={instanceList}
                        selection={[instanceList.find((option) => option.value === login?.instance)]}
                        value={login?.instance || ""}
                        variant="readonly"
                    />
                </div>
            ) : null}
        </>
    );
};

export default RegionSelection;
