import React from "react";
import { Pill } from "@salesforce/design-system-react";
import { Button, Spinner } from "@salesforce/design-system-react";
import { TreeSectionKeys } from "../../../components/navigation-tree/types";
import { RecordPillType } from "../Explore";

interface RecordPillContainerProps {
    records: { types?: RecordPillType; data?: RecordPillType };
    treeSectionList: TreeSectionKeys[];
    handleRemoveItem: (secKey: TreeSectionKeys) => void;
    handleFind: () => void;
    resetPillRecords: () => void;
    loading: boolean;
}

const RecordPillContainer: React.FC<RecordPillContainerProps> = ({ records, treeSectionList, handleRemoveItem, handleFind, resetPillRecords, loading }) => {
    return (
        <div className="slds-m-horizontal_medium container-record-pill">
            {!!Object.keys(records).length ? (
                <>
                    <div className="record-pill-container">
                        <div className="slds-pill_container slds-p-horizontal_small">
                            {treeSectionList?.map((secKey) => {
                                if (!records[secKey]) return null;
                                const { label, title } = records[secKey];
                                return <Pill className="custom-pill" key={secKey} variant="option" labels={{ label, title, removeTitle: "Remove" }} onRemove={() => handleRemoveItem(secKey)} />;
                            })}
                        </div>
                    </div>
                    <div className="slds-grid slds-grid_align-end slds-m-top_x-small">
                        <Button label="Reset" title="Reset" onClick={resetPillRecords} />
                        <Button title="Find more Patterns" onClick={handleFind} variant="brand">
                            Find Patterns
                            {loading && <Spinner size="x-small" variant="brand" />}
                        </Button>
                    </div>
                </>
            ) : (
                <div className="slds-scoped-notification slds-media__body slds-scoped-notification_light" role="status">
                    <p>
                        No active filters.
                        <br />
                        Refine results by selecting one or more filters.
                    </p>
                </div>
            )}
        </div>
    );
};

export default RecordPillContainer;
