import { Button } from "@salesforce/design-system-react";
import React from "react";

import "./ExpandButton.css";

interface Props {
    position?: "horizontal" | "vertical"; // default "vertical"
    iconPosition?: "start" | "center" | "end"; // default "center"
    expand: boolean;
    setExpand: (val: boolean) => void;
}

//  How to use this button
//  vertical: add class to expandable container -> expanded add slds-p-right_small. not expanded add slds-hide
//  horizontal: add class to expandable container -> expanded add slds-p-bottom_small. not expanded add slds-hide
//  Mostly used for "@salesforce/design-system-react" Card

const ExpandButton = ({ expand, setExpand, position = "vertical", iconPosition = "center" }: Props) => {
    return (
        <div className={`split-btn-${position}`}>
            <Button
                className={`split-btn ${iconPosition} ${expand ? "expanded" : "collapsed"}`}
                iconCategory="utility"
                iconSize="x-small"
                iconVariant="border-filled"
                variant="icon"
                title={expand ? "Collapse view" : "Expand view"}
                iconName={position === "vertical" ? (expand ? "left" : "right") : expand ? "up" : "down"}
                onClick={() => setExpand(!expand)}
            />
        </div>
    );
};

export default ExpandButton;
