import React, { useEffect, useMemo, useRef, useState } from "react";
import { Card } from "@salesforce/design-system-react";
import { useNavigate, useSearchParams } from "react-router-dom";

import "./Build.css";
import PsSearchGrid from "../../components/ps-search-grid/PsSearchGrid";
import PsPatternDetailedView from "../../components/ps-pattern-detailed-view/PsPatternDetailedView";
import PsSetupStatus from "../../components/ps-setup-status/PsSetupStatus";
import useAuthContext from "../../context/useAuthContext";
import NavigationTree from "../../components/navigation-tree/NavigationTree";
import { TreeItemKeys, TreeSectionKeys } from "../../components/navigation-tree/types";
import { EventType } from "../types";
import DropBoxBar from "./components/drop-box-bar/DropBoxBar";
import { ConstraintsType } from "./components/drop-box-bar/types";
import { isValidJSON } from "./components/drop-box-bar/utils";
import ExpandButton from "../../ui/buttons/ExpandButton";
import { TREE_SECTIONS } from "../../components/navigation-tree/constants";
import PsNavigationHeader from "../../components/ps-navigation-header/PsNavigationHeader";
import PsErrorBoundary from "../../components/ps-error-boundary/PsErrorBoundary";

const disableDraggable: { [key in TreeSectionKeys]?: TreeItemKeys[] } = { chains: ["source", "container"], maps: ["source", "container"] };
const treeSectionList: TreeSectionKeys[] = ["data", "types", "aggs", "transforms", "chains", "maps"]; // type TreeSectionsKeys[] from types navigation tree
const defaultSection = treeSectionList[0];
const defaultObj = treeSectionList[0]; // section and object is same for top level item
const defaultId = TREE_SECTIONS[defaultObj].id;
const defaultSelected = `${defaultSection}_${defaultObj}_${defaultId}`;

const Build = () => {
    const patternRef = useRef(null); // keep pattern in a useRef because does not need to render the component every time is changed, use patternId instead

    const [selected, setSelected] = useState<string>(defaultSelected);
    const [selectedSection, setSelectedSection] = useState<TreeSectionKeys>(defaultSection);
    const [patternId, setPatternId] = useState<string>("");
    const [constraints, setConstraints] = useState<ConstraintsType | null>(null);

    const [grabbedItem, setGrabbedItem] = useState(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [treeExpanded, setTreeExpanded] = useState<boolean>(true);
    const [propagateEvent, setPropagateEvent] = useState<EventType | undefined>();
    const [applyConstraints, setApplyConstraints] = useState<object | null>(null);

    const { handleLogout } = useAuthContext();
    const [searchParams, setSearchParams] = useSearchParams();
    const [applyNow, setApplyNow] = useState(true);
    const navigate = useNavigate();

    // sync state with url when the search params
    useEffect(() => {
        const urlPatternId = searchParams.get("patternId");

        // this case is only on sharing the url
        if (!patternRef?.current && urlPatternId === "pattern") {
            searchParams.delete("patternId");
            setSearchParams(searchParams);
            return;
        }
        syncStateWithUrl(searchParams);
    }, [searchParams]);

    function syncStateWithUrl(searchParams: URLSearchParams) {
        const urlSelected = searchParams.get("selected") || defaultSelected; // set defaultSelected if is no selected url
        const urlPatternId = searchParams.get("patternId") || "";
        const urlConstraints = searchParams.get("constraints") || "";

        if (urlSelected !== selected) {
            const [section, _obj, _id] = urlSelected.split("_");
            setSelected(urlSelected);
            setSelectedSection(section as TreeSectionKeys);
        }
        if (urlPatternId !== patternId) setPatternId(urlPatternId);
        if (urlConstraints !== JSON.stringify(constraints)) setConstraints(isValidJSON(urlConstraints) ? JSON.parse(urlConstraints) : null);
    }

    function onDragEnd() {
        setGrabbedItem(null);
        return;
    }

    function onDragStart(e: any, item: any) {
        const dragImage = document.createElement("span");
        dragImage.textContent = item.label;
        dragImage.classList.add("build-drag-item");
        document.body.appendChild(dragImage);
        e.dataTransfer.setDragImage(dragImage, 0, 0);

        setTimeout(() => {
            document.body.removeChild(dragImage);
        }, 0);
        setGrabbedItem(item);
        e.dataTransfer.setData("item", JSON.stringify(item));
    }

    function onChangeDroppedHandler(value: ConstraintsType) {
        if (Object.keys(value).length) {
            searchParams.set("constraints", JSON.stringify(value));
        } else {
            searchParams.delete("constraints");
        }
        setSearchParams(searchParams);
    }

    function handleEventRouter(event: EventType) {
        switch (event.type) {
            case "tab":
                // TODO: change event.tab to event.path
                navigate("/" + event.tab);
                break;

            case "logout":
                handleLogout();
                break;
            case "sync":
                if (event.action === "apply" && !searchParams.get("patternId")) {
                    setApplyConstraints(JSON.parse(JSON.stringify(constraints)));
                } else {
                    setPropagateEvent(event);
                }
                break;
            case "record":
                const section = selectedSection;
                setPropagateEvent({ ...event, section });
                break;
            case "payload":
                if (event.action === "viewDetails") {
                    const id = event?.pattern?.id || "pattern";
                    if (id === "pattern") patternRef.current = event.pattern;
                    searchParams.set("patternId", id);
                    setSearchParams(searchParams);
                }
                break;

            case "navigation":
                if (event.action === "close") {
                    searchParams.delete("patternId");
                } else {
                    const section = event?.section || selectedSection || defaultSection;
                    const { id, obj } = event;
                    searchParams.set("selected", `${section}_${obj}_${id}`);
                    searchParams.delete("patternId");
                }
                setSearchParams(searchParams);
                break;

            default:
                setPropagateEvent(event);
                break;
        }
    }

    return (
        <div className="build">
            <PsNavigationHeader childToParent={handleEventRouter} loading={loading} showClose={!!patternId} showApplyNow applyNow={applyNow} setApplyNow={setApplyNow} />
            <div className="tab-content slds-p-around_medium">
                {/* <!-- using slds-hide to prevent rebuilding views that need to keep their state --> */}
                <div className={!!patternId ? "slds-hide" : "slds-grid slds-size_1-of-1"}>
                    <div className="slds-m-right_medium slds-grid">
                        <Card className={!treeExpanded ? "slds-hide" : "slds-p-right_small card-height-full"} heading={<span className="card-main-title-lh32 slds-card__header-title">Browse</span>}>
                            {useMemo(() => {
                                return (
                                    <NavigationTree
                                        pxOffsetHV={194}
                                        draggable
                                        sectionList={treeSectionList}
                                        bubbleEvent={handleEventRouter}
                                        propagateEvent={propagateEvent}
                                        selected={[selected]}
                                        onDragStart={onDragStart}
                                        onDragEnd={onDragEnd}
                                        disableDraggable={disableDraggable}
                                        isTopLevelExpanded={false}
                                    />
                                );
                            }, [selected, propagateEvent, searchParams])}
                        </Card>
                        <ExpandButton expand={treeExpanded} setExpand={setTreeExpanded} />
                    </div>

                    <DropBoxBar grabbedItemFromMenu={grabbedItem} constraints={constraints} onChange={onChangeDroppedHandler} />
                    {/* content */}
                    <div className={"right"}>
                        <PsSetupStatus title="Build" tagLine="Drag items to create the graphs you want to see." />
                        <PsSearchGrid
                            queryFilter={{ constraints: applyNow ? constraints : applyConstraints, version: 1 }}
                            childToParent={handleEventRouter}
                            propagateEvent={propagateEvent}
                            loading={loading}
                            onLoadingChange={setLoading}
                            emptyLine="Drag items to create the graphs you want to see"
                        />
                    </div>
                </div>
                {/* Detail View */}
                {!!patternId && (
                    <PsPatternDetailedView
                        pattern={patternId === "pattern" ? patternRef.current : { id: patternId }}
                        childToParent={handleEventRouter}
                        propagateEvent={propagateEvent}
                        applyNow={applyNow}
                    />
                )}
            </div>
        </div>
    );
};
const ErrorHandledBuild = () => <PsErrorBoundary children={<Build />} />;
export default ErrorHandledBuild;
