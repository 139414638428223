import React from "react";

interface TabLinkProps {
    handleGoToTab: (tabName: string) => void;
}

const ExploreSearchTabLinks: React.FC<TabLinkProps> = ({ handleGoToTab }) => {
    const handleClick = (e: React.MouseEvent<HTMLAnchorElement>, tabName: string) => {
        e.preventDefault();
        handleGoToTab(tabName);
    };

    return (
        <>
            <a href="#" onClick={(e) => handleClick(e, "Explore")} role="button" tabIndex={0}>
                Explore
            </a>{" "}
            and{" "}
            <a href="#" onClick={(e) => handleClick(e, "Search")} role="button" tabIndex={0}>
                Search
            </a>{" "}
            tabs.
        </>
    );
};

export default ExploreSearchTabLinks;
