import { Amplify, Auth, API } from "aws-amplify";
import Cookies from "js-cookie";
import {
    CalloutConfigType,
    CalloutParametersType,
    OnErrorType,
    OnSuccessType,
    GetRecordParametersType,
    GetRecordsParametersType,
    SubmitRecordParametersType,
    DeleteRecordParametersType,
    DoActionParametersType,
    PutRecordParametersType,
} from "./types";
import { buildQueryString } from "./utils";
import { auth, endpoints, instances } from "../utils";

const apiName = "Application";

// Use this function to get the Authorization header string for request
async function getAuthHeader() {
    try {
        const session = await Auth.currentSession();

        const jwt = session.getAccessToken().getJwtToken();
        return `Bearer ${jwt}`;
    } catch (error) {
        if (error === "No current user") {
            const err = { response: { data: { message: "No current user" } } };
            errorHandler(err);
        }
        console.error("Failed to get current session from Amplify:", error);
        throw error;
    }
}
// Error handler function for all requests
function errorHandler(error: any) {
    if (error.response?.data?.message === "User is not active" || error.response?.data?.message === "No current user") {
        window.location.href = "/login";
    }
    console.error("API error response:", error);
    throw error;
}
// The parseResponse function is designed to handle and validate the response from an API call
function parseResponse(res: any, failsWhenEmpty: boolean) {
    if (!res) {
        throw {
            success: false,
            data: {
                type: "NotConnected",
                message: "No response from server",
                suggestion: "Check your network connection",
            },
        };
    }
    if (res.status === 200) {
        if (failsWhenEmpty && (!res.data || res.data.length === 0))
            throw {
                success: false,
                data: { type: "RecordsNotFound", message: "Record(s) not found" },
            };
        // Any call that has been make by the makeCallout return response data and not the all response object
        return res.data;
    }
    if (res.type === "Timeout")
        throw {
            success: false,
            data: {
                type: "Timeout",
                message: "Service busy",
                suggestion: "We cannot currently handle your request due to high server load. Please try again in a few moments",
            },
        };
    throw {
        success: false,
        data: res.data || {
            type: "UnknownError",
            message: "An unknown error occurred",
        },
    };
}

// Use makeCallout to handle request
// The `failsWhenEmpty` parameter (default: `true`) is used for single record requests. If the response is an empty array and failsWhenEmpty is true, it will throw an error.
// Set `failsWhenEmpty` to `false` when expecting a list of records to avoid this behavior.
export async function makeCallout({ path, method, body }: CalloutParametersType, failsWhenEmpty: boolean = true) {
    try {
        // Prepare headers with the Authorization token
        const headers = { Authorization: await getAuthHeader() };
        // Configure API parameters
        const config: CalloutConfigType = { headers, response: true }; // if response = true 'response' might now contain the full response object: { status: 200, headers: {...}, data: {...} }
        let response;
        // Make the API call based on the provided method
        switch (method) {
            case "GET":
                response = await API.get(apiName, path, config);
                break;
            case "POST":
                if (body) config.body = body;
                response = await API.post(apiName, path, config);
                break;
            case "PUT":
                if (body) config.body = body;
                response = await API.put(apiName, path, config);
                break;
            case "DELETE":
                response = await API.del(apiName, path, config);
                break;
            case "PATCH":
                if (body) config.body = body;
                response = await API.patch(apiName, path, config);
                break;
            default:
                throw new Error(`Unsupported HTTP method: ${method}`);
        }
        // Parse and return the response
        return parseResponse(response, failsWhenEmpty);
    } catch (error) {
        errorHandler(error);
    }
}
// START API Request
// The following functions can be called in two ways:
// 1. Synchronously: Use `await getRecordAPI(...parameters)` to wait for the response.
// 2. Asynchronously: Call `getRecordAPI(...parameters)` without awaiting the response.
//
// Note: `getRecordsAPI`, `getRecordAPI`,  `submitRecordAPI` have corresponding custom hooks (useGetRecords, useGetRecord, useSubmitRecord) that manage state (loading, error, and data) for easier integration into components.
//
// The "API" suffix is added to these function names for easier searchability and to distinguish them from older functions.
export async function getRecordsAPI({ module, object, filters }: GetRecordsParametersType, onSuccess?: OnSuccessType, onError?: OnErrorType) {
    const path = `/${module}/${object}${buildQueryString(filters)}`;
    try {
        const res = await makeCallout({ path, method: "GET" }, false);
        onSuccess?.(res);
        return res;
    } catch (error) {
        onError?.(error.response);
        throw error;
    }
}

export async function getRecordAPI({ module, object, recordId, filters }: GetRecordParametersType, onSuccess?: OnSuccessType, onError?: OnErrorType) {
    let path = `/${module}/${object}`;
    if (recordId) path += `/${recordId}`;
    path += buildQueryString(filters);
    try {
        const res = await makeCallout({ path, method: "GET" });
        onSuccess?.(res);
        return res;
    } catch (error) {
        onError?.(error.response);
        throw error;
    }
}
// Use submitRecord function to update or create record dementing on inputBody id
export async function submitRecordAPI({ module, object, inputBody }: SubmitRecordParametersType, onSuccess?: OnSuccessType, onError?: OnErrorType) {
    let path = `/${module}/${object}`;
    try {
        if (inputBody[0]?.id) {
            let body = inputBody;
            if (inputBody.length === 1) {
                body = inputBody[0];
                path += `/${inputBody[0].id}`;
            }
            const res = await makeCallout({ path, method: "PATCH", body });
            onSuccess?.(res);
            return res;
        } else {
            const res = await makeCallout({ path, method: "POST", body: inputBody });
            onSuccess?.(res);
            return res;
        }
    } catch (error) {
        onError?.(error.response);
        throw error;
    }
}
export async function deleteRecordAPI({ module, object, recordId }: DeleteRecordParametersType, onSuccess?: OnSuccessType, onError?: OnErrorType) {
    const path = `/${module}/${object}/${recordId}`;
    try {
        const res = await makeCallout({ path, method: "DELETE" });
        onSuccess?.(res);
        return res;
    } catch (error) {
        onError?.(error.response);
        throw error;
    }
}
export async function doActionAPI({ module, object, action, args = {} }: DoActionParametersType, onSuccess?: OnSuccessType, onError?: OnErrorType) {
    const path = `/${module}/${object}/action`;
    try {
        const res = await makeCallout({ path, method: "POST", body: { action, ...args } });
        onSuccess?.(res);
        return res;
    } catch (error) {
        onError?.(error.response);
        throw error;
    }
}

// This works for PUT Records and for PUT record (adding id or not)
export async function putRecordAPI({ module, object, recordId, inputBody }: PutRecordParametersType, onSuccess?: OnSuccessType, onError?: OnErrorType) {
    let path = `/${module}/${object}`;
    if (recordId) path += `/${recordId}`;
    try {
        const res = await makeCallout({ path, method: "PUT", body: inputBody }, !!recordId);
        onSuccess?.(res);
        return res;
    } catch (error) {
        onError?.(error.response);
        throw error;
    }
}

export function currentInstance() {
    const instanceList = instances();
    const cookieInstance = Cookies.get("instance");
    const singleInstance = instanceList.length === 1 ? instanceList[0].value : null;
    const checkedInstance = instanceList.find((i) => i.value === cookieInstance)?.value;
    return singleInstance || checkedInstance;
}

export function applyInstance(instance?: string) {
    Cookies.set("instance", instance, { expires: 90 });
    Amplify.configure({
        Auth: auth(instance),
        API: {
            endpoints: endpoints(instance),
        },
    });
}

// Create more function request if it needs leave this line last ---> END API request
