import React, { useEffect, useMemo, useRef, useState } from "react";
import "./NavigationTree.css";
import { Tree, Spinner, Input, InputIcon, Pill } from "@salesforce/design-system-react";
import { emptyNode } from "./constants";
import {
    NavigationTreePropsType,
    TreeSectionKeys,
    TreeNodeType,
    NavTree,
    SelectedObjectType,
    SelectedItemType,
    OnExpandClickDataType,
    OnClickDataType,
    RestrictSearchType,
    StoreRecordsType,
    TreeItemKeys,
    RestrictType,
} from "./types";
import {
    calculateTeeHeight,
    deselectHandler,
    generateChildNodes,
    generateTreeInput,
    getDefaultItems,
    getNodes,
    initializeItemsChildToParent,
    initializeSearchData,
    treeActionHandler,
    TreeIcon,
} from "./utils";
import { EventNavigationType } from "../../pages/types";
import { areArraysEqual, getTitle } from "../../utils/index2";
import { getRecordAPI } from "../../services/api";

const NavigationTree = ({
    width,
    sectionList,
    pxOffsetHV,
    selected,
    draggable = false,
    singleSelect = true,
    showSearch = true,
    isTopLevelExpanded = true,
    propagateEvent,
    disableDraggable,
    navigationInputProps,
    searchText,
    bubbleEvent,
    onDragStart,
    onDragEnd,
    selectedItemsHandler,
    bubbleSearchLoading,
    onNodeInteraction,
}: NavigationTreePropsType) => {
    const scrollRef = useRef<HTMLDivElement | null>(null);
    const selectedItemsRef = useRef<SelectedObjectType>({}); // Store latest selectedItems to avoid side effect when the selected has changed outside the navigation tree
    const abortControllerRef = useRef<AbortController | null>(null);

    const [isInitialize, setIsInitialize] = useState<boolean>(false);
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [searchLoading, setSearchLoading] = useState<boolean>(true);
    const [scrollId, setScrollId] = useState<string | null>(null);

    // NOTE: if searchTreeItems is not undefined the component is in Search mode (every time that the search text is "" searchTreeItems become undefined).
    const [searchTreeItems, setSearchTreeItems] = useState<NavTree | undefined>();
    const [selectedSearchItems, setSelectedSearchItems] = useState<SelectedObjectType>({});
    const [restrictSearch, setRestrictSearch] = useState<RestrictSearchType>({});

    const [treeItems, setTreeItems] = useState<NavTree>({});

    const [searchQuery, setSearchQuery] = useState<string>("");
    const [refresh, setRefresh] = useState<boolean>(false);

    const pillOptions: { [Key in TreeSectionKeys]?: { id: string; label: string; title: string } } = useMemo(() => {
        let pills = {};
        sectionList.forEach((sectionKey) => {
            if (restrictSearch[sectionKey]) {
                const { id, label, breadcrumb }: TreeNodeType = restrictSearch[sectionKey];
                pills[sectionKey] = { id, label, title: !!breadcrumb?.length ? getTitle(breadcrumb) : label, sectionKey };
            }
        });
        setRefresh((prev) => !prev);
        return pills;
    }, [restrictSearch]);

    useEffect(() => {
        if (navigationInputProps?.focusItem && !navigationInputProps?.selectedRecord) setScrollId(`${sectionList[0]}-${navigationInputProps.focusItem.id}`);
    }, [navigationInputProps?.focusItem]);
    useEffect(() => {
        if (searchText === undefined) return;
        setSearchQuery(searchText || "");
        if (!searchText) {
            setSearchTreeItems(undefined);
            setSelectedSearchItems({});
        }
    }, [searchText]);

    // close Search mode when the searchTreeItems on reload
    useEffect(() => {
        if (searchQuery && !searchTreeItems) setSearchQuery("");
    }, [searchTreeItems]);

    useEffect(() => {
        if (!searchQuery) {
            setSearchLoading(false);
            bubbleSearchLoading?.(false);
            if (abortControllerRef.current) abortControllerRef.current.abort();
            return;
        }
        if (!loading) {
            setSearchLoading(true);
            bubbleSearchLoading?.(true);
        }
        if (abortControllerRef.current) abortControllerRef.current.abort();
        const handler = setTimeout(() => {
            const controller = new AbortController();
            abortControllerRef.current = controller;
            async function fetchData() {
                try {
                    // Restrict Search is set only when parentDataKey is dynamic.
                    const deepSelectedItems: SelectedObjectType = Object.values(selectedSearchItems || {}).every((value) => !value)
                        ? JSON.parse(JSON.stringify(selectedItemsRef.current))
                        : JSON.parse(JSON.stringify(selectedSearchItems));

                    const deepRestrictSearch: RestrictSearchType = JSON.parse(JSON.stringify(restrictSearch));

                    let restrict: RestrictType | undefined = {};
                    Object.keys(deepRestrictSearch).forEach((sectionKey) => {
                        if (deepRestrictSearch?.[sectionKey] && restrict) {
                            const { id, selfDataKey, record }: TreeNodeType = deepRestrictSearch?.[sectionKey];
                            if (id === "root") {
                                restrict[sectionKey] = { containerId: id, containerDataKey: id };
                            } else {
                                restrict[sectionKey] = { containerId: id, containerDataKey: selfDataKey, containerRecord: record };
                            }
                        }
                    });
                    // Check if all values in restrictValues are null
                    const allNull = Object.values(restrict).every((value) => !value);
                    restrict = allNull ? undefined : restrict;
                    const initTreeItems = navigationInputProps
                        ? await generateTreeInput(sectionList[0], navigationInputProps.object, searchQuery, navigationInputProps.filters)
                        : await initializeSearchData(sectionList, searchQuery, restrict);

                    // set search selected item
                    Object.values(deepSelectedItems).forEach((val) => {
                        if (!!val) {
                            const { node, sectionKey } = val;
                            if (!!initTreeItems?.[sectionKey]?.[node.id]) initTreeItems[sectionKey][node.id].selected = true;
                        }
                    });
                    if (!controller.signal.aborted) {
                        setSearchTreeItems(initTreeItems);
                        setSelectedSearchItems(deepSelectedItems);
                        setSearchLoading(false);
                        bubbleSearchLoading?.(false);
                    }
                    clearTimeout(handler);
                } catch (error) {
                    setSearchLoading(false);
                    bubbleSearchLoading?.(false);
                    clearTimeout(handler);
                }
            }
            fetchData();
        }, 500);

        return () => clearTimeout(handler);
    }, [searchQuery, refresh]);

    // initialize navigation tree
    useEffect(() => {
        if (isInitialize) return;
        // initialize fon navigation input
        if (navigationInputProps) {
            if (!navigationInputProps?.filters) return;
            const initializeTreeItems = async () => {
                try {
                    const { filters, object, focusItem, selectedRecord } = navigationInputProps;
                    // Section is the section from props it will be always one
                    const sectionKey = sectionList[0];
                    const deepTreeData = await generateTreeInput(sectionList[0], object, "", filters);

                    // selfDataKey it will be if selected record true the object from props otherwise it will be focusItem.itemKey exist
                    const selfDataKey = selectedRecord?.id ? object : focusItem?.itemKey || "";
                    // itemId it will be if selected record true the record.id otherwise it will be fucusItem.id if exist
                    const itemId = selectedRecord?.id || focusItem?.id || "";
                    // Update nodes

                    if (selfDataKey && itemId) {
                        const node: TreeNodeType = deepTreeData[sectionKey]?.[itemId];
                        if (deepTreeData[sectionKey] && node) {
                            if (node.nodes === undefined && node.type === "branch" && node.childDataKey)
                                await generateChildNodes({ treeSection: deepTreeData[sectionKey], sectionKey, childDKey: node.childDataKey, storeRecords: null, id: node.id });

                            const updatedNode: TreeNodeType = deepTreeData[sectionKey][itemId];
                            updatedNode.expanded = true;
                            if (selectedRecord) updatedNode.selected = true;
                        } else {
                            const defaultItem = getDefaultItems(sectionKey).defaultItems[selfDataKey];
                            if (defaultItem?.type === "dynamic") {
                                const { module, object } = defaultItem;
                                const res = await getRecordAPI({ module, object, recordId: itemId });
                                await initializeItemsChildToParent(deepTreeData, sectionKey, selfDataKey, res[0]);
                                const updatedNode: TreeNodeType = deepTreeData[sectionKey]?.[itemId];
                                if (updatedNode && selectedRecord) updatedNode.selected = true;
                            } // There is not else case because this component is only for dynamic items
                        }
                    }

                    setTreeItems(deepTreeData);
                    setLoading(false);
                    setIsInitialize(true);
                } catch (error) {
                    setLoading(false);
                }
            };
            initializeTreeItems();

            return;
        }
        // Keep the initializeItems function in the useEffect to do not create every time component render
        async function initializeItems(treeIdList: TreeSectionKeys[]) {
            setLoading(true);
            try {
                const initTreeItems: NavTree = {};
                const storeAllRecords: StoreRecordsType = {};
                for (let i = 0; i < treeIdList.length; i++) {
                    const sectionKey = treeIdList[i];
                    const { topNode } = getDefaultItems(sectionKey, isTopLevelExpanded);

                    // Initialize tree root
                    initTreeItems[sectionKey] = { root: { id: "root", nodes: [topNode.id], label: "root" } };

                    // Initialize tree top level node
                    initTreeItems[sectionKey][topNode.id] = topNode;
                    if (isTopLevelExpanded && topNode.childDataKey) {
                        const storeRecords = storeAllRecords?.[topNode.childDataKey] || null;
                        const { response } = await generateChildNodes({ treeSection: initTreeItems[sectionKey], sectionKey, childDKey: topNode.childDataKey, storeRecords, id: topNode.id });
                        if (storeRecords === null) storeAllRecords[topNode.childDataKey] = response;
                    }
                }

                setTreeItems(initTreeItems);
                setIsInitialize(true);
                setLoading(false);
            } catch (error) {
                console.error("Error on initializeItems:", error);
                setLoading(false);
            }
        }
        initializeItems(sectionList);
    }, [isInitialize]);

    // Update scroll position
    useEffect(() => {
        if (!scrollId || !scrollRef.current) return;
        const parentElement = scrollRef.current;
        const activeElement = parentElement.querySelector(":focus") as HTMLElement;
        if (activeElement && activeElement.tagName === "LI") activeElement.blur(); // Removes focus
        const interval = setInterval(() => {
            const targetElement = parentElement?.querySelector(`#${scrollId}`) as HTMLElement;
            if (targetElement) {
                const targetPosition = targetElement.offsetTop - parentElement?.offsetTop - 20; // 20 is to leave some space on the top
                parentElement?.scrollTo({ top: targetPosition, behavior: "smooth" });
                setScrollId(null);
                clearInterval(interval); // Stop polling once the element is found
                clearTimeout(timeout);
            }
        }, 100); // Check every 100ms

        // Set a timeout to stop the interval after 15 seconds
        const timeout = setTimeout(() => {
            clearInterval(interval);
        }, 5000);

        return () => {
            clearInterval(interval);
            clearTimeout(timeout);
        };
    }, [scrollId, scrollRef]);

    useEffect(() => {
        if (
            !searchTreeItems &&
            areArraysEqual(
                selected || [],
                Object.values(selectedItemsRef.current).map((selItem: SelectedItemType) => selItem?.selectedString || "")
            ) &&
            scrollId === null
        ) {
            const deepSelectedItems: SelectedObjectType = JSON.parse(JSON.stringify(selectedItemsRef.current));
            for (const val of Object.values(deepSelectedItems)) {
                if (val) {
                    setScrollId(`${val.sectionKey}-${val.node?.id}`);
                    break;
                }
            }
        }
    }, [searchTreeItems]);

    // Update selected item outside of the NavigationTree component
    useEffect(() => {
        if (
            !isInitialize ||
            isUpdating ||
            searchTreeItems ||
            areArraysEqual(
                selected || [],
                Object.values(selectedItemsRef.current).map((selItem: SelectedItemType) => selItem?.selectedString || "")
            )
        ) {
            return;
        }

        // Keep the selectedChangeHandler function in the useEffect to do not create every time component render
        async function selectedChangeHandler(selected: string[]) {
            setLoading(true);
            const deepTreeData: NavTree = JSON.parse(JSON.stringify(treeItems));
            const deepSelectedItems: SelectedObjectType = JSON.parse(JSON.stringify(selectedItemsRef.current));
            let updateScrollId: string | null = null;
            let selectedRecord: { section: TreeSectionKeys; node: TreeNodeType }[] = [];
            try {
                // map the selected string that contain [treeId, selfDataKey, itemId]
                const selectedMap = new Map<string, { selfDataKey: string; itemId: string }>();
                for (const sel of selected) {
                    const [sectionKey, selfDataKey, itemId] = sel.split("_");
                    selectedMap.set(sectionKey, { selfDataKey, itemId });
                }

                // deselect all the tree items and selectedItems state
                deselectHandler(deepTreeData, sectionList[0], sectionList, deepSelectedItems, true);

                for (let i = 0; i < sectionList.length; i++) {
                    const sectionKey = sectionList[i];
                    const selectedItem = selectedMap.get(sectionKey);
                    // if selectedItem does not exist move to next loop
                    if (!selectedItem || !selectedItem?.itemId || !selectedItem?.selfDataKey) continue;

                    const itemId = selectedItem.itemId;
                    const selfDataKey = selectedItem.selfDataKey as TreeItemKeys;

                    // Update  scroll id
                    if (updateScrollId === null) updateScrollId = `${sectionKey}-${itemId}`;

                    // Update nodes
                    const node: TreeNodeType = deepTreeData[sectionKey]?.[itemId];

                    if (node && deepTreeData[sectionKey]) {
                        if (node.nodes === undefined && node.type === "branch" && node.childDataKey) {
                            await generateChildNodes({ treeSection: deepTreeData[sectionKey], sectionKey, childDKey: node.childDataKey, storeRecords: null, id: node.id });
                        }
                        const updatedNode: TreeNodeType = deepTreeData[sectionKey][itemId];
                        updatedNode.expanded = true;
                        updatedNode.selected = true;
                        deepSelectedItems[sectionKey] = { sectionKey, node: updatedNode, selectedString: `${sectionKey}_${updatedNode.selfDataKey}_${updatedNode.id}` };
                        selectedRecord.push({ node: updatedNode, section: sectionKey });
                    } else {
                        const defaultItem = getDefaultItems(sectionKey).defaultItems[selfDataKey];
                        if (defaultItem?.type === "dynamic") {
                            const { module, object } = defaultItem;
                            const res = await getRecordAPI({ module, object, recordId: itemId });
                            await initializeItemsChildToParent(deepTreeData, sectionKey, selfDataKey, res[0]);
                            const updatedNode: TreeNodeType = deepTreeData[sectionKey]?.[itemId];
                            if (updatedNode) {
                                updatedNode.selected = true;
                                deepSelectedItems[sectionKey] = { sectionKey, node: updatedNode, selectedString: `${sectionKey}_${updatedNode.selfDataKey}_${updatedNode.id}` };
                                selectedRecord.push({ node: updatedNode, section: sectionKey });
                            }
                        } // There is not else case because static nodes are on top level and has initialized and  node exist
                    }
                }
                selectedItemsRef.current = deepSelectedItems;
                setTreeItems(deepTreeData);
                setScrollId(updateScrollId);
                selectedItemsHandler?.(selectedRecord);
                setLoading(false);
            } catch (error) {
                console.error("Error on selectedChangeHandler:", error);
                setLoading(false);
            }
        }
        if (selected) selectedChangeHandler(selected);
    }, [JSON.stringify(selected), isInitialize, searchTreeItems, isUpdating]);

    // Update tree depended on the parent to child event when the action is delete update create or reload
    useEffect(() => {
        if (isInitialize && propagateEvent?.type === "sync" && propagateEvent?.action === "reload") onReload();

        if (isInitialize && propagateEvent?.type === "record" && propagateEvent?.action !== "cancel") {
            setIsUpdating(true);
            setLoading(true);
            const updateTree = async (action: "update" | "delete" | "create") => {
                try {
                    const { obj, id, section } = propagateEvent;
                    const updatedTree = await treeActionHandler({ obj: obj as TreeItemKeys, id, sectionList, action, treeItems });
                    setTreeItems(updatedTree);
                    setSearchTreeItems(undefined);
                    if (action === "update") setScrollId(`${section}-${id}`); // Scroll to updated record because it does not navigate it just update the tree
                } catch (error) {
                    console.error("Error in updateTree:", error);
                } finally {
                    setLoading(false);
                    setIsUpdating(false);
                }
            };
            updateTree(propagateEvent.action);
        }
    }, [propagateEvent]);

    function onChange(val: string) {
        setSearchQuery(val);
        if (!val) {
            // reset search mode when the value become false
            setSearchTreeItems(undefined);
            setSelectedSearchItems({});
        }
        if (!val && loading) setLoading(false);
    }

    function onClickPill(sectionKey: TreeSectionKeys, id: string) {
        onNodeInteraction?.();
        const deepRestrictSearch: RestrictSearchType = JSON.parse(JSON.stringify(restrictSearch));
        if (deepRestrictSearch[sectionKey]?.id === id) {
            delete deepRestrictSearch[sectionKey];
            setRestrictSearch(deepRestrictSearch);
        }
    }

    function onReload() {
        selectedItemsRef.current = {};
        setRestrictSearch({});
        setSearchTreeItems(undefined);
        setIsInitialize(false);
    }

    function onChangeRestrict(sectionKey: TreeSectionKeys, treeData: NavTree, nodeId: string) {
        setRestrictSearch((prev) => {
            const updated = { ...prev, [sectionKey]: treeData[sectionKey]?.[nodeId] ?? prev[sectionKey] };
            return updated;
        });
    }
    function navigateHandler({ node, sectionKey }: SelectedItemType, _isSearch?: boolean) {
        const event: EventNavigationType = {
            type: "navigation",
            action: "navigate",
            source: "tree",
            id: node.id,
            section: sectionKey,
            parentId: node.parentId,
            obj: node.selfDataKey,
            record: node.record,
            label: node.label,
            breadcrumb: node.breadcrumb,
        };

        bubbleEvent(event);
    }
    async function handleExpandClick(sectionKey: TreeSectionKeys, { expand, node }: OnExpandClickDataType) {
        onNodeInteraction?.();
        const isSearch = !!searchTreeItems;
        const deepTreeItems: NavTree = JSON.parse(JSON.stringify(searchTreeItems || treeItems));
        const scrollContainer = scrollRef.current;
        const previousScrollTop = scrollContainer?.scrollTop || 0;
        // if is search mode update search tree state
        if (isSearch && deepTreeItems[sectionKey]) {
            deepTreeItems[sectionKey][node.id].expanded = expand;
            setSearchTreeItems(deepTreeItems);
        }
        if (!isSearch && deepTreeItems[sectionKey]) {
            // else update tree state
            setLoading(true);
            try {
                if (node.nodes === undefined && node.type === "branch" && node.childDataKey) {
                    await generateChildNodes({ treeSection: deepTreeItems[sectionKey], sectionKey, childDKey: node.childDataKey, id: node.id, storeRecords: null });
                }
                deepTreeItems[sectionKey][node.id].expanded = expand;
                setTreeItems(deepTreeItems);
                setLoading(false);
            } catch (error) {
                console.error("Error on handleExpandClick:", error);
                setLoading(false);
            }
        }

        //move scroll on  expand position
        setTimeout(() => {
            if (scrollContainer) {
                scrollContainer.scrollTop = previousScrollTop;
            }
        }, 0);
    }
    async function handleClick(sectionKey: TreeSectionKeys, { select, node }: OnClickDataType) {
        onNodeInteraction?.();
        if (node?.disabled || navigationInputProps?.disableOnView) return;
        const isSearch = !!searchTreeItems;
        const deepTreeItems: NavTree = JSON.parse(JSON.stringify(searchTreeItems || treeItems));

        // setRestrictSearch on click item even if is selected
        if (showSearch && node.type === "branch" && restrictSearch?.[sectionKey]?.id !== node.id) onChangeRestrict(sectionKey, deepTreeItems, node.id);

        if (deepTreeItems?.[sectionKey]?.[node.id]?.selected && !navigationInputProps) return;
        const shouldUpdate = !navigationInputProps || navigationInputProps.object === node.selfDataKey;

        const deepSelectedItems: SelectedObjectType = JSON.parse(JSON.stringify(selectedItemsRef.current));
        const selectedString = `${sectionKey}_${node.selfDataKey}_${node.id}`;
        // update prevues selected data
        deselectHandler(deepTreeItems, sectionKey, sectionList, deepSelectedItems, singleSelect);
        try {
            // update child nodes if needed only if the is not search mode
            if (deepTreeItems[sectionKey] && node.nodes === undefined && node.type === "branch" && node.childDataKey && !isSearch) {
                setLoading(true);
                await generateChildNodes({ treeSection: deepTreeItems[sectionKey], sectionKey, childDKey: node.childDataKey, id: node.id, storeRecords: null });
                setLoading(false);
            }
            const updatedNode = deepTreeItems[sectionKey]?.[node.id];
            updatedNode.expanded = select;
            if (shouldUpdate) updatedNode.selected = select;
            // update selectedItems (take node from state and not from the click parameter because click parameter can have jsx value for labe and throw error on JSON.parse(JSON.stringify(selectedItems)) )
            deepSelectedItems[sectionKey] = { sectionKey, node: updatedNode, selectedString };

            // update state
            if (isSearch) {
                setSelectedSearchItems(deepSelectedItems);
                setSearchTreeItems(deepTreeItems);
            } else {
                selectedItemsRef.current = deepSelectedItems;
                setTreeItems(deepTreeItems);
            }

            // send events outside the component
            shouldUpdate && navigateHandler(deepSelectedItems[sectionKey], isSearch);
        } catch (error) {
            console.error("Error on handleClick:", error);
            setLoading(false);
        }
    }

    return (
        <div className="ps-navigation-tree slds-m-horizontal_medium">
            {showSearch && (
                <div className="slds-m-vertical_x-small">
                    {showSearch === true && (
                        <Input
                            hasSpinner={searchLoading}
                            iconLeft={<InputIcon name="search" category="utility" assistiveText={{ icon: "Search" }} />}
                            iconRight={!!searchQuery && <InputIcon name="clear" category="utility" assistiveText={{ icon: "Clear" }} onClick={() => onChange("")} />}
                            value={searchQuery}
                            onChange={(e: any) => onChange(e.target.value)}
                            placeholder="Search..."
                        />
                    )}
                    {sectionList.length > 1 && (
                        <div className="pill-container">
                            {!!Object.keys(pillOptions)?.length ? (
                                <div className="slds-pill_container" style={{ width: width || "300px" }}>
                                    {sectionList.map((sectionKey, i) => {
                                        if (!pillOptions[sectionKey]) return null;
                                        const { label, id, title } = pillOptions[sectionKey];
                                        return (
                                            <Pill
                                                className="custom-pill"
                                                key={i}
                                                variant="option"
                                                icon={<TreeIcon iconKey={sectionKey} size={"xx-small"} />}
                                                labels={{ label, title, removeTitle: "Remove" }}
                                                onRemove={() => onClickPill(sectionKey, id)}
                                            />
                                        );
                                    })}
                                </div>
                            ) : (
                                <div className="slds-scoped-notification slds-media slds-media_center" role="status">
                                    <div className="slds-media__body">
                                        <p>Select items to restrict your search</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}

            <div className="trees-nav" style={{ maxHeight: calculateTeeHeight(pxOffsetHV, showSearch, sectionList.length > 1) }}>
                {(loading || searchLoading) && <Spinner />}
                <div className={`nav-trees-container ${draggable ? "nav-trees-draggable" : ""}`} ref={scrollRef} style={{ width: width || "300px" }}>
                    {sectionList.map((sectionKey, i) => {
                        const activeTreeItems = searchTreeItems || treeItems;
                        const topItems = activeTreeItems?.[sectionKey]?.["root"];
                        if (!topItems) return null;
                        return (
                            <div className="tree-content" data-tree-id={sectionKey} key={i}>
                                <Tree
                                    id={sectionKey}
                                    nodes={topItems?.nodes || [emptyNode]}
                                    onExpandClick={(_ev: any, data: OnExpandClickDataType) => handleExpandClick(sectionKey, data)}
                                    onClick={(_ev: any, data: OnClickDataType) => handleClick(sectionKey, data)}
                                    getNodes={(node: TreeNodeType) =>
                                        getNodes({ node, sectionKey, treeItems: activeTreeItems, draggable, isSearch: !!searchTreeItems, disableDraggable, onDragStart, onDragEnd })
                                    }
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default NavigationTree;
