export const checkConcurrencyValue = (value: string) => {
    let errorMessage = "";
    const numericValue = parseFloat(value);

    if (numericValue < 0) {
        errorMessage = "Concurrency value cannot be smaller than 0.";
    } else if (numericValue > 999) {
        errorMessage = "Concurrency value cannot be larger than 999.";
    }

    return errorMessage;
};

export const addIdsToOptions = (input: any) => {
    if (input.options?.length > 0) {
        input.options = input.options.map((option: any) => ({
            ...option,
            id: option.value || option.label,
        }));
    }

    if (input.inputs?.length > 0) {
        input.inputs = input.inputs.map((nestedInput: any) => addIdsToOptions(nestedInput));
    }

    return input;
};
