import { SlotType, DefaultBoxData, MapConstraintsWithEndPointType } from "./types";

export const SLOT_DATA: SlotType[] = ["val", "xst", "xnd", "qsh", "acr", "flt"];

export const BOX_DATA: DefaultBoxData = {
    val: { order: 1, name: "Value", info: "The value or values to be shown" },
    xst: { order: 2, name: "Breakdown", info: "Break down the values" },
    xnd: { order: 3, name: "Detail", info: "Break down the values further on a separate axis" },
    qsh: { order: 4, name: "Value Grouping", info: "Group and aggregate values before breaking down" },
    acr: { order: 5, name: "Value Select", info: "Specify how to select a single row from each group in the Value Grouping" },
    flt: { order: 6, name: "Filter", info: "Apply filters to show results with fewer rows of data" },
};

export const mapTreeItemWithConstraints: MapConstraintsWithEndPointType = {
    key: { section: "data", category: "key", attribute: "id", constraintType: "in" },
    map: { section: "maps", category: "map", attribute: "id", constraintType: "in" },
    container: { section: "data", category: "container", attribute: "id", constraintType: "in" },
    source: { section: "data", category: "source", attribute: "id", constraintType: "in" },
    chain: { section: "chains", category: "chain", attribute: "id", constraintType: "in" },
    agg: { section: "aggs", category: "aggregate", attribute: "function", constraintType: "in" },
    transform: { section: "transforms", category: "node", attribute: "type", constraintType: "in" },
    dataType: { section: "types", category: "dataType", attribute: "name", constraintType: "in", filterKey: "name" },
    dataRole: { section: "types", category: "dataType", attribute: "role", constraintType: "in", filterKey: "name" },
};
