// TODO: rename this to FolderSelect, as it doesn't necessarily have to do with patterns, and certainly doesn't save any patterns
import { useState, useEffect } from "react";
import { Button, Input, Modal, Spinner } from "@salesforce/design-system-react";

import { toastErrorMessage } from "../../../helpers/index.js";
import useToastContext from "../../../context/useToastContext";
import NavigationTree from "../../navigation-tree/NavigationTree.tsx";
import { TREE_SECTIONS } from "../../navigation-tree/constants.ts";
import { submitRecordAPI } from "../../../services/api";

const treeSectionList = ["folders"]; // type TreeSectionKeys[]  from types navigation tree
const defaultSection = treeSectionList[0];

const PatternSave = ({ onClose, onSave, childToParent, propagateEvent }) => {
    const [loading, setLoading] = useState(false);
    const [folderName, setFolderName] = useState("");
    const [folderDescription, setFolderDescription] = useState("");
    const [selectedItem, setSelectedItem] = useState([]);
    const [selectedFolder, setSelectedFolder] = useState(null);

    // global toast
    const { addToast } = useToastContext();

    useEffect(() => {
        if (propagateEvent?.type === "record" && propagateEvent?.action === "create") {
            const section = propagateEvent?.section || defaultSection;
            const { id, obj, record } = propagateEvent;
            setSelectedItem([`${section}_${obj}_${id}`]);
            setSelectedFolder(record);
        }
    }, [propagateEvent]);

    const createSaveFolder = async () => {
        if (!folderName) {
            addToast("error", "Name cannot be empty", "Name is created when creating a new folder.");
            return;
        }

        if (folderName.toLowerCase() === "liked") {
            addToast("warning", "Name cannot be 'Liked'", "You cannot create a folder named 'Liked'. Please choose a different name for the folder.");
            return;
        }

        let parentFolder = selectedFolder;
        let parentFolderId = parentFolder?.id;
        let rootFolderId = parentFolder?.rootFolder?.id || parentFolder?.rootFolderId || parentFolderId;
        var record = { name: folderName, description: folderDescription, rootFolderId, parentFolderId };

        const parentId = TREE_SECTIONS.filters.id;

        const onSuccess = function (response) {
            let recordId = response[0]?.id;
            record.id = recordId;
            setFolderName("");
            setFolderDescription("");
            childToParent({ type: "record", action: "create", parentId, module: "relate", obj: "folder", id: recordId, record, section: "folders" });
            addToast("success", "Folder Saved", "Folder successfully saved");
            setLoading(false);
        };

        const onError = function (response) {
            addToast("error", "Error", toastErrorMessage(response));
            setLoading(false);
        };
        setLoading(true);

        try {
            await submitRecordAPI({ module: "relate", object: "folder", inputBody: [record] }, onSuccess, onError);
        } catch (error) {
            addToast("error", "Error", toastErrorMessage(error));
            setLoading(false);
        }
    };

    function handleEventRouter(event) {
        switch (event.type) {
            case "navigation":
                if (event.source === "tree" && event.obj === "folder") {
                    const section = event?.section || defaultSection;
                    const { id, obj, record } = event;
                    setSelectedItem([`${section}_${obj}_${id}`]);
                    setSelectedFolder(record);
                }
                break;
            default:
                childToParent(event);
                break;
        }
    }

    const handleSave = () => {
        onSave(selectedFolder);
        onClose();
    };

    return (
        <Modal
            className="modal-z-index-9080)"
            isOpen
            onRequestClose={onClose}
            align="top"
            heading={"Select a Folder"}
            footer={
                <>
                    <Button onClick={onClose} label="Cancel" variant="outline-brand" />
                    <Button onClick={handleSave} disabled={!selectedFolder?.id} label="Save" variant="brand" />
                </>
            }
        >
            <div className="slds-m-around_medium  slds-box slds-grid slds-grid_vertical ">
                <div className="slds-text-title">
                    <b>Select an existing folder or create a new folder.</b>
                </div>
                <div className="slds-box slds-p-around_none slds-m-top_small">
                    <NavigationTree
                        width="100%"
                        pxOffsetHV={window.innerHeight - 250}
                        showSearch={false}
                        sectionList={treeSectionList}
                        selected={selectedItem}
                        bubbleEvent={handleEventRouter}
                        propagateEvent={propagateEvent}
                    />
                </div>
                <Input className="slds-m-top_small" type="text" label="Folder Name" autocomplete="off" value={folderName} onChange={(e) => setFolderName(e.target.value)} />
                <Input className="slds-m-top_small" type="text" label="Folder Description" autocomplete="off" value={folderDescription} onChange={(e) => setFolderDescription(e.target.value)} />
                <div className="slds-grid slds-grid_align-end slds-m-top_small">
                    <Button label={<div> Create Folder {loading && <Spinner size="x-small" variant="brand" />} </div>} title="Create Folder" onClick={() => createSaveFolder()} variant="brand" />
                </div>
            </div>
        </Modal>
    );
};

export default PatternSave;
