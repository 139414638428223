import React, { useEffect, useState } from "react";
import "./PsTopMenuBar.css";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { Menu } from "antd";
import { Avatar, Button, GlobalHeaderProfile, Popover, Icon } from "@salesforce/design-system-react";
import useAuthContext from "../../context/useAuthContext";
import PsPermissionWrapper from "../ps-permission-wrapper/PsPermissionWrapper";
import RecordConstants from "../../constants/RecordConstants";

const defaultTabs = [];
const setupPath = "Setup";

const PsTopMenuBar = () => {
    const [tabItems, setTabItems] = useState([]);
    const [openProfile, setOpenProfile] = useState(false);
    const { isAuthenticated, profile, handleLogout, account } = useAuthContext();
    const location = useLocation();
    const navigate = useNavigate();
    const [selectedTab, setSelectedTab] = useState(location.pathname);

    const addBB = selectedTab === "/" && !!isAuthenticated;
    const avatar = (
        <div
            onClick={(e) => {
                e.stopPropagation();
                setOpenProfile((prev) => !prev);
            }}
        >
            <Avatar title={openProfile ? "Close profile" : "View profile"} assistiveText={{ icon: "Avatar image" }} imgSrc="/assets/images/profile_avatar_96.png" imgAlt={profile?.name} />
        </div>
    );

    useEffect(() => {
        setSelectedTab(location.pathname);
    }, [location.pathname]);

    useEffect(() => {
        // TODO: consider moving permissions check into Router instead
        const tabs = [...defaultTabs];
        if (profile) {
            const userTabs = [
                { path: "/", title: "Home" },
                { path: "/Explore", title: "Explore" },
                { path: "/Search", title: "Search" },
                { path: "/Build", title: "Build" },
                { path: "/SavedInsights", title: "Saved Insights" },
            ];
            tabs.push(...userTabs);
        }

        if ([RecordConstants.USER_TYPE.Administrator.value].includes(profile?.type)) {
            const adminTabs = [
                { path: "/DataManagement", title: "Data Management" },
                { path: "/StoreManagement", title: "Store Management" },
            ];
            tabs.push(...adminTabs);
        }

        const tabItems = tabs.map((tab) => ({
            key: tab.path,
            label: (
                <div key={tab.path} onClick={() => handleClickMenuAndLink(tab.path)}>
                    <Link to={tab.path} className="ps-tab-link">
                        <span className="slds-truncate" title={tab.title}>
                            {tab.title}
                        </span>
                    </Link>
                </div>
            ),
        }));

        setTabItems(tabItems);
    }, [profile]);

    const handleClickMenuAndLink = (path) => {
        setSelectedTab(path);
    };

    const handleEditProfile = (e) => {
        e.preventDefault();
        setOpenProfile(false);
        navigate(`/Profile`);
    };

    const handleLogoutClick = (e) => {
        e.preventDefault();
        setOpenProfile(false);
        handleLogout();
    };

    //Both tab and path are required and should be strings. isSetupPage return boolean
    function isSetupPage(tab, path) {
        const tabLowerCase = tab?.toLowerCase();
        const pathLowerCase = path?.toLowerCase();
        if (tabLowerCase === pathLowerCase || tabLowerCase.includes(pathLowerCase)) return true;
        return false;
    }

    return (
        <div className={`ps-top-menu-bar-container ${addBB ? "top-menu-bb" : ""} ${!isAuthenticated ? "top-menu-bs" : ""}`}>
            <div className="logo-wrapper">
                <img src={account?.settings?.logoUrl || "/assets/images/PsLogo.png"} alt="Logo" className="logo-img" onClick={() => navigate("/", { replace: true })} />
            </div>

            {!!isAuthenticated && (
                <>
                    <div className="tab-container">
                        <Menu className="ps-top-tab-navigation" onClick={(e) => handleClickMenuAndLink(e.key)} selectedKeys={[selectedTab]} mode="horizontal" items={tabItems} />
                    </div>

                    <div className="setting-profile-container">
                        <PsPermissionWrapper permittedUserTypes={[RecordConstants.USER_TYPE.Administrator.value]}>
                            <Button
                                className={`ps-setup-btn ${isSetupPage(selectedTab, setupPath) ? "ps-setup-active" : ""}`}
                                assistiveText={{ icon: "Setting icon" }}
                                iconCategory="utility"
                                iconName="settings"
                                iconSize="medium"
                                iconVariant="global-header"
                                variant="icon"
                                title="Setup"
                                onClick={() => navigate(setupPath)} // Navigates to the setup page
                            />
                        </PsPermissionWrapper>

                        <div className="profile-container">
                            <GlobalHeaderProfile
                                userName=""
                                avatar={avatar}
                                popover={
                                    <Popover
                                        isOpen={openProfile}
                                        onClick={() => setOpenProfile(true)}
                                        onRequestClose={() => setOpenProfile(false)}
                                        // onOpen={() => setZIndex(9999)}
                                        // onClose={() => setZIndex(999)}
                                        id="header-profile-popover-id"
                                        ariaLabelledby="profile-heading"
                                        heading=""
                                        body={
                                            <div id="header-profile-custom-popover-content">
                                                <div className="slds-m-around_medium">
                                                    <div className="slds-tile slds-tile_board slds-m-horizontal_small">
                                                        <div className="user-container">
                                                            <div className="avatar">{avatar}</div>
                                                            <div className="user-info">
                                                                <p className="tile__title slds-text-heading_small slds-truncate user-name" title={profile?.name}>
                                                                    {profile?.name}
                                                                </p>
                                                                <h2 className="slds-tree__item-label slds-truncate user-email" title={profile?.email}>
                                                                    {profile?.email}
                                                                </h2>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="slds-tile__detail" // style={{ marginLeft: 17, marginTop: 5 }}
                                                        >
                                                            <p className="logout">
                                                                {/* <a className="slds-m-right_medium" href="">
                                                    Settings
                                                  </a> */}
                                                                <a href="#" onClick={handleLogoutClick}>
                                                                    Log Out
                                                                </a>
                                                            </p>
                                                        </div>

                                                        {profile?.instanceLabel && (
                                                            <div className="instance-wrapper">
                                                                <div className="instance-badge" title={profile.instanceLabel}>
                                                                    <img className="img-instance" src="/assets/images/global-header/cloud.svg" alt="location" />

                                                                    <p className="slds-tile__detail slds-truncate" title={"Instance: " + profile.instanceLabel}>
                                                                        Instance: {profile.instanceLabel}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="border-element" />

                                                    <div className="header-profile-custom-content">
                                                        <div className="header-profile-custom-content-row" onClick={handleEditProfile}>
                                                            <div className="header-profile-custom-content-item-icon">
                                                                <Icon assistiveText={{ label: "edit" }} category="utility" colorVariant="default" name="user" size="x-small" />
                                                            </div>
                                                            My Profile
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    />
                                }
                            />
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default PsTopMenuBar;
