import React from "react";

// TODO: rename to FormItemWrapper, and also figure out why formitems are used inside other formitems in 'read' mode, but not in 'edit' or 'new' mode in Field
interface FieldWrapperProps {
    label?: string;
    children?: React.ReactNode;
    tooltip?: React.ReactNode;
}

const FieldWrapper: React.FC<FieldWrapperProps> = ({ label, children = null, tooltip }) => {
    return (
        <div className="slds-form__item" role="listitem">
            <div className="slds-form-element slds-form-element_stacked">
                {label ? (
                    <div className="slds-form-element__label">
                        <b>{label}</b>
                    </div>
                ) : null}
                {tooltip}
                {children}
            </div>
        </div>
    );
};

export default FieldWrapper;
