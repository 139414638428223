import React, { useEffect, useState } from "react";
import { Checkbox, Combobox, Popover } from "@salesforce/design-system-react";


import { Restriction, RestrictionType } from "../../types";

interface RestrictionsPropsType {
    restrictionOptions: RestrictionType[];
    selectedValues: string[];
    recordRestrictions: Restriction[];
    handleSelectRestriction: Function;
}

const getInputString = (options: RestrictionType[], checked: string[]) => {
    const selectedLabels = options.filter((option) => checked.includes(option.id)).map((option) => option.label);

    if (selectedLabels.length === 0) return "Select an option";
    if (selectedLabels.length > 0) return `${selectedLabels.join(", ")}`;
    return `${selectedLabels.length} options selected`;
};

const Restrictions: React.FC<RestrictionsPropsType> = ({ restrictionOptions, selectedValues, handleSelectRestriction, recordRestrictions }) => {
    const [selection, setSelection] = useState<RestrictionType[]>([]);
    const [checked, setChecked] = useState<string[]>(selectedValues || []);
    const [options, setOptions] = useState<RestrictionType[]>(restrictionOptions || []);

    useEffect(() => {
        setOptions(restrictionOptions);
        setChecked(selectedValues);
    }, [restrictionOptions, selectedValues]);

    const getIsChecked = (id: string) => {
        return checked.some((value) => value === id);
    };

    const getIsDisabled = (id: string) => {
        const recordRestriction = recordRestrictions.find((restriction) => restriction?.restrictionType?.id === id);
        return recordRestriction?.allOrigins?.includes("Default");
    };

    const handleCheckboxChange = (targetChecked: boolean, selectedOption: RestrictionType) => {
        setChecked((prevChecked) => {
            if (targetChecked) {
                return [...prevChecked, selectedOption.id];
            } else {
                return prevChecked.filter((id) => id !== selectedOption.id);
            }
        });
    };

    const handleClose = (e: any, { trigger }: any) => {
        if (trigger === "cancel") {
            setSelection(restrictionOptions.filter((option) => selectedValues.includes(option.id)));
            setChecked(selectedValues);
        } else {
            handleSelectRestriction(checked);
        }
    };

    return (
        <Combobox
            assistiveText={{
                popoverLabel: "Restriction Options",
            }}
            id="combobox-dialog"
            labels={{
                label: "Restrictions",
                placeholder: getInputString(options, checked),
            }}
            popover={
                <Popover
                    body={
                        <fieldset className="slds-form-element">
                            <div className="slds-form-element__control">
                                {options.map((option, i) => (
                                    <Checkbox
                                        checked={getIsChecked(option.id)}
                                        id={`checkbox-${i}`}
                                        key={`checkbox-${i + 1}`}
                                        labels={{ label: option.label }}
                                        onChange={(e) => handleCheckboxChange(e.target.checked, option)}
                                        disabled={getIsDisabled(option.id)}
                                    />
                                ))}
                            </div>
                        </fieldset>
                    }
                    onClose={handleClose}
                    footer={""}
                />
            }
            selection={selection}
            value={getInputString(options, checked)}
            variant="popover"
        />
    );
};

export default Restrictions;
