import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Card } from "@salesforce/design-system-react";

import "./DataManagement.css";
import PsSourceList from "../../components/ps-source-list/PsSourceList";
import PsConnectorList from "../../components/ps-connector-list/PsConnectorList";
import PsObjectList from "../../components/ps-object-list/PsObjectList";
import PsFieldList from "../../components/ps-field-list/PsFieldList";
import PsSetupStatus from "../../components/ps-setup-status/PsSetupStatus";
import useAuthContext from "../../context/useAuthContext.js";
import NavigationTree from "../../components/navigation-tree/NavigationTree";
import { TREE_SECTIONS, TREE_STATIC_DATA } from "../../components/navigation-tree/constants";
import PsSource2 from "../../components/ps-source/PsSource2";
import { TreeSectionKeys, TreeItemKeys } from "../../components/navigation-tree/types";
import { EventType } from "../types";
import PsNavigationHeader from "../../components/ps-navigation-header/PsNavigationHeader";
import { getDefaultItems } from "../../components/navigation-tree/utils";
import PsErrorBoundary from "../../components/ps-error-boundary/PsErrorBoundary";
import PsConnector2 from "../../components/ps-connector/PsConnector2";
import PsObject2 from "../../components/ps-object/PsObject2";
import PsField2 from "../../components/ps-field/PsField2";

const treeSectionList: TreeSectionKeys[] = ["overview", "pump"]; // type TreeSectionKeys[] from types navigation tree

const defaultSection = treeSectionList[0];
const defaultObj = TREE_SECTIONS[defaultSection].treeItems[0];
const defaultId = TREE_STATIC_DATA[defaultObj][0].id; // Tree static data setup for setupOverview section (use id to change view in right side)
const defaultSelected = `${defaultSection}_${defaultObj}_${defaultId}`;

function DataManagement() {
    const [selected, setSelected] = useState<string>(defaultSelected);
    const [selectedObject, setSelectedObject] = useState<TreeSectionKeys | TreeItemKeys>(defaultObj);
    const [selectedId, setSelectedId] = useState<string>(defaultId);
    const [parentId, setParentId] = useState<string>("");
    const [isLogin, setIsLogin] = useState<boolean | undefined>();

    const [propagateEvent, setPropagateEvent] = useState<EventType | undefined>();
    const [setupStatusVisible, setSetupStatusVisible] = useState(true);
    const { handleLogout } = useAuthContext();

    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    // sync state with url when the search params
    useEffect(() => {
        const isLogin = searchParams.get("login");
        if (isLogin) {
            searchParams.delete("login");
            setSearchParams(searchParams, { replace: true });
            setIsLogin(true);
        }
        syncStateWithUrl(searchParams);
    }, [searchParams]);

    function syncStateWithUrl(searchParams: URLSearchParams) {
        const urlSelected = searchParams.get("selected") || defaultSelected; // set defaultSelected if is no selected url
        const urlParentId = searchParams.get("parentId") || "";
        if (urlSelected !== selected) {
            const [_section, obj, id] = urlSelected.split("_");
            setSelected(urlSelected);
            setSelectedId(id);
            setSelectedObject(obj as any);
        }
        if (urlParentId !== parentId) setParentId(urlParentId);
    }

    function handleEventRouter(event: EventType) {
        switch (event.type) {
            case "tab":
                // TODO: change event.tab to event.path
                navigate("/" + event.tab);
                break;

            case "logout":
                handleLogout();
                break;

            case "sync":
                setPropagateEvent(event);
                break;

            case "record":
                if (event.action === "cancel") {
                    searchParams.delete("parentId");
                    setSearchParams(searchParams);
                    // use "delete", "create", "update"].includes(event.action) to avoid "read" action it will remove when all records will update to PsRecord2
                } else if (["delete", "create", "update"].includes(event.action)) {
                    const section = "pump";
                    setPropagateEvent({ ...event, section });
                    // does not need to navigate when update the record just update the tree that it will happen from setPropagateEvent
                    if (["delete", "create"].includes(event.action)) {
                        const { obj, id, parentId } = event;
                        const parentObj = getDefaultItems(section).defaultItems[obj].parentDataKey;
                        const navSelected = event.action === "delete" ? `${section}_${parentObj}_${parentId}` : `${section}_${obj}_${id}`;
                        searchParams.set("selected", navSelected);
                        searchParams.delete("parentId");
                        setSearchParams(searchParams);
                    }
                }
                break;
            case "navigation":
                if (event.source === "grid" && !event.id) {
                    searchParams.set("parentId", event?.parentId);
                } else {
                    const section = event?.section || "pump";
                    const { id, obj } = event;
                    searchParams.set("selected", `${section}_${obj}_${id}`);
                    searchParams.delete("parentId");
                }
                setSearchParams(searchParams);
                break;

            default:
                setPropagateEvent(event);
                break;
        }
    }

    return (
        <div className="DataManagement">
            <PsNavigationHeader childToParent={handleEventRouter} />

            <div className="tab-content slds-p-around_medium">
                <div className="left slds-m-right_medium">
                    {/* <!-- navigation tree --> */}
                    <Card className="card-height-full" heading={<span className="card-main-title-lh32 slds-card__header-title">Browse</span>}>
                        <NavigationTree pxOffsetHV={194} sectionList={treeSectionList} bubbleEvent={handleEventRouter} propagateEvent={propagateEvent} selected={[selected]} />
                    </Card>
                </div>

                {/* <!-- right panel view --> */}
                <div className={setupStatusVisible ? "right" : "right slds-scrollable"}>
                    <PsSetupStatus title="Data Management" tagLine="Create and manage connected data sources." includeStatus={["Error"]} onSetupStatusVisible={setSetupStatusVisible} />

                    {/* Overview */}
                    {(selectedObject === "connectors" || selectedObject === "overview") && (
                        <>
                            {!!parentId ? (
                                // New connector view
                                <PsConnector2 parentId={defaultId} childToParent={handleEventRouter} propagateEvent={propagateEvent} options={{ connector: { isLogin: isLogin } }} />
                            ) : (
                                // Default view connector list
                                <PsConnectorList view="grid" parentId={defaultId} childToParent={handleEventRouter} propagateEvent={propagateEvent} />
                            )}
                        </>
                    )}
                    {/* Sources */}
                    {selectedObject === "pump" && (
                        <>
                            {!!parentId ? (
                                // New source view
                                <PsSource2 parentId={selectedId} childToParent={handleEventRouter} propagateEvent={propagateEvent} />
                            ) : (
                                // source list view
                                <PsSourceList parentId={selectedId} childToParent={handleEventRouter} propagateEvent={propagateEvent} />
                            )}
                        </>
                    )}

                    {/*Specific Source */}
                    {selectedObject === "source" && (
                        <>
                            {!!parentId ? (
                                // New connector view from specific source
                                <PsConnector2 parentId={parentId} childToParent={handleEventRouter} propagateEvent={propagateEvent} options={{ connector: { isLogin: isLogin } }} />
                            ) : (
                                <>
                                    <div className="slds-m-bottom_medium">
                                        <PsSource2 recordId={selectedId} childToParent={handleEventRouter} propagateEvent={propagateEvent} />
                                    </div>
                                    <PsConnectorList queryFilter={{ sourceId: selectedId }} parentId={selectedId} childToParent={handleEventRouter} propagateEvent={propagateEvent} />
                                </>
                            )}
                        </>
                    )}

                    {/*Specific Connector */}
                    {selectedObject === "connector" && (
                        <>
                            {!!parentId ? (
                                // New object view from specific connector
                                <PsObject2 parentId={parentId} childToParent={handleEventRouter} propagateEvent={propagateEvent} />
                            ) : (
                                <>
                                    <div className="slds-m-bottom_medium">
                                        <PsConnector2 recordId={selectedId} childToParent={handleEventRouter} propagateEvent={propagateEvent} options={{ connector: { isLogin: isLogin } }} />
                                    </div>
                                    <PsObjectList queryFilter={{ connectorId: selectedId }} parentId={selectedId} childToParent={handleEventRouter} propagateEvent={propagateEvent} />
                                </>
                            )}
                        </>
                    )}

                    {/*Specific Object */}
                    {selectedObject === "object" && (
                        <>
                            {!!parentId ? (
                                // New field view from specific object
                                <PsField2 parentId={parentId} childToParent={handleEventRouter} propagateEvent={propagateEvent} />
                            ) : (
                                // <PsField
                                //     parentId={parentId}
                                //     childToParent={(ev) => {
                                //         // This it will remove when the PsField2 is ready
                                //         if (!ev?.action && !ev?.obj) {
                                //             handleEventRouter({ obj: "field", parentId, type: "record", source: "record", action: "cancel", id: ev?.id });
                                //         } else if (ev.action === "create") handleEventRouter(ev);
                                //     }}
                                //     propagateEvent={propagateEvent}
                                // />
                                // New object view from specific connector
                                <>
                                    <div className="slds-m-bottom_medium">
                                        <PsObject2 recordId={selectedId} childToParent={handleEventRouter} propagateEvent={propagateEvent} />
                                    </div>
                                    <PsFieldList queryFilter={{ objectId: selectedId }} parentId={selectedId} childToParent={handleEventRouter} propagateEvent={propagateEvent} />
                                </>
                            )}
                        </>
                    )}

                    {/*Specific field */}
                    {selectedObject === "field" && <PsField2 recordId={selectedId} childToParent={handleEventRouter} propagateEvent={propagateEvent} />}
                </div>
            </div>
        </div>
    );
}
const ErrorHandledDataManagement = () => <PsErrorBoundary children={<DataManagement />} />;
export default ErrorHandledDataManagement;
