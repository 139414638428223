import "@blueprintjs/table/lib/css/table.css";
// import "normalize.css"; // This causes three dots to appear under the star that indicate the required field for lightning design components.
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import { Table2, Column, Cell, RowHeaderCell, ColumnHeaderCell } from "@blueprintjs/table";
import { HotkeysProvider, Menu, MenuItem, Classes, Icon } from "@blueprintjs/core";
import { useState, useEffect } from "react";

const DataTable = ({ columns, records, enableHeaderActions, onHeaderAction, enableColumnResize, onColumResize, enableColumnReordering, onColumnReorder, onOrderBy }) => {
    const [localColumns, setLocalColumns] = useState(columns || []);
    const minColumnWidth = 50;
    const maxColumnWidth = 1000;
    const defaultColumnWidth = 200;

    useEffect(() => {
        setLocalColumns(columns || []);
    }, [columns]);

    const renderMenu = (column) => {
        return (
            <Menu className={Classes.ELEVATION_1}>
                {column.actions.map((item) => (
                    <MenuItem icon="edit" key={item.label} text={item.label} onClick={() => onHeaderAction(item)} />
                ))}
                {/* <MenuDivider />
                <MenuItem icon="sort-asc" text="Sort Ascending" onClick={() => onOrderBy(column.name, "asc")} />
                <MenuItem icon="sort-desc" text="Sort Descending" onClick={() => onOrderBy(column.name, "desc")} /> */}
            </Menu>
        );
    };

    const checkWidth = (width) => {
        width = Math.max(Math.min(width, maxColumnWidth), minColumnWidth);
        return width;
    };

    const handleColumnWidthChanged = (index, width) => {
        const newColumns = [...localColumns];
        newColumns[index].width = checkWidth(width);
        setLocalColumns(newColumns);
        onColumResize && onColumResize(newColumns);
    };

    const handleColumnsReordered = (oldIndex, newIndex) => {
        const newColumns = [...localColumns];

        const [removed] = newColumns.splice(oldIndex, 1);
        newColumns.splice(newIndex, 0, removed);

        setLocalColumns(newColumns);
        onColumnReorder && onColumnReorder(newColumns);
    };

    const rowHeaderCellRenderer = (rowIndex) => {
        return <RowHeaderCell name={`${rowIndex + 1}`} />;
    };

    const nameRenderer = (column) => {
        return (
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <span className="slds-truncate" title={column.label} style={{ fontWeight: "bold" }}>
                    {" "}
                    {column.label}{" "}
                </span>
                {enableHeaderActions ? <Icon icon="chevron-down" text="chevron-down" /> : null}
            </div>
        );
    };

    const renderColumnHeader = (col) => {
        return <ColumnHeaderCell name={col.name} nameRenderer={() => nameRenderer(col)} menuRenderer={enableHeaderActions ? () => renderMenu(col) : null} selectCellsOnMenuClick={false} />;
    };

    const cellRenderer = (rowIndex, column) => {
        const record = records[rowIndex];
        const value = record[column.fieldName];
        return <Cell tooltip={value}>{value}</Cell>;
    };

    return (
        <HotkeysProvider>
            <Table2
                numRows={records?.length}
                enableColumnResizing={enableColumnResize}
                enableColumnReordering={enableColumnReordering}
                columnWidths={localColumns.map((col) => checkWidth(col.width || Math.max(minColumnWidth, defaultColumnWidth)))}
                onColumnWidthChanged={(index, width) => handleColumnWidthChanged(index, width)}
                rowHeaderCellRenderer={(index) => rowHeaderCellRenderer(index)}
                onColumnsReordered={handleColumnsReordered}
                cellRendererDependencies={[localColumns]}
            >
                {localColumns.map((column) => {
                    return <Column key={column.name} name={column.name} cellRenderer={(rowIndex) => cellRenderer(rowIndex, column)} columnHeaderCellRenderer={() => renderColumnHeader(column)} />;
                })}
            </Table2>
        </HotkeysProvider>
    );
};

export default DataTable;
