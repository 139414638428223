import { Tooltip } from "@salesforce/design-system-react";
import React, { useState } from "react";

interface TooltipItem {
    label: string;
    help: string;
    required?: boolean;
}

const FormattedTooltip: React.FC<{ item: TooltipItem }> = ({ item }) => {
    const [tooltipOpen, setTooltipOpen] = useState<string[]>([]);

    const renderTooltipContent = (content: string): JSX.Element[] => {
        const linkRegex = /<a href="([^"]*)">(.*?)<\/a>/g;
        let lastIndex = 0;
        const elements: JSX.Element[] = [];
        let uniqueKey = 0;

        let match;
        while ((match = linkRegex.exec(content)) !== null) {
            if (match.index > lastIndex) {
                elements.push(<span key={uniqueKey++}>{content.substring(lastIndex, match.index)}</span>);
            }

            elements.push(
                <a key={uniqueKey++} href={match[1]} target="_blank" rel="noopener noreferrer" style={{ color: "#fff", textDecoration: "underline" }}>
                    {match[2]}
                </a>
            );

            lastIndex = linkRegex.lastIndex;
        }

        if (lastIndex < content.length) {
            elements.push(<span key={uniqueKey++}>{content.substring(lastIndex)}</span>);
        }

        return elements;
    };

    const handleMouseEnter = (item: string) => {
        setTooltipOpen((prev) => [...prev, item]);
    };

    const handleMouseLeave = (item: string) => {
        setTimeout(() => {
            setTooltipOpen((prev) => prev.filter((tooltipItem) => tooltipItem !== item));
        }, 100);
    };

    const clickableTooltip = (item: TooltipItem) => (
        <div onMouseEnter={() => handleMouseEnter(item.label)} onMouseLeave={() => handleMouseLeave(item.label)}>
            <Tooltip align="top left" content={renderTooltipContent(item.help)} isOpen={tooltipOpen.includes(item.label)} variant="list-item" />
        </div>
    );

    return (
        <div className="slds-grid">
            <label className="slds-form-element__label">
                {item.required && (
                    <abbr className="slds-required" title="required">
                        *
                    </abbr>
                )}
                {item.label}
            </label>
            {item.help ? clickableTooltip(item) : null}
        </div>
    );
};

export default FormattedTooltip;
