import { Combobox, Input, Tooltip } from "@salesforce/design-system-react";
import React, { useState, forwardRef, useEffect, useImperativeHandle, useMemo } from "react";

import "./Filter.css";
import RecordConstants from "../../constants/RecordConstants";
import PsRecord2 from "../ps-record/PsRecord2";
import { Mode, PsRecordPropsType, FilterType, FilterOption, SelectedContainerType, UpdateFilterRequestType, CreateFilterRequestType } from "../../types";
import CheckAndCloseIcons from "../../ui/CheckAndCloseIcons";
import Field from "../../ui/wrappers/Field";
import Toggle from "../../ui/Toggle";
import { getRecordAPI, getRecordsAPI } from "../../services/api";
import { getTitle, getUseInput, getBreadCrumb, filterJson } from "./components/utils";
import DualListBox from "../../ui/DualListBox";
import {
    selectLoadedOption,
    checkRequiredField,
    nameFromDetails,
    flatten,
    toCSV,
    checkRequiredCombobox,
    checkMinDateAmountValidity,
    checkMaxDateAmountValidity,
    checkMinDateUnitValidity,
    checkMaxDateUnitValidity,
} from "../../utils/index2";
import FormItemWrapper from "../../ui/wrappers/FormItemWrapper";
import { formattedDate } from "../../utils";
import TreeInput from "../navigation-tree/TreeInput";

const recordObject = "filter";

export type DataType = {
    format: string;
    id: string;
    name: string;
    role: string;
};

export type KeyType = {
    id: string;
    name: string;
    title: string;
    dataType: DataType;
};

type ChainItemType = {
    chainFilter: null | any;
    selectedChain: null | any;
    chainItem: null | any;
    needsChain: boolean;
};

interface PsFilterRef {
    setFieldErrors: (field: string, value: string) => void;
    cardBody: React.ReactNode;
    record?: FilterType;
    fieldErrors: { [key: string]: string };
}

const PsFilter2 = forwardRef<PsFilterRef, PsRecordPropsType>((props, ref) => {
    const { recordId = "", parentId = "", childToParent, propagateEvent, options } = props;
    const { filter } = options || {};
    const { newScopes, hideAppliesTo, filterSetState, recordValue, isModal, selectedFieldRecord, selectedFilterBuild } = filter || {};
    const scopes = useMemo(() => {
        return newScopes.map((scope, index) => ({
            id: scope,
            value: scope,
            label: scope,
            selected: recordValue?.scope ? recordValue?.scope === scope : index === 0,
        }));
    }, [newScopes]);

    const [localParentId, setLocalParentId] = useState(parentId);
    const [mode, setMode] = useState<Mode>("init");
    const [record, setRecord] = useState<FilterType>();
    const [loading, setLoading] = useState<boolean>(false);
    const [fieldErrors, setFieldErrors] = useState({} as { [key: string]: string });
    const [isSaved, setIsSaved] = useState<boolean>(false);
    const [isInit, setIsInit] = useState<boolean>(false);
    const [useInput, setUseInput] = useState<string>("");
    const [valuesOptions, setValuesOptions] = useState<FilterOption[]>([]);
    const [operator, setOperator] = useState<string>("");
    const [operatorLabel, setOperatorLabel] = useState<string>("");
    const [operatorOptions, setOperatorOptions] = useState<FilterOption[]>([]);
    const [operatorSelection, setOperatorSelection] = useState<FilterOption[]>([{ id: "Not Available", value: "Not Available", label: "Not Available", selected: true }]);
    const [containerFilter, setContainerFilter] = useState(null);
    const [selectedContainer, setSelectedContainer] = useState<SelectedContainerType | FilterType>();
    const [containerItem, setContainerItem] = useState(null);
    const [selectedKey, setSelectedKey] = useState<FilterType>(null);
    const [keyItem, setKeyItem] = useState(null);
    const [chain, setChain] = useState<ChainItemType>({
        chainFilter: null,
        selectedChain: null,
        chainItem: null,
        needsChain: false,
    });
    const [selectedPreset, setSelectedPreset] = useState<string>("");
    const [presetOptions, setPresetOptions] = useState<FilterOption[]>([]);
    const [presetSelection, setPresetSelection] = useState<FilterOption[]>([]);
    const [selectedPresetLabel, setSelectedPresetLabel] = useState<string>("");
    const [selectedValues, setSelectedValues] = useState([]);
    const [selectedValue, setSelectedValue] = useState<string>("");
    const [freeTextValues, setFreeTextValues] = useState<string>("");
    const [minNumber, setMinNumber] = useState<string | number>();
    const [maxNumber, setMaxNumber] = useState<string | number>();
    const [minDate, setMinDate] = useState<string>("");
    const [maxDate, setMaxDate] = useState<string>("");
    const [minDateAmount, setMinDateAmount] = useState<string>("");
    const [minDateUnit, setMinDateUnit] = useState<string>("");
    const [minDateDefault, setMinDateDefault] = useState<string>("");
    const [minNumberDefault, setMinNumberDefault] = useState<number>();
    const [maxNumberDefault, setMaxNumberDefault] = useState<number>();
    const [maxDateDefault, setMaxDateDefault] = useState<string>("");
    const [maxDateAmount, setMaxDateAmount] = useState<string>("");
    const [maxDateUnit, setMaxDateUnit] = useState<string>("");
    const [minDateUnitLabel, setMinDateUnitLabel] = useState<string>("");
    const [maxDateUnitLabel, setMaxDateUnitLabel] = useState<string>("");
    const [dateTimeUnits, setDateTimeUnits] = useState(RecordConstants.DATETIME_UNITS || []);
    const [minDateTimeUnitsSelection, setMinDateTimeUnitsSelection] = useState([]);
    const [maxDateTimeUnitsSelection, setMaxDateTimeUnitsSelection] = useState([]);
    const [scopeSelection, setScopeSelection] = useState<FilterOption[]>([
        recordValue?.scope ? { id: recordValue?.scope, value: recordValue?.scope, label: recordValue?.scope, selected: true } : scopes[0],
    ]);
    const [scopeOptions, setScopeOptions] = useState<FilterOption[]>(scopes);
    const [cmpState, setCmpState] = useState({
        activeField: "",
        isExpanded: false,
    });

    const defaultRecord: FilterType = {
        name: "",
        scope: newScopes[0],
        active: true,
        acceptMissing: false,
    };

    useEffect(() => {
        handleReload();
    }, [recordId]);

    useEffect(() => {
        loadContainer();
    }, [parentId]);

    // This useEffect is used to update the filter for the build page only
    useEffect(() => {
        if (!!selectedFilterBuild) {
            setIsSaved(true); // On the build page, "isSaved" should always be true
            return;
        }
        if (!!selectedFieldRecord) {
            setIsSaved(true);
            // Updates the "Field" input by adding the record selected from the tree menu (outside of the filters)
            handleKeySelect(selectedFieldRecord);
        }
    }, [selectedFieldRecord, selectedFilterBuild]);

    function loadContainer() {
        const containerId = parentId;
        if (containerId) {
            const onSuccess = function (response: any) {
                let record = response[0];
                record.title = getTitle(getBreadCrumb("container", record));
                setSelectedContainer(record);
                setContainerItem(nameFromDetails("containers", "container", record.id));
                setContainerFilter({ id: record.id });
                setContainer(record);
            };
            const onError = function (response: any) {
                unloadContainer();
            };
            getRecordAPI({ module: "store", object: "container", recordId: containerId }, onSuccess, onError);
        } else {
            unloadContainer();
        }
    }

    function handleReload() {
        setIsInit(true);
        if (!recordId) {
            loadContainer();
        }
    }

    function unloadContainer() {
        setLoading(false);
        setChainFilter("leftContainerId", null);
        setSelectedContainer(null);
        setContainerItem(null);
        setContainerFilter(null);
    }

    const parseResponse = (response: FilterType[]): FilterType[] => {
        return response.map((filter) => ({ ...filter, acceptMissing: !!filter.acceptMissing }));
    };

    const parseUpdateRequest = (filter: FilterType): UpdateFilterRequestType => {
        let updatedFilter = filterJson(
            filter,
            operatorOptions,
            operator,
            valuesOptions,
            minDate,
            maxDate,
            selectedPreset,
            presetOptions,
            selectedValues,
            minDateAmount,
            maxDateAmount,
            minDateUnit,
            maxDateUnit,
            minNumber,
            maxNumber,
            record?.scope,
            selectedKey,
            selectedContainer,
            chain.selectedChain,
            freeTextValues
        );
        updatedFilter = JSON.parse(JSON.stringify(updatedFilter)); // deepcopy to prevent changing original record

        return (({ id, active, name, acceptMissing, operator, settings, overridden }) => ({ id, active, name, acceptMissing, operator, settings, overridden }))(updatedFilter);
    };

    const parseCreateRequest = (filter: FilterType): CreateFilterRequestType => {
        let updatedFilter = filterJson(
            filter,
            operatorOptions,
            operator,
            valuesOptions,
            minDate,
            maxDate,
            selectedPreset,
            presetOptions,
            selectedValues,
            minDateAmount,
            maxDateAmount,
            minDateUnit,
            maxDateUnit,
            minNumber,
            maxNumber,
            record?.scope,
            selectedKey,
            selectedContainer,
            chain.selectedChain,
            freeTextValues
        );
        updatedFilter = JSON.parse(JSON.stringify(updatedFilter)); // deepcopy to prevent changing original record

        return (({ active, type, name, scope, acceptMissing, containerId, operator, settings, inputs }) => ({ active, type, name, scope, acceptMissing, containerId, operator, settings, inputs }))(
            updatedFilter
        );
    };

    const handleSelectPreset = (event: React.MouseEvent<HTMLDivElement>, data: any) => {
        if (data.selection.length === 0) return;
        const newPreset = data.selection[0].value;
        setSelectedPreset(newPreset);
        setSelectedPresetLabel(data.selection[0].label);
        setPresetSelection(data.selection);
        setFieldErrors((prev) => ({ ...prev, preset: checkRequiredCombobox(newPreset) }));
    };

    function handleMinDateAmount(value: string) {
        setMinDateAmount(value);
        const { minDateAmountMessage, minDateUnitMessage } = checkMinDateAmountValidity(value, minDateUnit);
        setFieldErrors((prev) => ({ ...prev, minDateAmount: minDateAmountMessage, minDateUnit: minDateUnitMessage }));
    }

    function handleMaxDateAmount(value: string) {
        setMaxDateAmount(value);
        const { maxDateAmountMessage, maxDateUnitMessage } = checkMaxDateAmountValidity(value, maxDateUnit);
        setFieldErrors((prev) => ({ ...prev, maxDateAmount: maxDateAmountMessage, maxDateUnit: maxDateUnitMessage }));
    }

    function handleMinDateUnitChange(event: React.MouseEvent<HTMLDivElement>, data: any) {
        if (!data.selection.length) return;

        const { value, label } = data.selection[0];
        setMinDateUnitLabel(label);
        setMinDateTimeUnitsSelection(data.selection);
        setMinDateUnit(value);
        const { minDateAmountMessage, minDateUnitMessage } = checkMinDateUnitValidity(value, minDateAmount);
        setFieldErrors((prev) => ({ ...prev, minDateAmount: minDateAmountMessage, minDateUnit: minDateUnitMessage }));
    }

    function handleMaxDateUnitChange(event: React.MouseEvent<HTMLDivElement>, data: any) {
        if (!data.selection.length) return;

        const { value, label } = data.selection[0];
        setMaxDateUnitLabel(label);
        setMaxDateTimeUnitsSelection(data.selection);
        setMaxDateUnit(value);
        const { maxDateAmountMessage, maxDateUnitMessage } = checkMaxDateUnitValidity(value, maxDateAmount);
        setFieldErrors((prev) => ({ ...prev, maxDateAmount: maxDateAmountMessage, maxDateUnit: maxDateUnitMessage }));
    }

    const unloadChains = () => {
        setChain((prevChain) => ({
            ...prevChain,
            selectedChain: null,
            chainItem: Object.assign(nameFromDetails("chains", "chain", null), {
                label: "Not Available",
            }),
        }));
        setLoading(false);
    };

    const loadChains = (updatedChain: ChainItemType) => {
        setLoading(true);
        const onSuccess = function (response: any) {
            if (!response || !response.length) {
                unloadChains();
            } else {
                let chain = response[0];
                chain.title = getTitle(getBreadCrumb("chain", chain));
                setChain((prevChain) => ({
                    ...prevChain,
                    selectedChain: chain,
                    chainItem: nameFromDetails("chains", "chain", chain.id),
                }));
                if (fieldErrors.chain) {
                    setFieldErrors((prev) => ({ ...prev, chain: "" }));
                }
                setLoading(false);
            }
        };

        const onError = function () {
            unloadChains();
        };

        const needsChain = updatedChain.needsChain;
        if (needsChain) {
            const filter = updatedChain.chainFilter?.chains?.chain || {};
            const selectedChain = updatedChain.selectedChain || {};

            const leftContainerId = selectedChain.leftContainerId || selectedChain.leftContainer?.id;
            const rightContainerId = selectedChain.rightContainerId || selectedChain.rightContainer?.id;
            const changed = leftContainerId !== filter.leftContainerId || rightContainerId !== filter.rightContainerId;
            if (changed) {
                const queryFilter = Object.assign({}, filter, {
                    orderby: "relevance DESC",
                });
                getRecordsAPI({ module: "store", object: "chain", filters: queryFilter }, onSuccess, onError);
            } else {
                setLoading(false);
            }
        } else {
            unloadChains();
        }
    };

    const setChainFilter = (name: string, containerId: string) => {
        setChain((prevChain) => {
            let updatedChainFilter = prevChain.chainFilter || { chains: { chain: {} } };

            if (containerId) {
                updatedChainFilter.chains.chain[name] = containerId;
            } else {
                delete updatedChainFilter.chains.chain[name];
            }

            const leftContainerId = updatedChainFilter.chains.chain.leftContainerId;
            const rightContainerId = updatedChainFilter.chains.chain.rightContainerId;

            const needsChain = leftContainerId && rightContainerId && leftContainerId !== rightContainerId;

            if (!Object.keys(updatedChainFilter.chains.chain).length) {
                updatedChainFilter = null;
            }

            const updatedChain = { ...prevChain, chainFilter: updatedChainFilter, needsChain };

            loadChains(updatedChain);

            return updatedChain;
        });
    };

    const setContainer = (container: FilterType) => {
        setChainFilter("leftContainerId", container.id);

        // set selected key to the pattern.key's container, for ease of use
        const key = selectedKey || { containerId: "", container: { id: "" } };
        const selectedContainerId = key.containerId || key.container?.id;
        if (!selectedContainerId) {
            setKeyItem(nameFromDetails("keys", "container", container.id));
        }
    };

    const setMinDateValues = (newMinDateAmount: string, newMinDateUnit: string) => {
        const { selectedValue, options, selectedItemLabel } = selectLoadedOption(dateTimeUnits, newMinDateUnit, false);
        setMinDateUnit(selectedValue);
        setMinDateTimeUnitsSelection([options.find((option) => option.value === selectedValue)]);
        setDateTimeUnits(options);
        setMinDateUnitLabel(selectedItemLabel);
        setMinDateAmount(newMinDateAmount);
    };

    const setMaxDateValues = (newMaxDateAmount: string, newMaxDateUnit: string) => {
        const { selectedValue, options, selectedItemLabel } = selectLoadedOption(dateTimeUnits, newMaxDateUnit, false);
        setMaxDateUnit(selectedValue);
        setMaxDateTimeUnitsSelection([options.find((option) => option.value === selectedValue)]);
        setDateTimeUnits(options);
        setMaxDateUnitLabel(selectedItemLabel);
        setMaxDateAmount(newMaxDateAmount);
    };

    const setScopeValues = (newScopeOptions: FilterOption[], newScopeValue: string) => {
        const { options, selectedValue } = selectLoadedOption(newScopeOptions, newScopeValue);
        setScopeOptions(options);
        setRecord((prev) => ({ ...prev, scope: selectedValue }));
    };

    function unloadKeyDetails() {
        setRecord((prev) => ({ ...prev, type: null }));
        setOperatorOptions([{ id: "Not Available", value: "Not Available", label: "Not Available", selected: true }]);
        setOperator("Not Available");
        setOperatorLabel(null);
        setUseInput(null);
        setValuesOptions([{ id: "Not Available", value: "Not Available", label: "Not Available", selected: true }]);
        setSelectedValues(null);
        setFreeTextValues(null);
        setSelectedPreset(null);
        setSelectedPresetLabel(null);
        setPresetOptions(null);
        setMinDateUnit(null);
        setMaxDateUnit(null);
        setMinDateUnitLabel(null);
        setMaxDateUnitLabel(null);
        setChainFilter("rightContainerId", null);
        setSelectedKey(null);
        setKeyItem(null);
    }

    function setOperatorValue(
        operatorOptions: FilterOption[],
        operator: string,
        record: FilterType,
        minNumberDefault: number,
        maxNumberDefault: number,
        updatedIsInit: boolean,
        updatedValuesOptions: FilterOption[]
    ) {
        const { selectedValue, selectedItemLabel } = selectLoadedOption(operatorOptions, operator, !updatedIsInit);
        setOperatorLabel(selectedItemLabel);
        setOperator(selectedValue);
        setFieldErrors((prev) => ({ ...prev, operator: checkRequiredCombobox(selectedValue) }));
        setOperatorSelection([operatorOptions.find((option) => option.value === selectedValue)]);
        setUseInput(getUseInput(operatorOptions, selectedValue, updatedValuesOptions));

        const filter = record || { type: "", settings: {}, presets: [] as any[] };
        type FilterType = keyof typeof RecordConstants.FILTER_PRESETS;
        const type = filter.type as FilterType;
        if (!updatedIsInit) {
            const presets = filter.presets;
            const presetDefaults = JSON.parse(JSON.stringify(RecordConstants.FILTER_PRESETS[type])); // deepclone to prevent changing original values
            setPresetOptions(presets || presetDefaults);
            if (type === "Text") {
                setFreeTextValues(null);
            } else if (type === "DateTime") {
                setMinDate(minDateDefault);
                setMaxDate(maxDateDefault);
            } else if (type === "Number") {
                setMinNumber(minNumberDefault);
                setMaxNumber(maxNumberDefault);
            }
        }
    }

    function loadKeyDetails(operator: string, selectedKey: FilterType, updatedIsInit: boolean) {
        // load existing record, or open new record
        const key = selectedKey;
        const isInit = updatedIsInit;

        if (key) {
            let type = "" as keyof typeof RecordConstants.FILTER_TYPES;
            let record = {} as FilterType;
            let updatedMinNumberDefault = minNumberDefault;
            let updatedMaxNumberDefault = maxNumberDefault;
            setLoading(true);

            const onSuccess = function (response: any[]) {
                record = response[0];
                type = record.dataType?.format as keyof typeof RecordConstants.FILTER_TYPES;

                record = { ...record, type };
                record.title = getTitle(getBreadCrumb("key", record));

                setRecord((prev) => ({ ...prev, type }));
                setSelectedKey({ ...record });
                const operatorOptions = RecordConstants.FILTER_TYPES[type];
                if (!operatorOptions) {
                    unloadKeyDetails();
                } else {
                    // set values before selecting operator, so it can switch between MultiSelect and FreeText
                    if (!isInit) {
                        if (!recordValue) {
                            setRecord((prev) => ({ ...prev, name: record.name }));
                        }
                        setSelectedValues(null);
                        setFieldErrors((prev) => ({ ...prev, name: checkRequiredField(record.name) }));
                    }
                    const values = record.values || [];
                    const newValuesOptions = values.map(({ label }) => ({ id: label, label, value: label })); // API expects labels, not values
                    setValuesOptions(newValuesOptions);

                    // defaults
                    if (type === "DateTime") {
                        const minDate = record.robustMin ? formattedDate(new Date(record.robustMin / 1000000)) : null;
                        const maxDate = record.robustMax ? formattedDate(new Date(record.robustMax / 1000000)) : null;
                        setMinDateDefault(minDate);
                        setMaxDateDefault(maxDate);
                    } else if (type === "Number") {
                        updatedMinNumberDefault = record.robustMin;
                        updatedMaxNumberDefault = record.robustMax;
                        setMinNumberDefault(updatedMinNumberDefault);
                        setMaxNumberDefault(updatedMaxNumberDefault);
                    }
                    setOperatorOptions(operatorOptions);
                    setOperatorValue(operatorOptions, operator, record, updatedMinNumberDefault, updatedMaxNumberDefault, updatedIsInit, newValuesOptions);

                    // chain
                    setChainFilter("rightContainerId", record.containerId || record.container?.id);
                }
            };

            const onError = function (response: any) {
                unloadKeyDetails();
            };

            getRecordAPI({ module: "store", object: "key", recordId: key.id }, onSuccess, onError);
        } else {
            unloadKeyDetails();
        }
    }

    function updateUI(record: FilterType, isInitArg: boolean) {
        const updatedIsInit = isInitArg === undefined ? isInit : isInitArg;
        const filter = record;

        // get Key and Chain from nodes
        let key: any;
        let multiple: boolean;
        let updatedChain: any;
        let container = filter?.container;
        let nodes = flatten(filter, "inputs");
        for (let node of nodes) {
            if (key && node.key) {
                multiple = true;
            }
            if (updatedChain && node.chain) {
                multiple = true;
            }
            key = node.key;
            updatedChain = node.chain;
        }
        // for now, unset key and chain if there are mulitple
        // IMPROVEMENT: edit tree structure in UI
        if (multiple) {
            key = null;
            updatedChain = null;
        }
        // set selected key and chain
        if (container) {
            container.title = getTitle(getBreadCrumb("container", container));
            setSelectedContainer(container);
            setContainerItem(nameFromDetails("containers", "container", container.id));
            setContainerFilter({ id: container.id });
            setChainFilter("leftContainerId", container.id);
        }
        if (key) {
            key.title = getTitle(getBreadCrumb("key", key));
            setSelectedKey(key);
            setKeyItem(nameFromDetails("keys", "key", key.id));
            setChainFilter("rightContainerId", key.containerId || key.container?.id);
        }
        if (updatedChain) {
            updatedChain.title = getTitle(getBreadCrumb("chain", updatedChain));

            // the chain isn't always loaded with left and right container: IMPROVEMENT: udpate API to include left and right container
            updatedChain.leftContainerId = updatedChain.leftContainerId || updatedChain.leftContainer?.id || filter.containerId || container?.id;
            updatedChain.rightContainerId = updatedChain.rightContainerId || updatedChain.rightContainer?.id || key.containerId || key?.container?.id;
            setChainFilter("leftContainerId", updatedChain.leftContainerId || updatedChain.leftContainer?.id);
            setChainFilter("rightContainerId", updatedChain.rightContainerId || updatedChain.rightContainer?.id);

            // make sure to set selectedChain as the last step, because setChainFilter may unset it
            setChain((prevChain) => ({ ...prevChain, selectedChain: updatedChain, chainItem: nameFromDetails("chains", "chain", updatedChain.id) }));
        }
        // parse fields
        let type = filter.type as keyof typeof RecordConstants.FILTER_TYPES;
        let settings = filter.settings || {};
        let alias = settings.alias;
        let operator = filter.operator;
        // settings.alias overrides the operator
        if (alias) {
            operator = alias;
        }
        setOperator(operator);
        setFieldErrors((prev) => ({ ...prev, operator: checkRequiredCombobox(operator) }));

        // put settings into input fields
        let options = RecordConstants.FILTER_TYPES[type] || [];
        let option = options.find((i) => i.value === operator) || { useInput: "" };
        let useInput = option.useInput;
        if (useInput === "Preset") {
            let presets = filter.presets || (JSON.parse(JSON.stringify(RecordConstants.FILTER_PRESETS[type])) as typeof presetSelection); // deepclone to prevent changing original values
            setSelectedPreset(settings.preset);
            setPresetOptions(presets);
            setPresetSelection([presets.find((preset) => preset.value === settings.preset)]);
            const { selectedValue, options, selectedItemLabel } = selectLoadedOption(presets, settings.preset, false);
            if (JSON.stringify(presets) !== JSON.stringify(options)) {
                setPresetOptions(options);
            }
            if (JSON.stringify(settings.preset) !== JSON.stringify(selectedValue)) {
                setSelectedPreset(selectedValue);
            }
            setSelectedPresetLabel(selectedItemLabel);
        } else if (["FreeText", "MultiSelect", "ListValues"].includes(useInput)) {
            setSelectedValues(settings.values);
            setFreeTextValues(toCSV(settings.values));
        } else if (useInput === "BetweenDateTime") {
            setMinDate(formattedDate(settings.min));
            setMaxDate(formattedDate(settings.max));
        } else if (useInput === "RelativeDateTime") {
            const minParts = (settings.min || "").split(" ");
            const maxParts = (settings.max || "").split(" ");
            const newMinDateAmount = minParts[0];
            const newMinDateUnit = minParts[1];
            const newMaxDateAmount = maxParts[0];
            const newMaxDateUnit = maxParts[1];
            setMinDateValues(newMinDateAmount, newMinDateUnit);
            setMaxDateValues(newMaxDateAmount, newMaxDateUnit);
        } else if (useInput === "BetweenNumber") {
            setMinNumber(settings.min);
            setMaxNumber(settings.max);
        }
        // new / existing record
        const isSaved = Boolean(filter.id || filter.floatingId);
        setIsSaved(isSaved);

        // scope
        let filterScopes = JSON.parse(JSON.stringify(RecordConstants.FILTER_SCOPES)); // deepcopy to prevent changing underlying values
        if (!isSaved) {
            filterScopes = filterScopes.filter(function (v) {
                return newScopes.includes(v.value);
            });
        }
        const newScopeOptions = filterScopes;
        const newScopeValue = filter.scope;
        setScopeValues(newScopeOptions, newScopeValue);
        const selectedKey = key;
        loadKeyDetails(operator, selectedKey, updatedIsInit);
    }

    const handleContainerSelect = (record: FilterType) => {
        setSelectedContainer({ ...record });
        setContainer(record);
        loadChains(chain);
    };

    const handleKeySelect = (record: FilterType) => {
        const selectedKey = { ...record };
        setSelectedKey({ ...selectedKey });
        const updatedIsInit = false;
        setIsInit(updatedIsInit);
        loadKeyDetails(operator, selectedKey, updatedIsInit);
    };

    const onEdit = () => {
        setFieldErrors({});
        setMode("edit");
    };

    const handleOperatorChange = (event: React.MouseEvent<HTMLDivElement>, data: any) => {
        if (data.selection.length === 0) {
            return;
        }

        const newOperator = data.selection[0].value;
        setOperator(newOperator);
        setOperatorLabel(data.selection[0].label);
        setOperatorSelection(data.selection);
        const updatedIsInit = false;
        setIsInit(updatedIsInit);
        setOperatorValue(operatorOptions, newOperator, record, minNumberDefault, maxNumberDefault, updatedIsInit, valuesOptions);
    };

    const handleSelectedValuesChange = (value: string, process: string) => {
        let updatedSelectedValues;
        if (process === "optionsToSelected") {
            updatedSelectedValues = selectedValues && selectedValues.includes(value) ? selectedValues : [...(selectedValues || []), value];
        } else {
            updatedSelectedValues = selectedValues && selectedValues.length > 0 ? selectedValues.filter((item) => item !== value) : [];
        }
        setSelectedValues(updatedSelectedValues);
        setSelectedValue(null);
    };

    const handleSelectedValueUpOrDown = (process: string) => {
        const currentIndex = selectedValues.indexOf(selectedValue);

        if (currentIndex === -1) {
            return;
        }

        const updatedSelectedValues = [...selectedValues];
        if (process === "up" && currentIndex > 0) {
            const temp = updatedSelectedValues[currentIndex];
            updatedSelectedValues[currentIndex] = updatedSelectedValues[currentIndex - 1];
            updatedSelectedValues[currentIndex - 1] = temp;
        } else if (process === "down" && currentIndex < updatedSelectedValues.length - 1) {
            const temp = updatedSelectedValues[currentIndex];
            updatedSelectedValues[currentIndex] = updatedSelectedValues[currentIndex + 1];
            updatedSelectedValues[currentIndex + 1] = temp;
        }
        setSelectedValues(updatedSelectedValues);
    };

    const handleChangeSelectedValue = (selected: string) => setSelectedValue(selected);
    function setParent(record: any) {
        if (recordId) setLocalParentId(record?.container?.id || "");
    }

    // record card body-content
    const cardBody = (
        <div className="slds-form slds-var-m-around_medium" role="list">
            <h3 className="slds-section-title--divider slds-var-m-top_medium">Filter Details</h3>
            <div className="slds-form__row">
                <FormItemWrapper>
                    <div className="slds-grid slds-grid_vertical slds-wrap">
                        <div className="slds-col slds-size_1-of-1">
                            <div className="slds-grid slds-grid_vertical-align-center slds-var-m-bottom_small">
                                {/* Active */}
                                <div className="slds-col slds-size_xxx-small">
                                    <Field
                                        mode={mode}
                                        isEditable
                                        isFormItem
                                        value={mode === "edit" ? null : <CheckAndCloseIcons selectedItem={record?.active} />}
                                        label="Active"
                                        onEdit={onEdit}
                                        customChangeHandler
                                        body={
                                            <Toggle
                                                label="Active"
                                                active={record?.active}
                                                setActive={() => setRecord((prev) => ({ ...prev, active: !prev?.active }))} //
                                            />
                                        }
                                    />
                                </div>
                                {/* Name */}
                                <div className="slds-grow slds-m-left_small">
                                    <Field
                                        recordObject={recordObject}
                                        setRecord={setRecord}
                                        mode={mode}
                                        value={record?.name}
                                        label="Name"
                                        onEdit={onEdit}
                                        fieldName="name"
                                        isEditable
                                        isFormItem
                                        canOverride
                                        hasOverride={record?.hasOverride?.name}
                                        checkValidity={checkRequiredField}
                                        setFieldErrors={setFieldErrors}
                                        body={<Input name="name" autoComplete="off" label="Name" required value={record?.name || ""} errorText={fieldErrors?.name} />}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </FormItemWrapper>
                {/* Scope */}
                <FormItemWrapper>
                    <Field
                        mode={mode}
                        value={record?.scope}
                        label="Scope"
                        onEdit={onEdit}
                        fieldName="scope"
                        isEditable
                        isFormItem
                        setRecord={setRecord}
                        setComboboxSelection={setScopeSelection}
                        body={
                            <Combobox
                                labels={{ label: "Scope", placeholder: "--Please Select--" }}
                                menuPosition="relative"
                                options={scopeOptions}
                                selection={scopeSelection}
                                value={record?.scope || ""}
                                variant="readonly"
                                singleInputDisabled={isSaved}
                                disabled={isSaved}
                                name="scope"
                            />
                        }
                    />
                </FormItemWrapper>
            </div>

            {!hideAppliesTo && (
                <>
                    <h3 className="slds-section-title--divider slds-var-m-top_medium">Applies To</h3>
                    <div className="slds-form__row">
                        {/* Container */}
                        {/* <Field
                            mode={mode}
                            value={selectedContainer?.name}
                            label="Object"
                            onEdit={onEdit}
                            fieldName="container"
                            checkValidity={checkRequiredField}
                            setFieldErrors={setFieldErrors}
                            customChangeHandler
                            body={
                                <PsNavigationInput
                                    label="Object"
                                    object="container"
                                    sections={["containers"]}
                                    selected={containerItem}
                                    record={selectedContainer}
                                    required
                                    disabled={isSaved}
                                    onChange={handleContainerSelect}
                                    filters={containerFilter}
                                    isExpanded={cmpState.isExpanded}
                                    activeField={cmpState.activeField}
                                    setFilterState={setCmpState}
                                    filterSetState={filterSetState}
                                    fieldErrors={fieldErrors}
                                    childToParent={childToParent}
                                    value={selectedContainer}
                                    name="container"
                                />
                            }
                        /> */}
                        <Field
                            customChangeHandler
                            mode={mode}
                            value={selectedContainer?.name}
                            label="Object"
                            onEdit={onEdit}
                            fieldName="container"
                            checkValidity={checkRequiredField}
                            setFieldErrors={setFieldErrors}
                            body={
                                <TreeInput
                                    section="containers"
                                    object="container"
                                    selectedRecord={selectedContainer}
                                    filters={containerFilter}
                                    onSelect={handleContainerSelect}
                                    inputProps={{
                                        label: "Object",
                                        required: true,
                                        disabled: isSaved,
                                        errorText: fieldErrors?.container,
                                    }}
                                />
                            }
                        />

                        {/* Chain */}
                        {chain.needsChain ? (
                            <>
                                {/* <Field
                                    mode={mode}
                                    value={chain.selectedChain?.name}
                                    label="Path"
                                    onEdit={onEdit}
                                    fieldName="chain"
                                    checkValidity={checkRequiredField}
                                    setFieldErrors={setFieldErrors}
                                    body={
                                        <PsNavigationInput
                                            label="Path"
                                            object="chain"
                                            sections={["chains"]}
                                            selected={chain.chainItem}
                                            record={chain.selectedChain}
                                            disabled={isSaved}
                                            required
                                            filters={chain.chainFilter}
                                            isExpanded={cmpState.isExpanded}
                                            activeField={cmpState.activeField}
                                            setFilterState={setCmpState}
                                            filterSetState={filterSetState}
                                            fieldErrors={fieldErrors}
                                            childToParent={childToParent}
                                            value={chain.selectedChain}
                                            name="chain"
                                        />
                                    }
                                /> */}
                                <Field
                                    customChangeHandler
                                    mode={mode}
                                    value={chain.selectedChain?.name}
                                    label="Path"
                                    onEdit={onEdit}
                                    fieldName="chain"
                                    checkValidity={checkRequiredField}
                                    setFieldErrors={setFieldErrors}
                                    body={
                                        <TreeInput
                                            object="chain"
                                            section={"chains"}
                                            selectedRecord={chain.selectedChain}
                                            filters={chain?.chainFilter?.chains?.chain || {}}
                                            inputProps={{
                                                label: "Path",
                                                required: true,
                                                disabled: isSaved,
                                                errorText: fieldErrors?.chain,
                                            }}
                                        />
                                    }
                                />
                            </>
                        ) : (
                            <FormItemWrapper></FormItemWrapper>
                        )}
                    </div>
                </>
            )}
            <h3 className="slds-section-title--divider slds-var-m-top_medium">Settings</h3>
            <div className="slds-form__row">
                {/* Key (Field) */}
                {/* <Field
                    mode={mode}
                    value={selectedKey?.name || keyItem}
                    label="Field"
                    onEdit={onEdit}
                    fieldName="key"
                    checkValidity={checkRequiredField}
                    setFieldErrors={setFieldErrors}
                    customChangeHandler
                    body={
                        <PsNavigationInput
                            label="Field"
                            object="key"
                            sections={["keys"]}
                            selected={keyItem}
                            record={selectedKey}
                            required
                            disabled={isSaved}
                            onChange={handleKeySelect}
                            activeField={cmpState.activeField}
                            setFilterState={setCmpState}
                            filterSetState={filterSetState}
                            fieldErrors={fieldErrors}
                            childToParent={childToParent}
                            value={selectedKey}
                            name="key"
                        />
                    }
                /> */}
                <Field
                    customChangeHandler
                    mode={mode}
                    value={selectedKey?.name || keyItem}
                    label="Field"
                    onEdit={onEdit}
                    fieldName="key"
                    checkValidity={checkRequiredField}
                    setFieldErrors={setFieldErrors}
                    body={
                        <TreeInput
                            section="keys"
                            object="key"
                            selectedRecord={selectedKey}
                            filters={{}}
                            onSelect={handleKeySelect}
                            focusItem={{ id: parentId, itemKey: "container" }}
                            inputProps={{
                                label: "Field",
                                required: true,
                                disabled: isSaved,
                                errorText: fieldErrors?.key,
                            }}
                        />
                    }
                />

                {/* Operator */}
                <Field
                    mode={mode}
                    value={operatorLabel}
                    label="Operator"
                    onEdit={onEdit}
                    isEditable
                    fieldName="operator"
                    setFieldErrors={setFieldErrors}
                    checkValidity={checkRequiredCombobox}
                    customChangeHandler
                    body={
                        <Combobox
                            menuItemVisibleLength={10}
                            events={{ onSelect: (event: React.MouseEvent<HTMLDivElement>, data: any) => handleOperatorChange(event, data) }}
                            labels={{ label: "Operator", placeholder: "--Please Select--" }}
                            menuPosition="relative"
                            options={operatorOptions}
                            selection={operatorSelection}
                            value={operator || ""}
                            variant="readonly"
                            required
                            name="operator"
                            errorText={fieldErrors?.operator}
                            disabled={operatorOptions.length === 0 ? true : false}
                        />
                    }
                />
            </div>
            {operator && (
                <div className="slds-form__row">
                    {/* Preset */}
                    {useInput === "Preset" && (
                        <Field
                            mode={mode}
                            value={selectedPresetLabel}
                            label="Preset"
                            onEdit={onEdit}
                            isEditable
                            fieldName="preset"
                            setFieldErrors={setFieldErrors}
                            checkValidity={checkRequiredCombobox}
                            customChangeHandler
                            body={
                                <Combobox
                                    name="preset"
                                    menuItemVisibleLength={10}
                                    events={{ onSelect: (event: React.MouseEvent<HTMLDivElement>, data: any) => handleSelectPreset(event, data) }}
                                    labels={{ label: "Preset", placeholder: "--Please Select--" }}
                                    menuPosition="relative"
                                    options={presetOptions}
                                    selection={presetSelection}
                                    value={selectedPreset || ""}
                                    variant="readonly"
                                    required
                                    errorText={fieldErrors?.preset}
                                />
                            }
                        />
                    )}

                    {/* Freetext */}
                    {useInput === "FreeText" && (
                        <Field
                            customChangeHandler
                            mode={mode}
                            value={freeTextValues}
                            label="Values"
                            onEdit={onEdit}
                            fieldName="freeTextValues"
                            body={
                                <Input
                                    name="freeTextValues"
                                    label="Values"
                                    autocomplete="off"
                                    value={freeTextValues || ""}
                                    fieldLevelHelpTooltip={
                                        <Tooltip
                                            id="field-level-help-tooltip"
                                            align="top left"
                                            content='Enter one or more values separated by commas. Enclose values with commas in double quotes. 
    For example: orange, "pear, apple", banana'
                                        />
                                    }
                                    onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setFreeTextValues(e.target.value)}
                                />
                            }
                        />
                    )}

                    {/* MultiSelect */}
                    {useInput === "MultiSelect" && (
                        <Field
                            customChangeHandler
                            mode={mode}
                            value={freeTextValues}
                            label="Values"
                            onEdit={onEdit}
                            fieldName="freeTextValues"
                            body={
                                <DualListBox
                                    handleSelectedValuesChange={handleSelectedValuesChange}
                                    handleChangeSelectedValue={handleChangeSelectedValue}
                                    handleSelectedValueUpOrDown={handleSelectedValueUpOrDown}
                                    label="Values"
                                    fieldLevelHelp=""
                                    valuesOptions={selectedValues && selectedValues.length > 0 ? valuesOptions.filter((option) => !selectedValues.includes(option.value)) : valuesOptions}
                                    selectedOptions={
                                        selectedValues && selectedValues.length > 0
                                            ? selectedValues.map((selectedValue) => ({
                                                  value: selectedValue,
                                                  label: selectedValue === "POINT SIGMA EMPTY VALUE" ? "--None--" : selectedValue,
                                              }))
                                            : []
                                    }
                                    selectedValue={selectedValue}
                                />
                            }
                        />
                    )}

                    {/* Between Number  */}
                    {useInput === "BetweenNumber" && (
                        <>
                            {/* Min Number */}
                            <Field
                                mode={mode}
                                value={minNumber}
                                label="From"
                                onEdit={onEdit}
                                fieldName="minNumber"
                                customChangeHandler
                                body={
                                    <Input
                                        type="text"
                                        name="minnumber"
                                        autoComplete="off"
                                        label="From"
                                        required={false}
                                        value={minNumber || ""}
                                        fieldLevelHelpTooltip={<Tooltip id="field-level-help-tooltip" align="top left" content="Minimum value (included). Leave empty to ignore." />}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setMinNumber(e.target.value)}
                                    />
                                }
                            />
                            {/* Max Number */}
                            <Field
                                mode={mode}
                                value={maxNumber}
                                label="To"
                                onEdit={onEdit}
                                fieldName="maxNumber"
                                customChangeHandler
                                body={
                                    <Input
                                        type="text"
                                        name="maxnumber"
                                        autoComplete="off"
                                        label="To"
                                        required={false}
                                        value={maxNumber || ""}
                                        fieldLevelHelpTooltip={<Tooltip id="field-level-help-tooltip" align="top left" content="Maximum value (included). Leave empty to ignore." />}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setMaxNumber(e.target.value)}
                                    />
                                }
                            />
                        </>
                    )}

                    {/* Between DateTime */}
                    {useInput === "BetweenDateTime" && (
                        <>
                            {/* Min DateTime */}
                            <Field
                                mode={mode}
                                value={minDate}
                                label="From"
                                onEdit={onEdit}
                                fieldName="mindate"
                                isEditable
                                customChangeHandler
                                body={
                                    <Input
                                        type="datetime-local"
                                        name="mindate"
                                        autoComplete="off"
                                        label="From"
                                        value={minDate}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>, data: { value: string }) => setMinDate(data.value)}
                                        fieldLevelHelpTooltip={<Tooltip id="field-level-help-tooltip" align="top left" content="Minimum date and time (included). Leave empty to ignore." />}
                                    />
                                }
                            />

                            {/* Max DateTime */}
                            <Field
                                mode={mode}
                                value={maxDate}
                                label="To"
                                onEdit={onEdit}
                                fieldName="maxdate"
                                isEditable
                                customChangeHandler
                                body={
                                    <Input
                                        type="datetime-local"
                                        name="maxdate"
                                        autoComplete="off"
                                        label="To"
                                        value={maxDate}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>, data: { value: string }) => setMaxDate(data.value)}
                                        fieldLevelHelpTooltip={<Tooltip id="field-level-help-tooltip" align="top left" content="Maximum date and time (excluded). Leave empty to ignore." />}
                                    />
                                }
                            />
                        </>
                    )}

                    {/* Relative DateTime */}
                    {useInput === "RelativeDateTime" && (
                        <>
                            <FormItemWrapper
                                label="From"
                                tooltip={
                                    <Tooltip
                                        id="base"
                                        align="top left"
                                        content="Calendar date or time unit; e.g.: '0 weeks': start of this week; '-1 months': start of last month, '1 years': start of next year. Leave empty to ignore."
                                        variant="learnMore"
                                        dialogClassName="dialog-classname"
                                    />
                                }
                            >
                                <div className="slds-grid small-gap">
                                    {/* Min Amount */}
                                    <Field
                                        isFormItem
                                        mode={mode}
                                        value={minDateAmount}
                                        label="Amount"
                                        onEdit={onEdit}
                                        fieldName="minDateAmount"
                                        checkValidity={minDateUnit && minDateUnit !== "--None--" ? checkRequiredField : null}
                                        setFieldErrors={setFieldErrors}
                                        customChangeHandler
                                        body={
                                            <Input
                                                type="number"
                                                step={1}
                                                name="minDateAmount"
                                                label="Amount"
                                                required={minDateUnit && minDateUnit !== "--None--" ? true : false}
                                                value={minDateAmount}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleMinDateAmount(e.target.value)}
                                                errorText={fieldErrors?.minDateAmount}
                                            />
                                        }
                                    />
                                    {/* Min Unit */}
                                    <Field
                                        isFormItem
                                        mode={mode}
                                        value={minDateUnitLabel}
                                        label="Unit"
                                        onEdit={onEdit}
                                        fieldName="minDateUnit"
                                        checkValidity={minDateAmount ? checkRequiredCombobox : null}
                                        setFieldErrors={setFieldErrors}
                                        customChangeHandler
                                        body={
                                            <Combobox
                                                menuItemVisibleLength={10}
                                                events={{ onSelect: (event: React.MouseEvent<HTMLDivElement>, data: any) => handleMinDateUnitChange(event, data) }}
                                                labels={{
                                                    label: "Unit",
                                                    placeholder: "--Please Select--",
                                                }}
                                                menuPosition="relative"
                                                options={dateTimeUnits}
                                                selection={minDateTimeUnitsSelection}
                                                value={minDateUnit}
                                                name="minDateUnit"
                                                variant="readonly"
                                                required={minDateAmount ? true : false}
                                                errorText={fieldErrors?.minDateUnit}
                                            />
                                        }
                                    />
                                </div>
                            </FormItemWrapper>
                            <FormItemWrapper
                                label="To"
                                tooltip={
                                    <Tooltip
                                        id="base"
                                        align="top left"
                                        content="Calendar date or time unit; e.g.: '0 weeks': end of last week; '-1 months': end of the month before last, '1 years': end of this year. Leave empty to ignore."
                                        variant="learnMore"
                                        dialogClassName="dialog-classname"
                                    />
                                }
                            >
                                <div className="slds-grid small-gap">
                                    {/* Max Amount */}
                                    <Field
                                        isFormItem
                                        mode={mode}
                                        value={maxDateAmount}
                                        label="Amount"
                                        onEdit={onEdit}
                                        fieldName="maxDateAmount"
                                        checkValidity={maxDateUnit && maxDateUnit !== "--None--" ? checkRequiredField : null}
                                        setFieldErrors={setFieldErrors}
                                        customChangeHandler
                                        body={
                                            <Input
                                                type="number"
                                                step={1}
                                                name="maxDateAmount"
                                                label="Amount"
                                                required={maxDateUnit && maxDateUnit !== "--None--" ? true : false}
                                                value={maxDateAmount}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleMaxDateAmount(e.target.value)}
                                                errorText={fieldErrors?.maxDateAmount}
                                            />
                                        }
                                    />
                                    {/* Max Unit */}
                                    <Field
                                        isFormItem
                                        mode={mode}
                                        value={maxDateUnitLabel}
                                        label="Unit"
                                        onEdit={onEdit}
                                        fieldName="maxDateUnit"
                                        checkValidity={maxDateAmount ? checkRequiredCombobox : null}
                                        setFieldErrors={setFieldErrors}
                                        customChangeHandler
                                        body={
                                            <Combobox
                                                menuItemVisibleLength={10}
                                                events={{ onSelect: (event: React.MouseEvent<HTMLDivElement>, data: any) => handleMaxDateUnitChange(event, data) }}
                                                labels={{
                                                    label: "Unit",
                                                    placeholder: "--Please Select--",
                                                }}
                                                menuPosition="relative"
                                                options={dateTimeUnits}
                                                selection={maxDateTimeUnitsSelection}
                                                name="maxDateUnit"
                                                value={maxDateUnit}
                                                variant="readonly"
                                                required={maxDateAmount ? true : false}
                                                errorText={fieldErrors?.maxDateUnit}
                                            />
                                        }
                                    />
                                </div>
                            </FormItemWrapper>
                        </>
                    )}
                </div>
            )}

            <div className="slds-form__row">
                {/* AcceptMissingValuesToggle */}
                <Field
                    mode={mode}
                    isEditable
                    value={mode === "edit" ? null : <CheckAndCloseIcons selectedItem={record?.acceptMissing} />}
                    label="Accept missing values"
                    onEdit={onEdit}
                    customChangeHandler
                    body={<Toggle label="Accept missing values" active={record?.acceptMissing} setActive={() => setRecord((prev) => ({ ...prev, acceptMissing: !prev?.acceptMissing }))} />}
                />
                <FormItemWrapper></FormItemWrapper>
            </div>
        </div>
    );

    // To access the parent component child state
    useImperativeHandle(ref, () => ({
        setFieldErrors: (field, errorMessage) => {
            setFieldErrors((prev) => ({
                ...prev,
                [field]: errorMessage,
            }));
        },
        cardBody,
        record,
        fieldErrors,
        filterJson: () => {
            const filter = record;
            let updatedFilter = filterJson(
                filter,
                operatorOptions,
                operator,
                valuesOptions,
                minDate,
                maxDate,
                selectedPreset,
                presetOptions,
                selectedValues,
                minDateAmount,
                maxDateAmount,
                minDateUnit,
                maxDateUnit,
                minNumber,
                maxNumber,
                record?.scope,
                selectedKey,
                selectedContainer,
                chain.selectedChain,
                freeTextValues
            );
            return updatedFilter;
        },
    }));

    return (
        <PsRecord2
            recordLabel="Filter"
            recordModule="store"
            recordObject={recordObject}
            overrideFields={["name"]}
            record={record}
            recordValue={recordValue}
            defaultRecord={defaultRecord}
            showEdit
            showDelete
            isModal={isModal}
            mode={mode}
            recordId={recordId}
            parentId={localParentId}
            propagateEvent={propagateEvent}
            updateUI={updateUI}
            setMode={setMode}
            onEdit={onEdit}
            setRecord={setRecord}
            setFieldErrors={setFieldErrors}
            loading={loading}
            setLoading={setLoading}
            childToParent={childToParent}
            parseResponse={parseResponse}
            parseCreateRequest={parseCreateRequest}
            parseUpdateRequest={parseUpdateRequest}
            cardBody={cardBody}
            setParent={setParent}
        />
    );
});

export default PsFilter2;
