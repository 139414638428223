import React, { useState, useEffect } from "react";
import { Card, Button, Badge, Icon } from "@salesforce/design-system-react";


import "./PsConnectorTile.css";

function PsConnectorTile(props) {
    const [cmpState, setCmpState] = useState({
        record: {},
        order: 0,
        mode: "init", //<!-- init, empty, view, error -->
    });

    // const parentFunctions = props.parentFunctions;
    var cmpWorking = {};

    useEffect(() => {
        cmpWorking.current = { ...cmpState };
        cmp.init();
    }, []);

    useEffect(() => {
        cmpWorking = { ...cmpState };
    }, [props]);

    const cmp = {
        get: (key) => {
            if (cmpWorking.current.hasOwnProperty(key)) return cmpWorking.current[key];
            return props[key];
        },

        set: (key, value) => {
            cmpWorking.current[key] = value;
            setCmpState((prev) => ({ ...prev, [key]: value }));
        },

        init: function () {},

        dispatchEvent: function (event) {
            props.childToParent(event);
        },

        bubbleEvent: function (event) {
            let stopPropagation = false;

            if (!stopPropagation) {
                props.childToParent(event);
            }
        },
    };

    const handleSelect = function () {
        var record = props.record;
        var parentId = record?.sourceId || record?.source?.id;
        cmp.dispatchEvent({ type: "navigation", parentId, module: "pump", obj: "connector", id: record?.id, source: "record" });
    };

    const handleRefresh = () => cmp.dispatchEvent({ type: "sync", action: "reload" });
    
    return (
        <div className="slds-card_boundary">
            <Card
                id="recordGrid"
                heading={<span className="slds-card__header-title">{props.record.name || ""}</span>}
                footer={
                    <div>
                        <Button label="Manage" onClick={handleSelect} variant="brand" />
                        {props.record.runStatus === "Running" && <Button label="Check Status" onClick={handleRefresh} variant="outline-brand" />}
                    </div>
                }
            >
                <div className="slds-p-horizontal_medium slds-p-bottom_small">
                    <div className="source">Source: {props.record.sourceName}</div>

                    <div className="slds-illustration slds-m-vertical_small pointer" aria-hidden="true" onClick={handleSelect}>
                        <img
                            src={"/assets/images/connectors/" + props?.record?.connectorType?.image}
                            className="illustration-medium"
                            alt={"connector-icon"}
                            onError={
                                // If image does not exist of file does not exist load default svg
                                (e) => (e.target.src = "/assets/images/connector-no-icon.svg")
                            }
                        />
                    </div>

                    <div className="runStatus">
                        {props.record.runStatus === "Running" && (
                            <Badge
                                id="badge-base-example-inverse"
                                color="inverse"
                                content={props.record.runStatus}
                                icon={<Icon category="utility" name="spinner" size="xx-small" colorVariant="base" />}
                            />
                        )}
                    </div>
                </div>
            </Card>
        </div>
    );
}

export default PsConnectorTile;
