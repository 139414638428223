import { MutableRefObject } from "react";
import { mapTreeItemWithConstraints, SLOT_DATA } from "./constants";
import { DroppedItemsType, DroppedItemType, SlotDataType } from "./types";
import { TreeItemKeys } from "../../../../components/navigation-tree/types";
import { TREE_DATA_ITEMS, TREE_STATIC_DATA } from "../../../../components/navigation-tree/constants";
import { getTitle } from "../../../../utils/index2";
import { getBreadcrumbTree } from "../../../../components/navigation-tree/utils";
import { getRecordsAPI } from "../../../../services/api";

export function convertSlotDataToConstraints(slotData: SlotDataType, droppedItems: MutableRefObject<DroppedItemsType>): null | object {
    const deepCopyConstraints: SlotDataType = JSON.parse(JSON.stringify(slotData));
    const itemList = droppedItems.current;
    const updateConstrainer = {};

    for (const slot of SLOT_DATA) {
        if (deepCopyConstraints[slot]?.length) {
            const slotIds = deepCopyConstraints[slot];
            const sltObject = {};
            slotIds.forEach((id) => {
                const { category, attribute, constraintType } = mapTreeItemWithConstraints[itemList[id].obj];
                if (!sltObject[category]) sltObject[category] = {};
                if (!sltObject[category][attribute]) sltObject[category][attribute] = {};
                if (!sltObject[category][attribute][constraintType]) sltObject[category][attribute][constraintType] = [];
                sltObject[category][attribute][constraintType].push(id);
            });
            updateConstrainer[slot] = sltObject;
        }
    }
    return updateConstrainer;
}

export async function initializedDroppedItems(droppedItemsRef: MutableRefObject<DroppedItemsType>, initItemKeysIds: { [key in TreeItemKeys]?: string[] }) {
    try {
        const boxItems = droppedItemsRef.current;
        for (const key of Object.keys(initItemKeysIds) as TreeItemKeys[]) {
            const treeItem = TREE_DATA_ITEMS[key];
            const { type } = treeItem;
            const { section, filterKey } = mapTreeItemWithConstraints[key];

            const generateDroppedItems = (record: any) => {
                const value = createDroppedItem({ obj: key, label: record.name, id: record.id, title: getTitle(getBreadcrumbTree(section, key, record)) });
                boxItems[value.id] = value;
            };

            if (type === "static") TREE_STATIC_DATA[treeItem.object]?.forEach((record) => generateDroppedItems(record));

            if (type === "dynamic") {
                const { object, module } = treeItem;
                const filters = { [`${filterKey || "id"}`]: initItemKeysIds[key] };
                const res: any[] = await getRecordsAPI({ object, module, filters });
                res?.forEach((record) => generateDroppedItems(record));
            }
        }
    } catch (error) {
        console.error("Error initializedDroppedItems :", error);
    }
}

export function createDroppedItem({ obj, label, id, title }: { obj: TreeItemKeys; label: string; id: string; title: string }): DroppedItemType {
    return {
        id: obj === "dataType" || obj === "dataRole" ? label : id, // dataType and dataRole is using label instead of id
        label,
        title,
        obj,
    };
}

// TODO: remove this function and use getTitle like above
export function getDropBoxTitle(config: any, resValue: any) {
    if (config === "container") return `${resValue?.source?.name} -> ${resValue.name}`;
    if (config === "key") return `${resValue?.container?.source?.name} -> ${resValue?.container?.name} -> ${resValue?.name}`;
    if (config === "chain") return `${resValue?.leftContainer?.source?.name} -> ${resValue?.leftContainer?.name} -> ${resValue?.name}`;
    if (config === "dataType") return `${resValue?.dataRole?.name} -> ${resValue?.name}`;
    // add map ....
    return null;
}

// TODO: we already have this function somewhere else, as we check input fields for valid JSON in the Key record page.
export function isValidJSON(str) {
    try {
        JSON.parse(str); // Try to parse the string
        return true; // If parsing is successful, it's valid JSON
    } catch (e) {
        return false; // If an error occurs, it's not valid JSON
    }
}
