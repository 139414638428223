import React from "react";
import { Icon } from "@salesforce/design-system-react";

interface File {
    name: string;
}

interface FilesProps {
    files: File[];
}

const Files2: React.FC<FilesProps> = ({ files }) => {
    return (
        <div>
            {files.map((file: File) => (
                <div key={file.name} style={{ display: "flex", margin: 4 }}>
                    <div style={{ display: "flex", margin: 4 }}>
                        <Icon assistiveText={{ label: "attach" }} category="utility" colorVariant="default" name="attach" size="xx-small" />
                    </div>
                    <div key={file.name} style={{ margin: 4, color: "red" }}>
                        {file.name}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Files2;
