import { useState } from "react";
import { Button } from "@salesforce/design-system-react";

import PsRecordGrid from "../ps-record-grid/PsRecordGrid";
import Record from "../../helpers/recordLayer";
import { RECORD_COLUMNS } from "./constants";
import { toastErrorMessage } from "../../helpers/index.js";
import useToastContext from "../../context/useToastContext";

const PsKeyList = ({ parentId, queryFilter, childToParent, propagateEvent }) => {
    const [mode, setMode] = useState("init");
    const [view, setView] = useState("table");
    const [orderBy, setOrderBy] = useState("name");
    const [orderDirection, setOrderDirection] = useState("asc");
    const [hasMore, setHasMore] = useState(false);
    const [loading, setLoading] = useState(false);

    const { addToast } = useToastContext();

    const parseResponse = (response) => {
        return response.map(({ id, name, container, numValues, robustDistinct, dataType, relevance, dataLastChangedOn }) => ({
            id,
            name,
            sourceName: container.source.name,
            containerName: container.name,
            numValues,
            robustDistinct,
            dataType: dataType.name,
            image: dataType.image,
            relevance,
            dataLastChangedOn,
        }));
    };

    const actionRunSupply = () => {
        let previousMode = mode;
        setLoading(true);

        const onSucces = function () {
            addToast("info", "Data Processing Started", "Started processing data, this may take a few moments");
            setLoading(false);
            setMode(previousMode);
        };

        const onError = function (response) {
            addToast("error", "Error", toastErrorMessage(response));
            setLoading(false);
            setMode(previousMode);
        };

        Record.doAction("store", "data", "supply", {}, onSucces, onError);
    };

    const emptyCallToAction = () => {
        const label = "Process Data";
        const title = "Start processing data";
        return <Button label={label} title={title} onClick={actionRunSupply} disabled={loading} />;
    };

    return (
        <PsRecordGrid
            module="store"
            object="key"
            queryFilter={queryFilter}
            showTitle
            emptyLine="No Fields found"
            emptyCallToAction={emptyCallToAction}
            recordLabel="Field"
            recordLabelPlural="Fields"
            parseResponse={parseResponse}
            parentId={parentId}
            mode={mode}
            onModeChange={(value) => setMode(value)}
            view={view}
            onViewChange={setView}
            orderBy={orderBy}
            onOrderByChange={(value) => setOrderBy(value)}
            orderDirection={orderDirection}
            onOrderDirectionChange={(value) => setOrderDirection(value)}
            loading={loading}
            onLoadingChange={(value) => setLoading(value)}
            showLoadMore
            hasMore={hasMore}
            onHasMoreChange={setHasMore}
            recordColumns={RECORD_COLUMNS}
            maxRecords={100}
            childToParent={childToParent}
            propagateEvent={propagateEvent}
            fullHeight={false}
        />
    );
};

export default PsKeyList;
