import { useState } from "react";
import { Button } from "@salesforce/design-system-react";

import PsRecordGrid from "../ps-record-grid/PsRecordGrid";
import Record from "../../helpers/recordLayer";
import { RECORD_COLUMNS } from "./constants";
import { toastErrorMessage } from "../../helpers/index.js";
import useToastContext from "../../context/useToastContext";

const PsChainList = ({ parentId, queryFilter, childToParent, propagateEvent }) => {
    const [mode, setMode] = useState("init");
    const [view, setView] = useState("table");
    const [orderBy, setOrderBy] = useState("name");
    const [orderDirection, setOrderDirection] = useState("asc");
    const [hasMore, setHasMore] = useState(false);
    const [loading, setLoading] = useState(false);

    const { addToast } = useToastContext();

    const parseResponse = (response) => {
        return response.map(({ id, name, custom, leftContainer, rightContainer, relevance, relationship }) => ({
            id,
            name,
            custom,
            leftContainerName: leftContainer.name,
            rightContainerName: rightContainer.name,
            relevance,
            relationship,
        }));
    };

    const handleNew = () => {
        childToParent({ type: "navigation", parentId, module: "store", obj: "chain", id: null, source: "grid" });
    };

    const actionRunTack = () => {
        let previousMode = mode;
        setLoading(true);

        const onSucces = function () {
            addToast("info", "Path Finding Started", "Started searching for Paths, this may take a few moments");
            setLoading(false);
            setMode(previousMode);
        };

        const onError = function (response) {
            addToast("error", "Error", toastErrorMessage(response));
            setLoading(false);
            setMode(previousMode);
        };

        Record.doAction("store", "data", "tack", {}, onSucces, onError);
    };

    const emptyCallToAction = () => {
        const label_find = "Find Paths";
        const title_find = "Start finding Paths";
        return <Button label={label_find} title={title_find} onClick={actionRunTack} disabled={loading} />;
    };

    return (
        <PsRecordGrid
            module="store"
            object="chain"
            queryFilter={queryFilter}
            showTitle
            emptyLine="No Paths found"
            emptyCallToAction={emptyCallToAction}
            showCreate
            recordLabel="Path"
            recordLabelPlural="Paths"
            parseResponse={parseResponse}
            parentId={parentId}
            mode={mode}
            onModeChange={(value) => setMode(value)}
            view={view}
            onViewChange={setView}
            orderBy={orderBy}
            onOrderByChange={(value) => setOrderBy(value)}
            orderDirection={orderDirection}
            onOrderDirectionChange={(value) => setOrderDirection(value)}
            loading={loading}
            onLoadingChange={(value) => setLoading(value)}
            showLoadMore
            hasMore={hasMore}
            onHasMoreChange={setHasMore}
            recordColumns={RECORD_COLUMNS}
            maxRecords={100}
            childToParent={childToParent}
            propagateEvent={propagateEvent}
            fullHeight={false}
        />
    );
};

export default PsChainList;
