import { useLayoutEffect, useRef, useState } from "react";

import { ToastContainer, Toast } from "@salesforce/design-system-react";

import useToastContext from "../../context/useToastContext";
import useWindowSize from "../../hooks/useWindowSize";

const ToastComponent2 = () => {
    const [positions, setPositions] = useState([]);

    const { toasts, removeToast } = useToastContext();
    const toastRefs = useRef([]);

    const { width } = useWindowSize();

    // runs synchronously after all DOM mutations but before the browser paints, making it ideal for reading layout and applying changes.
    useLayoutEffect(() => {
        // Calculate positions
        const calculatedPositions = toastRefs.current.map((ref, index) => {
            const previousHeight = index > 0 ? toastRefs.current[index - 1]?.toast?.offsetHeight || 0 : 50;

            const spacing = 10; // Spacing between toasts
            return index === 0 ? 50 : positions[index - 1] + previousHeight + spacing;
        });

        // Only update state if positions have actually changed
        if (JSON.stringify(calculatedPositions) !== JSON.stringify(positions)) {
            setPositions(calculatedPositions);
        }
    }, [toasts, positions, width]);

    return (
        <>
            {toasts.map((toast, index) => {
                if (!toast.visible) return null;

                return (
                    <ToastContainer key={toast.id}>
                        <Toast
                            labels={{
                                heading: toast.heading || "",
                                details: toast.details,
                            }}
                            variant={toast.variant}
                            onRequestClose={() => removeToast(toast.id)}
                            ref={(el) => (toastRefs.current[index] = el)} // Assign refs dynamically
                            style={{
                                position: "absolute",
                                top: positions[index] || 50, // Use calculated positions
                                left: "50%",
                                transform: "translateX(-50%)",
                            }}
                            duration={5000}
                        />
                    </ToastContainer>
                );
            })}
        </>
    );
};

export default ToastComponent2;
