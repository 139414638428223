import { useState } from "react";
import { Icon } from "@salesforce/design-system-react";

import { formattedPercentage } from "../../../helpers";

const Details = ({ pattern }) => {
    const [showSection, setShowSection] = useState(false);

    return (
        pattern?.relevanceType && (
            <div className="slds-m-horizontal_x-small" style={{ flexBasis: "300px", flexGrow: 1, minWidth: "300px" }}>
                <div className={`slds-section ${showSection ? "slds-is-open" : "slds-is-close"}`}>
                    <h3>
                        <button className="slds-button slds-section__title-action" onClick={() => setShowSection(!showSection)}>
                            <span>
                                <Icon assistiveText={{ label: "switch section" }} category="utility" name="switch" size="x-small" className="slds-section__title-action-icon slds-button__icon_left" />
                            </span>
                            <span>Details</span>
                        </button>
                    </h3>
                    <div className="slds-section__content" style={{ display: "flex" }}>
                        <span style={{ marginRight: "0.25em" }}>Relevance: </span>
                        {formattedPercentage(pattern?.relevance)} ({pattern?.relevanceType})
                    </div>
                </div>
            </div>
        )
    );
};

export default Details;
