import React, { ClipboardEvent, KeyboardEvent, useEffect, useRef, useState } from "react";
import { Button, Dropdown } from "@salesforce/design-system-react";
import { addSpan, convertSearchToContent, endsWithWhitespace, splitStringAtIndex } from "./utils";
import { EventNavigationType } from "../../types";
import { TREE_DATA_ITEMS } from "../../../components/navigation-tree/constants";
import { getTitle } from "../../../utils/index2";

interface Props {
    value: string;
    search: string;
    onChange: (value: string, searchText: string, navPosition: null | { top: number; left: number }) => void;
    select: EventNavigationType | null | string;
    setSelect: (value: null) => void;
    reFocus: boolean;
    setRefocus: (value: boolean) => void;
    generativeAISearchEnabled: boolean;
    suggestionData: { value: string; label: string }[];
    onSearch: (text: string) => void;
    onEnter: boolean;
    setOnEnter: (value: boolean) => void;
    onEnterDown: () => void;
    onClickArrow: () => void;
    setShowTree: (navPosition: { top: number; left: number } | null) => void;
}

const ContentEditable = ({
    value,
    search,
    onChange,
    select,
    setSelect,
    reFocus,
    setRefocus,
    generativeAISearchEnabled,
    suggestionData,
    onSearch,
    onEnter,
    onClickArrow,
    setOnEnter,
    onEnterDown,
    setShowTree,
}: Props) => {
    const contentEditableRef = useRef<HTMLDivElement>(null);
    const focusNodeRef = useRef<Node | null>(null);
    const caretPositionRef = useRef(0);

    const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
    const [showPlaceholder, setShowPlaceholder] = useState<boolean>(true);
    const [menuStyle, setMenuStyle] = useState<{ top: number; left: number; minWidth: string }>({
        top: 0,
        left: 0,
        minWidth: "auto",
    });

    useEffect(() => {
        setShowPlaceholder(!value);
        if (contentEditableRef.current && contentEditableRef.current?.innerHTML !== value) contentEditableRef.current.innerHTML = value;
    }, [value]);

    useEffect(() => {
        try {
            if (contentEditableRef.current && onEnter) {
                let childIndex = -1;
                let newStr = "";
                let currentIndex = 0;

                for (let child of contentEditableRef.current.childNodes as any) {
                    if (child.nodeType === Node.ELEMENT_NODE) {
                        newStr += child.outerHTML;
                    } else {
                        if (child === focusNodeRef.current) {
                            childIndex = currentIndex;
                            newStr += child.textContent.slice(0, caretPositionRef.current) + "\n" + "&nbsp;" + child.textContent.slice(caretPositionRef.current);
                        } else if (child.nodeType === Node.TEXT_NODE) {
                            newStr += child.textContent;
                        }
                    }

                    currentIndex++;
                }
                if (childIndex !== -1) {
                    if (endsWithWhitespace(newStr)) newStr += "&nbsp;";
                    contentEditableRef.current.innerHTML = newStr;
                    const range = document.createRange();
                    const selection = window.getSelection();
                    const targetNode = contentEditableRef.current.childNodes[childIndex];
                    range.setStart(targetNode, caretPositionRef.current + 2);
                    range.collapse(true);
                    selection?.removeAllRanges();
                    selection?.addRange(range);
                    contentEditableRef.current.focus();
                }
            }
            setOnEnter(false);
        } catch (error) {
            setOnEnter(false);
        }
    }, [onEnter]);

    useEffect(() => {
        try {
            if (contentEditableRef.current && !!select) {
                let childIndex = -1; // Default index if not found
                let newStr = ""; // Store the new HTML structure
                let currentIndex = 0;

                for (let child of contentEditableRef.current.childNodes as any) {
                    // Preserve text nodes as they are
                    if (child.nodeType === Node.ELEMENT_NODE) {
                        newStr += child.outerHTML;
                    } else {
                        if (child === focusNodeRef.current) {
                            const { firstPart, lastPart } = splitStringAtIndex(child.textContent, caretPositionRef.current);
                            childIndex = currentIndex + (firstPart ? 2 : 1);
                            const selectIsString = typeof select === "string";
                            const id = selectIsString ? select : select.id;
                            const label = selectIsString ? select : select.label;
                            const title = selectIsString ? select : getTitle(select.breadcrumb);
                            const obj = selectIsString ? TREE_DATA_ITEMS.key.object : select?.obj; // if the select is string then the obj is always "key" for now
                            newStr += firstPart + addSpan({ id, label, obj, title }) + "&nbsp;" + lastPart;
                        } else if (child.nodeType === Node.TEXT_NODE) {
                            newStr += child.textContent;
                        }
                    }

                    currentIndex++;
                }
                if (childIndex !== -1) {
                    if (!/\s$/.test(newStr)) newStr += "&nbsp;";
                    contentEditableRef.current.innerHTML = newStr;
                    const range = document.createRange();
                    const selection = window.getSelection();
                    const targetNode = contentEditableRef.current.childNodes[childIndex];
                    range.setStart(targetNode, 1);
                    range.collapse(true);
                    selection?.removeAllRanges();
                    selection?.addRange(range);
                    contentEditableRef.current.focus();
                }
            }
            setSelect(null);
            setShowTree(null);
        } catch (error) {
            setSelect(null);
            setShowTree(null);
        }
    }, [select]);

    useEffect(() => {
        if (contentEditableRef.current && reFocus) {
            let childIndex = -1; // Default index if not found
            for (let child of contentEditableRef.current.childNodes as any) {
                childIndex++;
                if (child === focusNodeRef.current) break;
            }

            if (childIndex !== -1) {
                const range = document.createRange();
                const selection = window.getSelection();
                const targetNode = contentEditableRef.current.childNodes[childIndex];
                range.setStart(targetNode, caretPositionRef.current);
                range.collapse(true);
                selection?.removeAllRanges();
                selection?.addRange(range);
                contentEditableRef.current.focus();
            }
        }

        if (reFocus) setRefocus(false);
    }, [reFocus]);

    useEffect(() => {
        const handleSelectionChange = () => {
            const selection = window.getSelection();

            // return if the selection is false and is focus in the "SPAN" or contentEditableRef.current = selection?.focusNode
            if (
                !selection?.rangeCount ||
                selection?.focusNode?.parentNode?.nodeName === "SPAN" ||
                contentEditableRef?.current === selection?.focusNode ||
                !contentEditableRef?.current?.contains(selection?.focusNode)
            )
                return;

            const range = selection?.getRangeAt(0);
            const parentElement = range.commonAncestorContainer;

            if (contentEditableRef?.current && contentEditableRef.current.contains(parentElement)) {
                const caretRect = range.getBoundingClientRect();
                const position = selection.focusOffset;
                const focusNode = selection.focusNode;
                focusNodeRef.current = focusNode;
                caretPositionRef.current = position;
                const word = focusNode?.textContent ? splitStringAtIndex(focusNode?.textContent, caretPositionRef.current).word : "";
                const top = caretRect.top - 80 - (generativeAISearchEnabled ? 44 : 0); // minus 80 position of the input and minus the input of the question if is visible
                const left = caretRect.left - 20; // margin 16 px extra 4 px for better visualization

                // Ensure the caret is visible inside the contentEditable
                const editor = contentEditableRef.current;
                const editorRect = editor.getBoundingClientRect();
                // Check if caret is out of visible area
                if (caretRect.top < editorRect.top) {
                    console.log("test1");
                    editor.scrollTop -= editorRect.top - caretRect.top; // Scroll up
                } else if (caretRect.bottom > editorRect.bottom) {
                    console.log("test2");
                    editor.scrollTop += caretRect.bottom - editorRect.bottom; // Scroll down
                }

                onChange(contentEditableRef.current.innerHTML, word, { left, top });
            }
        };
        const handleInput = () => {
            if (!contentEditableRef?.current?.textContent) onChange("", "", null);
        };
        document.addEventListener("selectionchange", handleSelectionChange);
        contentEditableRef.current?.addEventListener("input", handleInput);

        return () => {
            document.removeEventListener("selectionchange", handleSelectionChange);
            contentEditableRef.current?.removeEventListener("input", handleInput);
        };
    }, [generativeAISearchEnabled]);

    function updateDropdownPosition() {
        setShowSuggestions(true);
        if (contentEditableRef.current) {
            const rect = contentEditableRef.current.getBoundingClientRect();
            const inputValue = contentEditableRef.current.innerHTML;
            setMenuStyle({
                top: rect.top - 67 - (generativeAISearchEnabled ? 44 : 0), // Position below the div
                left: 0,
                minWidth: `${rect.width}px`, // Match width
            });
            onChange(inputValue, "", null);
        }
    }

    function onSearchHandler() {
        if (!contentEditableRef.current) {
            onSearch("");
            return;
        }
        let value = "";
        contentEditableRef.current.childNodes.forEach((node) => {
            if (node.nodeType === Node.TEXT_NODE) {
                value += node.textContent; // Keep all spaces
            } else if (node.nodeType === Node.ELEMENT_NODE && (node as HTMLElement).tagName === "SPAN") {
                const span = node as HTMLElement;
                const id = span.getAttribute("id");
                const obj = span.getAttribute("obj");
                const title = span.getAttribute("title");
                const label = span.textContent;
                value += `\${${obj}:${id},name:${label},title:${title}}`;
            }
        });
        onSearch(value);
    }

    function onClear() {
        onChange("", "", null);
        onSearch("");
    }
    function onKeyDown(e: KeyboardEvent<HTMLDivElement>) {
        if (e.key === "Enter") {
            e.preventDefault();
            onEnterDown();
        }
        if (e.key === "ArrowDown") {
            e.preventDefault();
            // Get the current selection
            const selection = window.getSelection();
            // return if the selection is false and is focus in the "SPAN" or contentEditableRef.current = selection?.focusNode
            if (!selection?.rangeCount || selection?.focusNode?.parentNode?.nodeName === "SPAN" || contentEditableRef?.current === selection?.focusNode) return;
            const range = selection.getRangeAt(0);
            const caretRect = range.getBoundingClientRect();
            const top = caretRect.top - 80 - (generativeAISearchEnabled ? 44 : 0); // minus 80 position of the input and minus the input of the question if is visible
            const left = caretRect.left - 20; // margin 16 px extra 4 px for better visualization
            setShowTree({ top, left });
        }
    }

    function onPaste(e: ClipboardEvent<HTMLDivElement>) {
        e.preventDefault(); // Prevent default paste behavior
        const text = e.clipboardData.getData("text/plain"); // Get only plain text

        // Get the current selection
        const selection = window.getSelection();
        if (!selection.rangeCount || !text) return;

        // Get the first range (current cursor position)
        const range = selection.getRangeAt(0);
        range.deleteContents(); // Remove selected content if any
        range.insertNode(document.createTextNode(text)); // Insert plain text

        // Move the cursor to the end of inserted text
        range.collapse(false);
        if (!value) setShowPlaceholder(false);
    }

    function onSelectSuggestions(item: any) {
        const inputValue = convertSearchToContent(item.value);
        if (contentEditableRef.current && contentEditableRef.current?.innerHTML !== inputValue) {
            contentEditableRef.current.innerHTML = inputValue;
            onSearchHandler();
        }
    }

    return (
        <>
            {showPlaceholder && <div className="custom-placeholder">Search...</div>}
            <div
                className="slds-input content-editable slds-p-top_xx-small slds-p-bottom_xx-small slds-m-right_xx-small"
                contentEditable
                ref={contentEditableRef}
                onKeyDown={onKeyDown}
                onPaste={onPaste}
            />

            {!!generativeAISearchEnabled ? (
                <>
                    <Dropdown
                        triggerClassName="dropdown-trigger"
                        id="options-1"
                        assistiveText={{ icon: showSuggestions ? "Chevron Down" : "Chevron Right" }}
                        buttonVariant="icon"
                        iconCategory="utility"
                        iconName={showSuggestions ? "chevrondown" : "chevronright"}
                        iconVariant="border"
                        onOpen={updateDropdownPosition}
                        onClose={() => setShowSuggestions(false)}
                        onClick={onClickArrow}
                        onSelect={onSelectSuggestions}
                        value={search.trim()}
                        checkmark
                        options={suggestionData}
                        disabled={!suggestionData?.length}
                        menuStyle={menuStyle}
                    />

                    <Button className="search-button slds-m-left_xx-small" variant="outline-brand" name="update" label="Update" onClick={onSearchHandler} />
                </>
            ) : (
                <>
                    <Button className="slds-m-left_xx-small" label="Clear" onClick={onClear} />
                    <Button className="search-button" variant="brand" name="search" label="Search" onClick={onSearchHandler} />
                </>
            )}
        </>
    );
};

export default ContentEditable;
