import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Card } from "@salesforce/design-system-react";

import "./Explore.css";
import PsSuggestionList from "../../components/ps-suggestion-list/PsSuggestionList";
import PsCompositionGrid from "../../components/ps-composition-grid/PsCompositionGrid";
import PsPatternDetailedView from "../../components/ps-pattern-detailed-view/PsPatternDetailedView";
import PsSetupStatus from "../../components/ps-setup-status/PsSetupStatus";
import useAuthContext from "../../context/useAuthContext.js";
import useToastContext from "../../context/useToastContext";
import { getTitle } from "../../utils/index2";
import NavigationTree from "../../components/navigation-tree/NavigationTree";
import { TreeNodeType, TreeSectionKeys } from "../../components/navigation-tree/types";
import { EventType } from "../types";
import { doActionAPI } from "../../services/api";
import RecordPillContainer from "./components/RecordPillContainer";
import { TREE_DATA_ITEMS, TREE_SECTIONS } from "../../components/navigation-tree/constants";
import PsNavigationHeader from "../../components/ps-navigation-header/PsNavigationHeader";
import PsErrorBoundary from "../../components/ps-error-boundary/PsErrorBoundary";

const treeSectionList: TreeSectionKeys[] = ["types", "data"];

const psCompositionGridProps = { parentId: "", maxRecords: "", tagLine: "", setEmpty: "" };

export type RecordPillType = { id: string; title: string; label: string };
function Explore() {
    const [selected, setSelected] = useState<string[]>([]);

    const [patternId, setPatternId] = useState<string>("");
    const [filter, setFilter] = useState<{ [key: string]: string }>({});

    const [records, setRecords] = useState<{ types?: RecordPillType; data?: RecordPillType }>({});
    const [loading, setLoading] = useState<boolean>(false);
    const [applyNow, setApplyNow] = useState<boolean>(true);
    const [propagateEvent, setPropagateEvent] = useState<EventType | undefined>();

    // global context
    const { handleLogout } = useAuthContext();
    const { addToast } = useToastContext();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        syncStateWithUrl(searchParams);
    }, [searchParams]); // runs on init and when search params are updated

    function syncStateWithUrl(searchParams: URLSearchParams) {
        const urlTypes = searchParams.get("types") || "";
        const urlData = searchParams.get("data") || "";
        const urlPatternId = searchParams.get("patternId") || "";
        const updateFilter = {};
        let updateSelected = [];

        if (urlTypes) {
            updateSelected.push(urlTypes);
            const [section, obj, id] = urlTypes.split("_");
            if (section !== obj) updateFilter[`${obj}Id`] = id;
        }
        if (urlData) {
            updateSelected.push(urlData);
            const [section, obj, id] = urlData.split("_");
            if (section !== obj) updateFilter[`${obj}Id`] = id;
        }

        setSelected(updateSelected);
        setFilter(updateFilter);
        if (urlPatternId !== patternId) setPatternId(urlPatternId);
    }

    function handleRemoveItem(secKey: TreeSectionKeys) {
        const copyRecords = { ...records };
        if (copyRecords[secKey]) delete copyRecords[secKey];
        setRecords(copyRecords);
        searchParams.delete(secKey);
        setSearchParams(searchParams);
    }

    async function handleFind() {
        try {
            setLoading(true);
            const onSuccess = () => {
                addToast("info", "Searching more Patterns", "This may take a few mintues");
                setLoading(false);
            };
            const onError = () => setLoading(false);
            await doActionAPI({ module: "relate", object: "composition", action: "run.select", args: filter }, onSuccess, onError);
        } catch (error) {
            setLoading(false);
        }
    }

    function getPillRecord({ id, selfDataKey, breadcrumb, label }) {
        return {
            id,
            title: TREE_DATA_ITEMS[selfDataKey].name + ": " + getTitle(breadcrumb),
            label: TREE_DATA_ITEMS[selfDataKey].name + ": " + label,
        };
    }

    function selectedItemsHandler(items: { section: TreeSectionKeys; node: TreeNodeType }[]) {
        const updateRecords = {};
        items.forEach(({ node: { id, label, breadcrumb, selfDataKey }, section }) => (updateRecords[section] = getPillRecord({ id, selfDataKey, breadcrumb, label })));
        setRecords(updateRecords);
    }

    function resetPillRecords() {
        setRecords({});
        searchParams.delete("types");
        searchParams.delete("data");
        setSearchParams(searchParams);
    }

    function handleEventRouter(event: EventType) {
        switch (event.type) {
            case "tab":
                // TODO: change event.tab to event.path
                navigate("/" + event.tab);
                break;

            case "logout":
                handleLogout();
                break;
            case "sync":
                setPropagateEvent(event);
                break;
            case "payload":
                if (event.action === "viewDetails") {
                    const id = event?.pattern?.id;
                    if (id) searchParams.set("patternId", id);
                    setSearchParams(searchParams);
                }
                break;

            case "navigation":
                if (event.action === "close") {
                    searchParams.delete("patternId");
                    setSearchParams(searchParams);
                } else {
                    const { id, obj } = event;
                    const section = [...TREE_SECTIONS.types.treeItems, "types"].includes(obj as any) ? "types" : "data";
                    const selected = `${section}_${obj}_${id}`;
                    searchParams.set(section, selected);
                    searchParams.delete("patternId");
                    if (event.source === "tree")
                        // setRecord only if it is from navigation tree
                        setRecords((prev) => {
                            const copyRecords = { ...prev };
                            section !== obj ? (copyRecords[section] = getPillRecord({ id, selfDataKey: obj, breadcrumb: event.breadcrumb, label: event.label })) : delete copyRecords[section];
                            return copyRecords;
                        });
                    setSearchParams(searchParams, { replace: event?.replace });
                }
                break;

            default:
                setPropagateEvent(event);
                break;
        }
    }

    return (
        <div className="Explore">
            <PsNavigationHeader childToParent={handleEventRouter} loading={loading} showClose={!!patternId} showApplyNow={!!patternId} applyNow={applyNow} setApplyNow={setApplyNow} />
            <div className="tab-content slds-p-around_medium">
                {/* <!-- using slds-hide to prevent rebuilding views that need to keep their state --> */}
                <div className={!patternId ? "left slds-m-right_medium" : "slds-hide"}>
                    {/* <!-- navigation tree --> */}
                    <Card className="card-height-full" heading={<span className="card-main-title-lh32 slds-card__header-title">Refine Results</span>}>
                        <RecordPillContainer
                            records={records}
                            treeSectionList={treeSectionList}
                            handleRemoveItem={handleRemoveItem}
                            handleFind={handleFind}
                            resetPillRecords={resetPillRecords}
                            loading={loading}
                        />
                        <NavigationTree
                            singleSelect={false}
                            pxOffsetHV={292}
                            sectionList={treeSectionList}
                            bubbleEvent={handleEventRouter}
                            propagateEvent={propagateEvent}
                            selected={selected}
                            selectedItemsHandler={selectedItemsHandler}
                        />
                    </Card>
                </div>
                {/* <!-- right -->
                <!-- uses slds-hide instead of conditional rendering to prevent reloading setupStatus and also to keep the scroll position when going back from details to list view --> */}
                <div className={!patternId ? "right" : "slds-hide"}>
                    <PsSetupStatus title="Explore" tagLine="Explore discovered patterns using simple navigation." />

                    {!Object.keys(filter).length && <PsSuggestionList childToParent={handleEventRouter} propagateEvent={propagateEvent} />}

                    {!!Object.keys(filter).length && <PsCompositionGrid {...psCompositionGridProps} showTitle queryFilter={filter} childToParent={handleEventRouter} propagateEvent={propagateEvent} />}
                </div>

                {/* <!-- pattern detail --> */}
                {!!patternId && <PsPatternDetailedView pattern={{ id: patternId }} childToParent={handleEventRouter} propagateEvent={propagateEvent} applyNow={applyNow} />}
            </div>
        </div>
    );
}

const ErrorHandledExplore = () => <PsErrorBoundary children={<Explore />} />;
export default ErrorHandledExplore;
