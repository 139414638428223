import React, { useRef } from "react";

import "./Field.css";
import EditButtonIcon from "../EditButtonIcon";
import UndoButtonIcon from "../UndoButtonIcon";
import { ComboboxDataType, ComboboxOption, ConnectorTypeOption, RecordTypeMap, SourceOption } from "../../types";
import { revertOverride, setOverride } from "../../utils";
import FieldSkeleton from "../FieldSkeleton";
import FormItemWrapper from "./FormItemWrapper";
import CustomPopover from "../custom-popover/CustomPopover";

interface FieldProps<T extends keyof RecordTypeMap> {
    recordObject?: T;
    value?: string | number | boolean | JSX.Element | JSX.Element[];
    label?: string;
    isEditable?: boolean;
    body?: JSX.Element;
    buttons?: JSX.Element;
    header?: JSX.Element;
    footer?: JSX.Element;
    onEdit?: () => void;
    onSave?: () => void;
    onCancel?: (reset: boolean) => void;
    onRevert?: Function;
    fieldName?: string; // to revert
    fieldLabel?: string;
    mode: string;
    hasOverride?: boolean;
    showStaticViewInEditMode?: boolean; // this is for non editable fields to show in edit mode
    showStaticViewInNewMode?: boolean;
    canOverride?: boolean;
    isFormItem?: boolean;
    popover?: { isVisible: boolean; isInline: boolean };
    saveOnEnter?: boolean;
    customChangeHandler?: boolean;
    options?: (SourceOption | ConnectorTypeOption)[];
    setRecord?: React.Dispatch<React.SetStateAction<RecordTypeMap[T]>>;
    setFieldErrors?: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>;
    checkValidity?: (value: string) => string;
    setComboboxSelection?: React.Dispatch<React.SetStateAction<ComboboxOption[]>>;
}

// Functional component
const Field = <T extends keyof RecordTypeMap>({
    value = "",
    label = "",
    isEditable = false,
    body = <></>,
    buttons,
    header,
    footer,
    onEdit,
    onSave,
    onCancel,
    fieldName,
    fieldLabel,
    mode,
    showStaticViewInEditMode = false,
    showStaticViewInNewMode = false,
    hasOverride = false,
    isFormItem = false,
    canOverride = false,
    popover,
    saveOnEnter = false,
    customChangeHandler = false,
    options,
    setRecord,
    checkValidity,
    setFieldErrors = () => {},
    onRevert,
    setComboboxSelection,
}: FieldProps<T>): JSX.Element => {
    const popoverRef = useRef({});

    const handleRevert = (field?: string) => {
        if (field === undefined) return;
        if (onRevert) {
            onRevert(field);
        } else {
            setRecord?.((prevRecord) => {
                return revertOverride(prevRecord, field);
            });
        }
        setFieldErrors((prev) => ({ ...prev, [field]: "" }));
    };

    // Handle dynamic field change
    const handleFieldChange = (value: string) => {
        if (checkValidity && fieldName) {
            const errorMessage = checkValidity(value);
            setFieldErrors((prev) => ({ ...prev, [fieldName]: errorMessage }));
        }

        if (setRecord && fieldName) {
            setRecord((prev) => {
                const record = canOverride ? setOverride(prev, fieldName) : prev;
                return { ...record, [fieldName]: value };
            });
        }
    };

    function setSelectedLabel(optionsAttribute: (SourceOption | ConnectorTypeOption)[], selectedValue: string, labelAttribute: string, errorsAttribute: string) {
        const options = optionsAttribute || [];
        const item = options.find((i) => i.value === selectedValue) || ({} as SourceOption);

        setRecord((prev) => ({ ...prev, [labelAttribute]: item.label }));
        setFieldErrors((prev) => ({ ...prev, [errorsAttribute]: "" }));
    }

    const handleSelectionChange = (data: ComboboxDataType) => {
        if (data.selection.length === 0) return;
        const selection = data.selection;

        if (setRecord) {
            setRecord((prev) => {
                const record = canOverride ? setOverride(prev, fieldName) : prev;
                return { ...record, [fieldName]: selection[0].id };
            });
        }
        setComboboxSelection(selection);
        if (fieldLabel) {
            setSelectedLabel(options, selection[0]?.id, fieldLabel, fieldName);
        }
    };

    const checkFieldValidity = () => {
        if (checkValidity && fieldName) {
            const valueAsString = typeof value === "string" ? value : (value ?? "").toString();
            const errorMessage = checkValidity(valueAsString);
            setFieldErrors((prev) => ({ ...prev, [fieldName]: errorMessage }));
        }
    };

    const isEditOrNew = mode === "new" || mode === "edit";

    // view mode
    const renderStaticView = () => (
        <div
            className={`slds-form-element_edit slds-form-element_stacked slds-hint-parent ${(isEditOrNew && showStaticViewInEditMode) || popover?.isVisible ? "" : "slds-form-element_readonly"} ${popover?.isVisible ? "popover-margin" : ""}`}
        >
            <span className="slds-form-element__label">{label}</span>
            <div className="slds-form-element__control slds-grid">
                {popover?.isVisible ? value : <div className="slds-form-element__static">{value}</div>}
                {isEditable && mode === "view" && <EditButtonIcon handleEdit={onEdit} />}
            </div>
        </div>
    );

    // edit and new mode
    const renderBody = () => {
        return popover?.isVisible ? (
            <CustomPopover
                popoverRef={popoverRef}
                title=""
                content={
                    customChangeHandler
                        ? body
                        : React.cloneElement(body as React.ReactElement, {
                              onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleFieldChange(e.target.value),
                          })
                }
                header={header}
                footer={footer}
                onSave={onSave}
                onCancel={() => onCancel?.(true)}
                showRevert={hasOverride}
                onRevert={() => handleRevert(fieldName)}
                onClickOutside={() => onCancel?.(false)}
                saveOnEnter={saveOnEnter}
                isInline={popover?.isInline}
                buttons={buttons}
            />
        ) : (
            <div className={hasOverride ? "render-body-container" : ""}>
                <div className="slds-form-element slds-form-element_stacked" onBlur={checkFieldValidity}>
                    {customChangeHandler
                        ? body
                        : React.cloneElement(body as React.ReactElement, {
                              onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleFieldChange(e.target.value),
                              events: {
                                  onSelect: (_: unknown, data: ComboboxDataType) => handleSelectionChange(data),
                              },
                          })}
                </div>
                {mode === "edit" && hasOverride && <UndoButtonIcon onClick={() => handleRevert(fieldName)} />}
            </div>
        );
    };

    const renderContent = () => {
        switch (mode) {
            case "init":
                return <FieldSkeleton />;
            case "new":
                return showStaticViewInNewMode ? renderStaticView() : renderBody();
            case "edit":
                return showStaticViewInEditMode ? renderStaticView() : renderBody();
            default:
                return renderStaticView();
        }
    };

    return isFormItem ? renderContent() : <FormItemWrapper>{renderContent()}</FormItemWrapper>;
};

export default Field;
