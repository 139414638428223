import { Input } from "@salesforce/design-system-react";
import React, { useState } from "react";

import PsRecord2 from "../ps-record/PsRecord2";
import { Mode, PsRecordPropsType, FolderResponseType, FolderType, UpdateFolderRequestType, CreateFolderRequestType } from "../../types";
import Field from "../../ui/wrappers/Field";
import { checkRequiredField } from "../../utils/index2";

const recordObject = "folder";

const defaultRecord = {
    name: "",
    description: "Active",
};

const PsFolder: React.FC<PsRecordPropsType> = ({ recordId = "", parentId = "", childToParent, propagateEvent }) => {
    const [mode, setMode] = useState<Mode>("init");
    const [record, setRecord] = useState<FolderType>(defaultRecord);
    const [loading, setLoading] = useState<boolean>(false);

    const [fieldErrors, setFieldErrors] = useState({} as { [key: string]: string });

    const parseResponse = (response: FolderResponseType[]): FolderType[] => {
        return response.map((item) => ({ id: item.id, name: item.name, description: item?.description || "" }));
    };

    const parseUpdateRequest = (updatedRecord: FolderType): UpdateFolderRequestType => {
        return { id: updatedRecord.id!, name: updatedRecord.name, description: updatedRecord.description };
    };

    const parseCreateRequest = (newRecord: FolderType): CreateFolderRequestType => {
        return { name: newRecord.name, description: newRecord.description };
    };

    const onEdit = () => {
        setFieldErrors({});
        setMode("edit");
    };

    // record card body-content
    const cardBody = (
        <div className="slds-form slds-m-around_medium" role="list">
            <h3 className="slds-section-title--divider slds-m-top_medium">Folder Details</h3>
            <div className="slds-form__row">
                {/* Name */}
                <Field
                    recordObject="folder"
                    setRecord={setRecord}
                    mode={mode}
                    value={record.name}
                    label="Name"
                    onEdit={onEdit}
                    isEditable
                    fieldName="name"
                    checkValidity={checkRequiredField}
                    setFieldErrors={setFieldErrors}
                    body={<Input name="name" autoComplete="off" label="Name" required value={record.name} errorText={fieldErrors.name} />}
                />
                {/* Status */}
                <Field
                    recordObject="folder"
                    mode={mode}
                    value={record.description}
                    label="Description"
                    fieldName="description"
                    isEditable
                    onEdit={onEdit}
                    setRecord={setRecord}
                    body={<Input name="description" autoComplete="off" label="Description" value={record.description} />}
                />
            </div>
        </div>
    );

    return (
        <PsRecord2
            recordLabel="Folder"
            recordModule="relate"
            recordObject={recordObject}
            record={record}
            defaultRecord={defaultRecord}
            showEdit
            showDelete
            mode={mode}
            recordId={recordId}
            parentId={parentId}
            propagateEvent={propagateEvent}
            setMode={setMode}
            onEdit={onEdit}
            setRecord={setRecord}
            setFieldErrors={setFieldErrors}
            loading={loading}
            setLoading={setLoading}
            childToParent={childToParent}
            parseResponse={parseResponse}
            parseUpdateRequest={parseUpdateRequest}
            parseCreateRequest={parseCreateRequest}
            cardBody={cardBody}
            showExpand
        />
    );
};

export default PsFolder;
