export const COMPANY_NAME = "Point Sigma";

export const CARTESIAN_CHART_TYPES = ["bar", "horizontalBar", "line", "scatter", "matrix"];
export const CIRCULAR_CHART_TYPES = ["doughnut", "pie", "polarArea", "radar"];
export const GEO_CHART_TYPES = ["choropleth"];

export const PS_FAVICON_URL = "https://framerusercontent.com/images/iSAwqV6l0xVu1KZEYpCFlEFItCw.png";


// PAGE_SEO is using in the Ps-SEO component and title is required. Can add more information if is needed
export const PAGE_SEO = {
    default: {
        // default is the meta tags that has been used in index.html file
        title: COMPANY_NAME,
        description: "Point Sigma Instant Business Intelligence",
    },
    login: {
        title: `${COMPANY_NAME} - Login`,
    },
    signup: {
        title: `${COMPANY_NAME} - Signup`,
    },
    build: {
        title: `${COMPANY_NAME} - Build`,
    },
    dataManagement: {
        title: `${COMPANY_NAME} - Data Management`,
    },
    explore: {
        title: `${COMPANY_NAME} - Explore`,
    },
    forgotPassword: {
        title: `${COMPANY_NAME} - Forgot Password`,
    },
    home: {
        title: `${COMPANY_NAME} - Home`,
    },
    saveInsights: {
        title: `${COMPANY_NAME} - Saved Insights`,
    },
    search: {
        title: `${COMPANY_NAME} - Search`,
    },
    store: {
        title: `${COMPANY_NAME} - Store Management`,
    },
    setup: {
        title: `${COMPANY_NAME} - Setup`,
    },
    profile: {
        title: `${COMPANY_NAME} - Profile`,
    },
};


export const ACCOUNT_SETUP = {
    // ---> Server option
    STATUS_LIST: [
        "Authenticated",
        "Inventory Loaded",
        "Data Loaded",
        "Data Interpreted",
        "Data Connected",
        "Objects Connected",
        "No Results",
        "Patterns Created",
        "Relevance Model Trained",
        "Complete",
    ],

    // Map the account setupStatus (received from the server) to the corresponding setupUIStatus and progress.(Please DO not change order)
    STATUS_PROGRESS_LIST: [
        {
            setupUIStatus: "Error",
            setupStatus: "Error",
            progress: 0,
            imgSrc: "/assets/images/icons/setuperror.png",
            done: false,
            message: "error", // message is mapping with the keys on SETUP_MESSAGE object that is on ps-setup-status
            btnLabel: "Email Support",
            action: () => (window.location.href = "mailto:support@point-sigma.com"),
        },
        {
            setupUIStatus: "Authenticated",
            setupStatus: "Authenticated",
            progress: 0,
            imgSrc: "/assets/images/icons/setupconnect.png",
            done: false,
            message: "authenticated",
            btnLabel: "Manage Data",
            action: () => (window.location.href = "/DataManagement"), // TODO: use navigation instead
        },
        {
            setupUIStatus: "Inventory Loaded",
            setupStatus: "Inventory Loaded",
            progress: 10,
            imgSrc: "/assets/images/icons/setupconnect.png",
            done: false,
            message: "inventoryLoaded",
            btnLabel: "Manage Data",
            action: () => (window.location.href = "/DataManagement"),
        },
        {
            setupUIStatus: "Processing",
            setupStatus: "Data Loaded",
            progress: 20,
            imgSrc: "/assets/images/icons/setupprocessing.gif",
            done: false,
            message: "processing",
            btnLabel: null,
            action: () => {
                return;
            },
        },
        {
            setupUIStatus: "Processing",
            setupStatus: "Data Interpreted",
            progress: 30,
            imgSrc: "/assets/images/icons/setupprocessing.gif",
            done: false,
            message: "processing",
            btnLabel: null,
            action: () => {
                return;
            },
        },
        {
            setupUIStatus: "Processing",
            setupStatus: "Data Connected",
            progress: 40,
            imgSrc: "/assets/images/icons/setupprocessing.gif",
            done: false,
            message: "processing",
            btnLabel: null,
            action: () => {
                return;
            },
        },
        {
            setupUIStatus: "Processing",
            setupStatus: "Objects Connected",
            progress: 60,
            imgSrc: "/assets/images/icons/setupprocessing.gif",
            done: false,
            message: "processing",
            btnLabel: null,
            action: () => {
                return;
            },
        },
        {
            setupUIStatus: "Processing",
            setupStatus: "Patterns Created",
            progress: 80,
            imgSrc: "/assets/images/icons/setupprocessing.gif",
            done: false,
            message: "processing",
            btnLabel: null,
            action: () => {
                return;
            },
        },
        {
            setupUIStatus: "Onboarding",
            setupStatus: "No Results",
            progress: 100,
            imgSrc: "/assets/images/icons/setupdone.png",
            done: false,
            message: "onboarding",
            btnLabel: "Book Onboarding Call",
            action: () => window.open("https://calendly.com/point-sigma/call", "_blank", "noopener,noreferrer"),
        },
        {
            setupUIStatus: "Onboarding",
            setupStatus: "Relevance Model Trained",
            progress: 100,
            imgSrc: "/assets/images/icons/setupdone.png",
            done: false,
            message: "onboarding",
            btnLabel: "Book Onboarding Call",
            action: () => window.open("https://calendly.com/point-sigma/call", "_blank", "noopener,noreferrer"),
        },
        {
            setupUIStatus: "Complete",
            setupStatus: "Complete",
            progress: 100,
            imgSrc: null,
            done: true,
            message: null,
            btnLabel: null,
            action: () => {
                return;
            },
        },
    ],
};

export const FIELD_ERROR_MESSAGES = {
    GENERAL_REQUIRED_FIELD: "Complete this field.",
    JSON_FORMAT: "Invalid JSON",
};


