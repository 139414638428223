import { useEffect } from "react";
import { ButtonGroup , Button} from "@salesforce/design-system-react";
// import Button from "@salesforce/design-system-react/components/button"; // importing from @salesforce/design-system-react directly misses some classes

import "./CustomPopover.css";

const CustomPopover = ({
    popoverRef,
    title = "",
    content,
    onSave,
    onCancel,
    showRevert = false,
    onRevert = () => {},
    buttons,
    isInline = true,
    onClickOutside = () => {},
    saveOnEnter = false,
    header,
    footer,
}) => {
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popoverRef.current instanceof HTMLElement && !popoverRef.current.contains(event.target)) {
                onClickOutside();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <section
            ref={popoverRef}
            className="slds-popover slds-popover_edit section"
            role="dialog"
            onKeyUp={(e) => {
                if (e.key === "Enter" && saveOnEnter) {
                    onSave();
                }
            }}
        >
            <div className="slds-popover__body body slds-m-right_xx-small">
                <div className="slds-form-element body-wrap">
                    <div className="slds-form-element__control slds-m-left_xx-small">
                        {title && <h2>{title}</h2>}

                        <div className={isInline ? "slds-grid" : ""}>
                            <div className="slds-grow">
                                {header}
                                {content}
                                {footer}
                            </div>

                            <div className="slds-shrink slds-grid">
                                <div className="slds-col slds-text-align_left">{buttons}</div>
                                <div className="slds-col slds-text-align_right">
                                    <ButtonGroup className="slds-m-left_xx-small">
                                        {showRevert && (
                                            <Button iconCategory="utility" iconName="undo" iconVariant="border" variant="icon" title="Revert to original value" onClick={() => onRevert()} />
                                        )}
                                        <Button iconCategory="utility" iconName="close" iconVariant="border" variant="icon" title="Cancel" onClick={onCancel} />
                                        <Button iconCategory="utility" iconName="check" iconVariant="brand" variant="icon" title="Update" onClick={onSave} style={{ justifyContent: "center" }} />
                                    </ButtonGroup>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CustomPopover;
