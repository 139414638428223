import React, { useEffect, useState } from "react";
import { Button, Icon, Pill, Tooltip } from "@salesforce/design-system-react";

import FilterModal from "./FilterModal";
import { getDropBoxTitle } from "../utils";
import { BOX_DATA } from "../constants";
import { getRecordAPI } from "../../../../../services/api";

const FilterDropbox = ({ dropAllowed, onFilterChange, flt, isItemGrabbed }) => {
    const [filters, setFilters] = useState([]);
    const [openFilter, setOpenFilter] = useState(null);
    // To apply multi filters in the future just remove disabledMultiDrops and disableMultiFilters.
    const disabledMultiDrops = !!filters?.length && isItemGrabbed;

    useEffect(() => {
        const id = flt?.key?.id?.in[0];
        if (!id) {
            setFilters([]);
            return;
        }
        if (id === filters?.[0]?.filter?.filterKey?.id) return;

        initializeFilter(id, flt);
    }, [flt]);

    async function initializeFilter(id, flt) {
        const res = await getRecordAPI({ module: "store", object: "key", recordId: id });
        const record = res[0];
        if (!!record.id) {
            const filter = {
                filterKey: {
                    ...record,
                    title: getDropBoxTitle("key", record),
                },
                inputs: [{ key: record, keyId: record.id }],
                name: record.name,
                operator: flt.filter.operator.assign,
                acceptMissing: flt.filter.acceptMissing.assign,
                active: flt.filter.active.assign,
                settings: flt.filter.settings.assign,
                type: record.dataType.dataFormat.name,
            };
            setFilters([{ filter }]);
        }
    }
    //  Functions
    function onFilterChangeHandler(values) {
        setFilters(values);
        const filter = values?.[0]?.filter;
        const id = filter?.filterKey?.id;
        if (!!id) {
            const key = { id: { in: [id] } };
            const updateFilter = {
                name: { assign: filter.name },
                acceptMissing: { assign: !!filter?.acceptMissing },
                active: { assign: !!filter?.active },
                operator: { assign: filter?.operator || "" },
                settings: { assign: filter?.settings },
            };
            const value = { key, filter: updateFilter };
            onFilterChange(value);
            return;
        }
        onFilterChange();
    }
    // Filter modal functions
    function onSaveFilter(filter, index) {
        const updatedFilter = { filter };
        if (!Array.isArray(filters)) {
            onFilterChangeHandler([updatedFilter]);
        } else {
            const copyFilters = [...filters]; // Copy of filters array
            if (index === undefined) {
                copyFilters.push(updatedFilter); // Add new filter
            } else {
                copyFilters[index] = updatedFilter; // Update existing filter
            }
            onFilterChangeHandler(copyFilters); // Update the filters state
        }
        setOpenFilter(null); // Close the filter modal
    }
    function onCancelFilterModal() {
        setOpenFilter(null);
    }
    // Drop zone element functions
    function onDrop(e) {
        e.preventDefault();
        if (!dropAllowed || disabledMultiDrops) return;
        // Retrieve the field data from the dragged item, which was set using setData("item", item) in onDragStart
        const field = JSON.parse(e.dataTransfer.getData("item"));
        setOpenFilter({ record: field.record });
    }
    function oDragOver(e) {
        e.preventDefault();
        if (!dropAllowed || disabledMultiDrops) {
            e.dataTransfer.dropEffect = "none"; // none drop cursor
        } else {
            e.dataTransfer.dropEffect = "copy"; // copy cursor drop
        }
    }

    // Filter item functions
    function onDeleteFilter(index) {
        const updatedFilters = [...filters];
        updatedFilters.splice(index, 1);
        onFilterChangeHandler(updatedFilters);
    }
    function onEditFilter(data, index) {
        setOpenFilter({ filter: data.filter, index }); // update
    }

    return (
        <>
            {!!openFilter && <FilterModal openFilter={openFilter} onCancel={onCancelFilterModal} onSaveFilter={onSaveFilter} />}
            <div className="builder-drop-box-container" onDrop={onDrop} onDragOver={oDragOver}>
                <div className="box-name-wrapper">
                    <p className="box-name">{BOX_DATA.flt.name}</p>
                    <div className="tooltip-wrapper">
                        <Tooltip content={BOX_DATA.flt.info} id="tooltip" align="top left" variant="learnMore" />
                    </div>
                </div>

                {!dropAllowed || disabledMultiDrops ? (
                    <div className={`drop-zone disabled`}>
                        <span className="drop-zone-container disabled">
                            <Icon assistiveText={{ label: "Ban" }} category="utility" name="ban" size="x-small" />
                            {!filters?.length ? <p className="text">Can not drop this item</p> : <p className="text">Filter already exist</p>}
                        </span>
                    </div>
                ) : (
                    <div className="drop-zone enabled">
                        <div className="drop-zone-container">
                            {!filters?.length && <p className="text">Drag from selection menu on the left</p>}
                            <>
                                {filters?.map((d, i) => {
                                    return (
                                        <div key={i} className="filter-item-wrapper">
                                            <Pill className="tag-drop-box-flt" key={i} variant="option" labels={{ label: d?.filter?.name, title: d.filter.filterKey.title }} />
                                            <div>
                                                <Button iconCategory="utility" iconName="settings" variant="icon" title="Edit" iconVariant="bare" iconSize="small" onClick={() => onEditFilter(d, i)} />
                                                <Button iconCategory="utility" iconName="clear" variant="icon" title="Delete" iconVariant="bare" iconSize="small" onClick={() => onDeleteFilter(i)} />
                                            </div>
                                        </div>
                                    );
                                })}
                            </>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default FilterDropbox;
