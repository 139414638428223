import { Combobox, Dropdown } from "@salesforce/design-system-react";
import React, { useEffect, useState } from "react";

import RecordConstants from "../../constants/RecordConstants";
import PsRecord2 from "../ps-record/PsRecord2";
import { formattedDateTime, formattedNumber, toastErrorMessage } from "../../utils";
import { Mode, PsRecordPropsType, DropdownOption, FieldApiType, FieldType, UpdateFieldRequestType, ComboboxOption } from "../../types";
import Field from "../../ui/wrappers/Field";
import useToastContext from "../../context/useToastContext";
import { doActionAPI, submitRecordAPI } from "../../services/api";
import Modal from "../../ui/Modal";
import ConnectorRunning from "../ps-connector/components/ConnectorRunning";
import CheckAndCloseIcons from "../ps-key/components/CheckAndCloseIcons";
import { EventType } from "../../pages/types";
import { merge, updateRestriction } from "../restriction/utils";
import useLoadRestrictionTypes from "../restriction/useLoadRestrictionTypes";
import Restrictions from "../restriction/Restrictions";

const statusOptions: ComboboxOption[] = Object.values(RecordConstants.FIELD_STATUS);

const recordObject = "field";

const PsField2: React.FC<PsRecordPropsType> = ({ recordId = "", parentId = "", childToParent, propagateEvent }) => {
    const [mode, setMode] = useState<Mode>("init");
    const [record, setRecord] = useState<FieldType>();
    const [loading, setLoading] = useState<boolean>(false);
    const [localParentId, setLocalParentId] = useState<string>(parentId || "");
    const [clearAction, setClearAction] = useState("");
    const [showClearConfirmDialog, setShowClearConfirmDialog] = useState(false);
    const [statusSelection, setStatusSelection] = useState([]);
    const [restrictionOptions, setRestrictionOptions] = useState([]);

    const { addToast } = useToastContext();

    const restrictionTypes = useLoadRestrictionTypes();

    useEffect(() => {
        setRestrictionOptions(restrictionTypes);
    }, [restrictionTypes]);

    const parseResponse = (response: FieldApiType[]): FieldType[] => {
        return response.map(({ id, name, type, status, restrictions, firstRunOn, lastRunOn, sourceExists, sourceIdentifier, isPrimaryKey, isChangeTracker, totalRecords, object }) => ({
            id,
            name,
            type,
            status,
            restrictions,
            firstRunOn: formattedDateTime(firstRunOn),
            lastRunOn: formattedDateTime(lastRunOn),
            sourceExists,
            sourceIdentifier,
            isPrimaryKey,
            isChangeTracker,
            totalRecords: formattedNumber(totalRecords),
            objectId: object.id,
            connectorId: object.connector.id,
            runStatus: object.connector.runStatus,
            setupStatus: object.connector.setupStatus,
        }));
    };

    const parseUpdateRequest = (record: FieldType): UpdateFieldRequestType => {
        return (({ id, status }) => ({ id, status }))(record);
    };

    function updateUI(record: FieldType) {
        const restrictions = record.restrictions || [];
        const updatedRecord = updateRestriction(record, restrictions) as FieldType;
        setRecord(updatedRecord);

        const statusSelection = statusOptions.filter((option) => option.id === updatedRecord?.status) || [];
        setStatusSelection(statusSelection);
    }

    function setParent(record: any) {
        if (recordId) setLocalParentId(record?.object?.id || "");
    }

    const onEdit = () => {
        setMode("edit");
    };

    const dispatchEvent = (event: EventType) => {
        childToParent(event);
    };

    const handleRefresh = () => {
        dispatchEvent({ type: "sync", action: "reload" });
    };

    const actionClear = () => {
        setLoading(true);
        const onSuccess = function () {
            addToast("info", "Connector Started", "Field clearing started");
            setRecord((prev) => ({ ...prev, runStatus: "Running" }));
            setLoading(false);
        };

        const onError = function () {
            setLoading(false);
        };
        doActionAPI({ module: "pump", object: "connector", action: "clear", args: { id: record.connectorId, objectId: record.objectId, fieldId: recordId } }, onSuccess, onError);
    };

    const showClearConfirm = (action: string) => {
        setClearAction(action);
        setShowClearConfirmDialog(true);
    };

    const handleAction = (action: string) => {
        switch (action) {
            case "exclude-clear":
                return showClearConfirm(action);
            default:
        }
    };

    const isBusy = record?.runStatus === "Running" || record?.runStatus === "Aborting";

    const cardActions =
        mode === "view" ? (
            <Dropdown
                onSelect={(selected: DropdownOption) => handleAction(selected.value)}
                options={[
                    {
                        label: "Exclude and Clear",
                        value: "exclude-clear",
                        leftIcon: {
                            category: "utility",
                            name: "clear",
                        },
                        disabled: isBusy,
                    },
                ]}
                assistiveText={{ icon: "Actions" }}
                iconVariant="border-filled"
                iconCategory="utility"
                iconName="down"
                align="right"
            />
        ) : null;

    const onSubmit = (updatedRecord: FieldType) => {
        try {
            setLoading(true);
            const onSuccess = function () {
                setLoading(false);
                // In case of success callback:
                setRecord((prev) => ({ ...prev, runStatus: "Running" }));
            };
            const onError = function (response: any) {
                setLoading(false);
                addToast("error", "Error", toastErrorMessage(response));
            };
            const parsedRecord = parseUpdateRequest(updatedRecord);
            submitRecordAPI({ module: "pump", object: recordObject, inputBody: [parsedRecord] }, onSuccess, onError);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };

    function actionExcludeClear() {
        const updatedRecord = { ...record, status: RecordConstants.OBJECT_STATUS.EXCLUDED.value };
        onSubmit(updatedRecord);
    }

    function confirmClearRecord() {
        if (clearAction === "exclude-clear") {
            actionExcludeClear();
        } else if (clearAction === "clear") {
            actionClear();
        }
        setShowClearConfirmDialog(false);
        setClearAction("");
    }

    function cancelClearRecord() {
        setClearAction("");
        setShowClearConfirmDialog(false);
    }

    function postSubmit() {
        const { changed, createdRestrictions, statusChangedRestrictions } = merge("fieldId", record, restrictionOptions);
        const onSuccess = function () {
            handleRefresh();
        };

        const onError = function (response: any) {
            addToast("error", "Error", toastErrorMessage(response));
            setLoading(false);
        };

        if (changed) {
            if (createdRestrictions.length > 0) {
                // create
                submitRecordAPI(
                    { module: "pump", object: "restriction", inputBody: createdRestrictions },
                    (createResponse: any) => {
                        const updatedStatusRestrictions = [
                            ...statusChangedRestrictions,
                            ...createResponse.map((res: any) => ({
                                id: res.id,
                                status: RecordConstants.RESTRICTION_STATUS.ACTIVE.value,
                            })),
                        ];

                        // call update after create
                        if (updatedStatusRestrictions.length > 0) {
                            submitRecordAPI({ module: "pump", object: "restriction", inputBody: updatedStatusRestrictions }, onSuccess, onError);
                        } else {
                            onSuccess();
                        }
                    },
                    onError
                );
            } else if (statusChangedRestrictions.length > 0) {
                // just update
                submitRecordAPI({ module: "pump", object: "restriction", inputBody: statusChangedRestrictions }, onSuccess, onError);
            }
        }
    }

    function handleSelectRestriction(selectedRestrictionValues: string[]) {
        setRecord((prev) => ({ ...prev, restrictionValues: selectedRestrictionValues }));
    }

    const cardBody = (
        <div className="slds-form slds-var-m-around_medium" role="list">
            {/*  Connector Running */}
            {mode === "view" && record?.runStatus === "Running" && <ConnectorRunning loading={loading} handleRefresh={handleRefresh} />}

            <h3 className="slds-section-title--divider slds-m-top_medium">Field Details</h3>
            <div className="slds-form__row">
                {/* Name */}
                <Field mode={mode} value={record?.name} label="Name" showStaticViewInEditMode />

                {/* Status */}
                <Field
                    mode={mode}
                    value={record?.status}
                    label="Status"
                    onEdit={onEdit}
                    isEditable={true}
                    fieldName="status"
                    setRecord={setRecord}
                    setComboboxSelection={setStatusSelection}
                    body={
                        <Combobox
                            labels={{ label: "Status", placeholder: "--Please Select--" }}
                            menuPosition="relative"
                            options={statusOptions}
                            selection={statusSelection}
                            value={record?.status || ""}
                            variant="readonly"
                        />
                    }
                />
            </div>

            <div className="slds-form__row">
                {/* Source Identifier */}
                {mode === "view" ? <Field mode={mode} value={record?.sourceIdentifier} label="Source Identifier" /> : null}

                {/* Restrictions */}
                <Field
                    mode={mode}
                    value={record?.restrictionLabels}
                    label="Restrictions"
                    onEdit={onEdit}
                    isEditable={true}
                    body={
                        <Restrictions
                            restrictionOptions={restrictionOptions}
                            selectedValues={record?.restrictionValues}
                            handleSelectRestriction={handleSelectRestriction}
                            recordRestrictions={record?.restrictions}
                        />
                    }
                />
            </div>

            <div className="slds-form__row">
                {/* Source Primary Key */}
                <Field mode={mode} value={<CheckAndCloseIcons selectedItem={record?.isPrimaryKey} />} label="Primary Key" />

                {/* Source Change Tracker */}
                <Field mode={mode} value={<CheckAndCloseIcons selectedItem={record?.isChangeTracker} />} label="Change Tracker" />
            </div>

            {mode === "view" && record?.totalRecords != null && (
                <>
                    <h3 className="slds-section-title--divider slds-m-top_medium">Status</h3>

                    <div className="slds-form__row">
                        {/* Source Exists */}
                        <Field mode={mode} value={<CheckAndCloseIcons selectedItem={record?.sourceExists} />} label="Found in Source" />

                        {/* TotalRecords */}
                        <Field mode={mode} value={record?.totalRecords} label="Number of Records Loaded" />
                    </div>
                    <div className="slds-form__row">
                        {/* First Loaded */}
                        <Field mode={mode} value={record?.firstRunOn} label="First Run On" />

                        {/* Last Loaded */}
                        <Field mode={mode} value={record?.lastRunOn} label="Last Run On" />
                    </div>
                </>
            )}
        </div>
    );

    return (
        <>
            <PsRecord2
                recordLabel="Field"
                recordModule="pump"
                recordObject={recordObject}
                record={record}
                showEdit
                showCardActions
                showDelete
                mode={mode}
                recordId={recordId}
                parentId={localParentId}
                propagateEvent={propagateEvent}
                setMode={setMode}
                onEdit={onEdit}
                setRecord={setRecord}
                loading={loading}
                setLoading={setLoading}
                childToParent={childToParent}
                parseResponse={parseResponse}
                parseUpdateRequest={parseUpdateRequest}
                setParent={setParent}
                updateUI={updateUI}
                postSubmit={postSubmit}
                cardBody={cardBody}
                cardActions={cardActions}
            />
            {showClearConfirmDialog ? (
                <Modal
                    apply={() => confirmClearRecord()}
                    cancel={() => cancelClearRecord()}
                    header="Confirmation"
                    modalContent="Clearing an Field will delete all its loaded data. Are you sure?"
                    applyButtonContent="OK"
                />
            ) : null}
        </>
    );
};

export default PsField2;
