export const RECORD_COLUMNS = [
    {
        key: "name",
        label: "Name",
        property: "name",
        type: "link",
        action: "details",
        sortable: true,
        minWidth: 200,
    },
    {
        key: "runStatus",
        label: "Status",
        property: "runStatus",
        type: "status",
        width: 80,
    },
    {
        key: "origin",
        label: "Origin",
        property: "origin",
        type: "text",
        width: 100,
    },
    {
        key: "createdOn",
        label: "Started",
        property: "createdOn",
        type: "formattedDate",
        width: 180,
        sortable: true,
    },
    {
        key: "runEnd",
        label: "Completed",
        property: "runEnd",
        type: "formattedDate",
        width: 180,
    },
    {
        key: "usedCredit",
        label: "Used Compute Credit",
        property: "usedCredit",
        type: "number",
        width: 180,
    },
    {
        key: "parameters",
        label: "Parameters",
        property: "parameters",
        type: "text",
        minWidth: 200,
    },
    {
        key: "runMessage",
        label: "Error Message",
        property: "runMessage",
        type: "text",
        minWidth: 200,
    },
];
