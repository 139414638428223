import { useState, useEffect } from "react";

import useToastContext from "../../context/useToastContext";
import { RestrictionType } from "../../types";
import { toastErrorMessage } from "../../utils";
import { getRecordsAPI } from "../../services/api";

const useLoadRestrictionTypes = () => {
    const [restrictionTypes, setRestrictionTypes] = useState([]);

    const { addToast } = useToastContext();

    useEffect(() => {
        const loadRestrictionTypes = () => {
            try {
                const onSuccess = function (response: RestrictionType[]) {
                    if (!response.length) {
                        addToast("error", "No Restriction Types Found", "Please contact Point Sigma Support");
                        return;
                    }

                    response.forEach((rt: RestrictionType) => {
                        rt.label = rt.name;
                        rt.value = rt.id;
                    });

                    setRestrictionTypes(response);
                };

                const onError = function (response: any) {
                    addToast("error", "Error loading Restriction Types", toastErrorMessage(response));
                };

                getRecordsAPI({ module: "core", object: "restrictiontype", filters: {} }, onSuccess, onError);
            } catch (error) {
                console.error(error);
            }
        };
        loadRestrictionTypes();
    }, []);

    return restrictionTypes;
};

export default useLoadRestrictionTypes;
