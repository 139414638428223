import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card } from "@salesforce/design-system-react";

import "./Home.css";
import PsSetupStatus from "../../components/ps-setup-status/PsSetupStatus";
import PsPermissionWrapper from "../../components/ps-permission-wrapper/PsPermissionWrapper";
import RecordConstants from "../../constants/RecordConstants.js";

function Home() {
    const [loading, setLoading] = useState<boolean>(false);
    const [setupStatusVisible, setSetupStatusVisible] = useState(true);

    const navigate = useNavigate();

    function exploreClick() {
        navigate("/Explore", { replace: false });
    }

    function searchClick() {
        navigate("/Search", { replace: false });
    }

    function savedInsightsClick() {
        navigate("/SavedInsights", { replace: false });
    }

    function setupClick() {
        navigate("/Setup", { replace: false });
    }
    function manageData() {
        navigate("/DataManagement", { replace: false });
    }

    return (
        <div className="Home" style={{ overflow: setupStatusVisible ? "hidden" : "auto" }}>
            <div className="full">
                <PsSetupStatus title="Home" tagLine="Welcome to Point Sigma." onSetupStatusVisible={setSetupStatusVisible} />

                {!loading && (
                    <div className="slds-grid slds-wrap slds-gutters_direct-x-small slds-grid_align-center">
                        <div className="slds-col slds-var-m-bottom_medium slds-size_1-of-1 slds-small-size_2-of-3 slds-medium-size_1-of-2 slds-large-size_1-of-3">
                            <Card
                                id="x"
                                heading={<span className="slds-card__header-title">Explore</span>}
                                className="slds-card_boundary"
                                footer={<Button label={<b>Start Exploring</b>} name="Start Exploring" onClick={() => exploreClick()} variant="brand" />}
                            >
                                <div className="card-content slds-p-around_medium">
                                    <p className="card-text">Explore discovered patterns using simple navigation.</p>
                                    <div className="slds-p-vertical_medium slds-text-align_center">
                                        <div className="gif-hover-active" onClick={exploreClick}>
                                            {/* <img src={"/assets/images/connectors/" +  props.record.connectorType.image}  className="illustration-medium" alt={ props.record.connectorType.image} /> */}
                                            <img src="/assets/images/previews/explore.png" alt="explore" className="static" />
                                            <img src="/assets/images/previews/explore.gif" alt="explore" />
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </div>

                        <div className="slds-col slds-var-m-bottom_medium slds-size_1-of-1 slds-small-size_2-of-3 slds-medium-size_1-of-2 slds-large-size_1-of-3">
                            <Card
                                id="x"
                                heading={<span className="slds-card__header-title">Search</span>}
                                className="slds-card_boundary"
                                footer={<Button label={<b>Search Now</b>} name="Start Exploring" onClick={() => searchClick()} variant="brand" />}
                            >
                                <div className="card-content slds-p-around_medium">
                                    <p className="card-text">Find the insights you need by typing just a few keywords.</p>
                                    <div className="slds-p-vertical_medium slds-text-align_center">
                                        <div className="gif-hover-active" onClick={searchClick}>
                                            <img src="/assets/images/previews/search.png" alt="explore" className="static" />
                                            <img src="/assets/images/previews/search.gif" alt="explore" />
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </div>

                        <div className="slds-col slds-var-m-bottom_medium slds-size_1-of-1 slds-small-size_2-of-3 slds-medium-size_1-of-2 slds-large-size_1-of-3">
                            <Card
                                id="x"
                                heading={<span className="slds-card__header-title">Saved Insights</span>}
                                className="slds-card_boundary"
                                footer={<Button label={<b>Show Saved Insights</b>} name="Show Saved Insights" onClick={() => savedInsightsClick()} variant="brand" />}
                            >
                                <div className="card-content slds-p-around_medium">
                                    <p className="card-text">View saved and liked insights, and organize insights as dashboards.</p>
                                    <div className="slds-p-vertical_medium slds-text-align_center">
                                        <div className="gif-hover-active" onClick={savedInsightsClick}>
                                            <img src="/assets/images/previews/saved.png" alt="explore" className="static" />
                                            <img src="/assets/images/previews/saved.gif" alt="explore" />
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </div>

                        <PsPermissionWrapper permittedUserTypes={[RecordConstants.USER_TYPE.Administrator.value]}>
                            <div className="slds-col slds-var-m-bottom_medium slds-size_1-of-1 slds-small-size_2-of-3 slds-medium-size_1-of-2 slds-large-size_1-of-3">
                                <Card id="x" heading={<b>Setup</b>} className="slds-card_boundary" footer={<Button label={<b>Setup</b>} name="Setup" onClick={() => setupClick()} variant="brand" />}>
                                    <div className="card-content slds-p-around_medium">
                                        <p className="card-text">Manage users and settings.</p>
                                    </div>
                                </Card>
                            </div>

                            <div className="slds-col slds-var-m-bottom_medium slds-size_1-of-1 slds-small-size_2-of-3 slds-medium-size_1-of-2 slds-large-size_1-of-3">
                                <Card
                                    id="x"
                                    heading={<span className="slds-card__header-title">Data Management</span>}
                                    className="slds-card_boundary"
                                    footer={<Button label={<b>Manage Data</b>} name="Manage Data" onClick={() => manageData()} variant="brand" />}
                                >
                                    <div className="card-content slds-p-around_medium">
                                        <p className="card-text">
                                            Create and manage connected data sources.
                                            <br />
                                        </p>
                                    </div>
                                </Card>
                            </div>

                            <div className="slds-col slds-var-m-bottom_medium slds-size_1-of-1 slds-small-size_2-of-3 slds-medium-size_1-of-2 slds-large-size_1-of-3">
                                <Card
                                    id="x"
                                    heading={<span className="slds-card__header-title">Contact Us</span>}
                                    className="slds-card_boundary"
                                    footer={
                                        <a
                                            href="https://calendly.com/point-sigma/call"
                                            className="slds-button slds-button_brand"
                                            onClick={() => console.log("Book a Call...")}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <b>Book a Call</b>
                                        </a>
                                    }
                                >
                                    <div className="card-content slds-p-around_medium">
                                        <p className="card-text">
                                            Contact support on{" "}
                                            <a href="mailto:support@point-sigma.com" target="_blank" rel="noreferrer">
                                                support@point-sigma.com
                                            </a>{" "}
                                            or book a call to speak with our team.
                                            <br />
                                        </p>
                                    </div>
                                </Card>
                            </div>
                        </PsPermissionWrapper>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Home;
