import React, { ReactNode, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import useAuthContext from "../../context/useAuthContext";

// ---> This component is for giving the user correct access and it can not be used outside of the account   <SettingContextProvider>

interface PsPermissionWrapperProps {
    children: ReactNode;
    permittedUserTypes: string[];
    redirectPath?: string;
}
const PsPermissionWrapper = ({
    children, // ---> children is React.Node element
    permittedUserTypes, // ---> permittedUserTypes  is an array of user type for example "Administrator"
    redirectPath, // ---> redirectPath  can be undefined or valid path to redirect the user if has not permission to visit children components
}: PsPermissionWrapperProps) => {
    const { profile } = useAuthContext();
    const [hasPermission, setHasPermission] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (!profile) {
            return;
        }
        const userAllowed = permittedUserTypes?.includes(profile.type);
        if (!userAllowed && redirectPath) {
            navigate(redirectPath);
        }
        setHasPermission(userAllowed);
    }, [profile]);
    return <>{hasPermission ? <>{children}</> : null}</>;
};

export default PsPermissionWrapper;
