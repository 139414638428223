import React, { useState, useEffect, useRef, KeyboardEvent } from "react";
import { Button, Textarea, InputIcon, Input } from "@salesforce/design-system-react";

import "./Search.css";
import PsSearchGrid from "../../components/ps-search-grid/PsSearchGrid";
import { useNavigate, useSearchParams } from "react-router-dom";
import PsPatternDetailedView from "../../components/ps-pattern-detailed-view/PsPatternDetailedView";
import PsNavigationHeader from "../../components/ps-navigation-header/PsNavigationHeader";
import PsSetupStatus from "../../components/ps-setup-status/PsSetupStatus";
import { toastErrorMessage } from "../../utils";
import useAuthContext from "../../context/useAuthContext";
import RecordConstants from "../../constants/RecordConstants";
import useToastContext from "../../context/useToastContext";
import PsErrorBoundary from "../../components/ps-error-boundary/PsErrorBoundary";
import NavigationTree from "../../components/navigation-tree/NavigationTree";
import ContentEditable from "./components/ContentEditable";
import { EventNavigationType, EventType } from "../types";
import { TreeSectionKeys } from "../../components/navigation-tree/types";
import { convertInterpretToQuery, convertSearchToContent, convertSearchToQuery } from "./components/utils";
import { putRecordAPI } from "../../services/api";

const treeSectionList: TreeSectionKeys[] = ["data", "types", "transforms", "aggs", "chains", "filters"];
const dropDownWidth = 500;

const SearchPage = () => {
    const patternRef = useRef(null); // keep pattern in a useRef because does not need to render the component every time is changed, use patternId instead
    const editableRef = useRef<HTMLDivElement | null>(null);

    const [questionText, setQuestionText] = useState(""); // text for question input
    const [loadingQuestion, setLoadingQuestion] = useState<boolean>(false);

    // Editable div
    const [content, setContent] = useState(""); // inner html for editable div
    const [refocus, setRefocus] = useState(false); // refocus in editable div if needed boolean
    const [onEnter, setOnEnter] = useState(false); // onEnter trigger when focus editable div

    // Tree
    const [showTree, setShowTree] = useState<boolean>(false);
    const [searchText, setSearchText] = useState(""); // search text for navigation tree search
    const [select, setSelect] = useState<null | EventNavigationType | string>(null); // item from navigation tree after press select button
    const [treePosition, setTreePosition] = useState<null | { left: number; top: number }>(null); // null or {left:number , top:number}
    const [treeRecord, setTreeRecord] = useState<null | { record: EventNavigationType; selected: string }>(null); // state for click in navigation tree object or null

    const [debugPrompt, setDebugPrompt] = useState<string>(""); // search text for navigation tree search
    const [debugRequest, setDebugRequest] = useState<string>(""); // search text for navigation tree search
    const [debugResponse, setDebugResponse] = useState<string>(""); // search text for navigation tree search

    const [search, setSearch] = useState(""); // same text with search url
    const [patternId, setPatternId] = useState("");
    const [propagateEvent, setPropagateEvent] = useState<EventType | undefined>();
    const [loading, setLoading] = useState<boolean>(false);

    const [showSearchSection, setShowSearchSection] = useState<boolean>(false);
    const [suggestionData, setSuggestionData] = useState<{ value: string; label: string }[]>([]);

    const [generativeAIDebugEnabled, setGenerativeAIDebugEnabled] = useState(false); // boolean
    const [generativeAISearchEnabled, setGenerativeAISearchEnabled] = useState(false); // boolean
    const [applyNow, setApplyNow] = useState(true); // boolean

    const { account, hasGenerativeAIAccess, handleLogout }: any = useAuthContext();
    const [searchParams, setSearchParams] = useSearchParams();
    const { addToast } = useToastContext();
    const navigate = useNavigate();

    // Show search if the search is true on first time
    useEffect(() => {
        const urlSearch = searchParams.get("search") || "";
        if (urlSearch) setShowSearchSection(true);
    }, []);

    // Add a click listener for outside clicks
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const { Metadata: METADATA, Debug: DEBUG } = RecordConstants.ACCOUNT_SETTINGS_GENERATIVE_AI;
        const genAiAccess = hasGenerativeAIAccess(METADATA);
        const genAiEnabled = account?.settings?.enableGenerativeAISearch;
        setGenerativeAISearchEnabled(genAiAccess && genAiEnabled);
        setGenerativeAIDebugEnabled(hasGenerativeAIAccess(DEBUG));
    }, [account]);

    // sync state with url when the search params
    useEffect(() => {
        const urlPatternId = searchParams.get("patternId");
        // this case is only on sharing the url
        if (!patternRef?.current && urlPatternId === "pattern") {
            searchParams.delete("patternId");
            setSearchParams(searchParams);
            return;
        }
        syncStateWithUrl(searchParams);
    }, [searchParams]);

    function syncStateWithUrl(searchParams: URLSearchParams) {
        const urlPatternId = searchParams.get("patternId") || "";
        const urlSearch = searchParams.get("search") || "";
        const urlQuestion = searchParams.get("question") || "";

        if (urlSearch !== search) {
            const urlContent = convertSearchToContent(urlSearch);
            if (content !== urlContent) setContent(urlContent);
            setSearch(urlSearch);
        }
        if (urlPatternId !== patternId) setPatternId(urlPatternId);
        if (urlQuestion !== questionText) {
            setQuestionText(urlQuestion);
            putQuestion(urlQuestion, !urlSearch);
        }
    }

    function handleEventRouter(event: EventType) {
        switch (event.type) {
            case "tab":
                // TODO: change event.tab to event.path
                navigate("/" + event.tab);
                break;
            case "logout":
                handleLogout();
                break;
            case "sync":
                setPropagateEvent(event);

                break;
            case "payload":
                if (event.action === "viewDetails") {
                    const id = event?.pattern?.id || "pattern";
                    if (id === "pattern") patternRef.current = event.pattern;
                    searchParams.set("patternId", id);
                    setSearchParams(searchParams);
                }
                break;

            case "navigation":
                if (event.action === "close") {
                    searchParams.delete("patternId");
                } else if (event.source === "tree") {
                    const { obj, id, section } = event;
                    setTreeRecord({ record: event, selected: `${section}_${obj}_${id}` });
                } else {
                    // if navigation is grid that mean is in pattern view and from the table of the suggestions and section is always data
                    navigate(`/Explore?selected=data_${event.obj}_${event.id}`);
                    return;
                }
                setSearchParams(searchParams);
                break;

            default:
                setPropagateEvent(event);
                break;
        }
    }

    function onSearch(val: string) {
        if (!val) {
            searchParams.delete("search");
        } else {
            searchParams.set("search", val);
        }
        hideTreeDropdown();
        setSearchParams(searchParams);
    }
    function onChange(updatedContent: string, search: string, treePos: null | { left: number; top: number }) {
        setSearchText(search);
        setContent(updatedContent);
        if (!!search) {
            const num = treePos.left + dropDownWidth + 32 - window.innerWidth;
            if (num > 0) treePos.left -= num;
            setTreePosition(treePos);
            setShowTree(true);
        }
    }

    function onSearchQuestion() {
        if (questionText) {
            searchParams.set("question", questionText);
        } else {
            searchParams.delete("question");
        }
        setSearchParams(searchParams);
        putQuestion(questionText, true);
    }

    async function putQuestion(question: string, updateSearch?: boolean) {
        if (question) {
            setLoadingQuestion(true);

            const onError = (res: any) => {
                if (res && res.type === "Timeout") {
                    addToast("error", "Service busy", "We cannot currently handle your request due to high server load. Please try again in a few moments");
                } else {
                    addToast("error", "Error", toastErrorMessage(res));
                }
                setLoadingQuestion(false);
            };

            const onSuccess = (res: any) => {
                const list = res?.filter((item: any) => item.type === "query").map((item: any) => ({ label: item.description, value: convertInterpretToQuery(item.query) })) || [];
                setSuggestionData(list);
                updateSearch && onSearch(list[0]?.value || "");
                if (generativeAIDebugEnabled) {
                    const msg = res?.filter((item: any) => item.type === "debug");
                    setDebugRequest(msg[0]?.request);
                    setDebugResponse(msg[0]?.response);
                }
                setShowSearchSection(true);
                setLoadingQuestion(false);
            };

            const inputBody: { question: string; debug?: boolean; prompt?: string } = { question };
            if (generativeAIDebugEnabled) {
                inputBody.debug = true;
                inputBody.prompt = debugPrompt;
            }
            await putRecordAPI({ module: "relate", object: "interpret", inputBody }, onSuccess, onError);
        } else {
            setSuggestionData([]);
            updateSearch && onSearch("");
            setDebugRequest("");
            setDebugResponse("");
        }
    }

    function onKeyDown(e: KeyboardEvent<HTMLDivElement>) {
        if (e.key === "Enter") {
            e.preventDefault();
            onSearchQuestion();
        }
    }

    function onEnterDown() {
        if (treeRecord || searchText) {
            onSelect();
            return;
        }
        setOnEnter(true);
    }
    function onTreeCancel() {
        setSearchText("");
        hideTreeDropdown();
    }

    function onSelect() {
        let newSelect: EventNavigationType | string = searchText;
        if (treeRecord && treeRecord?.record?.obj !== treeRecord?.record?.section) newSelect = treeRecord.record;
        setSelect(newSelect);
    }
    function setShowTreeHandler(navPosition: { top: number; left: number } | null) {
        if (!navPosition) {
            hideTreeDropdown();
            return;
        }
        setShowTree(true);
        setTreePosition(navPosition);
    }

    function hideTreeDropdown() {
        setShowTree(false);
        setTreePosition(null);
        setTreeRecord(null);
    }
    // Hides the tree dropdown when clicking outside the container
    function handleClickOutside(event: MouseEvent) {
        if (editableRef.current && !editableRef.current.contains(event.target as Node)) hideTreeDropdown();
    }

    return (
        <div className="Search">
            <PsNavigationHeader childToParent={handleEventRouter} showClose={!!patternId} showApplyNow={!!patternId} applyNow={applyNow} setApplyNow={setApplyNow} />
            <div className="tab-content slds-p-around_medium">
                <div className={!!patternId ? "slds-hide" : "top"}>
                    {generativeAISearchEnabled && (
                        <div className="slds-grid slds-grid_vertical-align-start ">
                            <Input
                                className="slds-col"
                                onKeyDown={onKeyDown}
                                hasSpinner={loadingQuestion}
                                iconLeft={<InputIcon name="search" category="utility" assistiveText={{ icon: "Search" }} />}
                                iconRight={!!questionText && <InputIcon name="clear" category="utility" assistiveText={{ icon: "Clear" }} onClick={() => setQuestionText("")} />}
                                value={questionText}
                                onChange={(e: any) => setQuestionText(e.target.value)}
                                placeholder="Question..."
                                disabled={loading || loadingQuestion}
                            />
                            <Button
                                className="slds-m-left_xx-small"
                                assistiveText={{ icon: showSearchSection ? "Chevron Down" : "Chevron Right" }}
                                iconCategory="utility"
                                iconName={showSearchSection ? "chevrondown" : "chevronright"}
                                iconVariant="border"
                                variant="icon"
                                title="Details"
                                onClick={() => setShowSearchSection((prev) => !prev)}
                            />
                            <Button className="search-button" variant="brand" name="search" label="Search" onClick={onSearchQuestion} />
                        </div>
                    )}

                    {(showSearchSection || !generativeAISearchEnabled) && (
                        <>
                            <div ref={editableRef} className="slds-grid slds-grid_vertical-align-start" style={{ position: "relative" }}>
                                <>
                                    <ContentEditable
                                        onEnterDown={onEnterDown}
                                        onSearch={onSearch}
                                        setRefocus={setRefocus}
                                        setOnEnter={setOnEnter}
                                        setSelect={setSelect}
                                        onChange={onChange}
                                        setShowTree={setShowTreeHandler}
                                        onEnter={onEnter}
                                        reFocus={refocus}
                                        value={content}
                                        select={select}
                                        generativeAISearchEnabled={generativeAISearchEnabled}
                                        suggestionData={suggestionData}
                                        search={search}
                                        onClickArrow={hideTreeDropdown}
                                    />

                                    {showTree && (
                                        <div
                                            className={`slds-box slds-theme_default search-tree-dropdown`}
                                            style={treePosition ? { top: `${treePosition.top}px`, left: `${treePosition.left}px`, width: dropDownWidth } : { width: dropDownWidth }}
                                        >
                                            <NavigationTree
                                                width="100%"
                                                pxOffsetHV={500}
                                                isTopLevelExpanded={false}
                                                showSearch={"only-pills"}
                                                bubbleEvent={handleEventRouter}
                                                propagateEvent={propagateEvent}
                                                sectionList={treeSectionList}
                                                searchText={searchText}
                                                onNodeInteraction={() => setRefocus(true)}
                                                selected={treeRecord?.selected ? [treeRecord.selected] : []}
                                            />

                                            <div className="slds-grid slds-grid_align-spread slds-grid_vertical-align-center slds-p-around_small slds-border_top">
                                                <div className="search-for-text slds-truncate" title={searchText}>
                                                    {!!searchText && (
                                                        <>
                                                            Searching for <mark>{searchText}</mark>
                                                        </>
                                                    )}
                                                </div>
                                                <div>
                                                    <Button name="cancel" label="Cancel" onClick={onTreeCancel} />
                                                    <Button
                                                        id="selectButton"
                                                        variant="brand"
                                                        name="select"
                                                        label="Select"
                                                        onClick={onSelect}
                                                        disabled={!treeRecord || treeRecord?.record?.obj === treeRecord?.record?.section}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </>
                            </div>

                            {!!generativeAIDebugEnabled && !!generativeAISearchEnabled && (
                                <>
                                    <Textarea id="prompt" label="GPT Prompt" onChange={(e: any) => setDebugPrompt(e.target.value)} value={debugPrompt} />
                                    <Textarea id="request" label="GPT Request" value={debugRequest} disabled />
                                    <Textarea id="response" label="GPT Response" value={debugResponse} disabled />
                                </>
                            )}
                        </>
                    )}

                    <div className={"right"}>
                        <PsSetupStatus title="Search" tagLine="Find the insights you need by typing just a few keywords." />
                        <PsSearchGrid
                            queryFilter={{ query: convertSearchToQuery(search) }}
                            childToParent={handleEventRouter}
                            propagateEvent={propagateEvent}
                            emptyLine="Start typing to create the graphs you want to see"
                            loading={loading || loadingQuestion}
                            onLoadingChange={(value: boolean) => setLoading(value)}
                        />
                    </div>
                </div>

                {!!patternId && (
                    <PsPatternDetailedView
                        pattern={patternId === "pattern" ? patternRef.current : { id: patternId }}
                        childToParent={handleEventRouter}
                        propagateEvent={propagateEvent}
                        applyNow={applyNow}
                    />
                )}
            </div>
        </div>
    );
};

const ErrorHandledSearchPage = () => <PsErrorBoundary children={<SearchPage />} />;
export default ErrorHandledSearchPage;
