import React from "react";
import { Icon } from "@salesforce/design-system-react";

import { getRecordAPI, getRecordsAPI } from "../../services/api";
import { FilterQueryType } from "../../services/types";
import { defaultsFilters, TREE_STATIC_DATA, TREE_SECTIONS, TREE_DATA_ITEMS, emptyNode, noSearchResultsNode, iconMapping } from "./constants";
import {
    TreeSectionKeys,
    GenerateChildNodesType,
    GenerateSearchNodesType,
    NavTree,
    TreeItemKeys,
    TreeItemDynamic,
    TreeItemStatic,
    NoteBreadcrumbType,
    RestrictType,
    SelectedObjectType,
    StoreRecordsType,
    TreeNodeType,
    DefaultItemsType,
    GetNodesType,
    TreeActionHandlerType,
} from "./types";

// React components

export const HighlightText = ({ label, match }: { label: string; match: string }) => {
    // Escape special regex characters in "match" to avoid errors
    const escapedMatch = match.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");

    // Create regex with escaped match string
    const regex = new RegExp(`(${escapedMatch})`, "gi");
    const parts = label.split(regex);
    return <span>{parts.map((part: string, i: number) => (regex.test(part) ? <mark key={i}>{part}</mark> : part))}</span>;
};

export const TreeIcon = ({ iconKey, size }: { iconKey: string; size?: "xx-small" | "x-small" | "small" | "medium" | "large" }) => {
    const iKey = iconKey || "Other";
    const iconName = iconMapping[iKey] || "standard_objects";
    return <Icon assistiveText={{ label: iKey }} category="utility" name={iconName} size={size || "xx-small"} title={iKey} className="slds-m-right_xx-small" />;
};

// Functions
export function getNodes({ node, sectionKey, treeItems, draggable = false, isSearch = false, disableDraggable, onDragStart, onDragEnd }: GetNodesType) {
    // Return the placeholder if the node has no children
    if (node?.nodes?.length === 0) return isSearch ? [noSearchResultsNode] : [emptyNode];

    // Extract and process nodes
    return (
        node?.nodes?.map((itemKey: string) => {
            const item: TreeNodeType = treeItems[sectionKey][itemKey];
            const label = item?.label || "--None--";
            const isTopItem = sectionKey === item.selfDataKey;
            const isDraggable = draggable && !isTopItem && !disableDraggable?.[sectionKey]?.includes(item.selfDataKey as any);

            return {
                ...item,
                assistiveText: label,
                label: !isDraggable ? (
                    <span title={label} className={`slds-truncate ${isTopItem ? "icon-label-container slds-text-title_caps" : ""}`}>
                        {isTopItem && <TreeIcon iconKey={sectionKey} />}
                        {item?.selfDataKey === "key" && <TreeIcon iconKey={item?.record?.dataType?.name} />}
                        {!!item?.searchText ? <HighlightText label={label} match={item?.searchText} /> : label}
                    </span>
                ) : (
                    <span
                        title={label}
                        id={`${sectionKey}-${item.id}-drag`}
                        className={`tree-item-draggable ${isTopItem ? "slds-text-title_caps" : ""}`}
                        draggable
                        onDragStart={(e) => onDragStart?.(e, { ...item, setting: { section: sectionKey, config: item?.selfDataKey }, label })}
                        onDragEnd={(e) => onDragEnd?.(e, { ...item, setting: { section: sectionKey, config: item?.selfDataKey }, label })}
                    >
                        {/* this has to specified where to show the draggable icon or not more generic */}
                        {isTopItem && <TreeIcon iconKey={sectionKey} />}
                        {item?.selfDataKey === "key" && <TreeIcon iconKey={item?.record?.dataType?.name} />}
                        <span className="slds-truncate">{!!item?.searchText ? <HighlightText label={label} match={item?.searchText} /> : label}</span>
                    </span>
                ),
            };
        }) ?? []
    );
}

export function calculateTeeHeight(containerHight: number, isSearch: boolean | "only-pills", showPills: boolean): string {
    let height = containerHight;
    if (isSearch === true) height += 40;
    if (showPills) height += 48;
    return `calc(100vh - ${height}px)`;
}

export function deselectHandler(treeItems: NavTree, sectionKey: TreeSectionKeys, sectionList: TreeSectionKeys[], selectedItems?: SelectedObjectType, singleSelect?: boolean) {
    sectionList.forEach((trId) => {
        // If singleSelect is true, reset all. Otherwise, only reset the current sectionKey.
        if (singleSelect || trId === sectionKey) {
            if (selectedItems?.[trId]) delete selectedItems[trId];
            if (treeItems[trId])
                Object.values(treeItems[trId]).forEach((nodeItem: TreeNodeType) => {
                    if (nodeItem?.selected) nodeItem.selected = false;
                });
        }
    });
}

export function getDefaultItems(sectionKey: TreeSectionKeys, isTopLevelExpanded: boolean = true): { topNode: TreeNodeType; defaultItems: DefaultItemsType } {
    const { treeItems, id, name } = TREE_SECTIONS[sectionKey];
    const topNode: TreeNodeType = {
        id: id,
        label: name,
        type: "branch",
        selfDataKey: sectionKey,
        record: { id, name },
        parentId: "root",
        breadcrumb: [{ id, name }],
        expanded: isTopLevelExpanded,
        childDataKey: treeItems[0],
    };

    const defaultItems: DefaultItemsType = {};

    for (let i = 0; i < treeItems.length; i++) {
        const key = treeItems[i];
        const parentDataKey = treeItems?.[i - 1] || sectionKey; // parent data key is the prevues key or the tree section that is the top level node
        let recordKey: string = parentDataKey;
        const childDataKey = treeItems?.[i + 1] || null; // child data key is the next key or null if is last level item

        const item = TREE_DATA_ITEMS[key];

        let defaultItem: TreeItemStatic | TreeItemDynamic;

        if ("chain" === key || key === "link") recordKey = "leftContainer";

        if (item.type === "dynamic") {
            const recordContainerKey = i !== 0 ? recordKey : undefined;
            const filterIdKey = i !== 0 ? `${recordKey}Id` : undefined;
            defaultItem = { ...item, parentDataKey, childDataKey, recordContainerKey, filterIdKey };
        } else {
            defaultItem = { ...item, parentDataKey, childDataKey };
        }

        defaultItems[key] = defaultItem;
    }

    return { defaultItems, topNode };
}

export function getBreadcrumbTree(sectionKey: TreeSectionKeys, selfDataKey: TreeItemKeys | TreeSectionKeys, recordContainerObject?: any, breadcrumb: NoteBreadcrumbType[] = []): NoteBreadcrumbType[] {
    // Add the current object to the breadcrumb if it exists

    if (selfDataKey === sectionKey) {
        const { id, name } = TREE_SECTIONS[sectionKey];
        breadcrumb.unshift({ id, name });
        return breadcrumb;
    }
    const { defaultItems } = getDefaultItems(sectionKey);
    const defaultItem = defaultItems[selfDataKey as TreeItemKeys]; // selfDataKey can not be TreeSectionKeys type from the if statement above

    if (defaultItem?.type === "dynamic" && recordContainerObject?.id) {
        // the dynamic data for the moment sits on top level node
        const { parentDataKey, recordContainerKey } = defaultItem;
        breadcrumb.unshift({ id: recordContainerObject.id, name: recordContainerObject.name });
        return getBreadcrumbTree(sectionKey, parentDataKey, recordContainerObject[recordContainerKey || ""], breadcrumb);
    }

    if (defaultItem && defaultItem.type === "static" && recordContainerObject?.id) {
        // the static data for the moment sits on top level node
        const { parentDataKey } = defaultItem;
        breadcrumb.unshift({ id: recordContainerObject.id, name: recordContainerObject.name });
        return getBreadcrumbTree(sectionKey, parentDataKey, undefined, breadcrumb);
    }

    return breadcrumb;
}

export async function generateChildNodes({ treeSection, sectionKey, childDKey, storeRecords, id }: GenerateChildNodesType): Promise<{ response: any }> {
    let response = storeRecords ?? [];
    try {
        const defaultItem = getDefaultItems(sectionKey).defaultItems[childDKey];
        if (!defaultItem) return { response };
        const { type, childDataKey, object } = defaultItem;

        let nodesIds: string[] = [];

        // Fetch records if is dynamic and is not provided
        if (type === "dynamic" && storeRecords == null) {
            const { module, filterIdKey } = defaultItem;
            const filters = filterIdKey ? { [filterIdKey]: id, ...defaultsFilters } : defaultsFilters;
            response = await getRecordsAPI({ module, object, filters });
        }

        let records = response;

        if (type === "static") records = TREE_STATIC_DATA[object];

        // Create child nodes
        for (const record of records) {
            const newNode: TreeNodeType = {
                id: record.id,
                label: record.name,
                type: childDataKey ? "branch" : "item",
                selfDataKey: childDKey,
                record,
                parentId: id,
                breadcrumb: getBreadcrumbTree(sectionKey, childDKey, record),
            };
            if (childDataKey) newNode.childDataKey = childDataKey;
            // Add the new node to the tree section
            treeSection[record.id] = newNode;
            nodesIds.push(record.id);

            // Recursively generate child nodes if `expand` is true
            if (records.length <= 1 && childDataKey) {
                await generateChildNodes({ treeSection, sectionKey, childDKey: childDataKey, storeRecords: null, id: record.id });
            }
        }

        // Assign the generated node IDs to the parent node
        treeSection[id].nodes = nodesIds;
        treeSection[id].expanded = true;

        // return response to avoid multi request
        return { response };
    } catch (error) {
        console.error(`Error on generateChildNodes`, error);
        return { response };
    }
}

export async function initializeItemsChildToParent(treeItems: NavTree, sectionKey: TreeSectionKeys, selfDataKey: TreeItemKeys, recordContainerObject: any) {
    try {
        const breadcrumbList = getBreadcrumbTree(sectionKey, selfDataKey, recordContainerObject);
        for (let i = 0; i < breadcrumbList.length; i++) {
            const { id } = breadcrumbList[i];
            const node: TreeNodeType = treeItems?.[sectionKey]?.[id]; // alway topLevel note has been initialized and has find the id of breadcrumbList[0] that is top level item
            if (node && treeItems[sectionKey]) {
                if (node.nodes === undefined && node.type === "branch" && node.childDataKey)
                    await generateChildNodes({ treeSection: treeItems[sectionKey], sectionKey, childDKey: node.childDataKey, storeRecords: null, id });
                node.expanded = true;
            }
        }
    } catch (error) {
        console.error("error on initializeItemsChildToParent", error);
    }
}

// Search
export async function generateSearchNodes({ treeSection, sectionKey, selfDataKey: dataKey, searchQuery, filter, storeRecords }: GenerateSearchNodesType): Promise<{ response: any[] }> {
    let response = storeRecords ?? [];
    const topLevelId = TREE_SECTIONS[sectionKey].id;
    const selfDataKey = dataKey as TreeItemKeys; // this is always item key because top level node has initialize
    const defaultItem = getDefaultItems(sectionKey).defaultItems[selfDataKey];
    if (dataKey === sectionKey || !defaultItem) return { response };
    const { childDataKey, parentDataKey } = defaultItem;

    let records: any[] = [];
    let childId: string | null = null;
    let searchText: string | null = null;

    const createSearchNode = async (record: any, recordContainerKey?: string) => {
        const parentId = record[recordContainerKey || ""]?.id || topLevelId;
        const newNote: TreeNodeType = {
            id: record.id,
            label: record.name,
            type: "item",
            selfDataKey,
            record: record,
            parentId,
            breadcrumb: getBreadcrumbTree(sectionKey, selfDataKey, record),
        };
        if (searchText) newNote.searchText = searchText;

        if (childDataKey) {
            newNote.childDataKey = childDataKey;
            newNote.expanded = true;
            newNote.nodes = childId ? [childId] : [];
            newNote.type = "branch";
        }
        treeSection[record.id] = newNote;
        const parentNode = treeSection[parentId];
        if (parentId === "root") return;
        if (parentNode) {
            const parentNodes = parentNode?.nodes || [];
            parentNode.nodes = [...parentNodes, record.id];
            parentNode.expanded = true;
        } else {
            await generateSearchNodes({
                treeSection,
                sectionKey,
                selfDataKey: parentDataKey,
                searchQuery: { childId: record.id, record: record[recordContainerKey || ""] },
                storeRecords: null,
            });
        }
    };

    if (defaultItem.type === "dynamic") {
        const { module, object, recordContainerKey } = defaultItem;
        if (typeof searchQuery === "string") {
            let filters: FilterQueryType = { ...defaultsFilters };
            if (!!searchQuery) filters = { ...filters, name: `%${searchQuery}%` }; // searchQuery can be "" that is false and does not set filters name
            if (!!filter) filters = { ...filters, ...filter };

            searchText = searchQuery;
            if (storeRecords == null) response = await getRecordsAPI({ module: module, object: object, filters });
            records = response;
        } else {
            records = [searchQuery.record]; // array
            childId = searchQuery?.childId || null;
        }
        records.forEach((record: any) => createSearchNode(record, recordContainerKey));
    } else {
        const { object } = defaultItem;
        if (typeof searchQuery === "string") {
            records = TREE_STATIC_DATA[object].filter((record: any) => record.name.toLowerCase().includes(searchQuery.toLowerCase()));
            searchText = searchQuery;
        }
        records.forEach((record: any) => createSearchNode(record));
    }

    return { response };
}

export async function initializeSearchData(treeItemsList: TreeSectionKeys[], searchQuery: string, restrict?: RestrictType): Promise<NavTree> {
    const initTreeItems: NavTree = {};
    const storeAllRecords: StoreRecordsType = {};

    for (let i = 0; i < treeItemsList.length; i++) {
        const section = treeItemsList[i];
        const { topNode } = getDefaultItems(section);

        // Initialize tree root
        initTreeItems[section] = { root: { id: "root", nodes: [topNode.id], label: "root" } };
        // Initialize tree top level node
        initTreeItems[section][topNode.id] = topNode;
        initTreeItems[section][topNode.id].searchText = searchQuery;
        if (!restrict || restrict[section]) initTreeItems[section][topNode.id].nodes = [];

        // do not search if restrict is true and specific section is false
        if (restrict && !restrict[section]) continue;

        // Initialize search tree nodes data
        const { treeItems } = TREE_SECTIONS[section];
        let keysSearch: TreeItemKeys[] = [...treeItems];
        let filter: FilterQueryType | undefined = undefined;

        // restrict is true and is not top level node then update single node and parent level
        if (restrict?.[section] && restrict[section].containerId !== topNode.id) {
            const { containerDataKey, containerId, containerRecord } = restrict[section];
            const selfDataKey = containerDataKey as TreeItemKeys;

            await generateSearchNodes({
                treeSection: initTreeItems[section],
                sectionKey: section,
                selfDataKey,
                searchQuery: { record: containerRecord },
                storeRecords: null,
            });
            const childDataKey = getDefaultItems(section)?.defaultItems[selfDataKey]?.childDataKey;
            if (childDataKey) {
                const defaultItem = getDefaultItems(section).defaultItems[childDataKey];
                if (defaultItem?.type === "dynamic" && defaultItem?.filterIdKey) {
                    const { filterIdKey } = defaultItem;
                    const parentDataKeyIndex = keysSearch.indexOf(selfDataKey);
                    // updating filter and keysSearch (Note we do not need to update keys Search for static data because they sit in top level )
                    if (parentDataKeyIndex >= 0) keysSearch = keysSearch.slice(parentDataKeyIndex + 1);
                    if (filterIdKey) filter = { [filterIdKey]: containerId };
                }
            }
        }

        for (let j = 0; j < keysSearch.length; j++) {
            const selfDataKey = keysSearch[j];
            const storeRecords = storeAllRecords?.[selfDataKey] || null;

            const { response } = await generateSearchNodes({ treeSection: initTreeItems[section], sectionKey: section, selfDataKey, searchQuery, filter, storeRecords });
            if (storeRecords === null) storeAllRecords[selfDataKey] = response;
        }
        // Order nodes by label
        const sectionNodes = initTreeItems[section];
        for (const key in sectionNodes) {
            let nodes: string[] = sectionNodes[key]?.nodes;

            if (nodes && nodes.length > 0) {
                nodes = nodes.sort((a, b) => {
                    const labelA = sectionNodes[a]?.label?.toString().toLowerCase() || "";
                    const labelB = sectionNodes[b]?.label?.toString().toLowerCase() || "";
                    return labelA.localeCompare(labelB);
                });

                sectionNodes[key].nodes = nodes;
            }
        }
    }
    return initTreeItems;
}

// TreeInput
// this function generate tree items only for specific section and specific tree level (selfDataKey)
export async function generateTreeInput(sectionKey: TreeSectionKeys, selfDataKey: TreeItemKeys, searchQuery: string, filter?: FilterQueryType): Promise<NavTree> {
    const initTreeItems: NavTree = {};
    const { topNode } = getDefaultItems(sectionKey);

    // Initialize sectionKey object root
    initTreeItems[sectionKey] = { root: { id: "root", nodes: [topNode.id], label: "root" } };

    // Initialize tree top level node
    initTreeItems[sectionKey][topNode.id] = topNode;
    initTreeItems[sectionKey][topNode.id].nodes = [];

    if (filter && !Object.keys(filter).length && !searchQuery && topNode.childDataKey) {
        await generateChildNodes({ treeSection: initTreeItems[sectionKey], sectionKey, childDKey: topNode.childDataKey, storeRecords: null, id: topNode.id });
    } else {
        await generateSearchNodes({ treeSection: initTreeItems[sectionKey], sectionKey, selfDataKey, searchQuery, filter, storeRecords: null });
        // Order nodes by label
        const sectionNodes = initTreeItems[sectionKey];
        for (const key in sectionNodes) {
            let nodes: string[] = sectionNodes[key]?.nodes;

            if (nodes && nodes.length > 0) {
                nodes = nodes.sort((a, b) => {
                    const labelA = sectionNodes[a]?.label?.toString().toLowerCase() || "";
                    const labelB = sectionNodes[b]?.label?.toString().toLowerCase() || "";
                    return labelA.localeCompare(labelB);
                });

                sectionNodes[key].nodes = nodes;
            }
        }
    }

    return initTreeItems;
}

export async function treeActionHandler({ obj: selfDataKey, id: itemId, sectionList, action, treeItems }: TreeActionHandlerType): Promise<NavTree> {
    const deepTreeItems: NavTree = JSON.parse(JSON.stringify(treeItems));
    const storeAllRecords: StoreRecordsType = {};
    let storeRecord: StoreRecordsType | null = null;

    for (let i = 0; i < sectionList.length; i++) {
        const sectionKey: TreeSectionKeys = sectionList[i];
        const { treeItems } = TREE_SECTIONS[sectionKey];

        // Important check if the tree section does not have selfDataKey on the treeItems array continue does not need to update any thing
        if (!treeItems.includes(selfDataKey)) continue;

        if (action === "delete" && deepTreeItems[sectionKey]) {
            const deletedNode: TreeNodeType = deepTreeItems?.[sectionKey]?.[itemId];
            if (!deletedNode) continue;
            // update parent nodes array
            const updateNodes = deepTreeItems?.[sectionKey]?.[deletedNode.parentId]?.nodes?.filter((nodeId: string) => nodeId !== itemId);
            deepTreeItems[sectionKey][deletedNode.parentId].nodes = updateNodes;
            // delete from state
            delete deepTreeItems[sectionKey][itemId];
        } else if (action === "update" && deepTreeItems[sectionKey]) {
            const updatedNode: TreeNodeType = deepTreeItems?.[sectionKey]?.[itemId];
            if (!updatedNode) continue;
            // Save the childeNodes selected and expanded because does not change
            const saveChildeNodes = updatedNode.nodes;
            const saveSelected = updatedNode?.selected;
            const saveExpanded = updatedNode?.expanded;

            // Update the parent nodes from request so can order correct and update correct
            const id = updatedNode.parentId;
            const childDKey = updatedNode.selfDataKey as TreeItemKeys;
            const storeRecords = storeAllRecords?.[id] || null;
            const { response } = await generateChildNodes({ treeSection: deepTreeItems[sectionKey], sectionKey, childDKey, storeRecords, id });
            if (storeRecords === null) storeAllRecords[id] = response;
            // Update child nodes  for update node
            deepTreeItems[sectionKey][itemId].nodes = saveChildeNodes;
            deepTreeItems[sectionKey][itemId].selected = saveSelected;
            deepTreeItems[sectionKey][itemId].expanded = saveExpanded;
        } else if (action === "create" && deepTreeItems[sectionKey]) {
            const { defaultItems, topNode } = getDefaultItems(sectionKey);
            const defaultItem = defaultItems[selfDataKey];
            // Only dynamic data can be created
            if (defaultItem?.type === "dynamic") {
                const { module, object, recordContainerKey } = defaultItem;
                if (!storeRecord) storeRecord = await getRecordAPI({ module, object, recordId: itemId });
                const parentId = storeRecord?.[0]?.[recordContainerKey || ""]?.id || topNode.id;
                const parentNode: TreeNodeType = deepTreeItems?.[sectionKey]?.[parentId];
                // TODO: create parent node if does not exist (this can happen when is in new mode and the user refresh the page then tree data reinitialize and parent not can lost )
                if (parentNode && parentNode.nodes !== undefined && parentNode.childDataKey) {
                    const id = parentNode.id;
                    const childDKey = parentNode.childDataKey;
                    const storeRecords = storeAllRecords?.[id] || null;
                    const { response } = await generateChildNodes({ treeSection: deepTreeItems[sectionKey], sectionKey, childDKey, storeRecords, id });
                    if (storeRecords === null) storeAllRecords[id] = response;
                }
            }
        }
    }

    return deepTreeItems; // Only returning the updated tree state
}
