import React, { Component, ErrorInfo, ReactNode } from "react";
import "./PsErrorBoundary.css";
import { ReactComponent as SVG } from "../../assets/illustrations/not-available-in-lightning.svg";
import { Button } from "@salesforce/design-system-react";

interface PsErrorBoundaryProps {
    children: ReactNode;
    fallback?: ReactNode;
}

interface PsErrorBoundaryState {
    hasError: boolean;
}

// Class component to catch errors in wrapped child components
class PsErrorBoundary extends Component<PsErrorBoundaryProps, PsErrorBoundaryState> {
    constructor(props: PsErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(_error) {
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error("Error caught by ErrorBoundary:", error, errorInfo);
    }

    // Function to handle going back to the previous page
    handleGoBack = () => {
        window.history.back();
        // Use a timeout to allow navigation to complete before reloading the page
        setTimeout(() => {
            window.location.reload(); // Force a page refresh to clear the error
        }, 100); // Navigate back to the previous page
    };

    render() {
        const { hasError } = this.state;
        const { fallback } = this.props; // props for PsErrorBoundary: children and fallback

        if (hasError && fallback === undefined) {
            return (
                <div className="ps-error-boundary-container">
                    <div className="slds-illustration slds-illustration_large">
                        <SVG />
                        <div className="slds-illustration slds-illustration_small">
                            <div className="slds-text-longform">
                                <h3 className="slds-text-heading_medium">Something went wrong</h3>
                                <p className="slds-text-body_regular">
                                    An unexpected error occurred. Please press the back button and try again. Contact Point Sigma Support on{" "}
                                    <a href="mailto:support@point-sigma.com" target="_blank" rel="noreferrer">
                                        support@point-sigma.com
                                    </a>{" "}
                                    if the problem persists.
                                </p>
                                <Button variant="base" onClick={this.handleGoBack} label="Go Back" />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        if (hasError && fallback !== undefined) {
            // If fallback is not undefined, return custom jsx element or null
            return fallback;
        }

        // Render children if no error
        return this.props.children;
    }
}

export default PsErrorBoundary;
