import { useState } from "react";

import PsRecordGrid from "../ps-record-grid/PsRecordGrid";
import { RECORD_COLUMNS } from "./constants";

const PsFilterList = ({ parentId, queryFilter, childToParent, propagateEvent }) => {
    const [mode, setMode] = useState("init");
    const [view, setView] = useState("table");
    const [orderBy, setOrderBy] = useState("name");
    const [orderDirection, setOrderDirection] = useState("asc");
    const [hasMore, setHasMore] = useState(false);
    const [loading, setLoading] = useState(false);

    const parseResponse = (response) => {
        return response.map(({ id, name, scope, type, custom, container, robustDistinct, relevance, dataLastChangedOn }) => ({
            id,
            name,
            source: container.source.name,
            container: container.name,
            custom,
            scope,
            type,
            robustDistinct,
            relevance,
            dataLastChangedOn,
        }));
    };

    return (
        <PsRecordGrid
            module="store"
            object="filter"
            queryFilter={queryFilter}
            showTitle
            emptyLine="No Filters found"
            showCreate
            recordLabel="Filter"
            recordLabelPlural="Filters"
            parseResponse={parseResponse}
            parentId={parentId}
            mode={mode}
            onModeChange={(value) => setMode(value)}
            view={view}
            onViewChange={setView}
            orderBy={orderBy}
            onOrderByChange={(value) => setOrderBy(value)}
            orderDirection={orderDirection}
            onOrderDirectionChange={(value) => setOrderDirection(value)}
            loading={loading}
            onLoadingChange={(value) => setLoading(value)}
            showLoadMore
            hasMore={hasMore}
            onHasMoreChange={setHasMore}
            recordColumns={RECORD_COLUMNS}
            maxRecords={100}
            childToParent={childToParent}
            propagateEvent={propagateEvent}
            fullHeight={false}
        />
    );
};

export default PsFilterList;
