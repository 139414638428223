import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import "./SavedInsights.css";
import PsFolderList from "../../components/ps-folder-list/PsFolderList";
import PsDashboardGrid from "../../components/ps-dashboard-grid/PsDashboardGrid";
import PsPatternDetailedView from "../../components/ps-pattern-detailed-view/PsPatternDetailedView";
import PsSetupStatus from "../../components/ps-setup-status/PsSetupStatus";
import useAuthContext from "../../context/useAuthContext";
import NavigationTree from "../../components/navigation-tree/NavigationTree";
import { Card } from "@salesforce/design-system-react";
import { TREE_SECTIONS } from "../../components/navigation-tree/constants";
import { TreeItemKeys, TreeSectionKeys } from "../../components/navigation-tree/types";
import { EventType } from "../types";
import PsFolder from "../../components/ps-folder/PsFolder";
import PsNavigationHeader from "../../components/ps-navigation-header/PsNavigationHeader";
import { getDefaultItems } from "../../components/navigation-tree/utils";
import PsErrorBoundary from "../../components/ps-error-boundary/PsErrorBoundary";

const treeSectionList: TreeSectionKeys[] = ["folders"]; // type TreeSectionsKeys[] from types navigation tree

const defaultSection = treeSectionList[0];
const defaultObj = treeSectionList[0];
const defaultId = TREE_SECTIONS[defaultObj].id; // Tree static data setup for setupOverview section (use id to change view in right side)
const defaultSelected = `${defaultSection}_${defaultObj}_${defaultId}`;

const SavedInsights = () => {
    const [selected, setSelected] = useState<string>(defaultSelected);
    const [selectedSection, setSelectedSection] = useState<TreeSectionKeys>(defaultSection);
    const [selectedObject, setSelectedObject] = useState<TreeSectionKeys | TreeItemKeys>(defaultObj);
    const [selectedId, setSelectedId] = useState<string>(defaultId);
    const [patternId, setPatternId] = useState<string>("");

    const [propagateEvent, setPropagateEvent] = useState<EventType | undefined>();
    const [applyNow, setApplyNow] = useState(true);

    const { handleLogout } = useAuthContext();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        syncStateWithUrl(searchParams);
    }, [searchParams]);

    function syncStateWithUrl(searchParams: URLSearchParams) {
        const urlSelected = searchParams.get("selected") || defaultSelected;
        const urlPatternId = searchParams.get("patternId") || "";

        if (urlSelected !== selected) {
            const [section, obj, id] = urlSelected.split("_");
            setSelected(urlSelected);
            setSelectedId(id);
            setSelectedObject(obj as any);
            setSelectedSection(section as TreeSectionKeys);
        }
        if (urlPatternId !== patternId) setPatternId(urlPatternId);
    }

    function handleEventRouter(event: EventType) {
        switch (event.type) {
            case "tab":
                // TODO: change event.tab to event.path
                navigate("/" + event.tab);
                break;

            case "logout":
                handleLogout();
                break;

            case "sync":
                setPropagateEvent(event);
                break;
            case "record":
                if (event.action === "cancel") {
                    searchParams.delete("parentId");
                    setSearchParams(searchParams);
                } else {
                    const section = selectedSection || treeSectionList[0];
                    setPropagateEvent({ ...event, section });
                    // Does not need to navigate when update the record just update the tree that it will happen from setPropagateEvent
                    // Does not need to navigate when is created because It can create a folder only when Pattern save is open
                    if (["delete"].includes(event.action)) {
                        const { obj, parentId } = event;
                        const parentObj = getDefaultItems(section).defaultItems[obj].parentDataKey;
                        searchParams.set("selected", `${section}_${parentObj}_${parentId}`);
                        searchParams.delete("parentId");
                        setSearchParams(searchParams);
                    }
                }
                break;
            case "payload":
                if (event.action === "viewDetails" && event?.pattern?.id) {
                    searchParams.set("patternId", event.pattern.id);
                    setSearchParams(searchParams);
                }
                break;

            case "navigation":
                if (event.action === "close") {
                    searchParams.delete("patternId");
                } else {
                    if (event.obj && !(event.obj === "folder") && !(event.obj === "folders")) {
                        // TODO: Create a standard navigation event to navigate throw pages
                        navigate(`/Explore?selected=data_${event.obj}_${event.id}`); // navigate to explore page if the obj is not folder or folders hard (coded for now until create a standard navigation global event)
                        return;
                    }
                    const { id, obj } = event;
                    const section = event?.section || selectedSection;
                    searchParams.set("selected", `${section}_${obj}_${id}`);
                    searchParams.delete("patternId");
                }
                setSearchParams(searchParams);
                break;

            default:
                setPropagateEvent(event);
                break;
        }
    }
    return (
        <div className="SavedInsights">
            <PsNavigationHeader childToParent={handleEventRouter} showClose={!!patternId} showApplyNow={!!patternId} applyNow={applyNow} setApplyNow={setApplyNow} />

            <div className="tab-content slds-p-around_medium">
                {/* <!-- using slds-hide to prevent rebuilding views that need to keep their state --> */}
                <div className={!patternId ? "left slds-m-right_medium" : "slds-hide"}>
                    <Card className="card-height-full" heading={<span className="card-main-title-lh32 slds-card__header-title">Browse</span>}>
                        <NavigationTree
                            pxOffsetHV={194}
                            sectionList={treeSectionList}
                            selected={[selected]}
                            bubbleEvent={handleEventRouter}
                            propagateEvent={propagateEvent?.action === "create" ? { type: "sync", action: "reload" } : propagateEvent}
                        />
                    </Card>
                </div>

                {/* <!-- right panel view --> */}
                <div className={!patternId ? "right slds-scrollable " : "slds-hide"}>
                    <PsSetupStatus title="Saved Insights" tagLine="View saved and liked insights, and organize insights as dashboards." />
                    {selectedObject === "folders" && <PsFolderList queryFilter={{}} childToParent={handleEventRouter} />}
                    {selectedObject === "folder" && (
                        <>
                            <div className="slds-m-bottom_medium">
                                <PsFolder recordId={selectedId} parentId={TREE_SECTIONS.folders.id} childToParent={handleEventRouter} propagateEvent={propagateEvent} />
                            </div>
                            <PsDashboardGrid queryFilter={{ folderId: selectedId }} childToParent={handleEventRouter} propagateEvent={propagateEvent} />
                        </>
                    )}
                </div>

                {/* <!-- pattern detail --> */}
                {!!patternId && <PsPatternDetailedView pattern={{ id: patternId }} childToParent={handleEventRouter} propagateEvent={propagateEvent} applyNow={applyNow} />}
            </div>
        </div>
    );
};
const ErrorHandledSavedInsights = () => <PsErrorBoundary children={<SavedInsights />} />;
export default ErrorHandledSavedInsights;
