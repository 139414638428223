import { format } from "date-fns";
// import { Auth } from "aws-amplify";

import RecordConstants from "../constants/RecordConstants";
import Record from "../helpers/recordLayer";

export function getInnerSize(element) {
    if (!element) {
        throw new Error("Element is required to calculate inner size.");
    }

    const computedStyle = window.getComputedStyle(element);

    const paddingLeft = parseFloat(computedStyle.paddingLeft) || 0;
    const paddingRight = parseFloat(computedStyle.paddingRight) || 0;

    const innerWidth = element.clientWidth - paddingLeft - paddingRight;

    return { width: innerWidth };
}

export function isValidEmail(email) {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
}

// export const checkCurrentSession = async () => {
//     try {
//         const issCurrentSession = await Auth.currentSession();
//         return issCurrentSession;
//     } catch (error) {
//         console.error("Error checking current session:", error);
//         return null;
//     }
// };

export const getLocalDateTime = function (utcDatetime) {
    try {
        if (!utcDatetime) {
            return null;
        }

        // utcDatetime 2022-09-12T13:09:46.000Z
        const utcDate = new Date(utcDatetime);
        const localDate = new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60000);
        // Convert to local timezone, automatically takes the local timezone of the system
        const formattedLocalDatetime = localDate.toISOString().slice(0, 23); // Format to '2022-09-12T13:09:46.000'
        // formattedLocalDatetime 2022-09-12T14:09:46.000 //BST, so this did work

        return formattedLocalDatetime;
    } catch (err) {
        console.error(err.stack);
    }
    return null;
};

export const getUTCDateTime = function (localDatetime) {
    try {
        if (!localDatetime) {
            return null;
        }

        // localDatetime 2022-09-12T14:09:46.000 //BST, so this did work

        const localDate = new Date(localDatetime);
        const formattedUtcDatetime = localDate.toISOString();

        // const utcDate = new Date(localDate.getTime() + (localDate.getTimezoneOffset() * 60000)); // Convert to UTC, automatically takes the local timezone of the system
        // const formattedUtcDatetime = utcDate.toISOString().slice(0, 23); // Format to '2022-09-12T13:09:46.000Z'

        // formattedUtcDatetime 2022-09-12T13:09:46.000Z

        return formattedUtcDatetime;
    } catch (err) {
        console.error(err.stack);
    }
    return null;
};

export const formattedDate = (date) => {
    if (!date) {
        return "";
    }

    return format(new Date(date), "yyyy-MM-dd HH:mm:ss");
};

export function shallowEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (let key of keys1) {
        if (object1[key] !== object2[key]) {
            return false;
        }
    }

    return true;
}

export function toastDetails(response) {
    const message = response?.data?.message || null;
    const reference = response?.data?.reference;
    const suggestion = response?.data?.suggestion;
    return (
        <>
            {message}
            {reference && (
                <>
                    <br /> {reference}
                </>
            )}
            {suggestion && (
                <>
                    <br />
                    {suggestion}
                </>
            )}
            {!message && !reference && !suggestion && "No response from server"}
        </>
    );
}
// TODO: this function also exists in src/helpers
export function truncateText(text, maxLength) {
    return text.length <= maxLength ? text : text.substring(0, maxLength - 1) + "…";
}

export const auth = (instance) => {
    return {
        region: process.env[`REACT_APP_COGNITO_REGION_${instance}`],
        userPoolId: process.env[`REACT_APP_COGNITO_USER_POOL_ID_REGION_${instance}`],
        userPoolWebClientId: process.env[`REACT_APP_COGNITO_APP_CLIENT_ID_REGION_${instance}`],
        mandatorySignIn: true,
    };
};

export const endpoints = (instance) => {
    return [
        {
            name: "Application",
            endpoint: "https://" + process.env[`REACT_APP_COGNITO_REGION_${instance}`] + "." + process.env.REACT_APP_API_URL,
            region: process.env[`REACT_APP_COGNITO_REGION_${instance}`],
        },
    ];
};

export const coloredText = (content) => {
    const matches = content.split(/(<\/?b>)/);

    const parsedContentArray = [];
    let isColored = false;
    matches.forEach((match) => {
        if (match === "<b>") {
            isColored = true;
        } else if (match === "</b>") {
            isColored = false;
        } else if (match.trim() !== "") {
            parsedContentArray.push({ text: match, isColored });
        }
    });

    return (
        <>
            {parsedContentArray.map((part, index) => (
                <span key={index} className={part.isColored ? "coloredText" : ""}>
                    {part.text}
                </span>
            ))}
        </>
    );
};

export const isEmptyOrSpaces = (str) => {
    return str == null || str.match(/^ *$/) !== null;
};

export const toastErrorMessage = (response) => {
    const returnValue = response?.data || {};
    if (RecordConstants.SILENT_API_ERRORS.includes(returnValue.type)) {
        return null;
    }

    // some cases message is coming as an JWT token
    if (response?.data?.message?.includes("Authorization header: 'Bearer")) {
        return "No response from server";
    }

    return toastDetails(response) || "No response from server";
};

export const formattedPercentage = (value, digits) => {
    // TODO: is there no lightning class for this, e.g., <lightning:formattedNumber
    return value == null
        ? ""
        : new Intl.NumberFormat("en-US", {
              style: "percent",
              maximumFractionDigits: digits,
          }).format(value);
};

export const formattedNumber = (number) => {
    return number == null ? null : number.toLocaleString();
};

export const formattedDateTime = (dateString) => {
    if (!dateString) {
        return "";
    }

    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hour = String(date.getHours()).padStart(2, "0");
    const minute = String(date.getMinutes()).padStart(2, "0");
    const second = String(date.getSeconds()).padStart(2, "0");
    return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
};

export const instances = () => {
    const instanceNumberCount = parseInt(process.env.REACT_APP_COGNITO_REGION_NUMBER_COUNT, 10);
    const instanceOptions = Array.from({ length: instanceNumberCount }, (v, i) => {
        const id = (i + 1).toString();
        const label = process.env[`REACT_APP_COGNITO_REGION_LABEL_${id}`];
        const value = id;
        return { id, label, value };
    });

    return instanceOptions;
};

// React combobox requires "id", instead of "value"
export const addIds = (array) => {
    array.map((item) => {
        item.id = item.value;
    });
    return array;
};

export const parseOverrides = (response, overrideFields = []) => {
    if (overrideFields.length > 0) {
        response.forEach((record) => {
            record.overridden = record.overridden || {};
            record.original = overrideFields.reduce((result, item) => {
                result[item] = item in record.overridden ? record.overridden[item] : record[item];
                return result;
            }, {});
            record.hasOverride = overrideFields.reduce((result, item) => {
                result[item] = item in record.overridden;
                return result;
            }, {});
        });
    }

    return response;
};

export const updateOverridden = (record, overrideFields = []) => {
    // updates the 'overridden' field, removing items that no longer have an override
    // TODO: instead, list list or properties for which to revert overrides in a new 'revert' field, and make sure it is included in the api PATCH call
    if (overrideFields && overrideFields.length) {
        var hasOverride = record.hasOverride || {};
        var overridden = record.overridden || {};

        overrideFields.forEach((field) => {
            if (hasOverride[field] === false) {
                delete overridden[field];
            }
        });

        // if there is no overridden field, should be null
        record.overridden = Object.keys(overridden).length === 0 ? null : overridden;
    }
    return record;
};

export const revertOverride = (record, field) => {
    return {
        ...record,
        ...(record.original ? { [field]: record.original[field] } : {}),
        ...(record.hasOverride ? { hasOverride: { ...record.hasOverride, [field]: false } } : {}),
    };
};

export const setOverride = (record, field) => {
    return {
        ...record,
        hasOverride: { ...record.hasOverride, [field]: true },
        overridden: { ...record.overridden, [field]: record.original?.[field] },
    };
};

export const selectLoadedOption = (optionsAttribute, selectedValueAttribute, selectedLabelAttribute = null, setDefault = false) => {
    let options = optionsAttribute || [];
    let selectedValue = selectedValueAttribute;

    // clear the selectedValue if it is not in the options
    if (selectedValue && !options.find((i) => i.value === selectedValue)) {
        selectedValue = null;
    }

    // if there is only one item in the options, select it
    if (options.length === 1) {
        selectedValue = options[0].value;
    }

    // no option was selected, see if there is a default option, and select it
    if (!selectedValue && setDefault) {
        selectedValue = options.find((i) => i.default)?.value;
    }

    // mark the relevant item as selected in the options items
    options.forEach((i) => {
        i.selected = i.value === selectedValue;
    });
    var selectedItem = options.find((i) => i.value === selectedValue) || {};

    const selectedItemLabel = selectedItem.label;

    return { selectedValue, options, selectedItemLabel };
};
