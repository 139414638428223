import { Combobox, Input } from "@salesforce/design-system-react";
import React, { useState } from "react";

import RecordConstants from "../../constants/RecordConstants";
import PsRecord2 from "../ps-record/PsRecord2";
import { addIds } from "../../utils";
import { ComboboxOption, SourceType, Mode, PsRecordPropsType, SourceResponseType, CreateSourceRequestType, UpdateSourceRequestType } from "../../types";
import Field from "../../ui/wrappers/Field";
import { checkRequiredField } from "../../utils/index2";

const statusOptions: ComboboxOption[] = addIds(Object.values(RecordConstants.ENVIRONMENT_STATUS));
const restrictOptions: ComboboxOption[] = addIds(Object.values(RecordConstants.SOURCE_RESTRICT));
const recordObject = "source";

const defaultRecord = {
    name: "",
    status: "Active",
    restrict: "--None--",
};

const PsSource2: React.FC<PsRecordPropsType> = ({ recordId = "", parentId = "", childToParent, propagateEvent }) => {
    const [mode, setMode] = useState<Mode>("init");
    const [record, setRecord] = useState<SourceType>();
    const [restrictSelection, setRestrictSelection] = useState<ComboboxOption[]>([restrictOptions[0]]);
    const [statusSelection, setStatusSelection] = useState<ComboboxOption[]>([statusOptions[0]]);
    const [loading, setLoading] = useState<boolean>(false);
    const [fieldErrors, setFieldErrors] = useState({} as { [key: string]: string });

    const parseResponse = (response: SourceResponseType[]): SourceType[] => {
        return response.map((item) => ({
            id: item.id,
            name: item.name,
            status: item.status,
            restrict: item.restrict === "--None--" ? "" : item.restrict,
        }));
    };

    const parseUpdateRequest = (updatedRecord: SourceType): UpdateSourceRequestType => {
        return { id: updatedRecord.id, name: updatedRecord.name || "", status: updatedRecord.status };
    };

    const parseCreateRequest = (newRecord: SourceType): CreateSourceRequestType => {
        return { name: newRecord.name || "", status: newRecord.status, restrict: newRecord.restrict === "--None--" ? "" : newRecord.restrict };
    };

    const onEdit = () => {
        setFieldErrors({});
        setMode("edit");
    };

    // record card body-content
    const cardBody = (
        <div className="slds-form slds-m-around_medium" role="list">
            <h3 className="slds-section-title--divider slds-m-top_medium">Source Details</h3>

            <div className="slds-form__row">
                {/* Name */}
                <Field
                    setRecord={setRecord}
                    mode={mode}
                    value={record?.name}
                    label="Name"
                    onEdit={onEdit}
                    isEditable
                    fieldName="name"
                    checkValidity={checkRequiredField}
                    setFieldErrors={setFieldErrors}
                    body={<Input name="name" autoComplete="off" label="Name" required value={record?.name} errorText={fieldErrors?.name} />}
                />
                {/* Status */}
                <Field
                    mode={mode}
                    value={record?.status}
                    label="Status"
                    fieldName="status"
                    onEdit={onEdit}
                    isEditable
                    setRecord={setRecord}
                    setComboboxSelection={setStatusSelection}
                    body={
                        <Combobox
                            labels={{ label: "Status", placeholder: "--Please Select--" }}
                            menuPosition="relative"
                            options={statusOptions}
                            selection={statusSelection}
                            value={record?.status || ""}
                            variant="readonly"
                        />
                    }
                />
            </div>
            <div className="slds-form__row">
                {/* Restrict */}
                <Field
                    mode={mode}
                    value={record?.restrict}
                    label="Restrict"
                    fieldName="restrict"
                    showStaticViewInEditMode
                    setRecord={setRecord}
                    setComboboxSelection={setRestrictSelection}
                    body={<Combobox labels={{ label: "Restrict", placeholder: "--Please Select--" }} options={restrictOptions} selection={restrictSelection} variant="readonly" />}
                />
            </div>
        </div>
    );

    return (
        <PsRecord2
            recordLabel="Source"
            recordModule="core"
            recordObject={recordObject}
            record={record}
            defaultRecord={defaultRecord}
            showEdit
            showDelete
            mode={mode}
            recordId={recordId}
            parentId={parentId}
            propagateEvent={propagateEvent}
            setMode={setMode}
            onEdit={onEdit}
            setRecord={setRecord}
            setFieldErrors={setFieldErrors}
            loading={loading}
            setLoading={setLoading}
            childToParent={childToParent}
            parseResponse={parseResponse}
            parseUpdateRequest={parseUpdateRequest}
            parseCreateRequest={parseCreateRequest}
            cardBody={cardBody}
        />
    );
};

export default PsSource2;
