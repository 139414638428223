import { label } from "aws-amplify";
import { iconMapping, TREE_SECTIONS } from "../../../components/navigation-tree/constants";

function parseContent(content: string): { obj: string; id: string; name: string; label: string; title: string } {
    const params = content.split(",").reduce(
        (acc, pair: string, i) => {
            const [key, value, label] = pair.split(":").map((part: string) => part.trim());
            if (i === 0) {
                acc.obj = key;
                acc.id = value;
            } else if (i === 1) {
                acc.name = value;
                acc.label = label || value; // if label does not exist then that mean is text and not item
            } else if (i === 2 && acc.name !== value) {
                acc.title = value;
            }
            return acc;
        },
        { obj: "", id: "", name: "", label: "", title: "" }
    );
    return params;
}

export function convertSearchToContent(search: string): string {
    let result = search.replace(/\$\{([^}]+)\}/g, (match, content) => {
        const { obj, id, label, title } = parseContent(content);
        return addSpan({ id, label, obj, title });
    });
    return endsWithWhitespace(result) ? result : result + "&nbsp;";
}

export function convertSearchToQuery(search: string): string {
    return search
        .replace(/\$\{([^}]+)\}/g, (match, content) => {
            const { obj, id } = parseContent(content);
            return `\${${obj}:${id}}`;
        })
        .trim();
}
export function convertInterpretToQuery(value: string): string {
    return value
        .replace(/\$\{([^}]+)\}/g, (match, content) => {
            const { obj, id, label, title } = parseContent(content);
            return `\${${obj}:${id},name:${label},title:${label}}`;
        })
        .trim();
}

export function addSpan({ id, label, obj, title }: { id: string; label: string; obj: string; title: string }): string {
    let sectionKey = "";
    for (const [key, section] of Object.entries(TREE_SECTIONS)) {
        if (section.treeItems.includes(obj as any)) {
            if (obj === "source" || obj === "container") {
                sectionKey = "data";
            } else {
                sectionKey = key;
            }
        }
    }

    const iconKey = label === id ? "text" : iconMapping?.[sectionKey] || iconMapping.Other;
    return `<span id="${id}" obj="${obj}" contentEditable="false" title="${title || label}" class="text slds-pill" style="--bg-image: url('/assets/icons/utility/${iconKey}.svg')">${label}</span>`;
}
export function endsWithWhitespace(str: string): boolean {
    return /\s$/.test(str);
}
function startsWithWhitespace(str: string): boolean {
    return /^\s/.test(str);
}
function isWhitespace(str: string): boolean {
    return /^\s*$/.test(str);
}

export function splitStringAtIndex(str: string, index: number): { firstPart: string; word: string; lastPart: string } {
    let firstPart = str.slice(0, index);
    let lastPart = str.slice(index);

    // if ((!str[index] || startsWithWhitespace(lastPart)) && (!str[index - 1] || endsWithWhitespace(firstPart))) return { firstPart, word: "", lastPart };

    let start = index;
    let end = index - 1;

    while (start > 0 && !isWhitespace(str[start - 1])) start--;
    while (end < str.length && !isWhitespace(str[end + 1])) end++;

    const lastIndexQuote = firstPart.lastIndexOf('"');
    if (lastIndexQuote > -1) {
        start = lastIndexQuote;
        end = str.length - 1;
    }

    firstPart = str.slice(0, start);
    const word = str
        .slice(start, end + 1)
        .replace(/"/g, "")
        .trim();
    lastPart = str.slice(end + 1);

    return { firstPart, word, lastPart };
}
