//This replaces the PatternFolderGrid
import { useState, useEffect } from "react";
import Record from "../../helpers/recordLayer";
import PsRecordGrid from "../ps-record-grid/PsRecordGrid";
import { toastErrorMessage } from "../../helpers/index.js";
import { RECORD_COLUMNS } from "./constants";
import PsPatternChart from "../ps-pattern-chart/PsPatternChart.js";
import useToastContext from "../../context/useToastContext";
import { getRecordAPI } from "../../services/api";

const PsDashboardGrid = ({ parentId = "", queryFilter, childToParent, propagateEvent }) => {
    const [mode, setMode] = useState("init");
    const [view, setView] = useState("grid");
    const [orderBy, setOrderBy] = useState("createdOn");
    const [orderDirection, setOrderDirection] = useState("asc");
    const [hasMore, setHasMore] = useState(false);
    const [loading, setLoading] = useState(false);

    const { addToast } = useToastContext();

    const [folder, setFolder] = useState(null);
    const [original, setOriginal] = useState(null);

    const handleRecordRowAction = (action, row) => {
        switch (action) {
            case "details":
                childToParent({ type: "payload", action: "viewDetails", pattern: row });
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        if (queryFilter?.folderId) getFolder(queryFilter.folderId);
    }, [queryFilter?.folderId]);

    useEffect(() => {
        if (queryFilter?.folderId && propagateEvent?.type === "record" && propagateEvent?.action === "update") getFolder(queryFilter.folderId);
    }, [queryFilter?.folderId, propagateEvent]);

    const getFolder = async (folderId) => {
        const onSuccess = (response) => {
            const record = parseFolder(response)[0];
            setFolder(record);
            setOriginal(JSON.parse(JSON.stringify(record)));
            setLoading(false);
        };
        const onError = (response) => {
            addToast("error", "Error", toastErrorMessage(response));
            setLoading(false);
        };
        setFolder(null); // set to null immediately, so we don't render any records from the new Folder with the previous Folder's layout
        setLoading(true);
        try {
            await getRecordAPI({ module: "relate", object: "folder", recordId: folderId }, onSuccess, onError);
        } catch (error) {
            onError(error);
        }
    };

    const parseFolder = (response) => {
        return response.map(({ id, name, type, description, grid }) => ({
            id,
            name,
            type,
            description,
            grid,
        }));
    };

    const handleCancelEdit = () => {
        setFolder(JSON.parse(JSON.stringify(original)));
    };

    const handleSaveEdit = (layout) => {
        const deleteIds = layout.filter((item) => item.static).map((item) => item.i);
        const newLayout = layout.filter((item) => !item.static);
        const newFolder = { ...folder, grid: { layout: newLayout } };
        const folderId = queryFilter?.folderId;
        let deleteIndex = 0;

        setFolder(newFolder); // set this immediately to prevent reverting back to the old layout, and flickering back to the new layout after saving
        setOriginal(JSON.parse(JSON.stringify(newFolder)));

        var onFolderSuccess = function (response) {
            setLoading(false);
        };

        var onFolderError = function (response) {
            addToast("error", "Error saving Folder", toastErrorMessage(response));
            setLoading(false);
        };

        var onDeleteSuccess = function (response) {
            deleteNext();
        };

        var onDeleteError = function (response) {
            addToast("error", "Unable to delete Pattern", toastErrorMessage(response));
            setLoading(false);
        };

        const deleteNext = function () {
            if (deleteIndex < deleteIds.length) {
                deleteIndex++; // watch out; this prevents endlessly calling the delete API!
                Record.deleteRecord("relate", "pattern", deleteIds[deleteIndex - 1], onDeleteSuccess, onDeleteError);
            } else {
                Record.updateRecord("relate", "folder", JSON.stringify(parseInputPlainText(newFolder)), folderId, onFolderSuccess, onFolderError);
            }
        };

        setLoading(true);
        deleteNext();
    };

    const parseInputPlainText = (record) => {
        var data = record.id
            ? (({ id, name, description, grid }) => ({
                  id,
                  name,
                  description,
                  grid,
              }))(record) // select fields that can be updated
            : (({ name, description, grid }) => ({
                  name,
                  description,
                  grid,
              }))(record); // select fields that can be created
        return data;
    };

    const parseResponse = (response) => {
        let records = response.map(({ id, compositionId, name, type, description, treeHash, relevance, createdOn, lastRunOn, container, overridden }) => ({
            id,
            compositionId,
            name,
            type,
            namePlain: Record.removeMarkup(name),
            nameMarkup: Record.markupToHtml(name),
            treeHash,
            relevance,
            createdOn,
            lastRunOn,
            container,
            containerName: container?.name,
            sourceName: container?.source?.name,
            title: container?.source?.name + ": " + container?.name,
            overridden,
        }));

        return records.sort((a, b) => new Date(a.createdOn) - new Date(b.createdOn)); // TODO: remove when createdOn is supported in the API
    };

    const header = () => {
        if (mode === "edit") return <div className="slds-m-around_medium"> Drag tiles to the desired location and reshape tiles from the right bottom corner.</div>;
        return <div className="slds-m-around_medium">{folder?.description || ""}</div>;
    };

    return (
        <PsRecordGrid
            module="relate"
            object="pattern"
            queryFilter={queryFilter}
            layout={folder ? folder?.grid?.layout || [] : null}
            gridComponent={PsPatternChart}
            showTitle
            title={folder ? folder.name || "--Empty--" : " "}
            header={header}
            emptyLine="This Folder is empty"
            viewOptions={[
                { label: "Table", value: "table" },
                { label: "Grid", value: "grid" },
            ]}
            useLayout
            changeView
            recordLabel="Pattern"
            recordLabelPlural="Patterns"
            parseResponse={parseResponse}
            parentId={parentId}
            mode={mode}
            onModeChange={(value) => setMode(value)}
            view={view}
            onViewChange={setView}
            orderBy={orderBy}
            onOrderByChange={(value) => setOrderBy(value)}
            orderDirection={orderDirection}
            onOrderDirectionChange={(value) => setOrderDirection(value)}
            loading={loading}
            onLoadingChange={(value) => setLoading(value)}
            hasMore={hasMore}
            onHasMoreChange={setHasMore}
            showEdit={view === "grid" && mode !== "empty"}
            onSave={handleSaveEdit}
            onCancel={handleCancelEdit}
            recordColumns={RECORD_COLUMNS}
            maxRecords={100} // TODO: build queing mechanism to prevent loading many many plots, e.g., when refreshing or large dashboards
            onRecordRowAction={handleRecordRowAction}
            childToParent={childToParent}
            propagateEvent={propagateEvent}
            fullHeight={false}
        />
    );
};

export default PsDashboardGrid;
