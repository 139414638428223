import { Helmet } from "react-helmet";

import PsTopMenuBar from "../components/ps-top-menu-bar/PsTopMenuBar";
import useAuthContext from "../context/useAuthContext";
import AuthRouter from "./AuthRouter";
import UnAuthRouter from "./UnAuthRouter";
import { PS_FAVICON_URL } from "../constants";

const CustomRouter = () => {
    const { isInitializing, isAuthenticated, account } = useAuthContext();

    return isInitializing ? null : (
        <div className="App">
            <Helmet>
                <link rel="icon" href={account?.settings?.faviconUrl || PS_FAVICON_URL} />
            </Helmet>
            <PsTopMenuBar />
            <div style={{ height: "40px" }}></div>
            <>{isAuthenticated ? <AuthRouter /> : <UnAuthRouter />}</>
        </div>
    );
};

export default CustomRouter;
