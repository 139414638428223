import RecordConstants from "../../constants/RecordConstants";
import Record from "../../helpers/recordLayer";
import { FieldType, ObjectType, Restriction, RestrictionType } from "../../types";

export const updateRestriction = (existingRecord: ObjectType | FieldType, restrictions: Restriction[]): ObjectType | FieldType => {
    restrictions = restrictions || [];
    const values: string[] = [];
    const labels: string[] = [];
    const required: string[] = [];
    let restrictionTypeId: string;
    let restrictionTypeName: string;
    for (const restriction of restrictions) {
        restrictionTypeId = restriction.restrictionType.id;
        restrictionTypeName = restriction.restrictionType.name;
        if (restriction.status !== RecordConstants.RESTRICTION_STATUS.INACTIVE.value) {
            // add if not inactive
            values.push(restrictionTypeId);
            labels.push(restrictionTypeName);
            // make required if there is a system origin
            if ((restriction.allOrigins || []).some((x) => x !== "User")) {
                required.push(restrictionTypeId);
            }
        }
    }
    Object.assign(existingRecord, {
        restrictions,
        restrictionValues: values,
        restrictionLabels: labels.join(", "),
        restrictionRequired: required,
    });

    return { ...existingRecord };
};

export function merge(
    whatId: string,
    record: ObjectType | FieldType,
    restrictionOptions: RestrictionType[]
): {
    createdRestrictions: ObjectType[];
    statusChangedRestrictions: ObjectType[];
    changed: boolean;
} {
    const values = record.restrictionValues || [];
    let restrictions = record.restrictions || ([] as any[]);

    const restrictionMap = restrictions.reduce(
        (obj, item) => {
            obj[item.restrictionType.id] = item;
            return obj;
        },
        {} as Record<string, any>
    );

    const restrictionTypes = restrictionOptions || [];
    const restrictionTypeMap = restrictionTypes.reduce(
        (obj, item) => {
            obj[item.id] = item;
            return obj;
        },
        {} as Record<string, any>
    );

    let changed = false;
    let createdRestrictions = [];
    let statusChangedRestrictions = [];

    // to set removed restrictions to Inactive
    for (const value in restrictionMap) {
        if (!values.includes(value)) {
            const restriction = restrictionMap[value];

            if (restriction.status !== RecordConstants.RESTRICTION_STATUS.INACTIVE.value) {
                changed = true;
                restriction.status = RecordConstants.RESTRICTION_STATUS.INACTIVE.value;
                statusChangedRestrictions.push({ id: restriction.id, status: restriction.status });
            }
        }
    }

    // to add new restrictions
    for (const value of values) {
        let restriction = restrictionMap[value];

        if (!restriction) {
            changed = true;
            const restrictionType = restrictionTypeMap[value];

            restriction = {
                restrictionTypeId: restrictionType.id,
                restrictionType: restrictionType,
                name: restrictionType.name,
                status: RecordConstants.RESTRICTION_STATUS.ACTIVE.value,
            };
            restriction[whatId] = record.id;

            createdRestrictions.push(restriction);
            restrictions.push(restriction);
            restrictionMap[restrictionType.id] = restriction;
        } else if (restriction.status !== RecordConstants.RESTRICTION_STATUS.ACTIVE.value) {
            changed = true;
            restriction.status = RecordConstants.RESTRICTION_STATUS.ACTIVE.value;
            statusChangedRestrictions.push({ id: restriction.id, status: restriction.status });
        }
    }

    return { createdRestrictions, statusChangedRestrictions, changed };
}
