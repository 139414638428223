import { Button, Checkbox, Combobox, Dropdown, Input, ProgressBar, Tooltip } from "@salesforce/design-system-react";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";

import RecordConstants from "../../constants/RecordConstants";
import PsRecord2 from "../ps-record/PsRecord2";
import { formattedDateTime, toastErrorMessage } from "../../utils";
import {
    Mode,
    PsRecordPropsType,
    ConnectorApiType,
    ConnectorType,
    UpdateConnectorRequestType,
    CreateConnectorRequestType,
    DropdownOption,
    SourceOption,
    ConnectorTypeOption,
    ActionValidateResponseItem,
    ComboboxOption,
} from "../../types";
import Field from "../../ui/wrappers/Field";
import { checkRequiredField, cronToHuman, humanToCron, selectLoadedOption } from "../../utils/index2";
import useToastContext from "../../context/useToastContext";
import { doActionAPI, getRecordAPI, getRecordsAPI, submitRecordAPI } from "../../services/api";
import Modal from "../../ui/Modal";
import FormItemWrapper from "../../ui/wrappers/FormItemWrapper";
import AuthDetailsModal from "./components/AuthDetailsModal";
import Files2 from "./components/Files2";
import { ConnectorTypeType, DefaultConnectMethodType, SelectedConnectMethod } from "./components/types";
import { FIELD_ERROR_MESSAGES } from "../../constants";
import ConnectorRunning from "./components/ConnectorRunning";
import ExploreSearchTabLinks from "./components/ExploreSearchTabLinks";
import { addIdsToOptions, checkConcurrencyValue } from "./components/utils";
import { EventNavigationType, EventType } from "../../pages/types";
import Record from "../../helpers/recordLayer";

const statusOptions: ComboboxOption[] = Object.values(RecordConstants.CONNECTOR_STATUS);
const fieldStatusOptions: ComboboxOption[] = Object.values(RecordConstants.DEFAULT_FIELD_STATUS);
const objectStatusOptions: ComboboxOption[] = Object.values(RecordConstants.DEFAULT_OBJECT_STATUS);
const scheduleOptions: ComboboxOption[] = Object.values(RecordConstants.CONNECTOR_SCHEDULE);

const recordObject = "connector";

const PsConnector2: React.FC<PsRecordPropsType> = ({ recordId = "", parentId = "", childToParent, propagateEvent, options }) => {
    const [mode, setMode] = useState<Mode>("init");
    const [record, setRecord] = useState<ConnectorType>();
    const [loading, setLoading] = useState<boolean>(false);
    const [fieldErrors, setFieldErrors] = useState({} as { [key: string]: string });
    const [localParentId, setLocalParentId] = useState<string>(parentId);
    const [connectorType, setConnectorType] = useState({} as ConnectorTypeType);
    const [connectorTypeOptionsLoading, setConnectorTypeOptionsLoading] = useState(false);
    const [connectorTypeOptions, setConnectorTypeOptions] = useState([] as ConnectorTypeOption[]);
    const [connectOptions, setConnectOptions] = useState([]);
    const [connectorTypeSelection, setConnectorTypeSelection] = useState<ComboboxOption[]>([]);
    const [defaultConnectMethod, setDefaultConnectMethod] = useState({} as DefaultConnectMethodType);
    const [selectedConnectOption, setSelectedConnectOption] = useState("");
    const [selectedConnectMethod, setSelectedConnectMethod] = useState({} as SelectedConnectMethod);
    const [credentialsModalIsOpen, setCredentialsModalIsOpen] = useState(false);
    const [files, setFiles] = useState([]);
    const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [loadAllData, setLoadAllData] = useState(true);
    const [fileUploadProgress, setFileUploadProgress] = useState(0);
    const [sourceOptionsLoading, setSourceOptionsLoading] = useState(false);
    const [sourceOptions, setSourceOptions] = useState([] as SourceOption[]);
    const [sourceSelection, setSourceSelection] = useState<[]>([]);
    const [connectSelection, setConnectSelection] = useState([]);
    const [localIsLogin, setLocalIsLogin] = useState(options?.connector?.isLogin); // isLogin is only set on component initialization
    const defaultRecord = {
        name: "",
        concurrency: "",
        sourceId: parentId,
        sourceName: parentId && sourceOptions?.length ? sourceOptions.find((item) => item.id === parentId)?.name : "",
        status: RecordConstants.CONNECTOR_STATUS.ACTIVE.id,
        defaultObjectStatus: RecordConstants.OBJECT_STATUS.INCLUDED.id,
        defaultFieldStatus: RecordConstants.FIELD_STATUS.INCLUDED.id,
        schedule: RecordConstants.CONNECTOR_SCHEDULE.NONE.id,
    } as ConnectorType;
    const [objectStatusSelection, setObjectStatusSelection] = useState([objectStatusOptions.find((option) => option.id === defaultRecord.defaultObjectStatus)]);
    const [fieldStatusSelection, setFieldStatusSelection] = useState([fieldStatusOptions.find((option) => option.id === defaultRecord.defaultFieldStatus)]);
    const [statusOptionsSelection, setStatusOptionsSelection] = useState([statusOptions.find((option) => option.id === defaultRecord?.status)]);
    const [scheduleOptionsSelection, setScheduleOptionsSelection] = useState([
        scheduleOptions.find((option) => (defaultRecord?.schedule ? option.id === defaultRecord?.schedule : option.id === "--None--")),
    ]);

    const { addToast } = useToastContext();

    const fileInputRef = useRef(null);
    const authDetailsModalRef = useRef(null);

    useEffect(() => {
        if (connectorTypeOptions.length === 0) {
            const recordId = record?.connectorType?.id;
            loadConnectorTypes(recordId);
        }
    }, []);

    useEffect(() => {
        // no noed to load sources if source is selected
        if (mode === "new" && !record?.sourceName) {
            loadSources();
        }
    }, [mode]);

    useEffect(() => {
        if (connectorTypeOptionsLoading || !record?.connectorTypeId || !connectorTypeOptions?.length) return;

        const selectedConnectorType = connectorTypeOptions.find((option) => option.value === record.connectorTypeId) || ({} as ConnectorTypeOption);
        const selectedValue = selectedConnectorType.value || "";
        const selectedItemLabel = selectedConnectorType.label || "";
        setRecord((prev) => ({ ...prev, connectorTypeId: selectedValue, connectorTypeName: selectedItemLabel }));
        setConnectorTypeValue(record?.connectorTypeId, connectorTypeOptions);
    }, [record?.connectorTypeId, connectorTypeOptionsLoading]);

    function loadSources() {
        setSourceOptionsLoading(true);
        const onSuccess = function (response: any) {
            const sourceOptions = [...response];

            // for combobox
            sourceOptions.forEach((s) => {
                s.value = s.id;
                s.label = s.name;
            });

            if (!response?.length) {
                addToast("info", "No Sources Found", "First create a Source before creating a Connector");
                notifyNavigation("source", "core", "source", null);
            }

            const sourceId = record?.sourceId || parentId;
            const { selectedValue, options, selectedItemLabel } = selectLoadedOption(sourceOptions, sourceId, false);
            setSourceOptions(options as SourceOption[]);
            setSourceOptionsLoading(false);
            setRecord((prev) => ({ ...prev, sourceId: selectedValue, sourceName: selectedItemLabel }));
        };

        const onError = function () {
            setSourceOptionsLoading(false);
        };

        getRecordsAPI({ module: "core", object: "source", filters: { orderby: "name ASC" } }, onSuccess, onError);
    }

    function loadConnectorTypes(recordId: string = null) {
        setConnectorTypeOptionsLoading(true);
        const onSuccess = function (response: ConnectorTypeOption[]) {
            response.forEach((ct) => {
                ct.label = ct.name; // for combobox
                ct.value = ct.id; // for combobox
                ct.supportsCredentials = ct.supportedMethods.includes("Credentials");
                ct.supportsSchedule = ct.supportedMethods.includes("Schedule");
                ct.supportsUpload = ct.supportedMethods.includes("Upload");
                ct.supportsConcurrency = ct.supportedMethods.includes("Concurrency");
            });
            setConnectorTypeOptions(response);
            setConnectorTypeOptionsLoading(false);
            if (!response.length) {
                addToast("error", "No Connector Types Found", "Please contact Point Sigma Support");
            }
        };

        const onError = function () {
            setConnectorTypeOptionsLoading(false);
        };

        if (recordId) {
            getRecordAPI({ module: "pump", object: "connectortype", recordId }, onSuccess);
        } else {
            getRecordsAPI({ module: "pump", object: "connectortype", filters: { orderby: "name ASC" } }, onSuccess, onError);
        }
    }

    function notifyNavigation(parentId: string, module: string, object: string, id: any = null) {
        const navigationEvent: EventNavigationType = { parentId, obj: object, id, source: "record", type: "navigation", action: "navigate" };
        childToParent(navigationEvent);
    }

    const mergeSetupStatus = (connectorStatus: string, runEnd: string) => {
        if (connectorStatus === "Data Loaded") {
            // for now, this just sets the status to 'Processing Done' an hour after data is last loaded
            const runEndParsed = Date.parse(runEnd);
            if (new Date().getTime() - runEndParsed > 60 * 60 * 1000) {
                return "Processing Done";
            }
        }
        return connectorStatus;
    };

    const parseResponse = (response: ConnectorApiType[]): ConnectorType[] => {
        return response.map((item) => ({
            id: item.id,
            name: item.name,
            status: item.status,
            setupStatus: mergeSetupStatus(item.setupStatus, item.runEnd),
            schedule: cronToHuman(item.schedule) === "--None--" ? "" : cronToHuman(item.schedule),
            sourceId: item.source.id,
            sourceName: item.source.name,
            connectorTypeId: item.connectorType.id,
            connectorTypeName: item.connectorType.name,
            runStatus: item.runStatus,
            nextScheduledRun: formattedDateTime(item.nextScheduledRun),
            runStart: formattedDateTime(item.runStart),
            runEnd: formattedDateTime(item.runEnd),
            runMessage: item.runMessage,
            lastModifiedOn: item.lastModifiedOn,
            concurrency: item.concurrency != null ? item.concurrency : "",
            defaultObjectStatus: item.defaultObjectStatus,
            defaultFieldStatus: item.defaultFieldStatus,
            connectorType: {
                id: item.connectorType.id,
                name: item.connectorType.name,
                image: "",
                supportedMethods: [],
            },
        }));
    };

    const parseUpdateRequest = (record: ConnectorType): UpdateConnectorRequestType => {
        let data = record.id
            ? (({ id, name, defaultObjectStatus, defaultFieldStatus }) => ({
                  id,
                  name,
                  defaultObjectStatus,
                  defaultFieldStatus,
              }))(record)
            : null;

        // method-specific fields
        if (connectorType.supportsSchedule) {
            data = { ...data, status: record.status, schedule: humanToCron(record.schedule) === "--None--" ? "" : humanToCron(record.schedule) } as UpdateConnectorRequestType;
        }
        if (connectorType.supportsConcurrency) {
            data = { ...data, concurrency: record.concurrency ? parseInt(record.concurrency) : null } as UpdateConnectorRequestType;
        }

        return data;
    };

    const parseCreateRequest = (record: ConnectorType): CreateConnectorRequestType => {
        let data = record.id
            ? null
            : (({ name, defaultObjectStatus, defaultFieldStatus, sourceId }) => ({
                  name,
                  defaultObjectStatus,
                  defaultFieldStatus,
                  sourceId,
                  connectorTypeId: connectorType?.id,
              }))(record);

        // method-specific fields
        if (connectorType.supportsSchedule) {
            data = { ...data, status: record.status, schedule: humanToCron(record.schedule) } as CreateConnectorRequestType;
        }
        if (connectorType.supportsConcurrency) {
            data = { ...data, concurrency: record.concurrency ? parseInt(record.concurrency) : null } as CreateConnectorRequestType;
        }

        return data;
    };

    function parseInputToEncrypt() {
        let data = null;
        if (connectorType.supportsCredentials) {
            const connectMethod = selectedConnectMethod || ({} as SelectedConnectMethod);
            let methodConfig = connectMethod.inputs || [];
            let inputs: { method?: string } = methodConfig.reduce((obj1, item1) => {
                if (item1.type === "section") {
                    let obj2 = {};
                    let option = item1.value;
                    let inputs = item1.inputs || [];
                    inputs.forEach((item2: any) => {
                        if (option == null || option === item2.option) {
                            if (item2.type === "list") {
                                obj2[item2.name] = Record.parseCSV(item2.value) || item2.default;
                            } else if (item2.type === "number") {
                                obj2[item2.name] = item2.value != null && item2.value !== "" ? parseFloat(item2.value) : item2.default;
                            } else {
                                obj2[item2.name] = item2.value || item2.default;
                            }
                        }
                    });
                    obj1[item1.name] = obj2;
                } else if (item1.type === "list") {
                    obj1[item1.name] = Record.parseCSV(item1.value) || item1.default;
                } else if (item1.type === "number") {
                    obj1[item1.name] = item1.value != null && item1.value !== "" ? parseFloat(item1.value) : item1.default;
                } else {
                    obj1[item1.name] = item1.value || item1.default;
                }
                return obj1;
            }, {});
            const hascredentials = Object.values(inputs).join("").match(/^ *$/) === null; // check if any connection parameter is provided, ignoring empty strings
            inputs.method = connectMethod.value;
            data = hascredentials ? inputs : null;
        }
        return data;
    }

    const setConnectorTypeValue = (connectorTypeId: string, connectorTypeOptions: ConnectorTypeOption[]) => {
        if (connectorTypeOptionsLoading) {
            return;
        }

        const connectorType = connectorTypeOptions?.find((ct: any) => ct.value === connectorTypeId) || ({} as ConnectorTypeType);
        setConnectorType(connectorType as ConnectorTypeType);
        const methodDetails = connectorType?.methodDetails || {};

        // adding id to options to avoid combobox error
        const methods =
            methodDetails?.methods?.length > 0
                ? methodDetails.methods.map((method: any) => ({
                      ...method,
                      id: method.value,
                      inputs: method.inputs?.length > 0 ? method.inputs.map((input: any) => addIdsToOptions(input)) : [],
                  }))
                : [];

        setConnectOptions(methods);
        let connectMethod = methods.find((am: any) => am.default) || {};
        setDefaultConnectMethod(connectMethod);

        const { selectedValue, options } = selectLoadedOption(methods, selectedConnectOption, true);
        connectMethod = options.find((am) => am.value === selectedValue) || {};
        setSelectedConnectMethod(connectMethod);
        setConnectSelection(connectMethod.value ? [{ ...connectMethod, id: connectMethod.value }] : []);
        setSelectedConnectOption(connectMethod.value);
    };

    function updateUI(record: ConnectorType) {
        if (localIsLogin) {
            showRunMessageToast(true, "Successfully authenticated with the source", record);
            setLocalIsLogin(false);
        }
    }

    const onEdit = () => {
        setFieldErrors({});
        setMode("edit");
    };

    const dispatchEvent = (event: EventType) => {
        childToParent(event);
    };

    const handleRefresh = () => {
        dispatchEvent({ type: "sync", action: "reload" });
    };

    const actionSync = () => {
        setLoading(true);

        const onSuccess = function () {
            addToast("info", "Connector Started", "Loading data inventory");
            setRecord((prev) => ({ ...prev, runStatus: "Running", runMessage: null, runStart: formattedDateTime(Date.now()), runEnd: null }));
            setLoading(false);
        };

        const onError = (res: any) => {
            setLoading(false);
            addToast("error", "Error", toastErrorMessage(res));
        };

        doActionAPI({ module: "pump", object: "connector", action: "sync", args: { id: recordId } }, onSuccess, onError);
    };

    const actionLoad = () => {
        setLoading(true);
        const onSuccess = function () {
            addToast("info", "Connector Started", "Loading data");
            setRecord((prev) => ({ ...prev, runStatus: "Running", runMessage: null, runStart: formattedDateTime(Date.now()), runEnd: null }));
            setLoading(false);
        };

        const onError = (res: any) => {
            setLoading(false);
            addToast("error", "Error", toastErrorMessage(res));
        };

        doActionAPI({ module: "pump", object: "connector", action: "load", args: { id: recordId } }, onSuccess, onError);
    };
    function showRunMessageToast(success: boolean, successMessage: string, receivedRecord: ConnectorType = record) {
        if (success) {
            const message = successMessage || receivedRecord?.runMessage;
            addToast("success", "Success", message);
        } else {
            const message = receivedRecord?.runMessage || "Unexpected error";
            addToast("error", "Error", message);
        }
    }

    const actionValidate = () => {
        setLoading(true);
        const connectorId = recordId;

        const onError = function () {
            showRunMessageToast(false, "", record);
            handleRefresh();
        };

        const onSuccess = function (response: ActionValidateResponseItem[]) {
            const success = response?.[0]?.status === "SUCCESS";
            showRunMessageToast(success, success ? "Successfully authenticated with the source" : "", record);
            handleRefresh();
        };

        doActionAPI({ module: "pump", object: "connector", action: "validate", args: { id: connectorId } }, onSuccess, onError);
    };

    const actionAbort = () => {
        setLoading(true);
        const onSuccess = function () {
            addToast("info", "Connector Aborted", "Connector aborted");
            setRecord((prev) => ({ ...prev, runStatus: "Aborting", runMessage: null, runStart: formattedDateTime(Date.now()), runEnd: null }));

            setLoading(false);
        };

        const onError = function () {
            setLoading(false);
        };

        doActionAPI({ module: "pump", object: "connector", action: "abort", args: { id: recordId } }, onSuccess, onError);
    };

    const clearData = () => {
        setLoading(true);
        setShowDeleteConfirmDialog(false);

        const onSuccess = function () {
            addToast("info", "Connector Started", "Clearing data");
            setRecord((prev) => ({ ...prev, runStatus: "Running", runMessage: null, runStart: formattedDateTime(Date.now()), runEnd: null }));
            setLoading(false);
        };

        const onError = function () {
            addToast("error", "Connector Failed to Start", "An unexpected error occurred");
            setLoading(false);
        };

        doActionAPI({ module: "pump", object: "connector", action: "clear", args: { id: recordId } }, onSuccess, onError);
    };

    const actionClear = () => {
        setShowDeleteConfirmDialog(true);
    };

    const handleGoToTab = (tab: string) => {
        dispatchEvent({ type: "tab", source: "tab", action: "navigate tab level", tab });
    };

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        const fileList = e.target.files;
        const filesArray = Array.from(fileList);
        setFiles(filesArray);
        startConnect(filesArray);
    };

    const handleDropFiles = (e: DragEvent) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        handleFileChange({
            target: { files },
        } as any);
    };

    const initiateOauthConnection = () => {
        setLoading(true);
        const onSuccess = function (response: any) {
            const actionResponse = response[0];
            if (actionResponse?.details?.loginUrl) {
                window.open(actionResponse.details.loginUrl, "_top");
            } else {
                const error = actionResponse.error || "";
                addToast("error", "Authentication Error", "Unable to start authentication\n" + error);
                setLoading(false);
            }
        };

        const onError = function (response: any) {
            setLoading(false);
            const actionResponse = response?.[0];
            addToast("error", "Error", toastErrorMessage(actionResponse));
        };

        const returnUrl = new URL(window.location.href);
        returnUrl.searchParams.set("login", "true");

        doActionAPI({ module: "pump", object: "connector", action: "initiate_oauth", args: { id: record.id, returnUrl } }, onSuccess, onError);
    };

    const uploadFile = async (link: any, file: File, index: number, progress: any, done: boolean) => {
        const url = link.url;
        const fields = link.fields;
        const formData = new FormData();

        formData.append("key", fields["key"]);
        formData.append("success_action_status", fields["success_action_status"]);
        formData.append("x-amz-algorithm", fields["x-amz-algorithm"]);
        formData.append("x-amz-credential", fields["x-amz-credential"]);
        formData.append("x-amz-date", fields["x-amz-date"]);
        formData.append("policy", fields["policy"]);
        formData.append("x-amz-signature", fields["x-amz-signature"]);
        formData.append("file", file);

        const xhr = new XMLHttpRequest();
        xhr.open("POST", url, true);
        xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");

        xhr.upload.addEventListener("progress", (e) => {
            progress[index] = e.loaded / e.total || 1;
            const updatedTotalProgress =
                progress.reduce((total: number, item: number) => {
                    return total + item;
                }, 0) / progress.length;
            setFileUploadProgress(100 * updatedTotalProgress);
        });

        xhr.addEventListener("readystatechange", (e) => {
            if (xhr.readyState === 4) {
                if (xhr.status !== 201) {
                    const message = xhr?.responseXML?.getElementsByTagName("Message")[0]?.innerHTML;
                    addToast("error", "Upload Failed", 'Could not upload "' + file.name + '"' + (message ? "; " + message : ""));
                }

                // Mark this file as done
                progress[index] = 1;

                // Check if all files are done
                if (!done && progress.every((x) => x === 1)) {
                    done = true; // Prevent other threads from triggering the action again
                    setUploading(false);

                    // Call actionLoad only once when all files are uploaded
                    if (loadAllData) {
                        actionLoad();
                        setFiles([]);
                    } else {
                        actionSync();
                    }
                }
            }
        });

        xhr.send(formData);
    };

    const uploadFiles = (files: File[]) => {
        try {
            const onSuccess = function (response: any) {
                try {
                    const link = response[0];
                    var progress = new Array(files.length);
                    const done = false;
                    progress.fill(0.0);
                    files.forEach((file: File, index: number) => {
                        uploadFile(link, file, index, progress, done);
                    });
                } catch (err) {
                    addToast("error", "Upload Unsuccessful", err);
                    setUploading(false);
                }
            };
            const onError = function () {
                setUploading(false);
            };
            setFileUploadProgress(0);
            setUploading(true);

            getRecordAPI({ module: "pump", object: "upload", filters: { id: recordId } }, onSuccess, onError);
        } catch (error) {
            console.error(error);
        }
    };

    const startConnect = (filesArg: File[]) => {
        const connectMethod = defaultConnectMethod || ({} as DefaultConnectMethodType);
        if (connectMethod.value === "oauth2" || connectMethod.value === "OAuth Credentials") {
            initiateOauthConnection();
        } else if (connectMethod.value === "upload") {
            const selectedFiles = filesArg || ({} as File[]);
            if (selectedFiles.length === 0) {
                addToast("warning", "Select a file", "Please select a file to upload.");
                return;
            }
            uploadFiles(selectedFiles);
        } else {
            setCredentialsModalIsOpen(true);
        }
    };

    function handleAction(action: string) {
        switch (action) {
            case "validate":
                actionValidate();
                break;
            case "sync":
                actionSync();
                break;
            case "load":
                actionLoad();
                break;
            case "abort":
                actionAbort();
                break;
            case "clear":
                actionClear();
                break;
            default:
        }
    }

    const isBusy = record?.runStatus === "Running" || record?.runStatus === "Aborting";

    const cardActions =
        mode === "view" ? (
            <Dropdown
                onSelect={(selected: DropdownOption) => handleAction(selected.value)}
                options={[
                    {
                        label: "Check Connection",
                        value: "validate",
                        leftIcon: {
                            category: "utility",
                            name: "key",
                        },
                        disabled: isBusy,
                    },
                    {
                        label: "Get Inventory",
                        value: "sync",
                        leftIcon: {
                            category: "utility",
                            name: "sync",
                        },
                        disabled: isBusy,
                    },
                    {
                        label: "Load Data",
                        value: "load",
                        leftIcon: {
                            category: "utility",
                            name: "download",
                        },
                        disabled: isBusy,
                    },
                    {
                        label: "Abort",
                        value: "abort",
                        leftIcon: {
                            category: "utility",
                            name: "stop",
                        },
                    },
                    {
                        label: "Clear Data",
                        value: "clear",
                        leftIcon: {
                            category: "utility",
                            name: "clear",
                        },
                        disabled: isBusy,
                    },
                ]}
                assistiveText={{ icon: "Actions" }}
                iconVariant="border-filled"
                iconCategory="utility"
                iconName="down"
                align="right"
            />
        ) : null;

    function selectConnect(data: any) {
        let value = data.selection[0].value;
        setSelectedConnectOption(value);
        setConnectSelection(data.selection);
        setConnectMethod(value);
        setFieldErrors((prev) => ({ ...prev, selectedConnectOption: "" }));
    }

    function setConnectMethod(selectedConnectOption: string) {
        unsetCredentials();
        const connectMethod = connectOptions.find((am) => am.value === selectedConnectOption) || ({} as SourceOption);
        setSelectedConnectMethod(connectMethod);
    }

    function setConnectProperty(value: string, indexes: number[], name: string) {
        const index = indexes[0];
        const nestedIndex = indexes[1];

        setSelectedConnectMethod((prev) => {
            const updatedInputs = prev.inputs.map((input, i) => {
                if (i === index) {
                    if (nestedIndex) {
                        return { ...input, inputs: input.inputs.map((input2, j) => (j === nestedIndex ? { ...input2, value: value } : input2)) };
                    } else {
                        return { ...input, value: value };
                    }
                }
                return input;
            });
            return { ...prev, inputs: updatedInputs };
        });
        setFieldErrors((prev) => ({ ...prev, [name]: "" }));
    }

    function selectConnectMethodInput(data: any, index: number, field?: string, nestedIndex?: number) {
        const id: string = data?.selection[0]?.id || data?.selection[0]?.value;

        setSelectedConnectMethod((prev: any) => {
            if (nestedIndex !== undefined) {
                return {
                    ...prev,
                    inputs: prev.inputs.map((input: any, i: number) =>
                        i === index
                            ? {
                                  ...input,
                                  inputs: input.inputs.map((nestedInput: any, j: number) => (j === nestedIndex ? { ...nestedInput, value: id } : nestedInput)),
                              }
                            : input
                    ),
                };
            } else {
                return {
                    ...prev,
                    inputs: prev.inputs.map((input: any, i: number) => (i === index ? { ...input, value: id } : input)),
                };
            }
        });
        setFieldErrors((prev) => ({ ...prev, [field]: "" }));
    }

    function unsetCredentials() {
        setConnectOptions((prevOptions) => {
            prevOptions.forEach((am) => {
                let inputs = am.inputs || [];
                inputs.forEach((v) => {
                    delete v.value;
                });
            });
            return prevOptions;
        });
    }

    function handleCancelCredentials() {
        setFieldErrors({});
        setCredentialsModalIsOpen(false);
        unsetCredentials();
    }

    const isFormValid = (): boolean => {
        let isValid = true;
        const validateElement = (element: React.ReactNode): void => {
            if (!element) return;

            if (React.isValidElement(element)) {
                const { props } = element;

                if (props.body && props.body?.props?.required && (!props.body?.props?.value || props.body?.props?.value === "--None--")) {
                    isValid = false;
                    if (props.body.props.name) {
                        setFieldErrors((prev) => ({
                            ...prev,
                            [props.body.props.name]: props.body.props.errorMessage || FIELD_ERROR_MESSAGES.GENERAL_REQUIRED_FIELD,
                        }));
                    }
                }
                if (props.children) {
                    React.Children.forEach(props.children, validateElement);
                }
            } else if (Array.isArray(element)) {
                element.forEach(validateElement);
            }
        };
        validateElement(authDetailsModalRef?.current?.modalContent);

        return isValid;
    };

    function handleSaveCredentials() {
        if (!isFormValid()) {
            const toastId = record?.id || "saveCredentialsErrorId";
            addToast("error", "Input Error", "Please update the invalid form entries and try again.", toastId);
            return;
        }
        saveCredentials();
    }

    function saveCredentials() {
        setCredentialsModalIsOpen(false);
        const callback = () => (selectedConnectMethod?.supportsOAuth ? initiateOauthConnection() : actionValidate());
        onSubmit(callback);
    }

    const onSubmit = (callback: () => void) => {
        setLoading(true);
        const onSuccess = function (response: any) {
            setLoading(false);
            callback();
        };

        const onError = function (response: any) {
            setLoading(false);
            addToast("error", "Error", toastErrorMessage(response));
            callback();
        };
        const updatedRecord = record.id ? parseUpdateRequest(record) : parseCreateRequest(record);

        submitRecordAPI({ module: "pump", object: recordObject, inputBody: [updatedRecord] }, onSuccess, onError);
    };

    function setParent(record: any) {
        // update parent Id only if there is recordId dementing on the response
        if (recordId) setLocalParentId(record?.source?.id || "");
    }

    const cardBody = (
        <div className="PsConnector slds-form slds-m-around_medium" role="list">
            {/*  Connector in progress */}
            {mode === "view" && (record?.runStatus === "Running" || record?.runStatus === "Aborting") && <ConnectorRunning loading={loading} handleRefresh={handleRefresh} />}

            {/* Connector setup flow */}
            {mode === "view" && record?.setupStatus && record?.runStatus !== "Running" && record?.runStatus !== "Aborting" && (
                <div>
                    <h3 className="slds-section-title--divider slds-m-top_medium">Next Steps</h3>
                    {record?.setupStatus === "Authenticated" && (
                        <div className="message">
                            <p className="slds-p-bottom_x-small">Check that this connection can access the source.</p>
                            <Button label="Check Connection" title="Check Connection" onClick={actionValidate} disabled={loading} variant="outline-brand" />
                        </div>
                    )}
                    {record?.setupStatus === "Connection Validated" && (
                        <div className="message">
                            <p className="slds-p-bottom_x-small">Start by loading the data inventory from the source.</p>
                            <p className="slds-p-bottom_x-small">Once the inventory is loaded, review which Objects and Fields to include, and start loading data.</p>
                            <Button label="Load Inventory" title="Load the data inventory from the source" onClick={() => actionSync()} disabled={loading} variant="outline-brand" />
                        </div>
                    )}

                    {record?.setupStatus === "Aborted" && (
                        <div className="message">
                            <p className="slds-p-bottom_x-small">Connector was aborted.</p>
                            <p className="slds-p-bottom_x-small">Review which Objects and Fields to inlude or exclude. Set a download schedule, or start loading data.</p>
                            <Button label="Load Data" title="Load data from the source" onClick={actionLoad} disabled={loading} variant="outline-brand" />
                        </div>
                    )}

                    {record?.setupStatus === "Inventory Loaded" && (
                        <div className="message">
                            <p className="slds-p-bottom_x-small">Inventory successfully loaded.</p>
                            {record?.supportsSchedule && <p className="slds-p-bottom_x-small">Review which Objects and Fields to inlude or exclude. Set a download schedule, or start loading data.</p>}
                            {!record?.supportsSchedule && <p className="slds-p-bottom_x-small">Review Objects and Fields to inlude or exclude, and start loading data.</p>}
                            <Button label="Load Data" title="Load data from the source" onClick={actionLoad} disabled={loading} variant="outline-brand" />
                        </div>
                    )}

                    {record?.setupStatus === "Data Loaded" && (
                        <div className="message">
                            <p className="slds-p-bottom_x-small">Your data is being processed and scanned for patterns, this may take a few moments.</p>
                            <p className="slds-p-bottom_x-small">
                                Discover results so far in the <ExploreSearchTabLinks handleGoToTab={handleGoToTab} />
                            </p>
                            <Button label="Check Status" title="Check Status" onClick={handleRefresh} disabled={loading} variant="outline-brand" />
                        </div>
                    )}

                    {record?.setupStatus === "Processing Done" && (
                        <div className="message">
                            <p className="slds-p-bottom_x-small">Finished processing your data.</p>
                            <p className="slds-p-bottom_x-small">
                                Discover results in the <ExploreSearchTabLinks handleGoToTab={handleGoToTab} />
                            </p>
                            {connectorType.supportsUpload && (
                                <p className="slds-p-bottom_x-small">Upload more files in the "Upload Files" section, then use the "Load Data" button to load the new data.</p>
                            )}
                            {connectorType.supportsCredentials && (
                                <>
                                    {!record?.schedule && (
                                        <p className="slds-p-bottom_x-small">Set a schedule to load new data at regular intervals, or use the "Load Data" button to load the latest data now.</p>
                                    )}
                                    {record?.schedule && <p className="slds-p-bottom_x-small">Use the "Load Data" button to load the latest data.</p>}
                                </>
                            )}
                            <Button label="Load Data" title="Load data from the source" onClick={actionLoad} disabled={loading} variant="outline-brand" />
                        </div>
                    )}
                </div>
            )}

            {/* Authentication section */}
            {mode === "view" && connectorType.supportsCredentials && record?.runStatus !== "Running" && (
                <div>
                    <h3 className="slds-section-title--divider slds-m-top_medium">Connector Setup</h3>
                    <div className="slds-form__row">
                        <div className="slds-form__item" role="listitem">
                            <div className="slds-form-element slds-form-element_stacked">{record?.setupStatus && <span className="slds-form-element__label">Successfully connected.</span>}</div>
                        </div>
                    </div>
                    <div className="slds-form__row">
                        <div className="slds-form__item" role="listitem">
                            <div className="slds-form-element slds-form-element_stacked">
                                <div className="slds-form-element__control">
                                    {defaultConnectMethod?.button && !record?.setupStatus && (
                                        <Button label={defaultConnectMethod?.button} title={defaultConnectMethod?.button} onClick={startConnect} disabled={loading} variant="brand" />
                                    )}
                                    <Button label="Setup..." title="Set connection details" onClick={() => setCredentialsModalIsOpen(true)} disabled={loading} variant="outline-brand" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Upload section (supportsUpload only) */}
            {mode === "view" && connectorType.supportsUpload && record?.runStatus !== "Running" && (
                <div>
                    <h3 className="slds-section-title--divider slds-m-top_medium">Upload Files</h3>
                    <div className="slds-form__row">
                        <div className="slds-form__item slds-grid_vertical-align-center" role="listitem">
                            <div className="slds-form-element slds-form-element_stacked">
                                <div className={uploading ? "slds-hide" : "slds-show_inline"}>
                                    {/* show extra input fields for the default connector /}
                                    {/* Iteration */}
                                    {defaultConnectMethod.inputs.map((item, index) => {
                                        return (
                                            // extra div might break the row part
                                            <div key={"defaultConnectMethod_inputs_" + index}>
                                                {item.type && (
                                                    <div className="slds-form__row">
                                                        <div className="slds-form__item slds-grid_vertical-align-center" role="listitem">
                                                            <div className="slds-p-top_x-small">
                                                                <div className="slds-form-element slds-form-element_stacked ">
                                                                    <div className="slds-text-color_weak">{item.label}</div>
                                                                    <div className="slds-box slds-box_x-small" onDragOver={(e) => e.preventDefault()} onDrop={(e: any) => handleDropFiles(e)}>
                                                                        <div className="slds-grid">
                                                                            <button
                                                                                className="slds-button slds-button_outline-brand"
                                                                                onClick={() => fileInputRef.current.click()}
                                                                                onDragOver={(e) => e.preventDefault()}
                                                                                onDrop={(e: any) => handleDropFiles(e)}
                                                                            >
                                                                                <svg className="slds-button__icon slds-button__icon_left" aria-hidden="true">
                                                                                    <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#upload"></use>
                                                                                </svg>
                                                                                Click to Upload or drop files
                                                                                <input ref={fileInputRef} type="file" multiple onChange={(e) => handleFileChange(e)} style={{ display: "none" }} />
                                                                            </button>
                                                                            {files.length > 0 && <Files2 files={files} />}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    })}
                                    <div className="slds-form__row">
                                        <div className="slds-form__item slds-grid_vertical-align-center" role="listitem">
                                            <Checkbox
                                                assistiveText={{ label: "Default" }}
                                                id="checkField_loadAllData"
                                                labels={{ label: "Load all data" }}
                                                onChange={() => setLoadAllData((prev) => !prev)}
                                                checked={loadAllData}
                                            />
                                            <Tooltip
                                                id="base"
                                                align="top left"
                                                content="Load all data after uploading. Uncheck to read the file's inventory only, then select Objects and Fields to include."
                                                variant="learnMore"
                                                dialogClassName="dialog-classname"
                                            />
                                        </div>
                                    </div>
                                </div>

                                {uploading && (
                                    <>
                                        <p className="slds-text-color_weak">Uploading...</p>
                                        <ProgressBar value={fileUploadProgress} radius="circular" />
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Connector details */}
            <h3 className="slds-section-title--divider slds-m-top_medium">Connector Details</h3>
            <div className="slds-form__row">
                {/* Name */}
                <Field
                    setRecord={setRecord}
                    mode={mode}
                    value={record?.name}
                    label="Name"
                    onEdit={onEdit}
                    isEditable={true}
                    fieldName="name"
                    checkValidity={checkRequiredField}
                    setFieldErrors={setFieldErrors}
                    body={<Input name="name" autoComplete="off" label="Name" required={true} value={record?.name} errorText={fieldErrors?.name} />}
                />

                {/* Source */}
                <Field
                    mode={mode}
                    value={record?.sourceName}
                    label="Source"
                    onEdit={onEdit}
                    showStaticViewInEditMode
                    showStaticViewInNewMode={parentId === record?.sourceId}
                    setRecord={setRecord}
                    setFieldErrors={setFieldErrors}
                    fieldName="sourceId"
                    setComboboxSelection={setSourceSelection}
                    fieldLabel="sourceName"
                    options={sourceOptions}
                    body={
                        <Combobox
                            labels={{
                                label: "Source",
                                noOptionsFound: sourceOptionsLoading ? "" : "No matches found",
                            }}
                            options={sourceOptions}
                            selection={sourceSelection}
                            value={record?.sourceId}
                            variant="readonly"
                            required={true}
                            errorText={fieldErrors?.sourceId}
                            name="sourceId"
                            hasMenuSpinner={sourceOptionsLoading}
                        />
                    }
                />
            </div>

            <div className="slds-form__row">
                {/* Type */}
                <Field
                    mode={mode}
                    value={record?.connectorTypeName}
                    label="Connector Type"
                    onEdit={onEdit}
                    showStaticViewInEditMode
                    setRecord={setRecord}
                    setFieldErrors={setFieldErrors}
                    fieldName="connectorTypeId"
                    fieldLabel="connectorTypeName"
                    setComboboxSelection={setConnectorTypeSelection}
                    options={connectorTypeOptions}
                    body={
                        <Combobox
                            labels={{
                                label: "Connector",
                                placeholderReadOnly: "--Please Select--",
                                noOptionsFound: connectorTypeOptionsLoading ? "" : "No matches found",
                            }}
                            menuItemVisibleLength={10}
                            menuPosition="relative"
                            options={connectorTypeOptions}
                            selection={connectorTypeSelection}
                            value={record?.connectorTypeId}
                            variant="readonly"
                            required={true}
                            errorText={fieldErrors?.connectorTypeId}
                            name="connectorTypeId"
                            hasMenuSpinner={connectorTypeOptionsLoading}
                        />
                    }
                />
            </div>

            <div className="slds-form__row">
                {/* Default Object Status */}
                <Field
                    mode={mode}
                    value={record?.defaultObjectStatus}
                    label="Default Object Status"
                    onEdit={onEdit}
                    isEditable
                    setComboboxSelection={setObjectStatusSelection}
                    setRecord={setRecord}
                    fieldName="defaultObjectStatus"
                    setFieldErrors={setFieldErrors}
                    body={
                        <Combobox
                            labels={{
                                label: "Default status for Objects added to the inventory:",
                                placeholderReadOnly: "--Please Select--",
                            }}
                            options={objectStatusOptions}
                            selection={objectStatusSelection}
                            value={record?.defaultObjectStatus}
                            variant="readonly"
                        />
                    }
                />

                {/* Default Field Status */}
                <Field
                    mode={mode}
                    value={record?.defaultFieldStatus}
                    label="Default Field Status"
                    onEdit={onEdit}
                    isEditable
                    setComboboxSelection={setFieldStatusSelection}
                    setRecord={setRecord}
                    fieldName="defaultFieldStatus"
                    setFieldErrors={setFieldErrors}
                    body={
                        <Combobox
                            labels={{
                                label: "Default status for Fields added to the inventory:",
                                placeholderReadOnly: "--Please Select--",
                            }}
                            options={fieldStatusOptions}
                            selection={fieldStatusSelection}
                            value={record?.defaultFieldStatus}
                            variant="readonly"
                        />
                    }
                />
            </div>

            {/* Status section (only when runStatus is set) */}
            {mode === "view" && record?.runStatus && <h3 className="slds-section-title--divider slds-m-top_medium">Status</h3>}

            {/* Issue with row being inside another div, so I need to put them in separate conditional blocks */}
            {mode === "view" && record?.runStatus && (
                <>
                    <div className="slds-form__row">
                        {/* Last Run Status */}
                        <Field mode={mode} value={record?.runStatus} label="Last Run Status" />

                        {/* Last Run Completed */}
                        <Field mode={mode} value={record?.nextScheduledRun} label="Next Scheduled Run" />
                    </div>
                    <div className="slds-form__row">
                        {/* Last Run Started */}
                        <Field mode={mode} value={record?.runStart} label="Last Run Started" />

                        {/* Last Run Completed */}
                        <Field mode={mode} value={record?.runEnd} label="Last Run Completed" />
                    </div>

                    <div className="slds-form__row">
                        {/* Last Run Message */}
                        <Field mode={mode} value={record?.runMessage} label="Last Run Message" />
                    </div>
                </>
            )}
            {/* Download Settings Section */}
            {(connectorType?.supportsSchedule || connectorType?.supportsConcurrency) && <h3 className="slds-section-title--divider slds-m-top_medium">Download Settings</h3>}
            {connectorType?.supportsSchedule && (
                <>
                    <div className="slds-form__row">
                        {/* Status */}
                        <Field
                            mode={mode}
                            value={record?.status}
                            label="Status"
                            onEdit={onEdit}
                            isEditable
                            setRecord={setRecord}
                            fieldName="status"
                            setFieldErrors={setFieldErrors}
                            setComboboxSelection={setStatusOptionsSelection}
                            body={
                                <Combobox
                                    labels={{
                                        label: "Status",
                                        placeholderReadOnly: "--Please Select--",
                                    }}
                                    options={statusOptions}
                                    selection={statusOptionsSelection}
                                    value={record?.status}
                                    variant="readonly"
                                />
                            }
                        />
                    </div>
                    <div className="slds-form__row">
                        {/* Schedule */}
                        <Field
                            mode={mode}
                            value={record?.schedule}
                            label="Schedule"
                            onEdit={onEdit}
                            isEditable
                            fieldName="schedule"
                            setRecord={setRecord}
                            setComboboxSelection={setScheduleOptionsSelection}
                            setFieldErrors={setFieldErrors}
                            body={
                                <Combobox
                                    labels={{
                                        label: "How often should data be loaded?",
                                        placeholderReadOnly: "--Please Select--",
                                    }}
                                    options={scheduleOptions}
                                    selection={scheduleOptionsSelection}
                                    value={record?.schedule}
                                    variant="readonly"
                                />
                            }
                        />

                        {/* Placeholder (can put custom cron expression in the future ) */}
                        <FormItemWrapper></FormItemWrapper>
                    </div>
                </>
            )}

            {connectorType?.supportsConcurrency && (
                <div className="slds-form__row">
                    {/* Concurrency */}
                    <Field
                        mode={mode}
                        value={record?.concurrency}
                        label="Concurrency"
                        onEdit={onEdit}
                        fieldName="concurrency"
                        isEditable
                        setRecord={setRecord}
                        checkValidity={checkConcurrencyValue}
                        setFieldErrors={setFieldErrors}
                        body={
                            <Input
                                type="number"
                                name="concurrency"
                                label="Concurrency"
                                fieldLevelHelpTooltip={
                                    <Tooltip
                                        id="field-level-help-tooltip"
                                        align="top left"
                                        content="How many Objects may be loaded concurrently? Leave empty to disable concurrent loading, or set to 0 to use the maximum concurrency for your account."
                                    />
                                }
                                value={record?.concurrency ?? ""}
                                errorText={fieldErrors?.concurrency}
                            />
                        }
                    />
                    {/* Placeholder */}
                    <FormItemWrapper></FormItemWrapper>
                </div>
            )}
            {/* Authentication Details Modal Window */}
            {credentialsModalIsOpen && (
                <AuthDetailsModal
                    loading={loading}
                    connectOptions={connectOptions}
                    selectedConnectOption={selectedConnectOption}
                    selectedConnectMethod={selectedConnectMethod}
                    selectConnect={selectConnect}
                    setConnectProperty={setConnectProperty}
                    selectConnectMethodInput={selectConnectMethodInput}
                    handleCancelCredentials={handleCancelCredentials}
                    handleSaveCredentials={handleSaveCredentials}
                    connectSelection={connectSelection}
                    fieldErrors={fieldErrors}
                    setFieldErrors={setFieldErrors}
                    ref={authDetailsModalRef}
                />
            )}
        </div>
    );

    return (
        <>
            <PsRecord2
                recordLabel="Connector"
                recordModule="pump"
                recordObject={recordObject}
                record={record}
                defaultRecord={defaultRecord}
                showEdit
                showCardActions
                showDelete
                mode={mode}
                recordId={recordId}
                parentId={localParentId}
                propagateEvent={propagateEvent}
                setMode={setMode}
                onEdit={onEdit}
                setRecord={setRecord}
                setFieldErrors={setFieldErrors}
                loading={loading}
                setLoading={setLoading}
                childToParent={childToParent}
                parseResponse={parseResponse}
                parseCreateRequest={parseCreateRequest}
                parseUpdateRequest={parseUpdateRequest}
                parseInputToEncrypt={parseInputToEncrypt}
                setParent={setParent}
                updateUI={updateUI}
                cardBody={cardBody}
                cardActions={cardActions}
            />
            {showDeleteConfirmDialog ? (
                <Modal
                    apply={() => clearData()}
                    cancel={() => setShowDeleteConfirmDialog(false)}
                    header="Confirmation"
                    modalContent="Are you sure you want to delete all data from this Connector?"
                    applyButtonContent="Delete"
                />
            ) : null}
        </>
    );
};

export default PsConnector2;
