import React from "react";
import { Button } from "@salesforce/design-system-react";

interface Props {
    handleRefresh: () => void;
    loading: boolean;
}

const ConnectorRunning: React.FC<Props> = ({ handleRefresh, loading }) => {
    return (
        <>
            <h3 className="slds-section-title--divider slds-m-top_medium">Connector Running</h3>
            <div className="message">
                <p className="slds-p-bottom_x-small">The Connector is running.</p>
                <p className="slds-p-bottom_x-small">Use the "Check Status" or the refresh buttons to check progress.</p>
                <Button label="Check Status" title="Check Status" onClick={() => handleRefresh()} disabled={loading} variant="outline-brand" />
            </div>
        </>
    );
};

export default ConnectorRunning;
