import { formattedDateTime } from ".";
import { FIELD_ERROR_MESSAGES } from "../constants";
import { ActionResponseType, ConnectorTypeOption, FilterOption, LinkAndChainInputType, SourceOption } from "../types";

type SourceType = {
    id?: string;
    name?: string;
};

type ContainerType = {
    id?: string;
    name?: string;
    source?: SourceType;
};

type RecordType = {
    id?: string;
    name?: string;
    container?: ContainerType;
    leftContainer?: ContainerType;
    source?: SourceType;
};

type BreadCrumbType = {
    id?: string;
    name?: string;
}[];

type SortableObject = {
    [key: string]: any;
};

export const selectLoadedOption = (optionsAttribute: FilterOption[] | SourceOption[] | ConnectorTypeOption[], selectedValueAttribute: string, setDefault = false) => {
    let options = optionsAttribute || [];
    let selectedValue = selectedValueAttribute;

    // clear the selectedValue if it is not in the options
    if (selectedValue && !options.find((i) => i.value === selectedValue)) {
        selectedValue = null;
    }

    // if there is only one item in the options, select it
    if (options.length === 1) {
        selectedValue = options[0].value;
    }

    // no option was selected, see if there is a default option, and select it
    if (!selectedValue && setDefault) {
        selectedValue = options.find((i) => i.default)?.value;
    }

    // mark the relevant item as selected in the options items
    options.forEach((i) => {
        i.selected = i.value === selectedValue;
    });
    const selectedItem = options.find((i) => i.value === selectedValue) || { label: "" };

    const selectedItemLabel = selectedItem.label;

    return { selectedValue, options, selectedItemLabel };
};

export const getTitle = (breadcrumb: BreadCrumbType) => {
    return breadcrumb
        .map((item) => item.name)
        .filter((item) => item != null)
        .join(" -> ");
};

// Type for the function
export const getBreadCrumb = (object: "key" | "chain" | "container", record: RecordType): BreadCrumbType => {
    let container: ContainerType = {};
    let source: SourceType = {};

    if (object === "key") {
        container = record.container || {};
        source = container.source || {};
        return [
            { id: source.id, name: source.name },
            { id: container.id, name: container.name },
            { id: record.id, name: record.name },
        ];
    } else if (object === "chain") {
        container = record.leftContainer || {};
        source = container.source || {};
        return [
            { id: source.id, name: source.name },
            { id: container.id, name: container.name },
            { id: record.id, name: record.name },
        ];
    } else if (object === "container") {
        source = record.source || {};
        return [
            { id: source.id, name: source.name },
            { id: record.id, name: record.name },
        ];
    }

    return [];
};

export const checkRequiredField = (value: string) => {
    let errorMessage = value ? "" : FIELD_ERROR_MESSAGES.GENERAL_REQUIRED_FIELD;
    return errorMessage;
};

export const checkRequiredCombobox = (value: string) => {
    let errorMessage = value && value !== "--None--" && value !== "Not Available" ? "" : FIELD_ERROR_MESSAGES.GENERAL_REQUIRED_FIELD;
    return errorMessage;
};

export const checkMinDateAmountValidity = (value: string, minDateUnit: string) => {
    let required = minDateUnit && minDateUnit !== "--None--";
    let newValue = required ? value : "notRequired";
    const minDateAmountMessage = newValue && newValue !== "--None--" ? "" : FIELD_ERROR_MESSAGES.GENERAL_REQUIRED_FIELD;

    required = !!value;
    newValue = required ? minDateUnit : "notRequired";
    const minDateUnitMessage = newValue && newValue !== "--None--" ? "" : FIELD_ERROR_MESSAGES.GENERAL_REQUIRED_FIELD;

    return { minDateAmountMessage, minDateUnitMessage };
};

export const checkMaxDateAmountValidity = (value: string, maxDateUnit: string) => {
    let required = maxDateUnit && maxDateUnit !== "--None--";
    let newValue = required ? value : "notRequired";
    const maxDateAmountMessage = newValue && newValue !== "--None--" ? "" : FIELD_ERROR_MESSAGES.GENERAL_REQUIRED_FIELD;

    required = !!value;
    newValue = required ? maxDateUnit : "notRequired";
    const maxDateUnitMessage = newValue && newValue !== "--None--" ? "" : FIELD_ERROR_MESSAGES.GENERAL_REQUIRED_FIELD;

    return { maxDateAmountMessage, maxDateUnitMessage };
};

export const checkMinDateUnitValidity = (value: string, minDateAmount: string) => {
    let required = !!minDateAmount;
    let newValue = !value || value === "--None--" ? "" : value;
    newValue = required ? newValue : "notRequired";
    const minDateUnitMessage = newValue && newValue !== "--None--" ? "" : FIELD_ERROR_MESSAGES.GENERAL_REQUIRED_FIELD;

    required = value && value !== "--None--";
    newValue = required ? minDateAmount : "notRequired";
    const minDateAmountMessage = newValue && newValue !== "--None--" ? "" : FIELD_ERROR_MESSAGES.GENERAL_REQUIRED_FIELD;

    return { minDateAmountMessage, minDateUnitMessage };
};

export const checkMaxDateUnitValidity = (value: string, maxDateAmount: string) => {
    let required = !!maxDateAmount;
    let newValue = !value || value === "--None--" ? "" : value;
    newValue = required ? newValue : "notRequired";
    const maxDateUnitMessage = newValue && newValue !== "--None--" ? "" : FIELD_ERROR_MESSAGES.GENERAL_REQUIRED_FIELD;

    required = value && value !== "--None--";
    newValue = required ? maxDateAmount : "notRequired";
    const maxDateAmountMessage = newValue && newValue !== "--None--" ? "" : FIELD_ERROR_MESSAGES.GENERAL_REQUIRED_FIELD;

    return { maxDateAmountMessage, maxDateUnitMessage };
};

export const sortByString = (field: string, reverse: boolean) => {
    const dir = reverse ? -1 : 1;
    return function (a: SortableObject, b: SortableObject) {
        const aValue = a[field] as string;
        const bValue = b[field] as string;
        if (aValue == null) {
            return dir;
        }
        if (bValue == null) {
            return -1 * dir;
        }
        if (typeof aValue !== "string" || typeof bValue !== "string") {
            throw new Error(`Values for field '${field}' are not strings`);
        }
        return dir * aValue.toLowerCase().localeCompare(bValue.toLowerCase());
    };
};

export const sortByOther = (field: string, reverse: boolean) => {
    const dir = reverse ? -1 : 1;
    return function (a: SortableObject, b: SortableObject) {
        const aValue = a[field] as number;
        const bValue = b[field] as number;

        return dir * (aValue > bValue ? 1 : aValue < bValue ? -1 : 0);
    };
};

// returns the sort function for the data type
export const sortFunction = (records: LinkAndChainInputType[], field: string) => {
    if (Array.isArray(records) && records.length && typeof records[0][field] === "string") {
        return sortByString;
    } else {
        return sortByOther;
    }
};

// very basic cron to human-readable conversion, assuming expression is filled from left to right
export const cronToHuman = (cron: string): string => {
    if (!cron) {
        return "";
    }
    const cronParts = ["Every Minute", "Hourly", "Daily", "Monthly", "Weekly", "Yearly"];
    const cronSplit = cron.split(" ");
    if (!["?", "*"].includes(cronSplit[4])) {
        return cronParts[4]; // Weekly
    } else {
        return cronParts[cronSplit.indexOf("*")] || "";
    }
};

interface CronMap {
    [key: string]: string | ((min: number, max: number) => string);
}

const CRON_MAP: CronMap = {
    "Every Minute": "* * * * ? *",
    Hourly: (min: number, max: number) => `${getRandomInt(min, max)} * * * ? *`,
    Daily: (min: number, max: number) => `${getRandomInt(min, max)} ${getRandomInt(0, 23)} * * ? *`,
    Monthly: (min: number, max: number) => `${getRandomInt(min, max)} ${getRandomInt(0, 23)} ${getRandomInt(1, 28)} * ? *`,
    Weekly: (min: number, max: number) => `${getRandomInt(min, max)} ${getRandomInt(0, 23)} ? * ${getRandomInt(0, 6)} *`,
    Yearly: (min: number, max: number) => `${getRandomInt(min, max)} ${getRandomInt(0, 23)} ${getRandomInt(1, 28)} ${getRandomInt(1, 12)} ? *`,
};

const getRandomInt = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1)) + min;

export const humanToCron = (human: string): string => {
    if (!human || human === "--None--") {
        return "";
    }
    const cronExpression = CRON_MAP[human];
    if (typeof cronExpression === "function") {
        return cronExpression(0, 59);
    }
    return cronExpression;
};
export function nameFromDetails(section: string | null, config: string | null, id: string | null): string {
    return [section, config, id].map((v) => v ?? "").join("_");
}

export const flatten = (record: { [key: string]: any }, attrib: string): any[] => {
    // flatten Object inputs on the specified attribute
    const flat = (record[attrib] || []).slice(); // shallow copy
    let i = 0;
    while (i < flat.length) {
        if (Array.isArray(flat[i][attrib])) {
            flat.push(...flat[i][attrib]);
        }
        i++;
    }
    return flat;
};

export const toCSV = (list: string[]): string | null => {
    if (!list) {
        return null;
    }
    const results = list.reduce((lst, item) => {
        if (item.includes(",")) {
            // put quotes around items with commas inside
            item = `"${item}"`;
        } else {
            // trim quotes from items with no commas inside
            item = item.replace(/^"+|"+$/g, "");
        }
        lst.push(item);
        return lst;
    }, []);
    return results.join(", ");
};

export function areArraysEqual(arr1: string[], arr2: string[]): boolean {
    const normalizeArray = (arr: string[]) => arr.filter((item) => item !== "").sort();
    const sortedArr1 = normalizeArray(arr1);
    const sortedArr2 = normalizeArray(arr2);
    if (sortedArr1.length !== sortedArr2.length) return false;
    return sortedArr1.every((value, index) => value === sortedArr2[index]);
}
