import React from "react";
import { Icon } from "@salesforce/design-system-react";

interface CheckAndCloseIconsProps {
    selectedItem: boolean;
}

const CheckAndCloseIcons: React.FC<CheckAndCloseIconsProps> = ({ selectedItem }) => {
    return selectedItem ? (
        <Icon assistiveText={{ label: "Yes" }} category="utility" name="check" size="x-small" title="Yes" />
    ) : (
        <Icon assistiveText={{ label: "No" }} category="utility" name="close" size="x-small" title="No" />
    );
};

export default CheckAndCloseIcons;
