import React, { useState } from "react";
import PsRecordGrid from "../ps-record-grid/PsRecordGrid";
import { RECORD_COLUMNS } from "./constants";
import { ActionResponseType } from "../../types";
import { formattedDateTime, formattedNumber } from "../../utils";

// This it will removed when PsRecordGrid file convert to tsx (just to avoid typescript error)
const missingRecordGridProps = {
    useLayout: undefined,
    layout: undefined,
    gridComponent: undefined,
    gridItemClass: undefined,
    title: undefined,
    header: undefined,
    emptyLine: undefined,
    emptyCallToAction: undefined,
    viewOptions: undefined,
    changeView: undefined,
    footer: undefined,
    showEdit: undefined,
    onSave: undefined,
    onCancel: undefined,
    showDelete: undefined,
    onDeleteClick: undefined,
    onRecordRowAction: undefined,
    cardActions: undefined,
    setEmpty: undefined,
    usePutAPI: undefined,
};
const PsActionList = ({ parentId = "", queryFilter = {}, childToParent, propagateEvent }) => {
    const [mode, setMode] = useState("init");
    const [view, setView] = useState("table");
    const [orderBy, setOrderBy] = useState("createdOn");
    const [orderDirection, setOrderDirection] = useState("desc");
    const [hasMore, setHasMore] = useState(false);
    const [loading, setLoading] = useState(false);

    const parseResponse = (response: ActionResponseType[]) => {
        return response.map((item) => {
            const isRunning = item.runStatus === "Running";
            const isTimeout = Date.parse(item.runTimeout) < Date.now();

            return {
                id: item.id,
                name: item.name,
                origin: item.origin,
                runStatus: isRunning && isTimeout ? "Timeout" : item.runStatus,
                runEnd: formattedDateTime(isTimeout ? item.runTimeout : item.runEnd),
                usedCredit: formattedNumber(item.usedCredit),
                parameters: item.parameters ? JSON.stringify(item.parameters) : "",
                runMessage: item.runMessage || "",
                createdOn: formattedDateTime(item.createdOn),
            };
        });
    };

    return (
        <PsRecordGrid
            {...missingRecordGridProps}
            showCreate={false}
            module="core"
            object="action"
            queryFilter={queryFilter}
            showTitle
            tagLine="Review compute Action logs."
            recordLabel="Action"
            recordLabelPlural="Actions"
            parseResponse={parseResponse}
            parentId={parentId}
            mode={mode}
            onModeChange={(value: string) => setMode(value)}
            view={view}
            onViewChange={setView}
            orderBy={orderBy}
            onOrderByChange={(value: string) => setOrderBy(value)}
            orderDirection={orderDirection}
            onOrderDirectionChange={(value: string) => setOrderDirection(value)}
            loading={loading}
            onLoadingChange={(value: boolean) => setLoading(value)}
            showLoadMore
            hasMore={hasMore}
            onHasMoreChange={setHasMore}
            recordColumns={RECORD_COLUMNS}
            maxRecords={100}
            childToParent={childToParent}
            propagateEvent={propagateEvent}
        />
    );
};
export default PsActionList;
